import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-other-messages',
  templateUrl: './other-messages.component.html',
  styleUrls: ['./other-messages.component.scss']
})
export class OtherMessagesComponent implements OnInit {

  displayedOtherColumns: string[] = ['MessageId', 'FromDate', 'Subject', 'FreeText'];
  @Input() OtherDataSource: any;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor() { }

  ngOnInit() {
    this.OtherDataSource.sort = this.sort;
  }

}
