<div *ngIf="OtherDataSource">
  <table mat-table [dataSource]="OtherDataSource" matSort class="mat-elevation-z8">

    <!-- MessageId Column -->
    <ng-container matColumnDef="MessageId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'NO.' | translate}} </th>
      <td mat-cell *matCellDef="let element"> {{element.MessageId}} </td>
    </ng-container>
  
    <!-- FromDate Column -->
    <ng-container matColumnDef="FromDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'FROMDATE' | translate}} </th>
      <td mat-cell *matCellDef="let element"> {{element.FromDate}} </td>
    </ng-container>
  
    <!-- Subject Column -->
    <ng-container matColumnDef="Subject">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'SUBJECT' | translate}} </th>
      <td mat-cell *matCellDef="let element"> {{element.Subject}} </td>
    </ng-container>
    
    <!-- FreeText Column -->
    <ng-container matColumnDef="FreeText">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'TEXT'| translate}} </th>
      <td mat-cell *matCellDef="let element"> {{element.Text}} </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedOtherColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedOtherColumns;"></tr>
  </table>
</div>
