import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StorageService } from '../../../shared/services/storage/storage.service';
import { CommonService } from '../../../shared/services/common/common.service';

@Component({
  selector: 'app-contact-us-send-email-dialog',
  templateUrl: './contact-us-send-email-dialog.component.html',
  styleUrls: ['./contact-us-send-email-dialog.component.scss']
})
export class ContactUsSendEmailDialogComponent implements OnInit {

  title: string;
  contactUsForm: FormGroup;
  contactUsData;

  constructor(public dialogRef: MatDialogRef<ContactUsSendEmailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private commonService: CommonService,
    private storageService: StorageService,
    private fb: FormBuilder) {
    this.title = data.title;
    this.contactUsData = data;
  }

  ngOnInit(): void {
    let userInformation = this.storageService.get('userInformation');
    this.contactUsForm = this.fb.group({
      Email: [userInformation && userInformation.Email ? userInformation.Email: '', [Validators.required, this.commonService.validateEmail()]],
      TelephoneNumber: [userInformation && userInformation.TelephoneNumber ? userInformation.TelephoneNumber: '', [Validators.required, this.commonService.validateNumber()]],
      MobileNumber: [userInformation && userInformation.MobileNumber ? userInformation.MobileNumber: '', [Validators.required, this.commonService.validateNumber()]],
      Subject: ['', Validators.required],
      Description: ['', Validators.required],
      selectedOptionId: [this.data && this.data.selectedRouteInfo ? this.data.selectedRouteInfo.RouteId: ''],
      SelectedRouteInformation: [ this.data && this.data.selectedRouteInfo ? this.data.selectedRouteInfo : ''],
      SelectedVehicleInformation: [ this.data && this.data.selectedVehicleInfo ? this.data.selectedVehicleInfo : '']
    });
  }

  onDismiss() {
    this.dialogRef.close(false);
  }

  onConfirm() {
    this.dialogRef.close({
      isDialogClose: true,
      data: this.contactUsForm.value
    });
  }
}
