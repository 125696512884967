import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '../../../shared/services/common/common.service';
import { StorageService } from '../../../shared/services/storage/storage.service';
import { AppConstants } from '../../../app.constants';
import { UpsertBusinessPartnerRequest } from '../../../information/models/vehicle-information/upsert-business-partner-request.model';
import { DriverService } from '../../services/driver/driver.service';
import { MasterDataService } from '../../../shared/services/master-data/master-data.service';

@Component({
  selector: 'app-add-business-partner',
  templateUrl: './add-business-partner.component.html',
  styleUrls: ['./add-business-partner.component.scss']
})
export class AddBusinessPartnerComponent implements OnInit {

	addBusinessPartnerForm: FormGroup;
  addressFormArray: FormArray;
  addressText = '';
  editFlow = false;
  addressTypes;
  title: string;
  countries;
  states;
  cities;
  EuropeCountries= [];
  language;
  SelectedBusinessPartnerAddress;

  savedBusinessDetails;

  enableNewBusinessPartnerAddress = false;

  @Output() addBusinessPartnerFormEventEmit = new EventEmitter<any>();

  constructor(private formBuilder: FormBuilder,
    private driverService: DriverService,
    private masterDataService: MasterDataService,
    private translateService: TranslateService,
    private storageService: StorageService,
    private commonService: CommonService,
    private snackBar: MatSnackBar) { 

  }

  ngOnInit(): void {
    this.EuropeCountries = AppConstants.Europe_Countries;
    this.addBusinessPartnerForm = this.formBuilder.group({
			firstname: ['', [Validators.required, this.commonService.validateAlphabetString()]],
			lastname: ['', [Validators.required, this.commonService.validateAlphabetString()]],
      companyname: [''],
      BuinessPartnerAddress: this.formBuilder.array([])
		});

    this.getAddressTypes();
    this.getCountriesAndStates();
    this.initAddresses();
  }

  get Addresses() {
    let address: any = this.addBusinessPartnerForm.get('BuinessPartnerAddress');
    return address.controls as FormArray;
  }

  addAddress() {
    if (this.Addresses && this.Addresses.length === 0) {
      this.initAddresses();
      this.getCountriesAndStates();
    }
  }

  onDeleteAddress(index) {
    for (let i = 0; i <= this.Addresses.length; i++) {
      if (i === index) {
        let address = this.addBusinessPartnerForm.controls.BuinessPartnerAddress;
        // this.Addresses.removeAt(i);
        const control = <FormArray>this.addBusinessPartnerForm.controls['BuinessPartnerAddress'];
        control.removeAt(i);
        console.log(address);
      }
    }
  }

  onSaveAddress(index) {
    let BuinessPartnerAddress: any = this.addBusinessPartnerForm.controls.BuinessPartnerAddress;
    if (BuinessPartnerAddress.controls[index].controls.BusinessPartnerAddress.value && BuinessPartnerAddress.controls[index].controls.BusinessPartnerAddress.value !== 0) {
      this.addBusinessPartner(index);
    }
    /*if (BuinessPartnerAddress
      && BuinessPartnerAddress.controls[index].controls.cityId.value && BuinessPartnerAddress.controls[index].controls.cityId.value !== 0
      && BuinessPartnerAddress.controls[index].controls.countryId.value && BuinessPartnerAddress.controls[index].controls.countryId.value !== 0
      && BuinessPartnerAddress.controls[index].controls.federalStateId.value && BuinessPartnerAddress.controls[index].controls.federalStateId.value !== 0
      && BuinessPartnerAddress.controls[index].controls.houseNo.value && BuinessPartnerAddress.controls[index].controls.houseNo.value !== ''
      && BuinessPartnerAddress.controls[index].controls.street.value && BuinessPartnerAddress.controls[index].controls.street.value !== '') {
      this.addBusinessPartner(index);
    }*/
  }

  initAddresses() {
    let address = this.formBuilder.group({
      houseNo: ['', Validators.required],
      street: ['', Validators.required],
      countryId: ['', Validators.required],
      countryName: ['', Validators.required],
      federalStateId: ['', Validators.required],
      federalStateName: ['', Validators.required],
      cityId: ['', Validators.required],
      cityName: ['', Validators.required],
      addressTypeId: [''],
      BusinessPartnerAddress: ['']
    });
    this.Addresses.push(address);
  }

  getCountriesAndStates() {
    this.masterDataService.getCountriesandStates().subscribe((response) => {
      this.countries = response;
      this.storageService.set('Countries', this.countries);
      let selectedCountry = this.storageService.get('SelectedCountry');
      if (selectedCountry) {
        let buinessPartnerAddressList: any = this.addBusinessPartnerForm.controls.BuinessPartnerAddress;
        if (buinessPartnerAddressList && buinessPartnerAddressList.controls && buinessPartnerAddressList.controls.length > 0) {
          for (let i=0; i < buinessPartnerAddressList.controls.length; i++) {
            buinessPartnerAddressList.controls[i].controls.countryId.setValue(selectedCountry.country.ID);
          }
        }
      }
    }, (error) => {
      console.log(error);
    });
  }

  selectedCountry(countryId, index, isDefault) {
    this.countries.forEach(country => {
      if (country.ID === countryId) {
        this.Addresses[index].controls.countryName.patchValue(country.Name);
        if (!isDefault) {
          this.Addresses[index].controls.federalStateId.patchValue('');
          this.Addresses[index].controls.federalStateName.patchValue('');
          this.Addresses[index].controls.cityId.patchValue('');
          this.Addresses[index].controls.cityName.patchValue('');
        }
      }
    });
    this.getStates(countryId);
  }

  getStates(countryId) {
    this.countries.forEach(country => {
      if (country.ID === countryId) {
        this.states = country.States;
        localStorage.setItem('States', JSON.stringify(country.States));
        this.cities = [];
      }
    });
  }

  selectedState(stateId, index, isDefault) {
    this.states.forEach(state => {
      if (state.ID === stateId) {
        this.Addresses[index].controls.federalStateName.setValue(state.Name);
        if (!isDefault) {
          this.Addresses[index].controls.cityId.setValue('');
          this.Addresses[index].controls.cityName.setValue('');
        }
      }
    });
    this.getCities(stateId);
  }
  
  selectedCity(cityId, index) {
    this.cities.forEach(city => {
      if (city.ID === cityId) {
        this.Addresses[index].controls.cityName.setValue(city.Name);
      }
    });
    console.log(this.Addresses[index]);
  }

  getCities(state) {
    const id = state && state.ID ? state.ID: state;
    this.masterDataService.getCities(id).subscribe((response) => {
      this.cities = response;
      localStorage.setItem('Cities', JSON.stringify(response));
    }, (error) => {
      console.log(error);
    });
  }

  getAddressTypes() {
    this.masterDataService.getAddressTypes().subscribe((response) => {
      this.addressTypes = response;
    }, (error) => {
      console.log(error);
    });
  }

  getAddressType() {
    if (this.addressTypes) {
      return this.addressTypes[1].Name;
    } else {
      return '';
    }
  }

  selectedAddressType(addressType) {
    console.log(addressType);
  }

  getErrorFirstname() {
    return this.addBusinessPartnerForm.get('firstname').hasError('required') ? this.translateService.instant('FIRSTNAMEREQUIRED'): '';
  }

  getErrorLastname() {
    return this.addBusinessPartnerForm.get('lastname').hasError('required') ? this.translateService.instant('LASTNAMEREQUIRED'): '';
  }

  onChangeFirstname() {
    // this.addBusinessPartnerFormEventEmit.emit(this.addBusinessPartnerForm.value);
  }

  onChangeLastname() {
    // this.addBusinessPartnerFormEventEmit.emit(this.addBusinessPartnerForm.value);
  }

  onChangeCompanyName() {
    // this.addBusinessPartnerFormEventEmit.emit(this.addBusinessPartnerForm.value);
  }

  onSaveBusinessPartner() {
    this.enableNewBusinessPartnerAddress = true;
    this.getCountriesAndStates();
  }

  addBusinessPartner(index) {
    let options: UpsertBusinessPartnerRequest = {
      FirstName: this.addBusinessPartnerForm.value.firstname,
      LastName: this.addBusinessPartnerForm.value.lastname,
      CompanyName: this.addBusinessPartnerForm.value.companyname
    }
    this.upsertBusinessPartner(options, index);
  }

  updateBusinessPartnerAddress(index) {
    console.log(index, this.Addresses[index].value.BusinessPartnerAddress);
    let options = {
      BusinessPartnerId: this.savedBusinessDetails.businessPartnerId,
      CityId: this.Addresses[index].value.cityId,
      CountryId: this.Addresses[index].value.countryId,
      FederalStateId: this.Addresses[index].value.federalStateId,
      Street: this.SelectedBusinessPartnerAddress.streetName, // this.Addresses[index].value.street,
      HouseNumber: this.SelectedBusinessPartnerAddress.streetNumber// this.Addresses[index].value.houseNo
    };
    this.driverService.upsertBusinessPartnerAddress(options).subscribe((response: any) => {
      if (response.status === 400) {
        this.snackBar.open(response.message, this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
        this.deleteBusinessPartner();
      } else {
        this.addBusinessPartnerFormEventEmit.emit(this.savedBusinessDetails);
      }
      if (response && response.message && response.status === 200) {
        this.snackBar.open(response.message, this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
        this.onDeleteAddress(index);
      }
    }, (error) => {
      this.deleteBusinessPartner();
      this.snackBar.open(error.message, this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
    });
  }

  upsertBusinessPartner(data: UpsertBusinessPartnerRequest, index) {
    this.driverService.upsertBusinessPartner(data).subscribe((response: any) => {
      // console.log(response);
      if (response && response.message && response.status === 200) {
        this.snackBar.open(response.message, this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
      }
      if (response && response.status === 400) {
        return;
      }
      this.savedBusinessDetails = response;
      this.savedBusinessDetails.Addresses = this.Addresses;
      this.updateBusinessPartnerAddress(index);
    }, (error: any) => {
      console.log(error);
      // this.snackBar.open(error.message, this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
    });
  }

  deleteBusinessPartner() {
    // console.log('The dialog was closed', result);
    this.driverService.deleteBusinessPartner(this.savedBusinessDetails.businessPartnerId).subscribe((response: any) => {
      // this.addBusinessPartnerFormEventEmit.emit(this.savedBusinessDetails);
      // this.snackBar.open(response.message, this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
    }, (error) => {
      this.snackBar.open(error.message, this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
    });
  }

  onCountryClick(country) {
    if (country && country.name) {
      this.language = [];
      this.language.push(country.code.toLowerCase());
    }
  }

  getLanguages() {
    let language = [];
    this.EuropeCountries.forEach((country) => {
      language.push(country.code.toLowerCase());
    });
    this.language = language;
  }

  onBusinessPartnerAddressAutocompleteSelected(event, index) {
    console.log(event);
  }

  onBusinessPartnerAddressLocationSelected(event, index) {
    console.log(event);
  }

  onGermanBusinessPartnerAddressMapped(event, index) {
    console.log(event, index);
    this.SelectedBusinessPartnerAddress = event;
    let countryName;
    countryName = (this.SelectedBusinessPartnerAddress.country.long.toLocaleLowerCase() === 'germany') ? 'Deutschland': this.SelectedBusinessPartnerAddress.country.long;
    let country = this.commonService.getCountryORStateORCityDetails(countryName, this.countries);
    this.Addresses[index].controls.countryId.setValue(country[0].ID);
    this.states = country[0].States;
    let state = this.commonService.getCountryORStateORCityDetails(this.SelectedBusinessPartnerAddress.state.long, this.states);
    this.Addresses[index].controls.federalStateId.setValue(state[0].ID);
    this.getCitiesData(state[0], index);
  }

  getCitiesData(state, index) {
    const id = state && state.ID ? state.ID: state;
    this.masterDataService.getCities(id).subscribe((response) => {
      this.cities = response;
      localStorage.setItem('Cities', JSON.stringify(response));
      let city = this.commonService.getCountryORStateORCityDetails(this.SelectedBusinessPartnerAddress.locality.long, this.cities);
      this.Addresses[index].controls.cityId.setValue(city[0].ID);
    }, (error) => {
      console.log(error);
    });
  }

}
