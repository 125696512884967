import { Component, OnInit, Input, Output, EventEmitter, Renderer2 } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DriverService } from '../../services/driver/driver.service';
import { CommonService } from '../../../shared/services/common/common.service';
import { StorageService } from '../../../shared/services/storage/storage.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { EditVehicleTripComponent } from '../../../dashboard/components/edit-vehicle-trip/edit-vehicle-trip.component';
import { SeparateVehicleRouteComponent } from '../../../shared/components/separate-vehicle-route/separate-vehicle-route.component';

@Component({
  selector: 'app-vehicle-route-detail',
  templateUrl: './vehicle-route-detail.component.html',
  styleUrls: ['./vehicle-route-detail.component.scss']
})
export class VehicleRouteDetailComponent implements OnInit {

	@Input() hiddenRightSectionCard: boolean;
	@Input() selectedRightSectionOption: string;
	@Input() selectedVehicleInfo: any;
	@Input() mapDetail: any;

	@Output() edit = new EventEmitter();
	@Output() enableCombineButtonClick = new EventEmitter();
	@Output() tripsCombineEmitter = new EventEmitter();
	@Output() tripSeparateEmitter = new EventEmitter();

	@Output() rightSideNavCloseClickedEmitter = new EventEmitter();

	selectedVehicleRouteInfo;

	public lat;
	public lng;

	public origin: any;
	public destination: any;

	combine = false;
	isAdminUser = false;

	constructor(private render: Renderer2,
		private storageService: StorageService,
		private translateService: TranslateService,
		private matDialog: MatDialog,
		private commonService: CommonService,
		public  dialogEditRef: MatDialogRef<EditVehicleTripComponent>,
		private driverService: DriverService) { }

	ngOnInit() {
		let UserInfo = this.storageService.get('userInfo');
		// console.log(UserInfo.UserRole);
		this.isAdminUser = UserInfo.UserRole && UserInfo.UserRole === 'Admin' ? true: false;
		window.addEventListener('scroll', (e) => {
			const elem: any = document.getElementById('right-section-card');
			if (window.innerWidth > 600 && elem) {
				if (window.scrollY >= 107) {
					this.render.addClass(elem, 'fixed');
				} else if (window.scrollY < 107) {
					this.render.removeClass(elem, 'fixed');
				}
			}
		});
	}

	get isDisableField() {
		let result = true;
		if (this.isAdminUser) {
			result = false;
		} else if (!this.selectedVehicleInfo?.UpdateExpired) {
			result = false;
		}
		return result;
	}

	toEdit(data) {
		// this.edit.emit(data);
		this.openEditVehicleTripComponent(data);
	}

	removRoute(selectedVehicleRouteInfo) {
		this.removeRoute(selectedVehicleRouteInfo);
	}

	removeRoute(selectedVehicleRouteInfo) {
		// console.log(selectedVehicleRouteInfo);
		this.rightSideNavCloseClicked();
		this.selectedVehicleRouteInfo = selectedVehicleRouteInfo;
		this.confirmDeleteRouteDialog();
	}

	confirmDeleteRouteDialog(): void {
		window.scroll(0, 0);
		const message = this.translateService.instant('DELETEROUTECONFIRMATIONMESSAGE');
		const dialogData = {
			title: this.translateService.instant('DELETEROUTECONFIRMATION'),
			message: message
		};
		const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
			maxWidth: '130vmin',
			data: dialogData
		});

		dialogRef.afterClosed().subscribe(dialogResult => {
		  	if (dialogResult) {
				// console.log(dialogResult, this.selectedVehicleRouteInfo);
				const options = {
					RouteId: this.selectedVehicleRouteInfo.RouteId,
					data: {
						routeTypeId: this.selectedVehicleRouteInfo.VehicleRouteTypeId || '',
						startAddress: this.selectedVehicleRouteInfo.StartAddress || '',
						endAddress: this.selectedVehicleRouteInfo.EndAddress || '',
						additionalInformation: this.selectedVehicleRouteInfo.AdditionalInformation || '',
						startMileage: this.selectedVehicleRouteInfo.StartMileage || '',
						endMileage: this.selectedVehicleRouteInfo.EndMileage || '',
						startTime: this.selectedVehicleRouteInfo.StartTime || '',
						endTime: this.selectedVehicleRouteInfo.EndTime || '',
						date: this.selectedVehicleRouteInfo.Date || '',
						vehicleId: this.selectedVehicleRouteInfo.VehicleId || '',
					}
				};
				// const selectedVehicle = localStorage.getItem('selectedVehicle');
				const selectedVehicle = this.storageService.get('selectedVehicle');
				if (selectedVehicle) {
					options.data.vehicleId = selectedVehicle.VehicleId;
				}
				this.driverService.deleteVehicleRoute(options).subscribe((response) => {
					// console.log(response);
					if (response.status === 200) {
						this.commonService.setVehicleTripsRefreshed(true);
					}
				});
		  	}
		});
	}

	separateTripBtnClick() {
		if (this.selectedVehicleInfo) {
			// selected vehicle route list to separate the trip
			// console.log(this.selectedVehicle.Routes);
			this.openSeparateTrip(this.selectedVehicleInfo);
		}
	}

	openSeparateTrip(data) {
		this.rightSideNavCloseClicked();
		const dialogConfig = new MatDialogConfig();
		dialogConfig.data = data;
		dialogConfig.disableClose = true;
		window.scrollTo(0, 0);
		let dialogRef = this.matDialog.open(SeparateVehicleRouteComponent, dialogConfig);
		dialogRef.afterClosed().subscribe(value => {
			if (value) {
				this.commonService.setVehicleTripsRefreshed(true);
			}
		});
	}

	rightSideNavCloseClicked() {
		this.rightSideNavCloseClickedEmitter.emit(true);
	}

	openEditVehicleTripComponent(data) {
		this.rightSideNavCloseClicked();
		const dialogConfig = new MatDialogConfig();
		dialogConfig.data = data ? data : '';
		dialogConfig.disableClose = true;
		dialogConfig.width = '400px';
		window.scrollTo(0, 0);
		this.dialogEditRef = this.matDialog.open(EditVehicleTripComponent, dialogConfig);
		this.dialogEditRef.afterClosed().subscribe(value => {
			// console.log(`Dialog sent: ${value}`);
			if (value && value.isClosedDialog) {
				this.commonService.setVehicleTripsRefreshed(true);
			}
		});
	}
}
