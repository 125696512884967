import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppConstants } from '../../../app.constants';
import * as FAQ_DE_DATA from '../../../../assets/FAQ_de.json';
import * as FAQ_EN_DATA from '../../../../assets/FAQ_en.json';
import { TranslateConfigService } from '../../services/translate/translate-config.service';

@Component({
  selector: 'app-docviewer',
  templateUrl: './docviewer.component.html',
  styleUrls: ['./docviewer.component.scss']
})
export class DocviewerComponent implements OnInit {

  // viewer = 'google';  
  // selectedType = 'docx';
  // @Input() selectedLanguage = 'en';
  // DemoDoc;
  step = 0;
  jsonData: any;
  currentlanguage;

  constructor(private translateService: TranslateService, private translateConfigService: TranslateConfigService) { }

  ngOnInit(): void {
    // this.DemoDoc = '../assets/FAQ_'+ this.selectedLanguage + '.docx';
    this.getJsonData(this.translateService.currentLang);
    this.translateConfigService.selectedLanguage$.subscribe((data) => {
      // console.log('language changed at FAQ page opened', data);
      this.getJsonData(data);
    });
  }

  getJsonData(data) {
    let result;
    this.currentlanguage = data;
    if (this.currentlanguage === AppConstants.prefferedLanguages.en) {
      result = FAQ_EN_DATA;
    } else if (this.currentlanguage === AppConstants.prefferedLanguages.de) {
      result = FAQ_DE_DATA;
    }
    this.jsonData = result.default;
    console.log('language based data:', this.jsonData);
  }

  setStep(index: number) {
    this.step = index;
  }

}
