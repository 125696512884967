<div class="vehicle-rides-section">
    <div class="summary-section">
        <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="space-between start">
            <p><b>{{'SUMMARY' | translate}}:</b></p>
            <button mat-raised-button color="primary" aria-label="Contact us" (click)="contactus()">
                {{'CONTACTUS' | translate}}
            </button>
        </div>

        <div fxLayout="row">
          <div fxFlex="50">
            <p>{{'TOTALDRIVENBUSINEESTRIPS'| translate}}: {{totalBusinessTripsDrivenKM}} KM</p>
            <p>{{'TOTALDRIVENPRIVATETRIPS'| translate}}: {{totalPrivateTripsDrivenKM}} KM</p>
          </div>

          <div fxFlex="50">
            <p>{{'TOTALDRIVENCOMMUTEHOME'| translate}}: {{totalHomeTripsDrivenKM}} KM</p>
            <p>{{'TOTALDRIVENCOMMUTEWORK'| translate}}: {{totalWorkTripsDrivenKM}} KM</p>
          </div>
        </div>
    </div>

    <div class="top-section">
        <div fxLayout="row" fxLayout.md="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="space-between center">
            <div fxFlex="49.5" fxFlex.md="55" fxFlex.sm="100" fxFlex.xs="100" class="box-section top-layer" fxLayout="column" fxLayoutAlign="none">
                <div fxLayout="column" fxLayout.sm="column" fxLayout.xs="column" class="box-section left-box-top-section">
                    <div fxLayout="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="space-between start">
                        <mat-label>
                            <p class="mat-card-title">{{ 'TRIPINFORMATION' | translate}}:</p>
                        </mat-label>
                        <div class="current-mileage-button margin-top-10" *ngIf="selectedVehicleCurrentMileage && selectedVehicleCurrentMileage !== '0'">
                            <button mat-raised-button>
                                {{'CURRENTMILEAGE' | translate}}: {{selectedVehicleCurrentMileage}}
                                <!-- temporary (click)="updateCurrentMileageInfo()"<mat-icon aria-hidden="false" aria-label="Current Mileage" color="primary">edit</mat-icon> -->
                            </button>
                        </div>
                    </div>
                    <form fxLayout="column" fxLayout.sm="column" fxLayout.xs="column" [formGroup]="dateForm">
                        <div fxLayout="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="space-between start">
                            <div class="start-date-section" fxFlex="50" fxFlex.sm="50" fxFlex.xs="100">
                                <mat-form-field class="start-date-form-field" appearance="outline" fxFlex="100">
                                    <mat-label>{{'STARTDATETRIPS' | translate}}</mat-label>
                                    <input matInput [matDatepicker]="startDate" formControlName="startDate">
                                    <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                                    <mat-datepicker #startDate color="primary"></mat-datepicker>
                                    <mat-error *ngIf="dateForm.get('startDate').hasError('required')">
                                        {{validationMessages.startDate.required}}
                                    </mat-error>
                                </mat-form-field>
                                <span class="material-icons-outlined start-date-info-button" id="STARTDATETOOLTIP" *ngIf="isNotMobileView" [matTooltip]="getToolTipInfo('STARTDATETOOLTIP')">info</span>
                                <span class="material-icons-outlined start-date-info-button" id="STARTDATETOOLTIP" *ngIf="!isNotMobileView" (click)="getToolTipInfo('STARTDATETOOLTIP')">info</span>
                            </div>
                            <mat-form-field class="end-date-form-field" appearance="outline" fxFlex="50" fxFlex.sm="50" fxFlex.xs="100">
                                <mat-label>{{'ENDDATETRIPS' | translate}}</mat-label>
                                <input matInput [matDatepicker]="endDate" [min]="dateForm.get('startDate').value" formControlName="endDate">
                                <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                                <mat-datepicker #endDate color="primary"></mat-datepicker>
                                <mat-error *ngIf="dateForm.get('endDate').hasError('required')">
                                    {{validationMessages.endDate.required}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center">
                            <div class="apply-button-section" fxFlex="50" fxFlex.sm="50" fxFlex.xs="100" [style.margin-bottom.px]="5" [style.height]="50" fxLayoutAlign="start">
                                <button [disabled]="dateForm.invalid" class="apply-button" mat-raised-button (click)="applyDateFilterOnDriverBookTrips()">{{'APPLY' | translate}}</button>
                                <span class="material-icons-outlined apply-info-button" id="APPLYTOOLTIP" *ngIf="isNotMobileView" [matTooltip]="getToolTipInfo('APPLYTOOLTIP')">info</span>
                                <span class="material-icons-outlined apply-info-button" id="APPLYTOOLTIP" *ngIf="!isNotMobileView" (click)="getToolTipInfo('APPLYTOOLTIP')">info</span>
                            </div>
                            <div class="apply-button-section" fxFlex="50" fxFlex.sm="50" fxFlex.xs="100" [style.height]="50" fxLayoutAlign="end end">
                            </div>
                        </div>
                    </form>
                </div>
                <div fxLayout="column" id="left-box-bottom-section" class="box-section left-box-bottom-section" fxLayout.sm="column" fxLayout.xs="column">
                    <mat-label><p class="mat-card-title">{{ 'SELECTEDRIDEPOSSIBILITIES' | translate }}:</p></mat-label>
                    <div fxLayout="row">
                        <div fxLayout="row" fxLayout.sm="row" fxLayout.xs="column" class="apply-button-section" [style.margin-bottom.px]="5" [style.height]="50" fxLayoutAlign="space-between center" fxLayoutAlign.xs="start" fxLayoutGap="10px" fxLayoutGap.sm="5px" fxLayoutGap.xs="5px">
                            <button mat-raised-button (click)="editTrip($event, selectedVehicleInfo)" [disabled]="isDisableButtonField">{{'TOEDIT' | translate }}</button>
                            <div>
                                <button class="apply-button" mat-raised-button (click)="enableCombineBtnClick()" *ngIf="!combine" [disabled]="isDisableButtonField">
                                    {{'ENABLECOMBINE' | translate }}
                                </button>
                                <button class="apply-button" mat-raised-button (click)="tripsCombine($event)" *ngIf="combine" [disabled]="isDisableButtonField">
                                    {{'TOCOMBINE' | translate }}
                                </button>
                                <span class="material-icons-outlined combine-info-button" id="ENABLECOMBINETOOLTIP" *ngIf="isNotMobileView" [matTooltip]="getToolTipInfo('ENABLECOMBINETOOLTIP')">info</span>
                                <span class="material-icons-outlined combine-info-button" id="ENABLECOMBINETOOLTIP" *ngIf="!isNotMobileView" (click)="getToolTipInfo('ENABLECOMBINETOOLTIP')">info</span>
                            </div>
                            <button mat-raised-button (click)="separateTripBtnClick()" [disabled]="isDisableButtonField">{{'SEPARATETRIP' | translate }}</button>
                            <button mat-raised-button (click)="removRoute(selectedVehicleInfo)" [disabled]="isDisableButtonField">{{'REMOVETRIP' | translate }}</button>
                        </div>
                    </div>
                </div>
            </div>
            <form fxFlex="49.5" fxFlex.md="44" fxFlex.sm="100" fxFlex.xs="100" class="box-section right-box-section" [formGroup]="vehicleForm" fxLayout="column">
                <mat-label>
                    <p class="mat-card-title">{{ 'CARINFORMATION' | translate}}:</p>
                </mat-label>
                <div fxLayout="column" fxLayout.sm="column" fxLayout.xs="column">
                    <div fxLayout="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="space-between start">
                        <div class="drivers-dropdown-section" fxFlex="50" *ngIf="userInfo.UserRole !== appConstants.userRole.driver">
                            <!--<mat-form-field style="width: 100%;" appearance="fill" class="vehicle-select-list">
                                <mat-label>{{ 'DRIVERS' | translate}}</mat-label>
                                <mat-select formControlName="driver" (selectionChange)="driverChange($event)">
                                    <mat-option *ngFor="let driver of driversSelectionList" [value]="driver.VehicleId">{{driver.Firstname}} {{driver.Lastname}} - {{ driver.PlateNumber}} {{ driver.VehicleBrand}}</mat-option>
                                </mat-select>
                            </mat-form-field>-->
                            <section fxLayout="row" fxLayoutGap="10px" class="example-section" [formGroup]="filterDriverVehicles">
                                <p><mat-checkbox formControlName="active">Active</mat-checkbox></p>
                                <p><mat-checkbox formControlName="inActive">In Active</mat-checkbox></p>
                            </section>
                            <mat-form-field style="width: 100%;" appearance="fill" class="example-full-width vehicle-select-list">
                                <mat-label>{{ 'DRIVERS' | translate}}</mat-label>
                                <input type="text"
                                    placeholder="Search driver name"
                                    aria-label="Search driver name"
                                    matInput
                                    formControlName="driver"
                                    (keypress)="driverChange($event)"
                                    (keyup.enter)="driverChangeKeyEnter($event)"
                                    [matAutocomplete]="auto">
                                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="selectedDriverOption($event.option.value)">
                                    <mat-option *ngFor="let driver of driversSelectionList" [value]="driver">
                                        {{driver.Firstname}} {{driver.Lastname}} - {{driver.PlateNumber? driver.PlateNumber: 'NA'}} {{driver.VehicleBrand? driver.VehicleBrand: ''}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>
                        <div class="vehicles-dropdown-section" *ngIf="userInfo.UserRole !== appConstants.userRole.admin" fxFlex="32" fxFlex.sm="40" fxFlex.xs="100">
                            <div fxLayout="row">
                                <mat-form-field appearance="fill" class="vehicle-select-list">
                                    <mat-label>{{ 'VEHICLES' | translate}}</mat-label>
                                    <mat-select formControlName="vehicle" (selectionChange)="vehicleChangeEvent(true)">
                                        <mat-option *ngFor="let vehicle of vehiclesSelectionList" [value]="vehicle.VehicleId">
                                            {{vehicle.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <span class="material-icons-outlined vehicle-info-button" id="VEHICLETOOTLTIP" *ngIf="isNotMobileView" [matTooltip]="getToolTipInfo('VEHICLETOOTLTIP')">info</span>
                                <span class="material-icons-outlined vehicle-info-button" id="VEHICLETOOTLTIP" *ngIf="!isNotMobileView" (click)="getToolTipInfo('VEHICLETOOTLTIP')">info</span>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="row" *ngIf="userInfo.UserRole !== appConstants.userRole.driver">
                        <div fxFlex="50" fxLayout="column" fxLayoutAlign="start start">
                            <b>{{'DRIVER'|translate}}:</b>
                            <p>{{vehicleForm.controls.driver.value.split('-')[0]? vehicleForm.controls.driver.value.split('-')[0]: 'NA'}}</p>
                        </div>
                        <div fxFlex="50" fxLayout="column" fxLayoutAlign="end end">
                            <b>{{'VEHICLE'|translate}}:</b>
                            <p>{{ vehicleForm.controls.driver.value.split('-')[1] ? vehicleForm.controls.driver.value.split('-')[1]: 'NA'}}</p>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="space-between end">
                        <div fxLayout="column" fxLayoutAlign="start start" fxFlex="32" fxFlex.sm="40" fxFlex.xs="45" *ngIf="vehicleStartTime && vehicleStartTime !== ''">
                            <p class="time-label">{{ 'STARTRUNNINGTIME' | translate}}:</p>
                            <p>{{vehicleStartTime}}</p>
                        </div>
                        <div fxLayout="column" fxLayoutAlign="end end" fxFlex="32" fxFlex.sm="40" fxFlex.xs="45" *ngIf="vehicleEndTime && vehicleEndTime !== ''">
                            <p class="time-label">{{ 'ENDRUNNINGTIME' | translate}}:</p>
                            <p>{{vehicleEndTime}}</p>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div>
        <div class="margin-bottom-10 create-trip-section" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="space-between start">
            <div fxFlex="35">
                <button mat-raised-button (click)="creatNewRoute()">
                    {{'CREATENEWTRIP' | translate}}
                    <mat-icon aria-hidden="false" aria-label="Create New Trip/Route" color="primary">add_circle</mat-icon>
                </button>
                <span class="material-icons-outlined create-new-info-button" id="CREATENEWROUTETOOLTIP" *ngIf="isNotMobileView" [matTooltip]="getToolTipInfo('CREATENEWROUTETOOLTIP')">info</span>
                <span class="material-icons-outlined create-new-info-button" id="CREATENEWROUTETOOLTIP" *ngIf="!isNotMobileView" (click)="getToolTipInfo('CREATENEWROUTETOOLTIP')">info</span>
            </div>

            <div class="expiry-button-section" fxFlex="65" fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="5px" style="width: 100%" *ngIf="userInfo.UserRole === appConstants.userRole.driver">
                <p>{{'EXPIRESIN'|translate}}:</p>

                <button mat-raised-button [matTooltip]="threeDaysExpireDescription" (click)="navigateTo3DaysSection()">
                    {{'3TAGS' | translate}}
                    <mat-icon aria-hidden="false" aria-label="" color="primary">taxi_alert</mat-icon>
                </button>
                <button mat-raised-button [matTooltip]="twoDaysExpireDescription" (click)="navigateTo2DaysSection()">
                    {{'2TAGS' | translate}}
                    <mat-icon aria-hidden="false" aria-label="" color="primary">taxi_alert</mat-icon>
                </button>
                <button mat-raised-button [matTooltip]="oneDayExpireDescription" (click)="navigateTo1DaysSection()">
                    {{'1TAGS' | translate}}
                    <mat-icon aria-hidden="false" aria-label="" color="primary">taxi_alert</mat-icon>
                </button>
            </div>
        </div>

    </div>
    <div class="box-section bottom-box-section" class="example-card left-section-card">
        <div *ngIf="selectedVehicle && selectedVehicle.Routes">
            <mat-selection-list #vehiclesInfo [@listAnimation]="selectedVehicle.Routes.length" [(ngModel)]="selectedOptionIds" (selectionChange)="onSelection($event)" [compareWith]="compareFunction">
                <div *ngFor="let route of selectedVehicle.Routes| reverse;let i=index;">
                    <div [id]="route.StartDate" class="selection-list" *ngIf="!route.IsDeleted">
                        <div class="subheader" fxLayout="row" fxLayoutAlign="space-between center">
                            <div class="subheader-section" fxLayout="row" fxLayout.sm="row" fxLayout.xs="column">
                                <span class="subheader-section-text">{{'FROMDATE' | translate}}:</span>
                                <span class="subheader-section-value">{{ route.StartDate }}</span>
                            </div>
                            <div class="subheader-section" fxLayout="row" fxLayout.sm="row" fxLayout.xs="column">
                                <span class="subheader-section-text">{{'TODATE' | translate}}:</span>
                                <span class="subheader-section-value">{{ route.EndDate }}</span>
                            </div>
                        </div>
                        <mat-list-option (click)="onSelectionOption(route)" checkboxPosition="before" [value]="route.RouteId">
                            <div fxLayout="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="none">
                                <div (click)="rightSideNavToggle()" fxFlex="50" fxFlex.sm="50" fxFlex.xs="100">
                                    <button class="button-icon" mat-icon-button>
                                        <mat-icon>home</mat-icon>
                                    </button>
                                    <div class="trip-section-1" mat-line fxLayout="column">
                                        <div class="trip-section-row-1" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
                                            <div class="login-time" fxFlex="10" fxFlex.sm="100" fxFlex.xs="100" fxLayoutAlign="start center">
                                                <span>{{route.StartTime}}</span>
                                            </div>
                                            <div class="address" fxFlex="70" fxFlex.sm="100" fxFlex.xs="100" fxLayoutAlign="start center">
                                                <span>{{route.StartAddress}}</span>
                                            </div>
                                        </div>
                                        <div class="trip-section-row-2" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
                                            <div class="logout-time" fxFlex="10" fxFlex.sm="100" fxFlex.xs="100" fxLayoutAlign="start center">
                                                <span>{{route.EndTime}}</span>
                                            </div>
                                            <div class="address" fxFlex="70" fxFlex.sm="100" fxFlex.xs="100" fxLayoutAlign="start center">
                                                <span>{{route.EndAddress}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div fxFlex="50" fxFlex.sm="50" fxFlex.xs="100" fxLayoutAlign="end end" fxLayoutAlign.sm="end end" fxLayoutAlign.xs="start">
                                    <div class="trip-section-2" mat-line fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
                                        <div class="router-section">
                                            <app-vehicle-trip-type [vehicleInfo]="route" (valueChanged)="selectedTripValueChanged($event)"></app-vehicle-trip-type>
                                        </div>
                                        <div *ngIf="route.LastUpdatedDateTime" class="marker-section" fxLayout="row">
                                            <img class="marker-img" src="../../assets/marker.png"/>
                                            <p>{{ 'LASTUPDATEDON'| translate}} {{ route.LastUpdatedDateTime }}</p>
                                        </div>
                                        <div *ngIf="route.isSelectedTrip && !route.UpdateExpired">
                                            <button class="edit-button-icon" mat-icon-button color="primary" aria-label="Edit trip">
                                                <mat-icon (click)="editTrip($event, route)">edit</mat-icon>
                                            </button>
                                        </div>
                                        <span *ngIf="i === 0 && isNotMobileView" class="material-icons-outlined trip-info-button" id="TRIPTOOLTIP" [matTooltip]="getToolTipInfo('TRIPTOOLTIP')">info</span>
                                        <span *ngIf="i === 0 && !isNotMobileView" class="material-icons-outlined trip-info-button" id="TRIPTOOLTIP" (click)="getToolTipInfo('TRIPTOOLTIP')">info</span>
                                    </div>
                                </div>
                            </div>
                        </mat-list-option>
                        <div *ngIf="!(isNotMobileView) && selectedVehicleInfo.RouteId === route.RouteId">
                            <app-vehicle-route-detail [selectedVehicleInfo]="selectedVehicleInfo" [selectedRightSectionOption]="selectedRightSectionOption" [hiddenRightSectionCard]="hiddenRightSectionCard" [mapDetail]="mapDetail" (removeRoute)="removeRoute($event)" (edit)="edit($event)"></app-vehicle-route-detail>
                        </div>
                    </div>
                </div>
            </mat-selection-list>
        </div>
        <div *ngIf="isEmptyRoute">
            <mat-card>
                <mat-card-content>
                    {{'NOTRIPDETAILS' | translate}}
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>
<div class="spinner-blocker" fxLayout="row" fxLayoutAlign="center center">
	<mat-spinner [style.display]="showSpinner ? 'block' : 'none'"></mat-spinner>
</div>
