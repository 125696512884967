import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { VehicleInformationComponent } from './components/vehicle-information/vehicle-information.component';

const routes: Routes = [
  {
    path: '',
    component: VehicleInformationComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InformationRoutingModule { }
