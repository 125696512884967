import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DriverService } from '../../../shared/services/driver/driver.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StorageService } from '../../../shared/services/storage/storage.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslateConfigService } from '../../../shared/services/translate/translate-config.service';
import { AppConstants } from '../../../app.constants';

@Component({
  selector: 'app-vehicle-trip-type',
  templateUrl: './vehicle-trip-type.component.html',
  styleUrls: ['./vehicle-trip-type.component.scss']
})
export class VehicleTripTypeComponent implements OnInit {

  @Input() vehicleInfo;
  @Output() valueChanged = new EventEmitter();
  routeTypesList;
  routeTypeForm: FormGroup;
  showSpinner: boolean = false;
  toSelect;

  constructor(private driverService: DriverService,
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private translateService: TranslateService,
    private translateConfigService: TranslateConfigService,
    private storageService: StorageService) {}

  ngOnInit() {
    this.routeTypeForm = this.fb.group({
      name: ['', Validators.required],
    });
    this.getVehicleRouteTypes();
    this.translateConfigService.selectedLanguage$.subscribe((data) => {
      this.getVehicleRouteTypes();
    });
  }

  getVehicleRouteTypes() {
    // const storageRouteTypesList = this.storageService.get('routeTypesList');
    setTimeout(() => {
      const storageRouteTypesList = this.translateService.instant('VehicleRouteTypes');
      if (storageRouteTypesList) {
        this.routeTypesList = storageRouteTypesList;
        this.getDefaultRouterType(this.vehicleInfo);
      } else {
        this.driverService.getVehicleRouteTypes().subscribe((typesData) => {
          this.storageService.set('routeTypesList', typesData);
          this.routeTypesList = typesData;
          this.getDefaultRouterType(this.vehicleInfo);
        }, (error) => {
          this.showSpinner = false;
          this.snackBar.open('getVehicleRouteTypes call error', this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
        });
      }
    }, 10);
  }

  getDefaultRouterType(vehicleInformation) {
    let toSelectedVal;
    if (this.routeTypesList && this.routeTypesList.length > 0) {
      toSelectedVal = this.routeTypesList.find(c => c.Id === vehicleInformation.VehicleRouteTypeId);
    }
    this.toSelect = toSelectedVal;
    this.routeTypeForm.controls['name'].setValue(this.toSelect);
    const data = {
      value: toSelectedVal
    };
    if (toSelectedVal) {
      this.vehicleInfo.VehicleRouteTypeId = toSelectedVal.Id;
    }
  }

  routeTypeClicked(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  selectChanged(e) {
    if (e.value) {
      this.vehicleInfo.VehicleRouteTypeId = e.value.Id;
      this.valueChanged.emit({
        value: e.value,
        vehicleInfo: this.vehicleInfo
      });
    }
  }

}
