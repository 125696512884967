<h2 mat-dialog-title>{{title}}</h2>

<mat-dialog-content>
    <form [formGroup]="tripForm" fxLayout="column" fxLayoutAlign="none" cdkScrollable>
        <mat-form-field class="date-form-field" appearance="outline" fxLayout="column" fxLayoutAlign="none"><!--*ngIf="!isEditFLow"-->
            <mat-label>{{'STARTDATE' | translate}}</mat-label>
            <input matInput [matDatepicker]="startDate" formControlName="StartDate">
            <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
            <mat-datepicker #startDate color="primary"></mat-datepicker>
            <mat-error *ngIf="tripForm.controls.StartDate.errors?.required">
                {{validationMessages.StartDate.required}}
            </mat-error>
            <mat-error *ngIf="tripForm.controls.StartDate.errors?.invalidDate">
                {{ 'STARTDATEERRORMESSAGE' | translate}}
            </mat-error>
        </mat-form-field>
        <mat-form-field class="date-form-field" appearance="outline" fxLayout="column" fxLayoutAlign="none"><!--*ngIf="!isEditFLow"-->
            <mat-label>{{'ENDDATE' | translate}}</mat-label>
            <input matInput [matDatepicker]="endDate" [min]="tripForm.get('StartDate').value" formControlName="EndDate">
            <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
            <mat-datepicker #endDate color="primary"></mat-datepicker>
            <mat-error *ngIf="tripForm.controls.EndDate.errors?.required">
                {{validationMessages.EndDate.required}}
            </mat-error>
        </mat-form-field>
        <div fxLayout="row" fxLayoutAlign="none">
            <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
                <mat-form-field class="example-full-width country-search-field" appearance="outline">
                    <mat-label>{{'STARTADDRESS' | translate}}</mat-label>
                    <input matInput required
                        matGoogleMapsAutocomplete
                        [type]="'address'"
                        [country]="language"
                        (onAutocompleteSelected)="onStartAddressAutocompleteSelected($event)"
                        (onLocationSelected)="onStartAddressLocationSelected($event)"
                        (onGermanAddressMapped)="onGermanStartAddressMapped($event)"
                        formControlName="StartAddress">
                    <mat-error *ngIf="tripForm.controls.StartAddress.errors?.required">
                        {{validationMessages.StartAddress.required}}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="column" fxLayoutAlign="none">
            <div>
                <mat-label>{{'STARTTIME' | translate}}:</mat-label>
            </div>
            <br/>
            <div fxLayoutAlign="none">
                <div class="min-time-example">
                    <ngx-timepicker-field formControlName="StartTime" [max]="maxStartTime" [defaultTime]="defaultStartTime" [toggleIcon]="icon" [format]="24" (timeChanged)="startTimeChanged($event)"></ngx-timepicker-field>
                    <ng-template #icon>
                        <mat-icon>schedule</mat-icon>
                    </ng-template>
                </div>  
            </div>
        </div>
        <br/>
        <div fxLayout="row" fxLayoutAlign="none" cdkScrollable>
            <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>{{'ENDADDRESS' | translate}}</mat-label>
                    <!-- <input matInput placeholder="" formControlName="EndAddress" required> -->
                    <input matInput required
                        matGoogleMapsAutocomplete
                        [type]="'address'"
                        [country]="language"
                        (onAutocompleteSelected)="onEndAddressAutocompleteSelected($event)"
                        (onLocationSelected)="onEndAddressLocationSelected($event)"
                        (onGermanAddressMapped)="onGermanEndAddressMapped($event)"
                        formControlName="EndAddress">
                    <mat-error *ngIf="tripForm.controls.EndAddress.errors?.required">
                        {{validationMessages.EndAddress.required}}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="column" fxLayoutAlign="none" class="margin-bottom-10">
            <div>
                <mat-label>{{'ENDTIME' | translate}}:</mat-label>
            </div>
            <br/>
            <div fxLayoutAlign="none">
                <div class="min-time-example">
                    <ngx-timepicker-field formControlName="EndTime" [min]="minEndTime" [defaultTime]="defaultEndTime" [toggleIcon]="icon1" [format]="24" (timeChanged)="endTimeChanged($event)"></ngx-timepicker-field>
                    <ng-template #icon1>
                        <mat-icon>schedule</mat-icon>
                    </ng-template>
                    <!-- <mat-error *ngIf="tripForm.controls.EndTime.errors?.required">
                        {{validationMessages.EndAddress.required}}
                    </mat-error> -->
                </div>    
            </div>
        </div>
        <div class="margin-bottom-10" fxLayout="column" fxLayoutAlign="none">
            <mat-label fxFlex="20" fxFlex.sm="100" fxFlex.xs="100">{{'TRIPTYPE' | translate}}:</mat-label><br/>
            <mat-radio-group (change)="onChangeRadioGroup($event)"
                aria-label="Select an option"
                formControlName="RouteType"
                fxFlex="80" fxFlex.sm="100" fxFlex.xs="100">
                <mat-radio-button class="radio-button" *ngFor="let object of types" [value]="object.Type">
                    {{object.Type.replaceAll(' ', '')| uppercase | translate}}
                </mat-radio-button>
            </mat-radio-group>
        </div>
        <div *ngIf="isBusinessPartnerType">
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <div></div>
                <button mat-icon-button *ngIf="!toggleAddNewBusinessPartnerDetails" (click)="toggleAddNewBusinessPartnerDetails=!toggleAddNewBusinessPartnerDetails">
                    <mat-icon>add</mat-icon>
                </button>
            </div>
            <div class="add-business-partner-section" *ngIf="toggleAddNewBusinessPartnerDetails">
                <mat-accordion>
                    <mat-expansion-panel [expanded]="true">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{'ADDNEWBUSINESSPARTNER'|translate}}:
                            </mat-panel-title>
                            <mat-panel-description></mat-panel-description>
                        </mat-expansion-panel-header>
                        <div class="field-section">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>{{ 'FIRSTNAME' | translate }}</mat-label>
                                <input class="input-tag" type="text" matInput placeholder="{{ 'FIRSTNAMEPLACEHOLDER' | translate }}" formControlName="firstname" (change)="onChangeFirstname()" required>
                                <mat-error *ngIf="tripForm.controls['firstname'].invalid && tripForm.controls['firstname'].touched">
                                    {{ getErrorFirstname() }}
                                </mat-error>
                                <mat-error *ngIf="tripForm.controls['firstname'].errors?.invalidAlphabets && tripForm.controls['firstname'].touched">
                                    {{ 'INVALIDALPHABET' | translate }}
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>{{ 'LASTNAME' | translate }}</mat-label>
                                <input class="input-tag" type="text" matInput placeholder="{{ 'LASTNAMEPLACEHOLDER' | translate }}" formControlName="lastname" (change)="onChangeLastname()" required>
                                <mat-error *ngIf="tripForm.controls['lastname'].invalid && tripForm.controls['lastname'].touched">
                                    {{ getErrorLastname() }}
                                </mat-error>
                                <mat-error *ngIf="tripForm.controls['lastname'].errors?.invalidAlphabets && tripForm.controls['lastname'].touched">
                                    {{ 'INVALIDALPHABET' | translate }}
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>{{ 'COMPANY' | translate }}</mat-label>
                                <input class="input-tag" type="text" matInput placeholder="{{ 'COMPANYPLACEHOLDER' | translate }}" formControlName="companyname" (change)="onChangeCompanyName()">
                            </mat-form-field>
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>{{ 'ADDRESS' | translate }}</mat-label>
                                <!-- <input matInput placeholder="" formControlName="EndAddress" required> -->
                                <input matInput required
                                    matGoogleMapsAutocomplete
                                    [type]="'address'"
                                    [country]="language"
                                    (onAutocompleteSelected)="onBusinessPartnerAddressAutocompleteSelected($event)"
                                    (onLocationSelected)="onBusinessPartnerAddressLocationSelected($event)"
                                    (onGermanAddressMapped)="onGermanBusinessPartnerAddressMapped($event)"
                                    formControlName="BusinessPartnerAddress">
                                <mat-error *ngIf="tripForm.controls.BusinessPartnerAddress.errors?.required">
                                    {{validationMessages.BusinessPartnerAddress.required}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <button mat-raised-button (click)="saveBusinessPartner()">Save</button>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="none" *ngIf="isBusinessPartnerType">
            <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
                <!-- <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>{{'BUSINESSPARTNERNAME' | translate}}</mat-label>
                    <input matInput placeholder="business partner name" formControlName="BusinessPartnerName">
                </mat-form-field> -->
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>{{'BUSINESSPARTNERNAME' | translate}}</mat-label>
                    <mat-select formControlName="BusinessPartnerId">
                        <mat-option *ngFor="let businessPartner of businessPartners" [value]="businessPartner.ID">
                            {{businessPartner.FirtName}} {{businessPartner.LastName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="none" *ngIf="!isAdminUser || driversList.length > 0">
            <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
                <!-- <mat-form-field class="example-full-width" appearance="outline" *ngIf="!isEditFLow">
                    <mat-label>{{'DRIVERNAME' | translate}}</mat-label>
                    <input matInput placeholder="" formControlName="DriverName">
                </mat-form-field> -->
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>{{'DRIVERNAME' | translate}}</mat-label>
                    <mat-select formControlName="DriverID">
                        <mat-option *ngFor="let driver of driversList" [value]="driver.ID">{{driver.Firstname}} {{driver.Lastname}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="none">
            <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>{{'REASONOFTRIP' | translate}}</mat-label>
                    <textarea matInput placeholder="{{'TRIPDETAILPLACEHOLDER' | translate}}" formControlName="AdditionalInformation" [required]="isBusinessPartnerType"></textarea>
                    <mat-error *ngIf="tripForm.controls.AdditionalInformation.errors?.required">
                        {{validationMessages.ReasonOfTrip.required}}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="none">
            <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>{{'DRIVENDISTANCE' | translate}}</mat-label>
                    <input matInput placeholder="{{'DRIVENPLACEHOLDER' | translate}}" formControlName="DrivenDistance" required (blur)="onBlurDistance()">
                    <mat-error *ngIf="tripForm.controls.DrivenDistance.errors?.required">
                        {{validationMessages.DrivenDistance.required}}
                    </mat-error>
                    <mat-error *ngIf="tripForm.controls.DrivenDistance.errors?.invalidNumber">
                        {{validationMessages.DrivenDistance.invalidNumber}}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="none">
            <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>{{'AVERAGESPEED'| translate}}:</mat-label>
                    <input matInput placeholder="{{'AVERAGESPEEDPLACEHOLDER' | translate}}" formControlName="AverageSpeed">
                </mat-form-field>
            </div>
        </div>
        <!--<div fxLayout="row" fxLayoutAlign="none">
            <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>{{'STARTMILEAGE' | translate}}</mat-label>
                    <input matInput readonly placeholder="{{'CURRENTMILEAGEPLACEHOLDER' | translate}}" formControlName="StartMileage" required (blur)="onBlurStartMileage()">
                </mat-form-field>
            </div>
        </div>
        <div  fxLayout="row" fxLayoutAlign="none">
            <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>{{'ENDMILEAGE' | translate}}</mat-label>
                    <input matInput readonly placeholder="{{'FINALMILEAGEPLACEHOLDER' | translate}}" formControlName="EndMileage" required>
                </mat-form-field>
            </div>
        </div>-->
        <div *ngIf="ErrorMessage" class="form-error-message">
            {{ErrorMessage}}
        </div>
    </form>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayout.xs="column" fxLayoutGap.xs="10px" fxLayoutAlign="space-between center">
    <button mat-raised-button class="close raised-button-action" mat-dialog-close>{{'CLOSE'| translate}}</button>
    <button class="raised-button-action" mat-raised-button color="primary" [disabled]="tripForm.invalid" (click)="save()">{{'SUBMIT'| translate}}</button>
</mat-dialog-actions>

<div class="spinner-blocker" *ngIf="showSpinner" fxLayout="row" fxLayoutAlign="center center">
	<mat-spinner [style.display]="showSpinner ? 'block' : 'none'"></mat-spinner>
</div>