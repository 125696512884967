<div class="forward-messages">
  <h2 mat-dialog-title>{{ 'ALLFORWARDEDMESSAGES' | translate }}</h2>
  <mat-dialog-content  *ngIf="ELEMENT_DATA && ELEMENT_DATA.length > 0">
    <div *ngFor="let vehicle of ELEMENT_DATA">
      <div fxLayout="row" fxLayoutAlign="none">
        <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
          <p>{{ 'VEHICLEBRAND' | translate}}: {{vehicle.VehicleBrand}}</p>
        </div>
        <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
          <p>{{ 'VEHICLENO' | translate}}: {{vehicle.PlateNumber}}</p>
        </div>
      </div>
      <div>
        <p>{{'INSPECRTIONWARNING' | translate}}:</p>
        <app-inspection-warning [InspectionDataSource]="getMessagesData(vehicle.VehicleMessages, 'inspectionWarning')"></app-inspection-warning>
      </div>
      <br/>
      <div>
        <p>{{'OTHERMESSAGE' | translate}}:</p>
        <app-other-messages [OtherDataSource]="getMessagesData(vehicle.VehicleMessages, 'otherMessages')"></app-other-messages>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>{{'ABORT' | translate}}</button>
  </mat-dialog-actions>
</div>
