import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { MileageComponent } from '../mileage/mileage.component';
import { PostMileageRequest } from '../../models/mileage/post-mileage-request.model';
import { DriverService } from '../../services/driver/driver.service';
import { StorageService } from '../../services/storage/storage.service';
import { AppConstants } from '../../../app.constants';

@Component({
  selector: 'app-mileage-snackbar',
  templateUrl: './mileage-snackbar.component.html',
  styleUrls: ['./mileage-snackbar.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class MileageSnackbarComponent implements OnInit {

  currentMileage = 0;
  VehicleMileageInfo: any;

  constructor(
    public mileageSnackBarRef: MatSnackBarRef<MileageSnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
		private matDialog: MatDialog,
    public  dialogRef: MatDialogRef<MileageComponent>,
    private driverService: DriverService,
    private storageService: StorageService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    console.log('vehicle sends mileage info', this.data);
  }

  remindMeClick() {
		let userInfo: any = this.storageService.get('userInfo'); 
    // ''
    this.mileageSnackBarRef.dismiss();
    this.snackBar.open('Hey ' + userInfo.name + '! , ' + this.translateService.instant('REMINDMELATERMESSAGE'), this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
  }

  yesBtnClick() {
    this.VehicleMileageInfo = localStorage.getItem('vehicleMileage');
    if (this.VehicleMileageInfo && this.VehicleMileageInfo !== '{}') {
      let parsedVehicleData = JSON.parse(this.VehicleMileageInfo);
      parsedVehicleData = parsedVehicleData[0];
      parsedVehicleData.isCorrectCurrentMileage = true;
      this.save(parsedVehicleData);
    }
  }

  noBtnClick() {
    this.VehicleMileageInfo = localStorage.getItem('vehicleMileage');
    if (this.VehicleMileageInfo && this.VehicleMileageInfo !== '{}') {
      let parsedVehicleData = JSON.parse(this.VehicleMileageInfo)
      parsedVehicleData.isCorrectCurrentMileage = false;
      this.openMileageDialog(parsedVehicleData);
    }
  }

  openMileageDialog(data) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = data[0];
    dialogConfig.disableClose = true;
		this.dialogRef = this.matDialog.open(MileageComponent, dialogConfig);
		this.dialogRef.afterClosed().subscribe(value => {
      if (value) {
        this.save(value);
      } else {
        this.snackBar.open(this.translateService.instant('REMINDMELATERMESSAGELOGIN'), this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
      }
      this.mileageSnackBarRef.dismiss();
      this.data.preClose();
		});
  }

  save(requestData) {
    let requestBody: PostMileageRequest = {
      CurrentMileage: null,
      VehicleId: null,
      CurrentMileageIsCorrect: null,
      PreviousMileage: null
    };
    if (requestData) {
      requestBody.CurrentMileage = requestData.CurrentMileage;
      requestBody.VehicleId = requestData.VehicleId;
      requestBody.CurrentMileageIsCorrect = requestData.isCorrectCurrentMileage;
		  requestBody.PreviousMileage = requestData.PreviousMileage.CurrentMileage;
    }
    // console.log('mileage request body', requestBody);
    this.driverService.saveMileage(requestBody).subscribe((response: any) => {
      // console.log('updated mileage response', response);
      if(response && response.status === 200) {
        let vehicleMileage = localStorage.getItem('vehicleMileage');
        let parsedVehicleMileage = JSON.parse(vehicleMileage);
        parsedVehicleMileage.CurrentMileage = requestBody.CurrentMileage;
        localStorage.setItem('vehicleMileage', JSON.stringify(parsedVehicleMileage));
      }
      this.snackBar.open(response.message, this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
      this.mileageSnackBarRef.dismiss();
      this.data.preClose();
    }, (error: any) => {
      this.snackBar.open(error, this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
      // this.mileageSnackBarRef.dismiss();
    });
  }

}
