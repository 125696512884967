import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { VehiclesComponent } from './components/vehicles/vehicles.component'; 

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    children: [{
        path: '',
        redirectTo: 'rides',
        pathMatch: 'full'
      }, {
        path: 'rides',
        component: VehiclesComponent,
      }, {
        path: 'information',
        loadChildren: () => import('../information/information.module').then(m => m.InformationModule),
      }, {
        path: 'geofence',
        loadChildren: () => import('../geofence/geofence.module').then(m => m.GeofenceModule),
      }, {
        path: 'vehicles',
        loadChildren: () => import('../vehicle-management/vehicle-management.module').then(m => m.VehicleManagementModule),
      }, {
        path: 'buildings',
        loadChildren: () => import('../building-management/building-management.module').then(m => m.BuildingManagementModule),
      }, {
        path: 'client-management',
        loadChildren: () => import('../client-management/client-management.module').then(m => m.ClientManagementModule),
      }, {
        path: 'gps-tracker',
        loadChildren: () => import('../gps-tracker/gps-tracker.module').then(m => m.GpsTrackerModule)
    }]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
