import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { StorageService } from '../../../shared/services/storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

	CR_URL = environment.crapi.URL;
	Assets_URL = environment.assetsapi.URL;
	isUserAuthenticated = false;
	private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	constructor(private http: HttpClient,
		private storageService: StorageService) { }

	get isUserLoggedIn() {
		return this.loggedIn.asObservable();
	}

	public isAuthenticated(): boolean {
		return this.isUserAuthenticated;
	}

	public setAuthenticated(value: boolean) {
		if (value) {
			this.loggedIn.next(true);
		} else {
			this.loggedIn.next(false);
		}
		this.isUserAuthenticated = value;
	} 

	loginCrApi(options): Observable<any> {
		return this.http.post(this.CR_URL+ '/api/Account/ExternalLogin', options);
	}

	loginCrApiToken(options): Observable<any> {
		const body = 'username='+ options.username + '&password='+ options.password + '&grant_type=' + options.grant_type;
		return this.http.post(this.CR_URL+ '/token', body).pipe(map(data => {
			return data;
		}));
	}

	loginAssetsApi(options): Observable<any> {
		const body = 'username='+ options.username + '&password='+ options.password + '&grant_type=' + options.grant_type;
		return this.http.post(this.Assets_URL+ '/token', body).pipe(map(data => {
			return data;
		}));
	}

	getUserInformation() {
		const CrApiSessionStorage = this.storageService.get('CrApiSession');
		return this.http.get(this.CR_URL+ '/api/Account/UserInfo', {
			headers: this.getAuthorizationHeaders(CrApiSessionStorage),
		});
	}

	resetPassword(options) {
		const CrApiSessionStorage = this.storageService.get('CrApiSession');
		return this.http.post(this.CR_URL + '/api/Account/ChangePassword', options, {
		  headers: this.getAuthorizationHeaders(CrApiSessionStorage),
		}).pipe(
		  map((response: HttpResponse<any>) => {
			return response;
		  }, (error) => {
			return error;
		  })
		);
	}

	private getAuthorizationHeaders(data) {
		const headers = {
			Authorization: '',
		};
		if (data && data !== '') {
			headers.Authorization = data.token_type + ' ' + data.access_token;
		}
		return headers;
	}

}
