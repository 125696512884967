import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { StorageService } from '../../../shared/services/storage/storage.service';

@Component({
  selector: 'app-terms-conditions-bottom-sheet',
  templateUrl: './terms-conditions-bottom-sheet.component.html',
  styleUrls: ['./terms-conditions-bottom-sheet.component.scss']
})
export class TermsConditionsBottomSheetComponent implements OnInit {

  constructor(private _bottomSheetRef: MatBottomSheetRef<TermsConditionsBottomSheetComponent>,
    private storageService: StorageService,
    private router: Router) { }

  ngOnInit(): void {
  }

  openLink(event: MouseEvent): void {
    // this._bottomSheetRef.dismiss();
    event.preventDefault();
  }

  cancel() {
    this._bottomSheetRef.dismiss();
    this.storageService.set('termsAndCondition', 'canceled');
  }

  accept() {
    this._bottomSheetRef.dismiss();
    this.storageService.set('termsAndCondition', 'accepted');
  }

  more() {
    this._bottomSheetRef.dismiss();
    this.router.navigate(['termsConditions']);
    // this.storageService.set('termsAndCondition', 'more');
  }

}
