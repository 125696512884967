import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import * as moment from 'moment';
import { CommonService } from '../../../shared/services/common/common.service';
import { StorageService } from '../../../shared/services/storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class ExportPdfService {

  constructor(private translateService: TranslateService,
    private storageService: StorageService,
    private commonService: CommonService) {}

  exportPdf(reportData) {
    
    // console.log(reportData);
    // const doc: any = new jsPDF('portrait', 'px', 'a4');

    const doc: any = new jsPDF('landscape', 'px', 'a4');

    var pageSize = doc.internal.pageSize;
    var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
    var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
    
    const image = new Image();
    image.src = './../assets/Logo_SVG_pdf.png';

    // const finalY = doc.lastAutoTable.finalY || 10;
    // console.log('finalY: ', finalY);
    const image1 = new Image();
    image1.src = './../assets/Logo_SVG_pdf.png';
    doc.addImage(image1, 'png', 30, 10, 320, 100);

    // header title
    doc.setFontSize(24);
    doc.setFont('helvetica', 'bold');
    doc.text(reportData.title, 30, 225);

    // header date duration
    doc.setFontSize(18);
    doc.text(reportData.option.initialTripDate, 30, 250);
    doc.text(' - ', 95, 250);
    doc.text(reportData.option.finalTripDate, 105, 250);

    // header downloaded date details
    doc.setFontSize(14);
    doc.text(this.translateService.instant('STATEMENTISSUED'), 30, 270);
    doc.text(moment().format('DD.MM.YYYY HH:mm:ss'), 135, 270);

    /* Page 2 processing trips details */
    doc.addPage();
    this.addHeaders(doc, image, reportData, pageWidth);
    
    let header = (data) => {
      // doc.setFontSize(25);
      // doc.setTextColor(0);
      // doc.text(data,15,15);
      // doc.setFontStyle('normal');
      this.addHeaders(doc, image, reportData, pageWidth);
      data.settings.margin.top = 80;
    };

    doc.autoTable({
      head: [reportData.headers],
      body: reportData.data,
      startY: 80,
      // startY: doc.internal.getNumberOfPages() > 1 ? doc.autoTableEndPosY() + 150 : 200,
      columnStyles: {
        0: {cellWidth: 90},
        1: {cellWidth: 90},
        2: {cellWidth: 50},
        3: {cellWidth: 40},
        4: {cellWidth: 50},
        5: {cellWidth: 70},
        6: {cellWidth: 60},
        7: {cellWidth: 60},
        8: {cellWidth: 70},
      },
      // tableLineColor: [189, 195, 199],
      // tableLineWidth: 0.75,
      headStyles: {
        // LineWidth: 2,
        fillColor: [255, 255, 255],
        fontSize: '6',
        // lineWidth: 0.06,
        // lineColor: [217, 216, 216]
      },
      theme: 'plain',
      styles: {
        overflow: 'linebreak',
        columnWidth: 'wrap',
        fillColor: [255, 255, 255],
        // cellPadding: 3,
        maxCellHeight: 10,
        // lineWidth: 0.02,
        // lineColor: [217, 216, 216]
      },
      margin: { top: 10 },
      willDrawCell: (HookData) => {
        console.log('willDrawCell:', HookData);
        const rows = HookData.rows;
        const doc = HookData.doc;
        if (HookData.section === 'head') {
          // doc.setFontSize("9");
          // doc.setFillColor(217, 216, 216); 
          // HookData.doc.setLineWidth(2);
          HookData.doc.line(30, HookData.cursor.y, pageWidth-30, HookData.cursor.y-0.5);
        }
        if (HookData.section === 'body') {
          doc.setFontSize("6");
          if (HookData.row.raw[9]) {
            doc.setTextColor(255, 99, 71);
          }
          // doc.setFillColor(217, 216, 216); 
          HookData.doc.line(30, HookData.cursor.y, pageWidth-30, HookData.cursor.y-0.5);
        }
        // if (HookData.section === 'body') {
        //   HookData.cell.styles.fillColor = [217, 216, 216];
        //   HookData.cell.styles.fontSize = 2;
        // }
      },
      didDrawCell: (HookData) => {
      },
      didDrawPage: header,
    });

    doc.setFontSize(8);
    doc.setTextColor(255, 99, 71);
    doc.text(this.translateService.instant('REDLABELDELETEDROUTESMESSAGE'), pageWidth-60, pageHeight-50, null, null, 'right');

    doc.setFontSize(8);
    doc.setTextColor(0, 0, 0);
    doc.text(this.translateService.instant('BLACKLABELAVIALABLEMESSAGE'), pageWidth-60, pageHeight-40, null, null, 'right');

    /* Page 3 processing year based trips summary */
    if (reportData.option.VehicleReport) {
      let YearsInformation = reportData.option.VehicleReport.Year;
      let resultantData;
      // console.log('YearInformation', YearInformation);
      for(let i=0; i < YearsInformation.length; i++) {
        doc.addPage();
        resultantData = this.commonService.processYearInformation(YearsInformation[i]);
        this.addHeaders(doc, image, reportData, pageWidth);
        const selectedReportYear = this.storageService.get('selectedReportYear') || '';
        doc.setFontSize(14);
        doc.text(this.translateService.instant('ANNUALOVERVIEW') + ' ' + YearsInformation[i].Name, 30, 80);
        doc.autoTable({
          head: [resultantData.headers],
          body: resultantData.data,
          startY: 100,
          // tableLineColor: [189, 195, 199],
          // tableLineWidth: 0.75,
          headStyles: {
            
            // LineWidth: 2,
            fillColor: [255, 255, 255],
            fontSize: '7',
            // lineWidth: 0.06,
            // lineColor: [217, 216, 216]
          },
          theme: 'plain',
          styles: {
            // overflow: 'linebreak',
            fillColor: [255, 255, 255],
            // lineWidth: 0.02,
            // lineColor: [217, 216, 216]
          },
          margin: { top: 10 },
          willDrawCell: (HookData) => {
            // console.log('willDrawCell:', HookData);
            const rows = HookData.rows;
            const doc = HookData.doc;
            if (HookData.section === 'head') {
              // doc.setFontSize("9");
              // doc.setFillColor(217, 216, 216); 
              // HookData.doc.setLineWidth(2);
              HookData.doc.line(30, HookData.cursor.y, pageWidth-30, HookData.cursor.y-0.5);
            }
            if (HookData.section === 'body') {
              doc.setFontSize("7");
              // doc.setFillColor(217, 216, 216); 
              HookData.doc.line(30, HookData.cursor.y, pageWidth-30, HookData.cursor.y-0.5);
            }
            // if (HookData.section === 'body') {
            //   HookData.cell.styles.fillColor = [217, 216, 216];
            //   HookData.cell.styles.fontSize = 2;
            // }
            
          },
          didDrawCell: (HookData) => {
          },
          didDrawPage: header,
        });
      }
    }

    /* Page 4 processing drivers trip summary */
    doc.addPage();
    if (reportData.option.VehicleReport) {
      let DriverInformation = reportData.option.VehicleReport.DriverInfo;
      let resultantData;
      // console.log('DriverInformation' , DriverInformation);
      resultantData = this.commonService.processDriversInformation(DriverInformation);
      this.addHeaders(doc, image, reportData, pageWidth);
      doc.setFontSize(14);
      doc.text(this.translateService.instant('DRIVEROVERVIEW'), 30, 80);
      doc.autoTable({
        head: [resultantData.headers],
        body: resultantData.data,
        startY: 100,
        // tableLineColor: [189, 195, 199],
        // tableLineWidth: 0.75,
        headStyles: {
          
          // LineWidth: 2,
          fillColor: [255, 255, 255],
          fontSize: '7',
          // lineWidth: 0.06,
          // lineColor: [217, 216, 216]
        },
        theme: 'plain',
        styles: {
          // overflow: 'linebreak',
          fillColor: [255, 255, 255],
          // lineWidth: 0.02,
          // lineColor: [217, 216, 216]
        },
        margin: { top: 10 },
        willDrawCell: (HookData) => {
          // console.log('willDrawCell:', HookData);
          const rows = HookData.rows;
          const doc = HookData.doc;
          if (HookData.section === 'head') {
            // doc.setFontSize("9");
            // doc.setFillColor(217, 216, 216); 
            // HookData.doc.setLineWidth(2);
            HookData.doc.line(30, HookData.cursor.y, pageWidth-30, HookData.cursor.y-0.5);
          }
          if (HookData.section === 'body') {
            doc.setFontSize("7");
            // doc.setFillColor(217, 216, 216); 
            HookData.doc.line(30, HookData.cursor.y, pageWidth-30, HookData.cursor.y-0.5);
          }
          // if (HookData.section === 'body') {
          //   HookData.cell.styles.fillColor = [217, 216, 216];
          //   HookData.cell.styles.fontSize = 2;
          // }
          
        },
        didDrawCell: (HookData) => {
        },
        didDrawPage: header,
      });
    }

    // doc.setFontSize(11);
    // doc.setFont('helvetica', 'bold');
    // doc.text('Summary:' , 30, doc.autoTable.previous.finalY + 30);

    // doc.setFont('helvetica', 'normal');
    // doc.text('Total driven for Business Trips:', 30, doc.autoTable.previous.finalY + 50);
    // doc.text(reportData.option.tripsSummary.businessTrip.toString() + ' KM', 150, doc.autoTable.previous.finalY + 50);
    
    // doc.text('Total driven for Private Trips:', 30, doc.autoTable.previous.finalY + 70);
    // doc.text(reportData.option.tripsSummary.privateTrip.toString() + ' KM', 140, doc.autoTable.previous.finalY + 70);

    // doc.text('Total driven for Commute home Trips:', 30, doc.autoTable.previous.finalY + 90);
    // doc.text(reportData.option.tripsSummary.commuteHome.toString() + ' KM', 175, doc.autoTable.previous.finalY + 90);

    // doc.text('Total driven for Commute work Trips:', 30, doc.autoTable.previous.finalY + 110);
    // doc.text(reportData.option.tripsSummary.commuteWork.toString() + ' KM', 175, doc.autoTable.previous.finalY + 110);

    
    // footer page numbers display on each page
    this.addFooterContent(doc, pageHeight);

    // doc.autoPrint();
    // window.open(doc.output('bloburl'), '_blank');

    doc.save(reportData.title + '.pdf');
  }

  addFooterContent(doc, pageHeight) {
    const pagecount = doc.internal.getNumberOfPages();
    doc.setFont('helvetica', 'italic')
    doc.setFontSize(8);
    for (var i=1; i<= pagecount; i++) {
      doc.setPage(i);
      doc.text(this.translateService.instant('EXPORTPDFPAGENUMBER' , {
        value: {
          count: String(i),
          pagecount: String(pagecount)
        }
      }), 30, pageHeight-20, null, null, 'left');
    }
  }

  addHeaders(doc, image, reportData, pageWidth) {
    // header logo image
    // doc.addImage(image, 'png', 30, 10, 80, 30);
    // // header vehicle details
    // doc.setFontSize(8);
    // doc.text(this.translateService.instant('VEHICLENUMBER') + reportData.option.vehicleNumber, pageWidth-20, 28, null, null, 'right');
    
    // // horizontal line
    // doc.line(0, 50, pageWidth, 50);


    // header logo image
    doc.addImage(image, 'png', 10, 10, 125, 35);

    // header title
    // doc.setFontSize(8);
    // doc.setFont('helvetica', 'bold');
    // doc.text(reportData.title, 120, 28);

    // doc.setFontSize(11);
    // doc.setFont('helvetica', 'normal');
    // // header driver details
    // doc.text('Driver Name:', 30, 50);
    // doc.text(reportData.option.driverName, 100, 50);

    // header vehicle details
    doc.setFontSize(8);
    doc.text(this.translateService.instant('VEHICLENUMBER') + reportData.option.vehicleNumber, pageWidth-20, 28, null, null, 'right');
    
    // horizontal line
    doc.line(0, 50, pageWidth, 50);

    // // header Mileage details
    // doc.text('Inital Mileage:', 30, 70);
    // doc.text((reportData.option.initalMileage ? reportData.option.initalMileage.toString() : ''), 100, 70);

    // doc.text('Final Mileage:', 250, 70);
    // doc.text((reportData.option.finalMileage ? reportData.option.finalMileage.toString() : ''), 320, 70);

  }
}
