<h1 mat-dialog-title>
    {{title}}
</h1>
  
<div mat-dialog-content>
    <form class="edit-contact-form-section" [formGroup]="EditContactForm">
        <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>{{'FIRSTNAME' | translate}}</mat-label>
            <input matInput placeholder="{{'FIRSTNAMEPLACEHOLDER' | translate}}" formControlName="Firstname" required>
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>{{'SURNAMEORLASTNAME' | translate}}</mat-label>
            <input matInput placeholder="{{'SURNAMEPLACEHOLDER' | translate}}" formControlName="Surname" required>
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>{{'COMPANY' | translate}}</mat-label>
            <input matInput placeholder="{{'COMPANYPLACEHOLDER' | translate}}" formControlName="Company" required>
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>{{'STREET' | translate}}</mat-label>
            <input matInput placeholder="{{'STREETPLACEHOLDER' | translate}}" formControlName="Street" required>
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>{{'CITY' | translate}}</mat-label>
            <input matInput placeholder="{{'CITYPLACEHOLDER' | translate}}" formControlName="City" required>
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>{{'ZIPCODE' | translate}}</mat-label>
            <input matInput placeholder="{{'ZIPCODEPLACEHOLDER'| translate}}" formControlName="Zipcode" required>
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>{{'COUNTRY' | translate}}</mat-label>
            <input matInput placeholder="{{'COUNTRYPLACEHOLDER'| translate}}" formControlName="Country" required>
        </mat-form-field>
    </form>
</div>
<div mat-dialog-actions align="end">
    <button mat-raised-button (click)="onDismiss()">{{'NO'| translate}}</button>
    <button mat-raised-button [disabled]="!EditContactForm.valid" color="primary" (click)="onConfirm()">{{'YES'| translate}}</button>
</div>