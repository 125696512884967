<section>
    <mat-card class="preffered-country-section">
        <mat-card-content>
            <h3>{{'PREFFEREDCOUNTRY' | translate}}</h3>
            <form [formGroup]="prefferedCountryForm">
                <mat-form-field appearance="outline">
                    <mat-label>{{'SELECTEDCOUNTRY' | translate}}</mat-label>
                    <mat-select (selectionChange)="selectedCountry($event.value)" formControlName="country">
                        <mat-option *ngFor="let country of countries" [value]="country">
                            {{country.Name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <br/>
                <button mat-raised-button color="primary" [disabled]="prefferedCountryForm.invalid" (click)="submit()">{{'SUBMIT' | translate}}</button>
            </form>
        </mat-card-content>
    </mat-card>
</section>
