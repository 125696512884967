import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { CommonService } from 'src/app/shared/services/common/common.service';
import { AppConstants } from '../../../app.constants';
import { BuildingsService } from '../../services/buildings.service';

@Component({
  selector: 'app-create-building',
  templateUrl: './create-building.component.html',
  styleUrls: ['./create-building.component.scss'],
	providers: [{ provide: MAT_DATE_FORMATS, useValue: AppConstants.DE_DATE_FORMATS }]
})
export class CreateBuildingComponent implements OnInit {
  EditData;
  buildingForm: FormGroup;
  isEditFlow = false;
  constructor(private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private buildingService: BuildingsService,
    private commonService: CommonService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private dialogRef: MatDialogRef<CreateBuildingComponent>) { }

  ngOnInit(): void {
    if (this.data) {
      this.isEditFlow = this.data && this.data.data? true: false;
      this.EditData = this.data && this.data.data? this.data.data: undefined;
      this.createFormBuilder();
    } else {
      this.createFormBuilder();
    }
  }

  createFormBuilder() {
    this.buildingForm = this.fb.group({
      city: [this.EditData && this.EditData.City? this.EditData.City: '', Validators.required],
      street: [this.EditData && this.EditData.Street? this.EditData.Street: '', Validators.required],
      number: [this.EditData && this.EditData.Number? this.EditData.Number: '', Validators.required],
      postalCode: [this.EditData && this.EditData.PostalCode? this.EditData.PostalCode: '', Validators.required],
      federalState: [this.EditData && this.EditData.State? this.EditData.State: '', Validators.required],
      numberOfObjectUnits: [this.EditData && this.EditData.NumberOfApartments? this.EditData.NumberOfApartments: '', Validators.required],
      builtOn: [this.EditData && this.EditData.BuiltOn? moment(this.EditData.BuiltOn).format('YYYY-MM-DD'): '', Validators.required]
    });
  }

  close(value) {
    this.dialogRef.close(value);
  }

  submit() {
    let address = this.buildingForm.value.street + ' ' + this.buildingForm.value.number  + ', ' + this.buildingForm.value.postalCode  + ' ' +  this.buildingForm.value.city + ' ' +  this.buildingForm.value.federalState;
    let options = {};
    if (address) {
      this.getLatLngByAddress(address).then((data) => {
        if (this.isEditFlow) {
          options['Address'] = address;
          options['Latitude'] = data.response[0].geometry.location.lat();
          options['Longitude'] = data.response[0].geometry.location.lng();
          options['BuiltOn'] = moment(this.buildingForm.value.builtOn).format('DD.MM.YYYY');
          options['City'] = this.buildingForm.value.city;
          options['Number'] = this.buildingForm.value.number;
          options['NumberOfApartments'] = this.buildingForm.value.numberOfObjectUnits;
          options['PostalCode'] = this.buildingForm.value.postalCode;
          options['State'] = this.buildingForm.value.federalState;
          options['Street'] = this.buildingForm.value.street;

          options['BuildingId'] = this.EditData.BuildingId;
          this.buildingService.updateBuilding(options).subscribe((data: any) => {
            if (data && data.message) {
              this.snackBar.open(data.message, this.translateService.instant('CANCEL'), AppConstants.DELAYTOOLTIP);
            }
            this.close(true);
          }, (error) => {
            console.log(error);
          });
        } else {
          options['Address'] = address;
          options['BuiltOn'] = moment(this.buildingForm.value.builtOn).format('DD.MM.YYYY');
          options['City'] = this.buildingForm.value.city;
          options['Latitude'] = data.response[0].geometry.location.lat();
          options['Longitude'] = data.response[0].geometry.location.lng();
          options['Number'] = this.buildingForm.value.number;
          options['NumberOfApartments'] = this.buildingForm.value.numberOfObjectUnits;
          options['PostalCode'] = this.buildingForm.value.postalCode;
          options['State'] = this.buildingForm.value.federalState;
          options['Street'] = this.buildingForm.value.street;

          this.buildingService.createBuilding(options).subscribe((data: any) => {
            if (data && data.message) {
              this.snackBar.open(data.message, this.translateService.instant('CANCEL'), AppConstants.DELAYTOOLTIP);
            }
            this.close(true);
          }, (error) => {
            console.log(error);
          });
        }
      });
    }
  }

  async getLatLngByAddress(address) {
    return await this.processLatLngByAddress(address);
  }

  processLatLngByAddress(address) {
    return this.commonService.getLatLngByAddress(address).then((value: any) => {
     return value;
    });
  }

}
