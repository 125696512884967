import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import * as moment from 'moment';
import { DriverService } from '../../../shared/services/driver/driver.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { Route } from '../../models/route/route.model';
import { AppConstants } from '../../../app.constants';
import { StorageService } from '../../../shared/services/storage/storage.service';
import { CommonService } from '../../../shared/services/common/common.service';
import { Location, Appearance, GermanAddress } from '@angular-material-extensions/google-maps-autocomplete';
import { TranslateService } from '@ngx-translate/core';
import PlaceResult = google.maps.places.PlaceResult;
import { UpsertBusinessPartnerRequest } from 'src/app/information/models/vehicle-information/upsert-business-partner-request.model';
import { MasterDataService } from 'src/app/shared/services/master-data/master-data.service';

@Component({
  selector: 'app-edit-vehicle-trip',
  templateUrl: './edit-vehicle-trip.component.html',
  styleUrls: ['./edit-vehicle-trip.component.scss'],
	providers: [
    { provide: MAT_DATE_FORMATS, useValue: AppConstants.DE_DATE_FORMATS }
	]
})
export class EditVehicleTripComponent implements OnInit {
  showSpinner: boolean = false;
  public appearance = Appearance;
  language;
  userInfo;
  types;
  tripForm: FormGroup;
  title: string;
  isEditFLow: boolean;
  minEndTime: string;
  maxStartTime: string;
  EndTime: string;
  StartTime: string;
  defaultStartTime: string;
  defaultEndTime: string;
  isBusinessPartnerType = false;
  toggleAddNewBusinessPartnerDetails = false;
  startLocation;
  endLocation;
  ErrorMessage;
  isFormSubmit;
  validationMessages = {
    StartDate: {
      required: this.translateService.instant('STARTDATEREQUIRED')
    },
    EndDate: {
      required: this.translateService.instant('ENDDATEREQUIRED')
    },
    StartAddress: {
      required: this.translateService.instant('STARTADDRESSREQUIRED')
    },
    EndAddress: {
      required: this.translateService.instant('ENDADDRESSREQUIRED')
    },
    ReasonOfTrip: {
      required: this.translateService.instant('REASONOFTRIPREQUIRED')
    },
    DrivenDistance: {
      required: this.translateService.instant('DRIVENDISTANCEREQUIRED'),
      invalidNumber: this.translateService.instant('DRIVENDISTANCEINVALIDNUMBER')
    },
    BusinessPartnerAddress: {
      required: this.translateService.instant('BUSINESSPARTNERREQUIRED')
    }
  };
  driversList = [];
  businessPartners = [];
  selectedImgSrc = '';
  isValidEditTimeField = true;
  isAverageSpeedExceeded = false;
  SelectedBusinessPartnerAddress;
  savedBusinessDetails;
  countries = [];
  states = [];
  cities = [];

  constructor(private dialogRef: MatDialogRef<EditVehicleTripComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private driverService: DriverService,
    private masterDataService: MasterDataService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private storageService: StorageService,
    private translateService: TranslateService,
    private changeDetectRef: ChangeDetectorRef,
    private commonService: CommonService) { }

  ngOnInit() {
    this.getBusinessPartners();
    this.selectedImgSrc = 'Germany.png';
    this.language = this.commonService.getLanguages();
    this.isEditFLow = this.data === '' ? false : true;
    if (this.isEditFLow) {
      if (this.data.isCombinedTrip) {
        this.title = this.translateService.instant('COMBINEDTRIP');
        this.StartTime = this.data.StartTime;
        this.EndTime = this.data.EndTime;
      } else {
        this.title =  this.translateService.instant('EDITTRIP');
      }
    } else {
      this.title = this.translateService.instant('CREATENEWTRIP');
    }
    this.types = this.storageService.get('routeTypesList');
    this.createTripFormBuilder(this.data);
    if (this.tripForm.get('StartMileage').value !== '' && this.tripForm.get('DrivenDistance').value !== '') {
      this.updateEndMileage();
    }
    this.tripForm.get('StartAddress').valueChanges.subscribe(value => {
    });
    this.tripForm.get('DrivenDistance').valueChanges.subscribe(value => {
    });
    this.tripForm.get('EndAddress').valueChanges.subscribe(value => {
    });
    this.tripForm.get('StartDate').valueChanges.subscribe(value => {
      this.isFormSubmit = false;
      this.getAverageSpeed();
    });
    this.tripForm.get('EndDate').valueChanges.subscribe(value => {
      this.isFormSubmit = false;
      this.getAverageSpeed();
      this.updateEndMileage();
    });
    this.tripForm.get('BusinessPartnerId').valueChanges.subscribe(value => {
    });
    this.tripForm.get('RouteType').valueChanges.subscribe(value => {
      this.isBusinessPartnerType = this.commonService.isBusinessTripType(value.toUpperCase());
      if (this.isBusinessPartnerType) {
        this.tripForm.get('AdditionalInformation').setValidators(Validators.required);
      } else {
        this.tripForm.controls["AdditionalInformation"].clearValidators();
      }
      this.tripForm.get('AdditionalInformation').updateValueAndValidity();
    });
    this.tripForm.get('DrivenDistance').valueChanges.subscribe(value => {
      this.getAverageSpeed();
    });
    this.userInfo = this.storageService.get('userInfo');
    const userInformation = this.storageService.get('userInformation');
    if (userInformation) {
      this.driversList = [];
      this.driversList.push({
        ID: 0,
        Firstname: userInformation.Firstname,
        Lastname: userInformation.Lastname
      });
      this.tripForm.controls['DriverID'].patchValue(this.driversList[0].ID);
      if (userInformation.AdditionalDrivers && userInformation.AdditionalDrivers.length > 0) {
        userInformation.AdditionalDrivers.map((additionDriver) => {
          this.driversList.push(additionDriver);
        });
        if (this.data.AdditionalDriver) {
          this.tripForm.controls['DriverID'].patchValue(this.data.AdditionalDriver.ID);
        }
      }
    }
    if (!this.isEditFLow) {
      this.defaultStartTime = '00:00';
      this.defaultEndTime = '00:00';
      this.tripForm.controls.RouteType.setValue(this.commonService.getRouteNameOrId('1', AppConstants.nameOrId.GetName));
    } else {
      this.defaultStartTime = this.data.StartTime ? this.data.StartTime.substr(0, 5) : '';
      this.defaultEndTime = this.data.EndTime ? this.data.EndTime.substr(0, 5) : '';
    }

    if ((this.isBusinessPartnerAvailable || this.data.VehicleRouteTypeId) && this.tripForm.get('RouteType').value.toUpperCase() === AppConstants.tripTypes.BUSINESS) {
      this.isBusinessPartnerType = true;
    } else {
      this.isBusinessPartnerType = false;
    }
    if (this.data.BusinessPartner) {
      this.getNearestBusinessPartners();
    }
    if (this.isEditFLow || (this.data !== '' && this.data.isCombinedTrip)) {
      setTimeout(() => {
        this.getAverageSpeed();
      }, 1000);
    }
    this.getCountriesAndStates();
  }
  get isAdminUser() {
    return this.userInfo && this.userInfo.UserRole === 'Admin';
  }
  get isBusinessPartnerAvailable() {
    return this.tripForm.get('BusinessPartnerId').value !== '';
  }
  getAverageSpeed() {
    let result;
    let startTime = this.tripForm.get('StartTime').value;
    let endTime = this.tripForm.get('EndTime').value;
    let DrivenDistance = this.commonService.getFloatWithDotDecimalValue(this.tripForm.get('DrivenDistance').value.toString());
    let startDate = this.tripForm.get('StartDate').value;
    let endDate = this.tripForm.get('EndDate').value;
    if ((!startDate || !endDate) || ((startDate && startDate.toString() === '') || (endDate && endDate.toString() === ''))) {
      // this.snackBar.open(this.translateService.instant('DATETIMEDISTANCEERRORMESSAGE'), this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
      return false;
    } else {
      const tripInNumberOfDays = this.commonService.getDifferanceInDays(startDate, endDate);
      let startTimeSplit = startTime && startTime !== '00:00' ? startTime.split(':'): [0, 0];
      let endTimeSplit = endTime && endTime !== '00:00' ? endTime.split(':'): [0, 0];
      startTimeSplit = parseFloat(startTimeSplit[0] + '.' + startTimeSplit[1]);
      endTimeSplit = parseFloat(endTimeSplit[0] + '.' + endTimeSplit[1]);
      if (tripInNumberOfDays > 3) {
        this.tripForm.get('EndDate').setValue('');
        if (this.isFormSubmit) {
          this.snackBar.open(this.translateService.instant('DATELIMITEXCEEDERRORMESSAGE'), this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
        }
        return false;
      } else if (tripInNumberOfDays === 1) {
        // same day start and end date so then below code executes
        if (startTime && startTime !== '00:00' && endTime && endTime !== '00:00' && DrivenDistance) {
          if (endTimeSplit > startTimeSplit) {
            let resultTime = endTimeSplit-startTimeSplit;
            result = this.commonService.getAverageSpeedByDistanceTime(DrivenDistance, resultTime);
            this.tripForm.controls['AverageSpeed'].setValue(result.toFixed(2));
            if (result > 240) {
              this.isAverageSpeedExceeded = true;
              if (this.isFormSubmit) {
                this.ErrorMessage = this.translateService.instant('AVERAGESPEEDERRORMESSAGE');
              }
              return false;
            } else {
              this.isAverageSpeedExceeded = false;
            }
          } else if (endTimeSplit <= startTimeSplit) {
            if (this.isFormSubmit) {
              this.ErrorMessage = this.translateService.instant('ENDTIMEERRORMESSAGE');
            }
            return false;
          }
        }
      } else if (tripInNumberOfDays === 2 || tripInNumberOfDays === 3) {
        let resultTime = this.commonService.getMinutesByDateTime(startTimeSplit, tripInNumberOfDays, endTimeSplit);
        result = this.commonService.getAverageSpeedByDistanceTime(DrivenDistance, resultTime);
        this.tripForm.controls['AverageSpeed'].setValue(result.toFixed(2));
        if (result > 240) {
          this.isAverageSpeedExceeded = true;
          if (this.isFormSubmit) {
            this.ErrorMessage = this.translateService.instant('AVERAGESPEEDERRORMESSAGE');
          }
        } else {
          this.isAverageSpeedExceeded = false;
        }
      }
      return true;
    }
  }

  getNearestBusinessPartners() {
    let vehicleRouteId = this.data ?  this.data.RouteId: '';
    this.driverService.getNearestBusinessPartners(vehicleRouteId).subscribe((response: any) => {
      this.businessPartners = response;
    });
  }

  getBusinessPartners() {
    this.businessPartners = [];
    this.driverService.getBusinessPartners().subscribe((response: any) => {
      this.businessPartners = response;
      if (this.savedBusinessDetails) {
        this.tripForm.controls.BusinessPartnerId.setValue(this.savedBusinessDetails.businessPartnerId);
      }
    });
  }

  createTripFormBuilder(data: Route) {
    const getRouteTypeName = data ? this.commonService.getRouteNameOrId(data.VehicleRouteTypeId, AppConstants.nameOrId.GetName): undefined;
    let EndDateValue;
    if (data.EndDate) {
      EndDateValue = data.EndDate.indexOf('.') > -1? this.commonService.formatToYYYYMMDD(data.EndDate): data.EndDate;
    } else if (data.StartDate && data.EndDate) {
      EndDateValue = data.EndDate.indexOf('.') > -1? this.commonService.formatToYYYYMMDD(data.StartDate): data.StartDate;
    }
    this.tripForm = this.fb.group({
      StartAddress: [(data.StartAddress ? data.StartAddress : ''), Validators.required],
      StartDate: [(data.StartDate ? data.StartDate.indexOf('.') > -1? this.commonService.formatToYYYYMMDD(data.StartDate): data.StartDate : ''), [Validators.required]], // commented the validation due to SVG-Automation/DriverBookLazyLoadingAppWebsite/issues/63 - this.commonService.validateStartDate()
      StartTime: [(data.StartTime ? data.StartTime.substr(0, 5) : '00:00'), Validators.required],
      EndAddress: [(data.EndAddress ? data.EndAddress : ''), Validators.required],
      EndDate: [EndDateValue, Validators.required],
      EndTime: [(data.EndTime ? data.EndTime.substr(0, 5) : '00:00'), Validators.required],
      RouteType: [(data.VehicleRouteTypeId ? getRouteTypeName : ''), Validators.required],
      AdditionalInformation: [(data.AdditionalInformation ? data.AdditionalInformation : '')],
      BusinessPartnerId: [(data.BusinessPartner ? data.BusinessPartner.ID : '')],
      DrivenDistance: [(data.TotalDistance ? data.TotalDistance : ''), [Validators.required, this.commonService.validateNumberDecimal()]],
      StartMileage: [(data.StartMileage ? this.commonService.processFloatValuesToFixed(data.StartMileage) : '')],
      EndMileage: [(data.EndMileage ? this.commonService.processFloatValuesToFixed(data.EndMileage) : '')],
      DriverID: [(data.AdditionalDriverName ? data.AdditionalDriverName : '')],
      AverageSpeed: [{value: '', disabled: true}],
      BusinessPartnerAddress: [],
      firstname: [],
      lastname: [],
      companyname: [],
      countryId: [],
      federalStateId: [],
      cityId: []
    });
  }

  close() {
    const result = {
      message: this.translateService.instant('VEHCILETRIPUPDATED'),
      isClosedDialog: true
    };
    this.dialogRef.close(result);
  }

  save() {
    if (this.isEditFLow) {
      if (this.data.isCombinedTrip) {
        this.submitUpdatedTripData();
        return;
      }
      this.confirmDialog();
    } else {
      this.submitUpdatedTripData();
    }
  }

  createSelectedDateTime(inputDate, inputTime) {
    if(typeof inputDate === 'string') {
      return new Date ('' + inputDate.split('.')[1] + '-' + inputDate.split('.')[0]
        + '-' + inputDate.split('.')[2] + ' ' + inputTime);
    } else {
      inputDate = moment(inputDate).format('DD.MM.YYYY');
      return new Date ('' + inputDate.split('.')[1] + '-' + inputDate.split('.')[0]
      + '-' + inputDate.split('.')[2] + ' ' + inputTime);
    }
  }

  filterDateBasedRoutes(availableRoutes: Route[]): Route[] {
    let result = [];
    let routeStartDate: number;
    let routeEndDate: number;
    let routeStartDateFormatted: Date;
    let routeEndDateFormatted: Date;
    let StartDateField: number;
    let EndDateField: number;
    result = availableRoutes.filter((route: Route) => {
      if (route.StartDate && typeof route.StartDate === 'string') {
        let startDateSplited: any = route.StartDate.split('.');
        let startDateFormatted: string = ' ' + startDateSplited[1] + '-' + startDateSplited[0] + '-' + startDateSplited[2];
        routeStartDateFormatted = new Date(startDateFormatted);
      }
      if (route.EndDate && typeof route.EndDate === 'string') {
        let endDateSplited: any = route.EndDate.split('.');
        let endDateFormatted: string = ' ' + endDateSplited[1] + '-' + endDateSplited[0] + '-' + endDateSplited[2];
        routeEndDateFormatted = new Date(endDateFormatted);
      }
      StartDateField = this.tripForm.get('StartDate').value && this.tripForm.get('StartDate').value!== '' ? Date.parse(this.tripForm.get('StartDate').value): undefined;
      routeStartDate = route.StartDate && route.StartDate!== '' ? +(routeStartDateFormatted): undefined;

      EndDateField = this.tripForm.get('EndDate').value && this.tripForm.get('EndDate').value!== '' ? Date.parse(this.tripForm.get('EndDate').value): undefined;
      routeEndDate = route.EndDate && route.EndDate!== '' ? +(routeEndDateFormatted): undefined;
      return (StartDateField === routeStartDate) && (EndDateField === routeEndDate);
    });
    return result;
  }

  constructAndInsertStartAndEndDateTimeToRoutes(routes: Route[]) {
    let getDateTime: Date;
    routes.forEach(route => {
      getDateTime = new Date();
      if (route.EndDate !== '' && route.EndTime !== '') {
        getDateTime = this.createSelectedDateTime(route.EndDate, route.EndTime);
        route.EndDateTime = getDateTime;
      }
      if (route.StartDate !== '' && route.StartTime !== '') {
        getDateTime = this.createSelectedDateTime(route.StartDate, route.StartTime);
        route.StartDateTime = getDateTime;
      }
    });
    return routes;
  }

  confirmDialog(): void {
    const title = this.translateService.instant('CONFIRMACTION');
    const message = this.translateService.instant('UPDATEROUTEMESSAGE');
    const dialogData = {
      title: title,
      message: message
    };
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
     width: '70vmin',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.submitUpdatedTripData();
      }
    });
  }

  submitUpdatedTripData() {
    const data: any = {};
    if (this.data) {
      if (!this.data.isCombinedTrip) {
        data.routeId = this.data.RouteId;
      } else if (this.data.isCombinedTrip) {
        data.routeIds = this.data.RouteIds;
      }
    }
    if (!this.isEditFLow || this.data.isCombinedTrip) {
      this.createTrip(data);
    } else {
      this.updateTrip(data);
    }
  }

  createTrip(data) {
    const selectedVehicle = this.storageService.get('selectedVehicle');
    if (selectedVehicle) {
      data.vehicleId = selectedVehicle.VehicleId;
    }
    data.startAddress = this.tripForm.get('StartAddress').value ? this.tripForm.get('StartAddress').value : '';
    data.endAddress = this.tripForm.get('EndAddress').value ? this.tripForm.get('EndAddress').value : '';
    data.startTime = this.StartTime ? this.StartTime : '';
    data.endTime = this.EndTime ? this.EndTime : '';
    data.distance = this.commonService.getFloatWithDotDecimalValue(this.tripForm.get('DrivenDistance').value.toString());
    data.additionalInformation = this.tripForm.get('AdditionalInformation').value ? this.tripForm.get('AdditionalInformation').value : '';
    data.routeTypeId = this.tripForm.get('RouteType').value ?
      this.commonService.getRouteNameOrId(this.tripForm.get('RouteType').value, AppConstants.nameOrId.GetId) : '';
    data.startMileage = this.tripForm.get('StartMileage').value && this.tripForm.get('StartMileage').value !== '' ? this.commonService.processFloatValuesToFixed((this.tripForm.get('StartMileage').value)): 0;
    data.endMileage = this.tripForm.get('EndMileage').value  && this.tripForm.get('EndMileage').value !== '' ? this.commonService.processFloatValuesToFixed((this.tripForm.get('EndMileage').value)): data.startMileage
      + this.commonService.processFloatValuesToFixed(data.distance);
    data.startDate = this.tripForm.get('StartDate').value ? moment(this.tripForm.get('StartDate').value).format('yyyy-MM-DD') : '';
    data.endDate = this.tripForm.get('EndDate').value ? moment(this.tripForm.get('EndDate').value).format('yyyy-MM-DD') : '';
    if (this.tripForm.get('BusinessPartnerId').touched || this.isBusinessPartnerAvailable) {
      data.BusinessPartnerId = this.tripForm.get('BusinessPartnerId').value;
    }
    if (this.data.isCombinedTrip) {
      data.routeTypeId = (data.BusinessPartnerId)? this.commonService.getRouteNameOrId(this.tripForm.get('RouteType').value, 'getId'): (this.data.VehicleRouteTypeId || undefined);
    }
    data.DrivenByEmployeeId = this.tripForm.get('DriverID').value;
    if (this.userInfo.UserRole === AppConstants.userRole.admin) {
      const selectedDriver = this.storageService.get('selectedDriver');
      data.DriverId = selectedDriver.ID;
    }
    this.showSpinner = true;
    this.driverService.createVehicleRoute(data).subscribe((response: any) => {
      this.showSpinner = false;
      if (response.status === 400 || response.status === 404) {
        this.snackBar.open(response.message, this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
        return;
      }
      this.close();
    }, (error) => {
      this.showSpinner = false;
      console.log(error);
    });
  }

  updateTrip(data) {
    this.isFormSubmit= true;
    let result = true;
    let resultAverageSpeed = this.getAverageSpeed();
    this.updateEndMileage();
    let conflictWithOtherTripResult = this.isConflictedWithOtherTrips(this.tripForm.controls.StartTime.value);
    let existingStartDate = moment(this.data.StartDate).format('yyyy-DD-MM').toString();
    result = conflictWithOtherTripResult && resultAverageSpeed;
    if (!result) {
      return;
    }
    if (this.tripForm.get('StartDate').touched && this.tripForm.get('StartDate').value !== existingStartDate) {
      data.startDate = moment(this.tripForm.get('StartDate').value).format('yyyy-MM-DD');
    }

    if (this.tripForm.get('EndDate').touched) {
      data.endDate = moment(this.tripForm.get('EndDate').value).format('yyyy-MM-DD');
    }

    if (this.tripForm.get('StartAddress').touched) {
      data.startAddress = this.tripForm.get('StartAddress').value;
    }

    if (this.tripForm.get('EndAddress').touched) {
      data.endAddress = this.tripForm.get('EndAddress').value;
    }

    if (this.StartTime && this.StartTime !== '') {
      data.startTime = this.StartTime;
    }

    if (this.EndTime && this.EndTime !== '') {
      data.endTime = this.EndTime;
    }

    if (this.tripForm.get('DrivenDistance').touched
      || (this.tripForm.get('StartAddress').touched
      || this.tripForm.get('EndAddress').touched
      || (this.StartTime && this.StartTime !== '')
      || (this.EndTime && this.EndTime !== ''))) {
      data.distance = this.commonService.getFloatWithDotDecimalValue(this.tripForm.get('DrivenDistance').value.toString());
      data.endMileage = this.commonService.processFloatValuesToFixed(this.tripForm.get('EndMileage').value);
    }

    if (this.tripForm.get('DriverID').touched) {
      data.DrivenByEmployeeId = this.tripForm.get('DriverID').value;
    }

    if (this.userInfo.UserRole === AppConstants.userRole.admin) {
      const selectedDriver = this.storageService.get('selectedDriver');
      data.DriverId = selectedDriver.ID;
    }

    if (this.tripForm.get('AdditionalInformation').touched) {
      data.additionalInformation = this.tripForm.get('AdditionalInformation').value;
    }

    if (this.tripForm.get('BusinessPartnerId').touched || this.isBusinessPartnerAvailable) {
      data.BusinessPartnerId = this.tripForm.get('BusinessPartnerId').value;
      // data.businessPartnerName = this.tripForm.get('BusinessPartnerName').value;
    }

    if (this.tripForm.get('RouteType').touched) {
      data.routeTypeId = this.tripForm.get('RouteType').value ? this.commonService.getRouteNameOrId(this.tripForm.get('RouteType').value, AppConstants.nameOrId.GetId) : '';
    }

    if (data && Object.keys(data).length > 1) {
      this.showSpinner = true;
      this.driverService.updateVehicleRoute(data).subscribe((response: any) => {
        this.showSpinner = false;
        if (response.status === 500) {
          this.snackBar.open(this.translateService.instant('VEHICLEMANAGEMENTNOINITIALMILEAGEMESSAGE'), this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
          return;
        }
        this.snackBar.open(response.message, this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
        this.close();
      }, (error) => {
        this.showSpinner = false;
        console.log(error);
      });
    } else {
      this.snackBar.open(this.translateService.instant('NOCHANGEMESSAGE'), this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
    }
  }

  onBlurDistance() {
    this.updateEndMileage();
  }

  updateEndMileage() {
    const distance = this.commonService.getFloatWithDotDecimalValue(this.tripForm.get('DrivenDistance').value.toString());
    const result = parseFloat(distance) + parseFloat(this.tripForm.get('StartMileage').value);
    if (result) {
      this.tripForm.controls['EndMileage'].setValue(this.commonService.processFloatValuesToFixed(Number(result)));
    }
    // this.changeDetectRef.detectChanges();
  }

  onBlurStartMileage() {
    this.updateEndMileage();
  }

  startTimeChanged(e) {
    this.StartTime = e + ':00';
    this.minEndTime = e;
    this.tripForm.controls.StartTime.setValue(e);
    this.isValidEditTimeField = true;
    this.getAverageSpeed();
  }

  isConflictedWithOtherTrips(value) {
    let selectedVehiclePreviousRoute = this.storageService.get('selectedVehiclePreviousRoute');
    if (selectedVehiclePreviousRoute) {
      let currentStartDate = moment(this.tripForm.controls.StartDate.value).format('DD.MM.YYYY');
      let previousDate = selectedVehiclePreviousRoute.EndDate;
      if (currentStartDate === previousDate && value+':00' <= selectedVehiclePreviousRoute.EndTime) {
        this.isValidEditTimeField = false;
        this.ErrorMessage = this.translateService.instant('TIMECONFLICTPREVIOUSERRORMESSAGE');
        return false;
      }
      this.isValidEditTimeField = true;
    }
    return true;
  }

  endTimeChanged(e) {
    this.EndTime = e + ':00';
    this.maxStartTime = e;
    let selectedVehicleNextRoute = this.storageService.get('selectedVehicleNextRoute');
    if (selectedVehicleNextRoute) {
      let currentEndDate = moment(this.tripForm.controls.EndDate.value).format('DD.MM.YYYY');
      let nextDate = selectedVehicleNextRoute.EndDate;
      if (currentEndDate === nextDate && e+':00' >= selectedVehicleNextRoute.StartTime) {
        this.isValidEditTimeField = false;
        this.snackBar.open(this.translateService.instant('TIMECONFLICTNEXTERRORMESSAGE'), this.translateService.instant('CANCEL'), AppConstants.SNACK_BAR_DELAY);
        return;
      }
      this.isValidEditTimeField = true;
    }
    this.tripForm.controls.EndTime.setValue(e);
    this.changeDetectRef.detectChanges();
    this.getAverageSpeed();
    this.updateEndMileage();
  }

  onStartAddressAutocompleteSelected(result: PlaceResult) {
  }

  onEndAddressAutocompleteSelected(result: PlaceResult) {
  }

  onStartAddressLocationSelected(location: Location) {
  }

  onEndAddressLocationSelected(location: Location) {
  }

  onGermanStartAddressMapped($event: GermanAddress) {
    this.tripForm.controls.StartAddress.setValue(this.commonService.formateToGermanAddress($event));
    this.startLocation = $event;
    if (this.tripForm.get('EndAddress').value !== '') {
      this.commonService.calculateDistance(this.startLocation.displayAddress, this.tripForm.controls.EndAddress.value, this.tripForm.controls.DrivenDistance, undefined).then(() => {
        this.updateEndMileage();
      });
    }
  }

  onGermanEndAddressMapped($event: GermanAddress) {
    this.endLocation = $event;
    this.tripForm.controls.EndAddress.setValue(this.commonService.formateToGermanAddress($event));
    if (this.tripForm.get('StartAddress').value !== '') {
      this.commonService.calculateDistance(this.tripForm.controls.StartAddress.value, this.endLocation.displayAddress, this.tripForm.controls.DrivenDistance, undefined).then(() => {
        this.updateEndMileage();
      });
    }
  }

  onChangeRadioGroup($event: any) {
    if (this.tripForm.controls.RouteType.value.toUpperCase() === AppConstants.tripTypes.BUSINESS) {
      this.isBusinessPartnerType = true;
      this.tripForm.get('BusinessPartnerId').setValidators([Validators.required]);
      this.tripForm.get('BusinessPartnerId').updateValueAndValidity();
    } else {
      this.tripForm.get('BusinessPartnerId').clearValidators();
      this.tripForm.get('BusinessPartnerId').updateValueAndValidity();
    }
  }

  onCountryClick(country) {
    if (country && country.name) {
      this.language = [];
      this.language.push(country.code.toLowerCase());
      this.selectedImgSrc = country.name.replaceAll(' ', '').trim() +'.png';
    }
  }

  saveBusinessPartner() {
    this.toggleAddNewBusinessPartnerDetails = !this.toggleAddNewBusinessPartnerDetails;
    let options = {
      CompanyName: this.tripForm.value.companyname,
      FirstName: this.tripForm.value.firstname,
      LastName: this.tripForm.value.lastname
    };
    this.upsertBusinessPartner(options);
  }

  updateBusinessPartnerAddress() {
    let options = {
      BusinessPartnerId: this.savedBusinessDetails.businessPartnerId,
      CityId: this.tripForm.value.cityId,
      CountryId:  this.tripForm.value.countryId,
      FederalStateId: this.tripForm.value.federalStateId,
      Street: this.SelectedBusinessPartnerAddress.streetName,
      HouseNumber: this.SelectedBusinessPartnerAddress.streetNumber
    };
    this.driverService.upsertBusinessPartnerAddress(options).subscribe((response: any) => {
      if (response && response.message && response.status === 200) {
        this.getBusinessPartners();
        this.snackBar.open(response.message, this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
      }
    }, (error) => {
      this.snackBar.open(error.message, this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
    });
  }

  upsertBusinessPartner(data: UpsertBusinessPartnerRequest) {
    this.driverService.upsertBusinessPartner(data).subscribe((response: any) => {
      if (response && response.message && response.status === 200) {
        this.snackBar.open(response.message, this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
      }
      if (response && response.status === 400) {
        return;
      }
      this.savedBusinessDetails = response;
      this.updateBusinessPartnerAddress();
    }, (error: any) => {
      console.log(error);
      // this.snackBar.open(error.message, this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
    });
  }

  getErrorFirstname() {
    return this.tripForm.get('firstname').hasError('required') ? this.translateService.instant('FIRSTNAMEREQUIRED'): '';
  }

  getErrorLastname() {
    return this.tripForm.get('lastname').hasError('required') ? this.translateService.instant('LASTNAMEREQUIRED'): '';
  }

  onBusinessPartnerAddressAutocompleteSelected(event) {
  }

  onBusinessPartnerAddressLocationSelected(event) {
  }

  onGermanBusinessPartnerAddressMapped(event) {
    this.SelectedBusinessPartnerAddress = event;
    this.tripForm.controls.BusinessPartnerAddress.setValue(this.commonService.formateToGermanAddress(event));
    let countryName;
    countryName = (this.SelectedBusinessPartnerAddress.country.long.toLocaleLowerCase() === 'germany') ? 'Deutschland': this.SelectedBusinessPartnerAddress.country.long;
    let country = this.commonService.getCountryORStateORCityDetails(countryName, this.countries);
    this.tripForm.controls.countryId.setValue(country[0].ID);
    let state = this.commonService.getCountryORStateORCityDetails(this.SelectedBusinessPartnerAddress.state.long, country[0].States);
    this.tripForm.controls.federalStateId.setValue(state[0].ID);
    this.getCitiesData(state[0]);
  }

  getCitiesData(state) {
    const id = state && state.ID ? state.ID: state;
    this.masterDataService.getCities(id).subscribe((response: any) => {
      this.cities = response;
      localStorage.setItem('Cities', JSON.stringify(response));
      let city = this.commonService.getCountryORStateORCityDetails(this.SelectedBusinessPartnerAddress.locality.long, this.cities);
      this.tripForm.controls.cityId.setValue(city[0].ID);
    }, (error) => {
      console.log(error);
    });
  }

  getCountriesAndStates() {
    this.masterDataService.getCountriesandStates().subscribe((response: any) => {
      this.countries = response;
      this.storageService.set('Countries', this.countries);
    }, (error) => {
      console.log(error);
    });
  }

  onChangeFirstname() {

  }

  onChangeLastname() {

  }

  onChangeCompanyName() {

  }

}
