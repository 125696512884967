import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-inspection-warning',
  templateUrl: './inspection-warning.component.html',
  styleUrls: ['./inspection-warning.component.scss']
})
export class InspectionWarningComponent implements OnInit {

  displayedInspectionColumns: string[] = ['MessageId', 'FromDate', 'RemainingKilometer', 'RemainingTime'];
  @Input() InspectionDataSource: any;

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor() { }

  ngOnInit() {
  }

}
