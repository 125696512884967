import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MasterDataService {
  
  CR_URL = environment.crapi.URL;

  constructor(private http: HttpClient) { }

  getAddressTypes() {
    return this.http.get(this.CR_URL + '/MasterData/AddressTypes', {
      headers: this.getAuthorizationHeaders()
    });
  }

  getCountriesandStates() {
    return this.http.get(this.CR_URL + '/MasterData/CountriesAndStates', {
      headers: this.getAuthorizationHeaders()
    });
  }

  getCities(id: string) {
    return this.http.get(this.CR_URL + '/MasterData/Cities/'+ id, {
      headers: this.getAuthorizationHeaders()
    });
  }

  getCompanyCategories() {
    return this.http.get(this.CR_URL+ '/MasterData/CompanyCategories', {
      headers: this.getAuthorizationHeaders()
    });
  }

  getAuthorizationHeaders() {
    const headers = {
      Authorization: ''
    };
    const session = localStorage.getItem('CrApiSession');
    if (session && session !== '') {
      const storageSession = JSON.parse(session);
      headers.Authorization = storageSession.token_type + ' ' + storageSession.access_token;
    }
    return headers;
  }

}
