import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from '../../../shared/services/common/common.service';

@Component({
  selector: 'app-update-standard-driver-modal',
  templateUrl: './update-standard-driver-modal.component.html',
  styleUrls: ['./update-standard-driver-modal.component.scss']
})
export class UpdateStandardDriverModalComponent implements OnInit {
  title: string;
  EditStandardDriverForm: FormGroup;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    public dialogRef: MatDialogRef<UpdateStandardDriverModalComponent>) {
      this.title = this.data.title;
    }

  ngOnInit() {
    if (this.data) {
      this.EditStandardDriverForm = this.formBuilder.group({
        Id: [this.data? this.data.ID: ''],
        Firstname: [{
            value: this.data? this.data.Firstname: '',
            disabled: true
          },
          [Validators.required, this.commonService.validateAlphabetString()]
        ],
        Lastname: [{
            value: this.data? this.data.Lastname: '',
            disabled: true
          },
          [Validators.required, this.commonService.validateAlphabetString()]
        ],
        Email: [this.data? this.data.Email: '', [Validators.required, this.commonService.validateEmail()]],
        TelephoneNumber: [this.data? this.data.TelephoneNumber:'', [Validators.required, this.commonService.validateNumber()]],
        MobileNumber: [this.data? this.data.MobileNumber:'', [Validators.required, this.commonService.validateNumber()]]
      });
    }
  }

  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close({
      isDialogClose: true,
      data: this.EditStandardDriverForm.value
    });
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }

}
