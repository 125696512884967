import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogRef } from '@angular/material/dialog';

import { AuthService } from './auth/services/auth/auth.service';
import { AuthGuard } from './auth/services/auth-guard/auth-guard.service';
import { ExportExcelService } from './shared/services/export-excel/export-excel.service';
import { ExportPdfService } from './shared/services/export-pdf/export-pdf.service';
import { MasterDataService } from './shared/services/master-data/master-data.service';
import { AppDateAdapterService } from './shared/services/date-adapter/date-adapter.service';
import { StorageService } from './shared/services/storage/storage.service';
import { CommonService } from './shared/services/common/common.service';
import { TranslateConfigService } from './shared/services/translate/translate-config.service';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { GeofenceService } from './geofence/services/geofence/geofence.service';
import { SharedModule } from './shared/shared.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { InformationModule } from './information/information.module';
import { AuthModule } from './auth/auth.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    AuthModule,
    DashboardModule,
    InformationModule
  ],
  providers: [
    AuthService,
    AuthGuard,
    MasterDataService,
    ExportExcelService,
    ExportPdfService,
    AppDateAdapterService,
    StorageService,
    CommonService, 
    {
      provide: MatDialogRef,
      useValue: undefined
    },
    TranslateConfigService,
    GeofenceService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
