import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StorageService } from '../../../shared/services/storage/storage.service';
import { AppConstants } from '../../../app.constants';
import { UpsertAddressRequest } from '../../models/vehicle-information/upsert-address-request.model';
import { DriverService } from '../../../shared/services/driver/driver.service';
import { MasterDataService } from '../../../shared/services/master-data/master-data.service';

@Component({
  selector: 'app-add-address',
  templateUrl: './add-address.component.html',
  styleUrls: ['./add-address.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddAddressComponent implements OnInit {

  addressForm: FormGroup;
  addressText = '';
  resultTitle = '';
  editFlow = false;
  addressTypes;
  title: string;
  countries;
  states;
  cities;
  SearchState = this.translateService.instant('SEARCHSTATE');
  SearchCity = this.translateService.instant('SEARCHCITY');
  @ViewChild('singleStateSelect') singleStateSelect: MatSelect;
  @ViewChild('singleCitySelect') singleCitySelect: MatSelect;

  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();

  constructor(private dialogRef: MatDialogRef<AddAddressComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private driverService: DriverService,
    private masterDataService: MasterDataService,
    private translateService: TranslateService,
    private storageService: StorageService,
    private snackBar: MatSnackBar) {
    this.addressText = '';
    this.title = this.data.title || '';
    if (this.data && this.data.address) {
      this.editFlow = true;
      this.addressText = this.translateService.instant('EDIT');
    }
    if (this.title === AppConstants.DriverAddressType.WORK) {
      this.resultTitle = this.translateService.instant('WORKADDRESS');
    } else if(this.title === AppConstants.DriverAddressType.HOME) {
      this.resultTitle = this.translateService.instant('HOMEADDRESS');
    }
  }

  ngOnInit() {
    this.addressForm = this.fb.group({
      houseNo: [this.data.address ? this.data.address.HouseNumber : '', Validators.required],
      street: ['', Validators.required],
      country: ['', Validators.required],
      federalState: [''],
      city: ['', Validators.required],
      postalCode: ['', Validators.required],
      addressTypeId: ['']
    });

    this.getAddressTypes();
  }

  getAddressTypes() {
    this.masterDataService.getAddressTypes().subscribe((response) => {
      this.addressTypes = response;
      if (this.editFlow) {
        this.addressForm.controls.addressTypeId.setValue(this.data.address.AddressTypeId);
      }
    }, (error) => {
      console.log(error);
    });
  }

  selectedCountry(countryId) {
    this.countries.forEach(country => {
      if (country.ID === countryId) {
        this.states = country.States;
        localStorage.setItem('States', JSON.stringify(country.States));
      }
    });
  }

  getAddressType() {
    return this.title === AppConstants.DriverAddressType.WORK? this.addressTypes[1].Name: this.addressTypes[0].Name;
  }

  selectedCity(city) {
    console.log(city);
  }

  selectedAddressType(addressType) {
    console.log(addressType);
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    let requestData: UpsertAddressRequest = {
      AddressType: (this.title === AppConstants.DriverAddressType.WORK? this.addressTypes[1].ID: this.addressTypes[0].ID),
      City: this.addressForm.value.city,
      Country: this.addressForm.value.country,
      FederalState: this.addressForm.value.federalState,
      Street: this.addressForm.value.street,
      HouseNumber: this.addressForm.value.houseNo,
      PostalCode: this.addressForm.value.postalCode,
    }

    if (this.editFlow) {
      requestData.ID = this.data.address.ID;
    }

    // console.log(requestData);
    this.driverService.saveDriverAddress(requestData).subscribe((response: any) => {
      // console.log('Added Address response', response);
      if(response && response.status === 200) {
        this.snackBar.open(this.translateService.instant('SAVEADDRESSMESSAGE'), this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
      }
      // else {
      //   this.snackBar.open(response.message, this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
      // }
      console.log(response.message);
      this.dialogRef.close(true);
    }, (error: any) => {
      this.snackBar.open(error, this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
      this.close();
    });
  }

}
