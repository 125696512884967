<mat-dialog-content>
    <div *ngIf="mileageData.CheckMileage && mileageData.InitialMileageUpdate">
        <h2 mat-dialog-title>{{'INITALMILEAGETITLE' | translate }}</h2>
        <p>{{'INITALMILEAGEMESSAGE' | translate }}:</p>
    </div>
    <div *ngIf="!(mileageData.CheckMileage && mileageData.InitialMileageUpdate)">
        <h2 mat-dialog-title>{{'CURRENTMILEAGEOF' | translate }} <b>{{mileageData.VehicleBrand}} ({{mileageData.PlateNumber}})</b></h2>
        <p>{{'CURRENTMILEAGEMESSAGE' | translate }}:</p>
    </div>
    <form class="mileage-form" [formGroup]="mileageForm">
        <mat-form-field class="example-full-width">
            <mat-label>{{'MILEAGEKM' | translate}}</mat-label><!-- placeholder="{{'MILEAGEPLACEHOLDER' | translate}}" -->
            <input matInput (change)="mileageChange()" formControlName="mileage" required>
            <mat-error *ngIf="mileageform.mileage && mileageform.mileage.invalid">
                <div *ngIf="mileageform.mileage.errors.validation">{{mileageform.mileage.errors.message}}</div>
                <div *ngIf="mileageform.mileage.errors.required">
                    {{ 'MILEAGEREQUIREDMESSAGE' | translate}}
                </div>
            </mat-error>
        </mat-form-field>
    </form>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center">
    <button fxFlex.xs="100" mat-raised-button class="close" (click)="remindMeClick()">{{'REMINDMELATER' | translate}}</button>
    <button fxFlex.xs="100" mat-raised-button class="save" color="primary" [disabled]="mileageForm.invalid" (click)="save()">{{'SAVE' | translate}}</button>
</mat-dialog-actions>