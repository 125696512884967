<button mat-icon-button class="close-button" [mat-dialog-close]="true">
    <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>
<h1 mat-dialog-title>
    <div fxLayout="row">
        <div fxFlex="80" fxFlex.sm="80" fxFlex.xs="100">{{title}}:</div>
        <div fxFlex="20" fxFlex.sm="20" fxFlex.xs="100"></div>
    </div>
</h1>
<div class="contactus" mat-dialog-content>
    <form [formGroup]="contactUsForm" class="example-form">
        <mat-form-field fxLayout="row" class="example-full-width" appearance="outline">
            <mat-label>{{'EMAIL' | translate}}:</mat-label>
            <input matInput placeholder="{{'EMAILPLACEHOLDER'| translate}}" formControlName="Email" required/>
            <mat-error *ngIf="contactUsForm.get('Email').hasError('required')">
                {{'FIELDREQUIRED' | translate}}
            </mat-error>
            <mat-error *ngIf="contactUsForm.get('Email').hasError('invalidEmail')">
                {{'EMAILINCORRECTMESSAGE' | translate}}
            </mat-error>
        </mat-form-field>
        <mat-form-field fxLayout="row" class="example-full-width" appearance="outline">
            <mat-label>{{'PHONENUMBER' | translate}}:</mat-label>
            <input matInput placeholder="{{'PHONENUMBERPLACEHOLDER'| translate}}" formControlName="TelephoneNumber" required/>
            <mat-error *ngIf="contactUsForm.get('TelephoneNumber').hasError('required')">
                {{'FIELDREQUIRED' | translate}}
            </mat-error>
            <mat-error *ngIf="contactUsForm.get('TelephoneNumber').hasError('invalidNumber')">
                {{'INVALIDNUMBER' | translate}}
            </mat-error>
        </mat-form-field>
        <mat-form-field fxLayout="row" class="example-full-width" appearance="outline">
            <mat-label>{{'MOBILENUMBER' | translate}}:</mat-label>
            <input matInput placeholder="{{'MOBILENUMBERPLACEHOLDER'| translate}}" formControlName="MobileNumber" required/>
            <mat-error *ngIf="contactUsForm.get('MobileNumber').hasError('required')">
                {{'FIELDREQUIRED' | translate}}
            </mat-error>
            <mat-error *ngIf="contactUsForm.get('MobileNumber').hasError('invalidNumber')">
                {{'INVALIDNUMBER' | translate}}
            </mat-error>
        </mat-form-field>
        <mat-form-field fxLayout="row" class="example-full-width" appearance="outline">
            <mat-label>{{'SUBJECT' | translate}}:</mat-label>
            <input matInput placeholder="{{'SUBJECTPLACEHOLDER'| translate}}" formControlName="Subject" required/>
            <mat-error *ngIf="contactUsForm.get('Subject').hasError('required')">
                {{'FIELDREQUIRED' | translate}}
            </mat-error>
        </mat-form-field>
        <mat-form-field fxLayout="row" class="example-full-width" appearance="outline">
            <mat-label>{{'DESCRIPTION' | translate}}:</mat-label>
            <textarea matInput placeholder="{{'INFORMATIONBACKOFFICEPLACEHOLDER'| translate}}" formControlName="Description" required></textarea>
            <mat-error *ngIf="contactUsForm.get('Description').hasError('required')">
                {{'FIELDREQUIRED' | translate}}
            </mat-error>
        </mat-form-field>
        <span *ngIf="data && data.selectedRouteInfo">{{'TRIPINFORMATION' | translate}}:</span>
        <br/><br/>
        <div fxLayout="row" fxLayoutAlign="none" class="selection-list" *ngIf="data && data.selectedRouteInfo">
            <mat-card fxLayout="column" fxLayoutAlign="none">
                <div class="subheader-section" fxLayout="row" fxLayout.sm="row" fxLayout.xs="column">
                    <span class="subheader-section-text"><b>{{'FROMDATE' | translate}}:</b></span>
                    <span class="subheader-section-value">{{ data.selectedRouteInfo.StartDate }}</span>
                </div>
                <div class="subheader-section" fxLayout="row" fxLayout.sm="row" fxLayout.xs="column">
                    <span class="subheader-section-text"><b>{{'TODATE' | translate}}:</b></span>
                    <span class="subheader-section-value">{{ data.selectedRouteInfo.EndDate }}</span>
                </div>
                <div class="trip-section-row-1" fxLayout="column">
                    <div class="address" fxFlex="50" fxFlex.sm="100" fxFlex.xs="100" fxLayoutAlign="start center">
                        <span><b>{{'STARTADDRESS' | translate}}:</b> {{data.selectedRouteInfo.StartAddress}}</span>
                    </div>
                    <div class="address" fxFlex="50" fxFlex.sm="100" fxFlex.xs="100" fxLayoutAlign="start center">
                        <span><b>{{'ENDADDRESS' | translate}}:</b> {{data.selectedRouteInfo.EndAddress}}</span>
                    </div>
                </div>
                <div class="trip-section-row-2" fxLayout="column">
                    <div class="time login-time" fxFlex="50" fxFlex.sm="100" fxFlex.xs="100" fxLayoutAlign="start center">
                        <span><b>{{'STARTTIME' | translate}}:</b> {{data.selectedRouteInfo.StartTime}}</span>
                    </div>
                    <div class="time logout-time" fxFlex="50" fxFlex.sm="100" fxFlex.xs="100" fxLayoutAlign="start center">
                        <span><b>{{'ENDTIME' | translate}}:</b> {{data.selectedRouteInfo.EndTime}}</span>
                    </div>
                </div>
            </mat-card>
        </div>
        <br/>
    </form>
</div>

<div mat-dialog-actions align="end">
    <button mat-raised-button (click)="onDismiss()">{{'CLOSE'| translate}}</button>
    <button mat-raised-button [disabled]="contactUsForm.invalid" color="primary" (click)="onConfirm()">{{'CONFIRM'| translate}}</button>
</div>