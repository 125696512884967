<button mat-icon-button class="close-button" [mat-dialog-close]="true">
    <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>
<h1 mat-dialog-title>
    <div fxLayout="row">
        <div fxFlex="80" fxFlex.sm="80" fxFlex.xs="100">{{title}}:</div>
        <div fxFlex="20" fxFlex.sm="20" fxFlex.xs="100"></div>
    </div>
</h1>
<div class="contactus" mat-dialog-content>
    <mat-card class="expiryTripInfo" *ngIf="expiryTripsInformation">
        {{'NOTE'|translate}} : 
        <div [innerHTML]="expiryTripsInformation"></div>
    </mat-card>
</div>

<div mat-dialog-actions align="end">
    <button mat-raised-button (click)="onDismiss()">{{'CLOSE'| translate}}</button>
</div>