import { Component, OnInit, ViewEncapsulation, ViewChild, ChangeDetectorRef} from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from './auth/services/auth/auth.service';
import { ExportExcelService } from './shared/services/export-excel/export-excel.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ExportPdfService } from './shared/services/export-pdf/export-pdf.service';
import * as moment from 'moment';
import { AppConstants } from './app.constants';
import { StorageService } from './shared/services/storage/storage.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslateConfigService } from './shared/services/translate/translate-config.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DriverService } from './shared/services/driver/driver.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { CommonService } from './shared/services/common/common.service';
import { TermsConditionsBottomSheetComponent } from './shared/components/terms-conditions-bottom-sheet/terms-conditions-bottom-sheet.component';
import { CommonBaseComponent } from './shared/components/common-base/common-base.component';
import { MediaMatcher } from '@angular/cdk/layout';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent extends CommonBaseComponent implements OnInit {
  title = 'DriverLogBook';
  routes: Array<{ label: string, link: string }> = [];
  userMenuItems: Array<{ label: string, link: string, icon: string }> = [];
  userInfo;
  isLoggedIn: Observable<boolean>;
  languages;
  selectedLanguage;

  sideNavMenu = true;
  drawerBackdropEnable = false;
  deviceInfo;
  selectedReportYear;
  @ViewChild('sidenav') sidenav;

  rightSideNaveMenu = true;
  drawerRightBackdropEnable = false;
  @ViewChild('rightSideNav') rightSideNav;
  vehicleData: any;

  mobileQuery: MediaQueryList;
  _mobileQueryListener: () => void;

  constructor(private authService: AuthService,
    private router: Router,
    public exportExcelService: ExportExcelService,
    private exportPdfService: ExportPdfService,
    private snackBar: MatSnackBar,
    protected storageService: StorageService,
    protected translateService: TranslateService,
    private deviceService: DeviceDetectorService,
    private driverService: DriverService,
    private commonService: CommonService,
    private bottomSheet: MatBottomSheet,
    protected translateConfigService: TranslateConfigService,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher
    ) {
    super(translateConfigService, translateService, storageService);
    super.ngOnInit();

    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }


  ngOnInit() {
    this.setDefaultLanguage();
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isLoggedIn = this.authService.isUserLoggedIn;

    this.authService.isUserLoggedIn.subscribe((data) => {
      this.loadUserInfo();
      this.updateMenuItems();
    });
    this.loadUserInfo();
    this.updateMenuItems();

    setTimeout(() => {
      this.loadUserInfo();
      this.updateMenuItems();
    }, 1500);

    this.commonService.rightSideNavSubject$.subscribe((data) => {
      if (data) {
        this.rightSideNavCloseClicked();
        this.vehicleData = data;
      }
    }, (error: Error) => {
      console.log(error);
    });
    this.openBottomSheet();
  }

  updateMenuItems() {
    if (this.userInfo) {
      this.userMenuItems = [
        {
          label: 'EXPORT',
          icon: 'cloud_download',
          link: 'dashboard/rides'
        },
        {
          label: 'FAQ',
          icon: 'festival',
          link: 'documentViewer',
        },
        {
          label: 'PREFFEREDCOUNTRY',
          icon: 'festival',
          link: 'preffered-country',
        },
        {
          label: 'CHANGEPASSWORD',
          icon: 'vpn_key',
          link: 'change-password',
        },
        {
          label: 'LOGOUT',
          icon: 'power_settings_new',
          link: 'logout'
        }
      ];

      this.routes = [
        {
          label: 'RIDES',
          link: '/dashboard/rides',
        },
        {
          label: 'INFORMATION',
          link: '/dashboard/information',
        }
      ];

      if (this.userInfo && this.userInfo.UserRole !== AppConstants.userRole.driver) {
        this.routes = this.routes.concat([
          {
            label: 'GEOFENCERULES',
            link: '/dashboard/geofence',
          },
          {
            label: 'VEHICLESTAB',
            link: '/dashboard/vehicles',
          },
          {
            label: 'TENANTMANAGEMENTTAB',
            link: '/dashboard/client-management',
          },
          {
            label: 'GPSTRACKER',
            link: '/dashboard/gps-tracker',
          }
        ]);
      }
    } else {
      this.userMenuItems = [];
      this.routes = [];
    }
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  isDisplayedMenu() {
    return this.sideNavMenu && this.isCrApiTokenAvailable;
  }

  get isCrApiTokenAvailable() {
    return this.storageService.get('CrApiSession')? true: false;
  }

  loadUserInfo() {
    const userInfo = this.storageService.get('userInfo');
    if (userInfo) {
      this.userInfo = userInfo;
    }
  }

  openBottomSheet(): void {
    let termsAndCondition = this.storageService.get('termsAndCondition');
    if (!termsAndCondition) {
      this.bottomSheet.open(TermsConditionsBottomSheetComponent);
    }
  }

  exportToExcel() {
    let driverBookResponse = [];
    let dataForExcel = [];

    const DriverBookInfo = this.storageService.get('DriverBookInfo');
    const VehicleMileage = this.storageService.get('vehicleMileage');
    const UserInfo = this.storageService.get('userInfo');
    const SelectedVehicle = this.storageService.get('selectedVehicle');
    const FilteredDates = this.storageService.get('filteredDates');
    const TripsSummary = this.storageService.get('tripsSummary');
    const VehicleReport = this.storageService.get('vehicleReport');
    const options = {
      driverName: '',
      vehicleNumber: '',
      initalMileage: '',
      finalMileage: '',
      initialTripDate: '',
      finalTripDate: '',
      tripsSummary: '',
      VehicleReport: ''
    };

    if (VehicleMileage) {
      options.vehicleNumber = VehicleMileage.VehicleBrand + ' - ' + VehicleMileage.PlateNumber;
    }

    let initialTripDate = moment().subtract(7, 'd').format('DD.MM.YYYY');
    let finalTripDate = moment().format('DD.MM.YYYY');
    if (FilteredDates) {
      initialTripDate = moment(FilteredDates.startDate).format('DD.MM.YYYY');
      finalTripDate = moment(FilteredDates.endDate).format('DD.MM.YYYY');
    }
    options.initialTripDate = initialTripDate;
    options.finalTripDate = finalTripDate;

    if (SelectedVehicle) {
      options.vehicleNumber = SelectedVehicle.Vehicle;
    }

    if (UserInfo) {
      options.driverName = UserInfo.name;
    }

    if (DriverBookInfo) {
      driverBookResponse = DriverBookInfo;
      const headerObject = this.translateService.instant('ROUTESTABELHEADER');
      let rowValues = [];
      if (driverBookResponse && driverBookResponse.length > 0) {
        let Date = '--';
        driverBookResponse.forEach((row: any) => {
          rowValues = [];
          rowValues[0] = row.StartAddress && row.VehicleRouteTypeId !== 1 ? row.StartAddress : '--';
          rowValues[1] = row.EndAddress && row.VehicleRouteTypeId !== 1? row.EndAddress : '--';

          rowValues[2] = (row.StartMileage || row.StartMileage === 0) && (row.EndMileage || row.EndMileage === 0) ? row.StartMileage + ' - ' + row.EndMileage : '--';
          rowValues[3] = row.TotalDistance ? row.TotalDistance.toString() : '--';
          if (row && row.VehicleRouteTypeId) {
            if (row.VehicleRouteTypeId === 1) {
              rowValues[4] = this.translateService.instant(AppConstants.tripTypes.PRIVATE);
            } else if (row.VehicleRouteTypeId === 2) {
              rowValues[4] = this.translateService.instant(AppConstants.tripTypes.BUSINESS);
            } else if (row.VehicleRouteTypeId === 3) {
              rowValues[4] = this.translateService.instant(AppConstants.tripTypes.COMMUTEHOME);
            }  else if (row.VehicleRouteTypeId === 4) {
              rowValues[4] = this.translateService.instant(AppConstants.tripTypes.COMMUTEWORK);
            }
          } else {
            rowValues[4] = '--';
          }
          rowValues[5] = row.AdditionalInformation ? row.AdditionalInformation : '--';
          rowValues[6] = row.BusinessPartner ? row.BusinessPartner.FirtName + ' '+ row.BusinessPartner.LastName : '--';
          rowValues[7] = row.BookingDate ? row.BookingDate : '--';
          rowValues[8] = row.LastUpdatedDateTime ? row.LastUpdatedDateTime : '--';
          rowValues[9] = row.IsDeleted ? row.IsDeleted : row.IsDeleted;
          if (row.StartDate) {
            if (Date !== row.StartDate) {
              dataForExcel.push([row.StartDate]);
              Date = row.StartDate;
            }
          } else if (row.EndDate) {
            if (Date !== row.EndDate) {
              dataForExcel.push([row.EndDate]);
              Date = row.EndDate;
            }
          }
          dataForExcel.push(rowValues);
        });
        options.initalMileage = driverBookResponse[0].StartMileage;
        const lastDriverBookObject = driverBookResponse[driverBookResponse.length - 1];
        options.finalMileage = lastDriverBookObject.EndMileage ? lastDriverBookObject.EndMileage : lastDriverBookObject.StartMileage;
      }

      if (TripsSummary) {
        options.tripsSummary = TripsSummary;
      }

      if (VehicleReport) {
        options.VehicleReport = VehicleReport;
      }

      const excelReportData = {
        title: this.translateService.instant('DRIVERLOGBOOKTITLE'),
        data: dataForExcel,
        headers: headerObject,
        option: options
      };
      this.exportPdfService.exportPdf(excelReportData);
    } else {
      this.snackBar.open(this.translateService.instant('NODATAAVAILABLEMESSAGE'), this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
    }
  }

  menuClicked() {
    this.toggleMenu();
  }

  drawerBackdropClicked() {
    this.sidenav.toggle();
    this.sideNavMenu = true;
    this.rightSideNav.toggle();
    this.rightSideNaveMenu = true;
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
  }

  rightSideNavCloseClicked() {
    this.rightSideNav.toggle();
    this.rightSideNaveMenu = !this.rightSideNaveMenu;
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
  }

  closedStart() {
  }

  onClose() {
  }

  navigate(route) {
    if (route.label === 'LOGOUT') {
      this.authService.setAuthenticated(false);
      this.commonService.removeLocalStorageValues();
      this.userInfo = null;
      this.updateMenuItems();
    } else if (route.label === 'EXPORT') {
      this.getYearReportDialogConfirmation();
    }

    let rout= route;
    setTimeout(() => {
      this.router.navigate([rout.link]);
    }, 10);
  }

  navigateToggle(route) {
    this.toggleMenu();
    this.navigate(route);
  }

  getYearReportDialogConfirmation() {
    let filteredDates = this.storageService.get('filteredDates');
    let data = {
      startYear: filteredDates.startDate ? filteredDates.startDate.split('.')[0]: null,
      endYear: filteredDates.startDate && filteredDates.endDate && filteredDates.startDate.split('.')[0] !== filteredDates.endDate.split('.')[0]?  filteredDates.endDate.split('.')[0]: null
    };
    this.getVehicleReport(data);
  }

  getVehicleReport(value) {
    let selectedVehicle = this.storageService.get('selectedVehicle');
    let defaultVehicle = this.storageService.get('defaultVehicle');
    this.selectedReportYear = value;
    this.storageService.set('selectedReportYear', this.selectedReportYear);
    let vehicleId;
    if ((selectedVehicle && selectedVehicle !== []) || selectedVehicle.length > 0) {
      vehicleId = selectedVehicle.VehicleId;
    } else if (defaultVehicle) {
      vehicleId = defaultVehicle.VehicleId;
    }
    let data = {
      vehicleID: vehicleId
    };
    if (this.selectedReportYear.startYear) {
      data['StartYear'] = this.selectedReportYear.startYear;
    }
    if (this.selectedReportYear.endYear) {
      data['EndYear'] = this.selectedReportYear.endYear;
    }
    this.driverService.getVehicleReport(data).subscribe((response) => {
      this.storageService.set('vehicleReport', response);
      this.exportToExcel();
    }, (error) => {
      this.snackBar.open(this.translateService.instant('ERRORMESSAGE'),
        this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
    });
  }

  toggleMenu() {
    this.sidenav.toggle();
    this.sideNavMenu = !this.sideNavMenu;
  }

  setDefaultLanguage() {
    this.translateConfigService.setPrefferedLanguage();
    this.languages = this.translateConfigService.languages;
    this.selectedLanguage = this.languages[0];
  }

  languageChange(value) {
    this.translateConfigService.setSelectedLanguage(value);
  }

  displayMessage() {
    this.translateService.get('INPROGRESS').subscribe((res: string) => {
      this.snackBar.open(res, this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
    });
  }

  goInformationPage() {
    this.router.navigate(['/dashboard/information']);
  }
}
