<h1 mat-dialog-title>
    {{title}}
</h1>

<div class="year-form-confirmation" mat-dialog-content>
    <form [formGroup]="YearForm">
        <mat-form-field appearance="outline">
            <mat-label>{{'CHOOSEYEAR'| translate}}:</mat-label>
            <mat-select formControlName="SelectedYear">
                <mat-option *ngFor="let year of years" [value]="year">{{year}}</mat-option>
            </mat-select>
        </mat-form-field>
    </form>
</div>

<div mat-dialog-actions align="end">
    <button mat-button (click)="onDismiss()">{{'NO'| translate}}</button>
    <button mat-raised-button color="primary" (click)="onConfirm()">{{'YES'| translate}}</button>
</div>