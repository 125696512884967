<h1 mat-dialog-title>
    {{title}}
</h1>
  
<div mat-dialog-content>
    <form class="edit-business-partner-form-section" [formGroup]="EditBusinessPartnerForm">
        <div fxLayout="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="space-between center">
            <mat-form-field class="example-full-width" appearance="outline" fxFlex="45" fxFlex.sm="45" fxFlex.xs="100">
                <mat-label>{{'FIRSTNAME' | translate}}</mat-label>
                <input matInput placeholder="{{'FIRSTNAMEPLACEHOLDER' | translate}}" formControlName="Firstname" required>
                <mat-error *ngIf="EditBusinessPartnerForm.get('Firstname').hasError('required')">
                    {{'FIELDREQUIRED' | translate}}
                </mat-error>
                <mat-error *ngIf="EditBusinessPartnerForm.controls['Firstname'].errors?.invalidAlphabets && EditBusinessPartnerForm.controls['Firstname'].touched">
                    {{ 'INVALIDALPHABET' | translate }}
                </mat-error>
            </mat-form-field>
            <mat-form-field class="example-full-width" appearance="outline" fxFlex="45" fxFlex.sm="45" fxFlex.xs="100">
                <mat-label>{{'SURNAMEORLASTNAME' | translate}}</mat-label>
                <input matInput placeholder="{{'SURNAMEPLACEHOLDER' | translate}}" formControlName="Surname" required>
                <mat-error *ngIf="EditBusinessPartnerForm.get('Surname').hasError('required')">
                    {{'FIELDREQUIRED' | translate}}
                </mat-error>
                <mat-error *ngIf="EditBusinessPartnerForm.controls['Surname'].errors?.invalidAlphabets && EditBusinessPartnerForm.controls['Surname'].touched">
                    {{ 'INVALIDALPHABET' | translate }}
                </mat-error>
            </mat-form-field>
        </div>
        <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>{{'COMPANY' | translate}}</mat-label>
            <input matInput placeholder="{{'COMPANYPLACEHOLDER' | translate}}" formControlName="Company" required>
            <mat-error *ngIf="EditBusinessPartnerForm.get('Company').hasError('required')">
                {{'FIELDREQUIRED' | translate}}
            </mat-error>
        </mat-form-field>
        <div>
            <app-business-partner-addresses [contact]="data.contact" 
                (businessPartnerAddAddressEmit)="businessPartnerAddAddressEmit($event)"
                (businessPartnerUpdateAddressEmit)="businessPartnerUpdateAddressEmit($event)"
                (businessPartnerDeleteAddressEmit)="businessPartnerDeleteAddressEmit($event)">
            </app-business-partner-addresses>
        </div>
    </form>
</div>
<div mat-dialog-actions align="end">
    <button mat-raised-button (click)="onDismiss()">{{'CLOSE'| translate}}</button>
    <!-- <button mat-raised-button [disabled]="!EditBusinessPartnerForm.valid" color="primary" (click)="onConfirm()">{{'CONFIRM'| translate}}</button> -->
</div>