import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { ForwardMessage } from '../../../information/models/forward-message/forward-message.model';
import * as moment from 'moment';
import { UpsertAddressRequest } from '../../../information/models/vehicle-information/upsert-address-request.model';
import { PostMileageRequest } from '../../models/mileage/post-mileage-request.model';
import { UpsertAdditionalDriverRequest } from '../../models/upsert-additional-driver-request.model';
import { UpsertBusinessPartnerRequest } from '../../../information/models/vehicle-information/upsert-business-partner-request.model';
import { BulkUploadBusinessPartnerRequest } from '../../models/bulk-upload-business-partner-request.model';
import { UpsertBusinessPartnerAdressRequest } from '../../models/upsert-business-partner-address-request.model';
import { StandardDriverRequest } from '../../../information/models/vehicle-information/standard-driver.model';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class DriverService {

  CR_URL = environment.crapi.URL;

  constructor(private http: HttpClient,
    private storageService: StorageService) { }

  createVehicleRoute(data: any) {
    return this.http.post<any>(this.CR_URL + '/VehicleRoute/Create', data, {
      headers: this.getAuthorizationHeaders()
    });
  }

  updateVehicleRoute(data: any) {
    return this.http.post(this.CR_URL + '/VehicleRoute/Update', data, {
      headers: this.getAuthorizationHeaders()
    });
  }

  splitVehicleRoute(data: any) {
    return this.http.post(this.CR_URL + '/VehicleRoute/Split', data, {
      headers: this.getAuthorizationHeaders()
    });
  }

  deleteVehicleRoute(options: any) {
    return this.http.delete<any>(this.CR_URL + '/VehicleRoute/Delete/' + options.RouteId, {
      headers: this.getAuthorizationHeaders()
    });
  }

  getVehicleRouteTypes() {
    return this.http.get(this.CR_URL + '/VehicleRoute/Types', {
      headers: this.getAuthorizationHeaders()
    });
  }

  updateRouteType(data: any): any {
    return this.http.post<any>(this.CR_URL + '/VehicleRoute/UpdateRouteType', data, {
      headers: this.getAuthorizationHeaders()
    });
  }

  getVehicleRouteStatus() {
    return this.http.get(this.CR_URL + '/VehicleRoute/Statuses', {
      headers: this.getAuthorizationHeaders()
    });
  }

  getVehicleReport(data) {
    return this.http.get(this.CR_URL + '/Driver/VehicleReport', {
      params: data,
      headers: this.getAuthorizationHeaders()
    }); 
  }

  getDriversList() {
    return this.http.get(this.CR_URL + '/Driver/List', {
      headers: this.getAuthorizationHeaders()
    });
  }

  getAdminDriverBookInfo(driverId, dateFilter) {
    let url = this.CR_URL + '/Driver/'+ driverId + '/DriverBook';
    let queryParams;
    if (dateFilter) {
      queryParams = dateFilter;
      return this.http.get(url, {
        params: queryParams,
        headers: this.getAuthorizationHeaders()
      });
    } else {
      return this.http.get(url, {
        headers: this.getAuthorizationHeaders()
      });
    }
  }

  getDriverBookInfo(dataFilter) {
    let url = this.CR_URL + '/Driver/DriverBook';
    let queryParams;
    if (dataFilter) {
      queryParams = dataFilter;
      return this.http.get(url, {
        params: queryParams,
        headers: this.getAuthorizationHeaders()
      });
    } else {
      return this.http.get(url, {
        headers: this.getAuthorizationHeaders()
      });
    }
  }

  getMileageCheck() {
    return this.http.get(this.CR_URL + '/Driver/MileageCheck', {
      headers: this.getAuthorizationHeaders()
    });
  }

  saveMileage(data: PostMileageRequest) {
    return this.http.post(this.CR_URL + '/Driver/PostMileage', data, {
      headers: this.getAuthorizationHeaders()
    })
  }

  getVehiclesInformation() {
    return this.http.get(this.CR_URL + '/Driver/Information', {
      headers: this.getAuthorizationHeaders()
    });
  }

  saveVehicleTechnicalCarMessage(data: ForwardMessage) {
    return this.http.post(this.CR_URL + '/Driver/PostVehicleMessage', data, {
      headers: this.getAuthorizationHeaders()
    });
  }

  saveDriverAddress(data: UpsertAddressRequest) {
    return this.http.post(this.CR_URL + '/Driver/UpsertDriverAddress', data, {
      headers: this.getAuthorizationHeaders()
    });
  }

  deleteDriverAddress(driverAddressId: string) {
    return this.http.delete(this.CR_URL + `/Driver/Address/Delete/${driverAddressId}`, {
      headers: this.getAuthorizationHeaders()
    });
  }

  updateStandardDriver(data: StandardDriverRequest, driverId: string) {
    if (driverId) {
      return this.http.post(this.CR_URL + `/Driver/${driverId}/UpdateContactInformation`, data, {
        headers: this.getAuthorizationHeaders()
      });
    } else {
      return this.http.post(this.CR_URL + '/Driver/UpdateContactInformation', data, {
        headers: this.getAuthorizationHeaders()
      });
    }
  }

  saveAdditionalDriver(data: UpsertAdditionalDriverRequest) {
    return this.http.post(this.CR_URL + '/Employee/UpsertAdditionalDriver', data, {
      headers: this.getAuthorizationHeaders()
    });
  }

  deleteAdditionalDriver(additionalDriverId: string) {
    return this.http.delete(this.CR_URL + `/Employee/DeleteAdditionalDriver/${additionalDriverId}`, {
      headers: this.getAuthorizationHeaders()
    });
  }

  upsertBusinessPartner(data: UpsertBusinessPartnerRequest) {
    return this.http.post(this.CR_URL + '/Employee/UpsertBusinessPartner', data, {
      headers: this.getAuthorizationHeaders()
    });
  }

  getNearestBusinessPartners(vehicleRouteId: string) {
    let userInfo = this.storageService.get('userInfo');
    let isAdminUser = userInfo && userInfo.UserRole === 'Admin';
    if (isAdminUser) {
      let selectedDriver = this.storageService.get('selectedDriver');
      return this.http.get(this.CR_URL + `/VehicleRoute/${vehicleRouteId}/GetNearestBusinessPartners?employeeID=${selectedDriver.ID}`, {
        headers: this.getAuthorizationHeaders()
      });
    } else {
      return this.http.get(this.CR_URL + `/VehicleRoute/${vehicleRouteId}/GetNearestBusinessPartners`, {
        headers: this.getAuthorizationHeaders()
      });
    }
  }

  deleteBusinessPartner(businessPartnerId: string) {
    return this.http.delete(this.CR_URL + `/Employee/DeleteBusinessPartner/${businessPartnerId}`, {
      headers: this.getAuthorizationHeaders()
    });
  }

  getBusinessPartners() {
    return this.http.get(this.CR_URL + '/Employee/GetBusinessPartners', {
      headers: this.getAuthorizationHeaders()
    });
  }

  upsertBusinessPartnerAddress(data: UpsertBusinessPartnerAdressRequest) {
    return this.http.post(this.CR_URL + '/Employee/UpsertBusinessPartnerAddress', data, {
      headers: this.getAuthorizationHeaders()
    });
  }

  deleteBusinessPartnerAddress(businessPartnerAddressId: string) {
    return this.http.delete(this.CR_URL + `/Employee/DeleteBusinessPartnerAddress/${businessPartnerAddressId}`, {
      headers: this.getAuthorizationHeaders()
    });
  }

  bulkUploadBusinessPartners(data: BulkUploadBusinessPartnerRequest) {
    return this.http.post(this.CR_URL + '/Employee/BulkUploadBusinessPartners', data, {
      headers: this.getAuthorizationHeaders()
    });
  }

  sendEmail(data) {
    return this.http.post(this.CR_URL + '/Driver/SendEmail', data, {
      headers: this.getAuthorizationHeaders()
    });
  }

  getEuropianDateFormat(date) {
    return moment(date).format('DD.MM.YYYY');
  }

  getAuthorizationHeaders() {
    const headers = {
      Authorization: ''
    };
    const session = localStorage.getItem('CrApiSession');
    if (session && session !== '') {
      const storageSession = JSON.parse(session);
      headers.Authorization = storageSession.token_type + ' ' + storageSession.access_token;
    }
    return headers;
  }

}
