import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '../../../shared/services/storage/storage.service';
  
export class MileageValidator {
    static validate(translateService: TranslateService, storageService: StorageService): ValidatorFn {
        return (control: AbstractControl): ValidationErrors|null => {
            let regexp1 = /^\+?[0-9]+$/;
            let regexp2 = /^\+?[0-9]+[\.,][0-9][0-9]+$/;
            let value = control.value as string;
            if (value) {
                if(regexp1.test(value) || regexp2.test(value)) {
                    if (parseInt(value, 10) > 2000000) {
                        return {
                            validation: true,
                            message: translateService.instant('MILEAGEMAXLIMITEERRORMESSAGE')
                        };
                    }
                    // restrict >30km current mileage input
                    let selectedVehicleMileageInfo = storageService.get('selectedVehicleMileageInfo');
                    if (selectedVehicleMileageInfo) {
                        if (!selectedVehicleMileageInfo.InitialMileageUpdate && (parseFloat(value) > (parseFloat(selectedVehicleMileageInfo.CurrentMileage) + 30) || (parseFloat(value) < (parseFloat(selectedVehicleMileageInfo.CurrentMileage) - 30)))) {
                            return {
                                validation: true,
                                message: translateService.instant('MAXMILEAGEERRORMESSAGE')
                            };
                        }
                    }
                } else {
                    return {
                        validation: true,
                        message: translateService.instant('MILEAGEVALIDATIONERRORMESSAGE')
                    };
                }
            }
            return  null;
        };
    }
}