<h1 mat-dialog-title>
    {{title}}
</h1>

<div mat-dialog-content>
    <form class="edit-business-partner-form-section" [formGroup]="EditStandardDriverForm">
        <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>{{'FIRSTNAME' | translate}}</mat-label>
            <input matInput placeholder="{{'FIRSTNAMEPLACEHOLDER' | translate}}" formControlName="Firstname" readonly>
            <mat-error *ngIf="EditStandardDriverForm.get('Firstname').hasError('required')">
                {{'FIELDREQUIRED' | translate}}
            </mat-error>
            <mat-error *ngIf="EditStandardDriverForm.controls['Firstname'].errors?.invalidAlphabets && EditStandardDriverForm.controls['Firstname'].touched">
                {{ 'INVALIDALPHABET' | translate }}
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>{{'SURNAMEORLASTNAME' | translate}}</mat-label>
            <input matInput placeholder="{{'SURNAMEPLACEHOLDER' | translate}}" formControlName="Lastname" readonly>
            <mat-error *ngIf="EditStandardDriverForm.get('Lastname').hasError('required')">
                {{'FIELDREQUIRED' | translate}}
            </mat-error>
            <mat-error *ngIf="EditStandardDriverForm.controls['Lastname'].errors?.invalidAlphabets && EditStandardDriverForm.controls['Lastname'].touched">
                {{ 'INVALIDALPHABET' | translate }}
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>{{'EMAIL' | translate}}</mat-label>
            <input matInput placeholder="{{'EMAILPLACEHOLDER' | translate}}" formControlName="Email" required>
            <mat-error *ngIf="EditStandardDriverForm.get('Email').hasError('required')">
                {{'FIELDREQUIRED' | translate}}
            </mat-error>
            <mat-error *ngIf="EditStandardDriverForm.get('Email').hasError('invalidEmail')">
                {{'EMAILINCORRECTMESSAGE' | translate}}
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>{{'PHONENUMBER' | translate}}</mat-label>
            <input matInput placeholder="{{'PHONENUMBERPLACEHOLDER' | translate}}" formControlName="TelephoneNumber" required>
            <mat-error *ngIf="EditStandardDriverForm.get('TelephoneNumber').hasError('required')">
                {{'FIELDREQUIRED' | translate}}
            </mat-error>
            <mat-error *ngIf="EditStandardDriverForm.get('TelephoneNumber').hasError('invalidNumber')">
                {{'INVALIDNUMBER' | translate}}
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>{{'MOBILENUMBER' | translate}}</mat-label>
            <input matInput placeholder="{{'MOBILENUMBERPLACEHOLDER' | translate}}" formControlName="MobileNumber" required>
            <mat-error *ngIf="EditStandardDriverForm.get('MobileNumber').hasError('required')">
                {{'FIELDREQUIRED' | translate}}
            </mat-error>
            <mat-error *ngIf="EditStandardDriverForm.get('MobileNumber').hasError('invalidNumber')">
                {{'INVALIDNUMBER' | translate}}
            </mat-error>
        </mat-form-field>
    </form>
</div>
<div mat-dialog-actions align="end">
    <button mat-raised-button (click)="onDismiss()">{{'CANCEL'| translate}}</button>
    <button mat-raised-button [disabled]="!EditStandardDriverForm.valid" color="primary" (click)="onConfirm()">{{'CONFIRM'| translate}}</button>
</div>