import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VehiclesComponent } from './components/vehicles/vehicles.component';
import { SharedModule } from '../shared/shared.module';
import { DashboardRoutingModule } from './dashboard.routing';
import { ContactUsSendEmailDialogComponent } from './components/contact-us-send-email-dialog/contact-us-send-email-dialog.component';
import { EditVehicleTripComponent } from './components/edit-vehicle-trip/edit-vehicle-trip.component';
import { VehicleTripTypeComponent } from './components/vehicle-trip-type/vehicle-trip-type.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { BusinessSummaryComponent } from './components/business-summary/business-summary.component';
@NgModule({
  declarations: [
    ContactUsSendEmailDialogComponent,
    EditVehicleTripComponent,
    VehicleTripTypeComponent,
    VehiclesComponent,
    DashboardComponent,
    BusinessSummaryComponent
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    SharedModule
  ]
})
export class DashboardModule { }
