<div class="login-section">
	<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="center center">
		<div class="left-section" fxFlex="50">
			<img src="./../../../assets/Logo_SVG_transparent1.png">
		</div>
		<div class="right-section" fxFlex="50" fxFlex.sm="100" fxFlex.xs="100">
			<mat-card class="example-card login-card">
				<mat-card-header>
				 <mat-card-title>{{ 'LOGIN' | translate }}</mat-card-title>
				</mat-card-header>
				<mat-card-content>
					 <form [formGroup]="loginForm" class="example-form">
						 <mat-form-field class="example-full-width" appearance="outline">
						  <mat-label>{{ 'USERNAME' | translate }}</mat-label>
						  <mat-icon matPrefix *ngIf="isUsernameFocused">person</mat-icon>
						  <input (focus)="isUsernameFocused=true" (blur)="isUsernameFocused=false" class="input-tag" type="text" matInput placeholder="{{ 'USERNAMEPLACEHOLDER' | translate}}" formControlName="username">
							<mat-error *ngIf="loginForm.controls['username'].invalid && loginForm.controls['username'].touched">
							  {{ getErrorUsername() }}
						  </mat-error>
						</mat-form-field>
						<mat-form-field class="example-full-width" appearance="outline">
						  <mat-label>{{ 'PASSWORD' | translate }}</mat-label>
						  <mat-icon matPrefix *ngIf="isPasswordFocused">vpn_key</mat-icon>
						  <input (keydown.enter)="keyEnterpressed()" (focus)="isPasswordFocused=true" (blur)="isPasswordFocused=false" class="input-tag" [type]="hide ? 'password' : 'text'" matInput placeholder="{{ 'PASSWORDPLACEHOLDER' | translate}}" formControlName="password">
						  <mat-icon matSuffix class="visibility-icon" (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
						  <mat-error *ngIf="loginForm.controls['password'].invalid && loginForm.controls['password'].touched">
							  {{ getErrorPassword() }}
						  </mat-error>
						</mat-form-field>
				  </form>
				</mat-card-content>
				<mat-card-actions class="card-actions" fxLayout="row" fxLayoutAlign="end end">
				  <button mat-raised-button (click)="login()" color="primary">{{ 'SUBMIT' | translate }}</button>
				</mat-card-actions>
			  </mat-card>
		</div>
	</div>
</div>
<div class="spinner-blocker" fxLayout="row" fxLayoutAlign="center center">
	<mat-spinner [style.display]="showSpinner ? 'block' : 'none'"></mat-spinner>
</div>