import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StorageService } from '../../shared/services/storage/storage.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BuildingsService {
  CR_URL = environment.crapi.URL;
	Assets_URL = environment.assetsapi.URL;
  constructor(private http: HttpClient,
		private storageService: StorageService) { }

  //Building services
  getBuildings(options) {
    if (options.SearchString && options.SearchString !== '') {
      return this.http.get(this.Assets_URL + '/Building/V2/GetBuildings?page='+ options.PageNumber + '&limit='+ options.PageSize + '&buildingQueryParameters.address='+ options.SearchString, {
        headers: this.getAuthorizationHeaders()
      });
    } else {
      return this.http.get(this.Assets_URL + '/Building/V2/GetBuildings?page='+ options.PageNumber + '&limit='+ options.PageSize, {
        headers: this.getAuthorizationHeaders()
      });
    }
  }

  getInfoByBuildingID(options) {
    return this.http.get(this.Assets_URL + `/Building/V2/GetBuilding/${options.buildingId}`, {
      headers: this.getAuthorizationHeaders()
    });
  }

  createBuilding(options) {
    return this.http.post(this.Assets_URL + '/Building/CreateBuilding', options, {
      headers: this.getAuthorizationHeaders()
    });
  }

  updateBuilding(options) {
    return this.http.put(this.Assets_URL + `/Building/UpdateBuilding/${options.BuildingId}`, options, {
      headers: this.getAuthorizationHeaders()
    });
  }

  deleteBuilding(options) {
    return this.http.delete(this.Assets_URL + `/Building/V2/DeleteBuilding/${options.BuildingId}`, {
      headers: this.getAuthorizationHeaders()
    });
  }

  getAuthorizationHeaders() {
    const headers = {
      Authorization: ''
    };
    const session = localStorage.getItem('AssetsApiSession');
    if (session && session !== '') {
      const storageSession = JSON.parse(session);
      headers.Authorization = storageSession.token_type + ' ' + storageSession.access_token;
    }
    return headers;
  }
}
