
import { AbstractControl, ValidationErrors } from '@angular/forms';

export class ChangePasswordValidators {
    static shouldBe1234(control: AbstractControl): Promise<ValidationErrors | null> {
        return new Promise((resolve, reject) => {
            if (control.value !== '1234')
                resolve({ shouldBe1234: true });
            else
                resolve(null);
        });
    }

    static matchPasswords(control: AbstractControl) {
        let newPwd2 = control.get('newPassword');
        let confirmPwd2 = control.get('confirmPassword');
        if (newPwd2 && confirmPwd2) {
            if (newPwd2.value !== confirmPwd2.value) {
                return { pwdsDontMatch: true };
            } else {
                return null;
            }
        }
        return null;
    }
}