import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { AppConstants } from '../../../app.constants';
import { DriverService } from '../../services/driver/driver.service';
import { MasterDataService } from '../../../shared/services/master-data/master-data.service';
import { CommonService } from '../../services/common/common.service';

@Component({
  selector: 'app-business-partner-addresses',
  templateUrl: './business-partner-addresses.component.html',
  styleUrls: ['./business-partner-addresses.component.scss']
})
export class BusinessPartnerAddressesComponent implements OnInit {
  addressForm: FormGroup;
  BuinessPartnerAddress: FormArray;
  @Input() contact;
  @Output() businessPartnerAddAddressEmit = new EventEmitter<any>();
  @Output() businessPartnerUpdateAddressEmit = new EventEmitter<any>();
  @Output() businessPartnerDeleteAddressEmit = new EventEmitter<any>();
  countries;
  states;
  cities;
  EuropeCountries= [];
  language;
  SearchState = this.translateService.instant('SEARCHSTATE');
  SearchCity = this.translateService.instant('SEARCHCITY');
  SelectedBusinessPartnerAddress;

  isAddNewAddressClicked = false;

  constructor(private formBuilder: FormBuilder,
    private driverService: DriverService,
    private snackbar: MatSnackBar,
    private translateService: TranslateService,
    private commonService: CommonService,
    private masterDataService: MasterDataService) { }

  ngOnInit(): void {
    console.log(this.contact);
    this.EuropeCountries = AppConstants.Europe_Countries;
    this.addressForm = this.formBuilder.group({
      AddBuinessPartnerAddress: this.formBuilder.array([]),
      BuinessPartnerAddress: this.formBuilder.array([])
    });
    this.initAddresses(this.contact);
  }

  get Addresses() {
    let address: any = this.addressForm.get('BuinessPartnerAddress');
    return address.controls as FormArray;
  }

  getCountriesAndStates() {
    this.masterDataService.getCountriesandStates().subscribe((response) => {
      this.countries = response;
      if (this.Addresses && this.Addresses.length > 0) {
        this.selectedCountry(this.Addresses[0].controls.CountryId.value, 0, true);
        this.getCities(this.states[0]);
      }
    }, (error) => {
      console.log(error);
    });
  }

  selectedCountry(countryId, index, isDefault) {
    this.countries.forEach(country => {
      if (country.ID === countryId) {
        this.Addresses[index].controls.CountryName.patchValue(country.Name);
        if (!isDefault) {
          this.Addresses[index].controls.FederalStateId.patchValue('');
          this.Addresses[index].controls.FederalStateName.patchValue('');
          this.Addresses[index].controls.CityId.patchValue('');
          this.Addresses[index].controls.CityName.patchValue('');
        }
      }
    });
    this.getStates(countryId);
  }

  getStates(countryId) {
    this.countries.forEach(country => {
      if (country.ID === countryId) {
        this.states = country.States;
        localStorage.setItem('States', JSON.stringify(country.States));
        this.cities = [];
      }
    });
  }

  selectedState(stateId, index, isDefault) {
    this.states.forEach(state => {
      if (state.ID === stateId) {
        this.Addresses[index].controls.FederalStateName.setValue(state.Name);
        if (!isDefault) {
          this.Addresses[index].controls.CityId.setValue('');
          this.Addresses[index].controls.CityName.setValue('');
        }
      }
    });
    this.getCities(stateId);
  }
  
  selectedCity(cityId, index) {
    this.cities.forEach(city => {
      if (city.ID === cityId) {
        this.Addresses[index].controls.CityName.setValue(city.Name);
      }
    });
    console.log(this.Addresses[index]);
  }

  onHouseNumberChange(houseNumber, index) {
    console.log(this.Addresses[index]);
    console.log(houseNumber);
  }

  onStreetChange(street, index) {
    console.log(this.Addresses[index]);
    console.log(street);
  }

  getCities(state) {
    const id = state && state.ID ? state.ID: state;
    this.masterDataService.getCities(id).subscribe((response) => {
      this.cities = response;
      localStorage.setItem('Cities', JSON.stringify(response));
    }, (error) => {
      console.log(error);
    });
  }

  updateBusinessPartnerAddress(address: any, index) {
    if (address.value.CountryId !== '' && address.value.FederalStateId !== '' && address.value.CityId !== '') {
      this.businessPartnerUpdateAddressEmit.emit(address);
    } else {
      this.snackbar.open(this.translateService.instant('FIELDCITYSTATECITYREQUIREDMESSAGE'), this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
    }
  }

  deleteBusinessPartnerAddress(address: any, index) {
    this.businessPartnerDeleteAddressEmit.emit(address);
  }

  initAddresses(contact) {
    if (contact) {
      if (contact.BusinessPartnerAddresses && contact.BusinessPartnerAddresses.length > 0) {
        contact.BusinessPartnerAddresses.forEach(address => {
          this.addAddress(address);
        });
      } else if (contact.HouseNumber) {
        this.addAddress(contact);
      }
    } else {
      this.addAddress(undefined);
    }
    this.getCountriesAndStates();
  }

  addAddress(address) {
    let addressItem = this.formBuilder.group({
      Id: [address ? address.ID: '', Validators.required],
      AddressID: [address ? address.AddressID: '', Validators.required],
      CityId: [address ? address.CityId: '', Validators.required],
      CityName: [address ? address. CityName: '', Validators.required],
      CountryId: [address ? address.CountryId: '', Validators.required],
      CountryName: [address ? address.CountryName: '', Validators.required],
      FederalStateId: [address ? address.FederalStateId: '', Validators.required],
      FederalStateName: [address ? address.FederalStateName: '', Validators.required],
      HouseNumber: [address ? address.HouseNumber: ''],
      PostalCode: [address ? address.PostalCode: '', Validators.required],
      Street:  [address ? address.Street: '', Validators.required],
      BusinessPartnerAddress: [this.getAddress(address)]
    });
    this.Addresses.push(addressItem);
  }

  getAddress(address) {
    let result = '';
    if (address) {
      if (address.Street && address.HouseNumber && address.CountryName && address.CityName && address.FederalStateName) {
        result = address.Street + ' ' + address.HouseNumber + ', ' + address.PostalCode + ' ' + address.CityName + ', ' + address.CountryName;
      }
    }
    return result;
  }

  get AddAddress() {
    let address: any = this.addressForm.get('AddBuinessPartnerAddress');
    return address.controls as FormArray;
  }

  addNewAddress() {
    this.isAddNewAddressClicked = true;
    if (this.AddAddress && this.AddAddress.length === 0) {
      this.initAddresses(undefined);
    }
  }

  selectedAddCountry(countryId, index, isDefault) {
    this.countries.forEach(country => {
      if (country.ID === countryId) {
        this.AddAddress[index].controls.CountryName.patchValue(country.Name);
        if (!isDefault) {
          this.AddAddress[index].controls.FederalStateId.patchValue('');
          this.AddAddress[index].controls.FederalStateName.patchValue('');
          this.AddAddress[index].controls.CityId.patchValue('');
          this.AddAddress[index].controls.CityName.patchValue('');
        }
      }
    });
    this.getStates(countryId);
  }

  selectedAddState(stateId, index, isDefault) {
    this.states.forEach(state => {
      if (state.ID === stateId) {
        this.AddAddress[index].controls.FederalStateName.setValue(state.Name);
        if (!isDefault) {
          this.AddAddress[index].controls.CityId.setValue('');
          this.AddAddress[index].controls.CityName.setValue('');
        }
      }
    });
    this.getCities(stateId);
  }
  
  selectedAddCity(cityId, index) {
    this.cities.forEach(city => {
      if (city.ID === cityId) {
        this.AddAddress[index].controls.CityName.setValue(city.Name);
      }
    });
    console.log(this.AddAddress[index]);
  }

  onDeleteAddress(index) {
    for (let i = 0; i <= this.AddAddress.length; i++) {
      if (i === index) {
        let address = this.addressForm.controls.AddBuinessPartnerAddress;
        const control = <FormArray>this.addressForm.controls['AddBuinessPartnerAddress'];
        control.removeAt(i);
        console.log(address);
      }
    }
  }

  onSaveAddress(index) {
    let options = {
      BusinessPartnerId: this.contact.ID,
      CityId: this.AddAddress[index].value.CityId,
      CountryId: this.AddAddress[index].value.CountryId,
      FederalStateId: this.AddAddress[index].value.FederalStateId,
      Street: this.AddAddress[index].value.Street,
      HouseNumber: this.AddAddress[index].value.HouseNumber
    }
    this.driverService.upsertBusinessPartnerAddress(options).subscribe((response: any) => {
      if (response.status === 400) {
        return;
      }
      if (response && response.message && response.status === 200) {
        this.snackbar.open(response.message, this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
      }
      this.businessPartnerAddAddressEmit.emit(true);
      this.onDeleteAddress(index);
    }, (error) => {
      console.log(error);
      // this.snackbar.open(error.message, this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
    });
  }

  openExpansionPanelGroup(address, index) {
    this.countries.forEach(country => {
      if (country.ID === address.value.CountryId) {
        this.Addresses[index].controls.CountryName.patchValue(country.Name);
        this.Addresses[index].controls.CountryId.patchValue(country.ID);
        this.states = country.States;
        this.states.forEach(state => {
          if (state.ID === address.value.FederalStateId) {
            this.Addresses[index].controls.FederalStateName.setValue(state.Name);
            this.Addresses[index].controls.FederalStateId.setValue(state.ID);
            this.masterDataService.getCities(address.value.FederalStateId).subscribe((response) => {
              this.cities = response;
              localStorage.setItem('Cities', JSON.stringify(response));
              this.cities.forEach(city => {
                if (city.ID === address.value.CityId) {
                  this.Addresses[index].controls.CityName.setValue(city.Name);
                  this.Addresses[index].controls.CityId.setValue(city.ID);
                }
              });
            }, (error) => {
              console.log(error);
            });
          }
        });
      }
    });
  }

  onBusinessPartnerAddressAutocompleteSelected(event, index) {
    console.log(event);
  }

  onBusinessPartnerAddressLocationSelected(event, index) {
    console.log(event);
  }

  onGermanBusinessPartnerAddressMapped(event, index) {
    console.log(event, index);
    this.SelectedBusinessPartnerAddress = event;
    let countryName;
    this.Addresses[index].controls.Street.setValue(event.streetName);
    this.Addresses[index].controls.HouseNumber.setValue(event.streetNumber);
    countryName = (this.SelectedBusinessPartnerAddress.country.long.toLocaleLowerCase() === 'germany') ? 'Deutschland': this.SelectedBusinessPartnerAddress.country.long;
    let country = this.commonService.getCountryORStateORCityDetails(countryName, this.countries);
    this.Addresses[index].controls.CountryId.setValue(country[0].ID);
    this.states = country[0].States;
    let state = this.commonService.getCountryORStateORCityDetails(this.SelectedBusinessPartnerAddress.state.long, this.states);
    this.Addresses[index].controls.FederalStateId.setValue(state[0].ID);
    this.getCitiesData(state[0], index);
  }

  getCitiesData(state, index) {
    const id = state && state.ID ? state.ID: state;
    this.masterDataService.getCities(id).subscribe((response) => {
      this.cities = response;
      localStorage.setItem('Cities', JSON.stringify(response));
      let city = this.commonService.getCountryORStateORCityDetails(this.SelectedBusinessPartnerAddress.locality.long, this.cities);
      this.Addresses[index].controls.CityId.setValue(city[0].ID);
    }, (error) => {
      console.log(error);
    });
  }

  getLanguages() {
    let language = [];
    this.EuropeCountries.forEach((country) => {
      language.push(country.code.toLowerCase());
    });
    this.language = language;
  }
}
