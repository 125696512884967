import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppConstants } from '../../../app.constants';
import { AuthService } from '../../../auth/services/auth/auth.service';
import { ChangePasswordValidators } from './change-password.validators';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  userInfo;
  resetPasswordForm: FormGroup;

  constructor(private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private authService: AuthService,
    private translateService: TranslateService,
    private router: Router) { }

  ngOnInit() {
    this.createResetPasswordForm();
    this.getUserInfo();
  }

  createResetPasswordForm() {
    this.resetPasswordForm = this.fb.group({
      'oldPassword': ['', Validators.required],
      'newPassword': ['', Validators.required],
      'confirmPassword': ['', [Validators.required]]
    }, {
      validator: [ChangePasswordValidators.matchPasswords]
    });
  }

  get oldPassword() {
    return this.resetPasswordForm.get('oldPassword');
  }

  get newPassword() {
    return this.resetPasswordForm.get('newPassword');
  }

  get confirmPassword() {
    return this.resetPasswordForm.get('confirmPassword');
  }

  private getUserInfo() {
    const userInfoStorageData = localStorage.getItem('userInfo');
    if (userInfoStorageData && userInfoStorageData !== '') {
      this.userInfo = JSON.parse(userInfoStorageData);
    }
    return this.userInfo;
  }

  submit() {
    if (this.resetPasswordForm.value.oldPassword === this.resetPasswordForm.value.newPassword) {
      this.snackBar.open(this.translateService.instant('OLDANDNEWPASSWORDSAMEERRORMESSAGE'), this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
      return;
    }
    const options = {
      username: this.userInfo.Username,
      oldPassword: this.resetPasswordForm.value.oldPassword,
      newPassword: this.resetPasswordForm.value.newPassword
    }
    this.authService.resetPassword(options).subscribe((data: any) => {
      if (data.status && data.status !== '200' && data.status !== 200) {
        this.snackBar.open('Error :' + data.message, this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
        return;
      }
      this.snackBar.open(this.translateService.instant('RESETSUCCESSMESSAGE'), this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
      this.router.navigate(['login']);
    }, (error) => {
      this.snackBar.open('Error: ' + error, this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
    });
  }

}
