<form [formGroup]="routeTypeForm" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="none">
    <span class="total-distance" *ngIf="vehicleInfo.EndAddress && vehicleInfo.EndAddress !== ''">{{vehicleInfo.TotalDistance}} Km</span>
    <mat-form-field>
        <mat-select class="routeTypeItem" matInput
        (click) = "routeTypeClicked($event)"
        (selectionChange)="selectChanged($event)"
        formControlName="name" required
        [disabled]="vehicleInfo?.Updated">
            <mat-option *ngFor="let routeTypeItem of routeTypesList" [value]="routeTypeItem">
                {{ routeTypeItem.Type }}
            </mat-option>
        </mat-select>
    </mat-form-field>
</form>