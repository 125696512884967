import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { AppConstants } from '../../../app.constants';
import { DriverService } from '../../../shared/services/driver/driver.service';

@Component({
  selector: 'app-business-partner-dialog',
  templateUrl: './business-partner-dialog.component.html',
  styleUrls: ['./business-partner-dialog.component.scss']
})
export class BusinessPartnerDialogComponent implements OnInit {
  title: string;
  nearestBusinessPartners;
  panelOpenState1 = true;
  newBusinessPartnerFormData;
  selectedBusinessPartnerId = '';
  updateBusinessPartnerForm: FormGroup;

  constructor(public dialogRef: MatDialogRef<BusinessPartnerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private driverService: DriverService) {
  }

  ngOnInit(): void {
    this.title = this.data.title;
    this.updateBusinessPartnerForm = this.formBuilder.group({
			SelectedBusinessPartnerId: [''],
      BusinessPartnerId: [''],
			AdditionalInformation: [this.data && this.data.vehicleInfo ? this.data.vehicleInfo.AdditionalInformation: ''],
		});
    // console.log(this.data);
    if (this.data.isAddNewBusinessPartner) {
      this.title = this.translateService.instant('ADDBUSINESSPARTNER');
      this.panelOpenState1 =  false;
    } else {
      this.getNearestBusinessPartners();
      this.updateBusinessPartnerForm.controls.AdditionalInformation.setValidators(Validators.required);
      this.updateBusinessPartnerForm.controls.AdditionalInformation.updateValueAndValidity();
    }
  }

  getNearestBusinessPartners() {
    let vehicleRouteId = this.data && this.data.vehicleInfo?  this.data.vehicleInfo.RouteId: '';
    if (vehicleRouteId) {
      this.driverService.getNearestBusinessPartners(vehicleRouteId).subscribe((response: any) => {
        this.nearestBusinessPartners = response;
        if (this.selectedBusinessPartnerId) {
          this.updateBusinessPartnerForm.controls.SelectedBusinessPartnerId.setValue(this.selectedBusinessPartnerId);
        }
      }, (error) => {
        console.log(error);
        this.nearestBusinessPartners = [];
        this.title = this.translateService.instant('ADDBUSINESSPARTNER');
      });
    }
  }

  onSelection(selectedBusinesspartner) {
    this.selectedBusinessPartnerId = selectedBusinesspartner.value;
  }

  onDismiss() {
    this.dialogRef.close(false);
  }

  addBusinessPartnerFormEventEmit(data) {
    // console.log('addBusinessPartnerFormEventEmit: ', data);
    this.selectedBusinessPartnerId = data.businessPartnerId;
    this.panelOpenState1 = true;
    this.getNearestBusinessPartners();
    if (this.data.isAddNewBusinessPartner && data.Addresses && data.Addresses.length === 1) {
      data.isClosedDialog = true;
      this.dialogRef.close(data);
    }
  }

  onConfirm() {
    if (this.updateBusinessPartnerForm.controls.SelectedBusinessPartnerId.value === '') {
      this.snackBar.open(this.translateService.instant('BUSINESSPARTNERREQUIRED'), this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY)
      return;
    }
    let data = {
      isClosedDialog: true,
      selectedBusinessPartnerId: this.selectedBusinessPartnerId,
      AdditionalInformation: this.updateBusinessPartnerForm.controls.AdditionalInformation.value,
      RouteId: this.data.vehicleInfo.RouteId
    }
    this.dialogRef.close(data);
  }

  addNewBusinessPartner() {
    this.panelOpenState1 =  false;
  }

  businessPartnerFormEventEmit(data) {
    this.newBusinessPartnerFormData = data;
  }

}
