import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { RouteSeparate } from '../../../dashboard/models/route/route-separate.model';

import { Location, Appearance, GermanAddress } from '@angular-material-extensions/google-maps-autocomplete';
import PlaceResult = google.maps.places.PlaceResult;
import { AppConstants } from '../../../app.constants';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { first } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import * as moment from 'moment';
import { DriverService } from '../../services/driver/driver.service';
import { StorageService } from '../../services/storage/storage.service';
import { CommonService } from '../../services/common/common.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-separate-vehicle-route',
  templateUrl: './separate-vehicle-route.component.html',
  styleUrls: ['./separate-vehicle-route.component.scss'],
	providers: [
		{
      provide: MAT_DATE_FORMATS,
      useValue: AppConstants.DE_DATE_FORMATS
    },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: {
        displayDefaultIndicatorType: false
      }
    }
	]
})
export class SeparateVehicleRouteComponent implements OnInit {
  title: string = this.translateService.instant('SEPARATETRIP');
  separateTripForm: FormGroup;
  maximumDividedTripsSize = [1,2,3,4];
  dividedTripSizeArray = [];
  defaultTime = '00:00';
  language = 'de';
  totalDrivenDistanceSummary = 0;
  @ViewChild('accordion', { static: true }) accordion: MatAccordion;
  private _mobile = new BehaviorSubject<boolean>(false);
  mobile$ = this._mobile.asObservable();
  // icons = ['edit', 'calculate', 'monetization_on'];
  @ViewChild('stepper', { static: false}) stepperEle: MatStepper;
  moment = moment;
  routeTypesList;
  driversList = [];
  validationMessages = {
    required: this.translateService.instant('FIELDREQUIRED'),
    invalid: this.translateService.instant("INVALIDTWODIGITNUMBER")
  }
  formFieldValid = true;
  formSubmitFieldInValid = false;
  minDate;
  maxDate;

  constructor(public dialogRef: MatDialogRef<SeparateVehicleRouteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RouteSeparate,
    public fb: FormBuilder,
    private breakpointObserver: BreakpointObserver,
    private snackBar: MatSnackBar,
    private driverService: DriverService,
    private storageService: StorageService,
    private commonService: CommonService,
    public translateService: TranslateService,
    private dialog: MatDialog
  ) {
    this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.HandsetPortrait,
      Breakpoints.HandsetLandscape
    ]).pipe(first()).subscribe(result => {
      this._mobile.next(result.matches)
    });
  }

  ngOnInit() {
    this.createFormGroup();
    const storageRouteTypesList = this.translateService.instant('VehicleRouteTypes');
    if (storageRouteTypesList) {
      this.routeTypesList = storageRouteTypesList;
    } else {
      this.driverService.getVehicleRouteTypes().subscribe((typesData) => {
        this.storageService.set('routeTypesList', typesData);
        this.routeTypesList = typesData;
      }, (error) => {
        this.snackBar.open(this.translateService.instant('ERRORMESSAGE'), this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
      });
    }
    // console.log('************', this.getFormRoutesList.controls);
    const userInformation = this.storageService.get('userInformation');
    let driversList = [];
    if (userInformation) {
      driversList.push({
        ID: 0,
        Firstname: userInformation.Firstname,
        Lastname: userInformation.Lastname
      });
      if (userInformation.AdditionalDrivers && userInformation.AdditionalDrivers.length > 0) {
        userInformation.AdditionalDrivers.map((additionDriver) => {
          driversList.push(additionDriver);
        });
      }
      this.driversList = driversList;
    }
  }

  createFormGroup() {
    this.separateTripForm = this.fb.group({
      dividedTripSize: [1, Validators.required],
      UpdateRouteInputModel: this.initUpdateRouteInputModel(this.data),
      CreateRouteInputModels: this.fb.array([]),
      RoutesList: this.fb.array([])
    });
    this.separateTripForm.controls.dividedTripSize.setValue(1);
  }

  getSeparateTripFormControl(key) {
    return this.separateTripForm.get(key).value;
  }

  get formCreateRouteInputModelArray() {
    return this.separateTripForm.get('CreateRouteInputModels') as FormArray;
  }

  get getFormRoutesList() {
    return this.separateTripForm.get('RoutesList') as FormArray;
  }

  addNewRoute() {
    this.getFormRoutesList.push(this.initRouteInputModel(''));
  }

  deleteNewRoute(index) {
    this.getFormRoutesList.removeAt(index);
  }

  deleteAllNewRoutes() {
    this.getFormRoutesList.clear();
  }

  initUpdateRouteInputModel(data) {
    this.minDate = data.StartDate? this.commonService.formatToYYYYMMDD(data.StartDate): null;
    this.maxDate = data.EndDate? this.commonService.formatToYYYYMMDD(data.EndDate): null;
    this.totalDrivenDistanceSummary = data.TotalDistance ? data.TotalDistance : 0;
    return this.fb.group({
      RouteId: [data.RouteId? data.RouteId: ''],
      RouteTypeId: [data.VehicleRouteTypeId? data.VehicleRouteTypeId: '', Validators.required],
      StartAddress: [data.StartAddress? data.StartAddress: '', Validators.required],
      EndAddress: [data.EndAddress? data.EndAddress: '', Validators.required],
      StartMileage: [data.StartMileage? data.StartMileage: ''],
      EndMileage: [data.EndMileage? data.EndMileage: ''],
      HourTime: [data.StartTime? data.StartTime.split(":")[0]: null, [Validators.required, this.commonService.validateTwoDigitIntegerString(), this.commonService.validateTwoDigitIntegerHourString()]],
      MinuteTime: [data.StartTime? data.StartTime.split(":")[1]: null, [Validators.required, this.commonService.validateTwoDigitIntegerString(), this.commonService.validateTwoDigitIntegerMinuteString()]],
      StartTime: [data.StartTime? data.StartTime: ''],
      EndTime: [data.EndTime? data.EndTime: ''],
      StartDate: [data.StartDate? data.StartDate: '', Validators.required],
      EndDate: [data.EndDate? data.EndDate: '', Validators.required],
      DriverName: [data.DriverName? data.DriverName: ''],
      BusinessPartnerName: [data.BusinessPartnerName? data.BusinessPartnerName: '']
    });
  }

  initCreateRouteInputModel() {
    return this.fb.group({
      VehicleId: ['', Validators.required],
      RouteTypeId: ['', Validators.required],
      StartAddress: ['', Validators.required],
      EndAddress: ['', Validators.required],
      StartMileage: [''],
      EndMileage: [''],
      StartTime: ['', Validators.required],
      EndTime: ['', Validators.required],
      StartDate: ['', Validators.required],
      EndDate: ['', Validators.required],
      DriverName: [''],
      BusinessPartnerName: ['']
    });
  }

  initRouteInputModel(startDateFormatedValue) {
    return this.fb.group({
      Address: ['', Validators.required],
      Date: [startDateFormatedValue, Validators.required],
      Time: [''],
      HourTime: ['', [Validators.required, this.commonService.validateTwoDigitIntegerString(), this.commonService.validateTwoDigitIntegerHourString()]],
      MinuteTime: ['', [Validators.required, this.commonService.validateTwoDigitIntegerString(), this.commonService.validateTwoDigitIntegerMinuteString()]],
      Driver: [''],
      BusinessPartnerName: [''],
      DrivenDistance: [{value: '', disabled: true }],
      RouteTypeId: [this.routeTypesList[0].Id, Validators.required],
      ReasonOfTrip: [''],
      AverageSpeed: [{value: '', disabled: true }],
      IsError: []
    });
  }

  getAvailableRoutesPreLoad() {
    let RouteInputModel;
    let firstRouteInputModel;
    let lastRouteInputModel;
    let divdedTripSize = this.getSeparateTripFormControl('dividedTripSize');
    let routeSize = ((divdedTripSize*2) + 2);
    // console.log(routeSize);
    let availableRouteInputModel = this.separateTripForm.controls.UpdateRouteInputModel.value;
    // console.log(availableRouteInputModel);
    const splitedStartDate = availableRouteInputModel.StartDate.split('.');
    const startDateFormatedValue = new Date((''+ splitedStartDate[1] +'.'+ splitedStartDate[0] +'.'+ splitedStartDate[2]));
    for(let i=0; i < 2; i++) {
      RouteInputModel = this.initRouteInputModel(startDateFormatedValue);
      if(i === 0) {
        RouteInputModel.controls.Address.setValue(availableRouteInputModel.StartAddress);
        RouteInputModel.controls.Date.setValue(startDateFormatedValue);
        RouteInputModel.controls.HourTime.setValue(availableRouteInputModel.StartTime.split(":")[0]);
        RouteInputModel.controls.MinuteTime.setValue(availableRouteInputModel.StartTime.split(":")[1]);
        RouteInputModel.controls.Time.setValue(availableRouteInputModel.StartTime.substr(0, 5));
        RouteInputModel.controls.BusinessPartnerName.setValue('');
        RouteInputModel.controls.DrivenDistance.setValue('');
        firstRouteInputModel = RouteInputModel;
      } else if(i === 1) {
        RouteInputModel.controls.Address.setValue(availableRouteInputModel.EndAddress);
        const splitedEndDate = availableRouteInputModel.EndDate.split('.');
        RouteInputModel.controls.Date.setValue(new Date((''+ splitedEndDate[1] +'.'+ splitedEndDate[0] +'.'+ splitedEndDate[2])));
        RouteInputModel.controls.HourTime.setValue(availableRouteInputModel.EndTime.split(":")[0]);
        RouteInputModel.controls.MinuteTime.setValue(availableRouteInputModel.EndTime.split(":")[1]);
        RouteInputModel.controls.Time.setValue(availableRouteInputModel.EndTime.substr(0, 5));
        RouteInputModel.controls.BusinessPartnerName.setValue('');
        RouteInputModel.controls.DrivenDistance.setValue(availableRouteInputModel.DrivenDistance);
        lastRouteInputModel = RouteInputModel;
      }
    }
    // logic for inserting the new routed in between of two routes
    for (let j=0; j < routeSize; j++) {
      if (j === 0) {
        this.getFormRoutesList.push(firstRouteInputModel);
      } else if (j < (routeSize-1)) {
        this.getFormRoutesList.push(this.initRouteInputModel(startDateFormatedValue));
      } else if (j === (routeSize-1)) {
        this.getFormRoutesList.push(lastRouteInputModel);
      }
    }
  }

  firstStepClick() {
    this.deleteAllNewRoutes();
    this.getAvailableRoutesPreLoad();
    this.storageService.set('DrivenDistance', []);
    if (this.getFormRoutesList.controls && this.getFormRoutesList.controls.length > 0) {
      if (this.driversList && this.driversList.length > 0) {
        this.getFormRoutesList.controls.forEach((route) => {
          route.get('Driver').patchValue(this.driversList[0].ID);
        });
      }
    }
  }

  secoundStepClick(RoutesListControls, stepperElement) {
    stepperElement.stepper.selected.interacted = true;
    const DrivenDistance = this.storageService.get('DrivenDistance');
    if (DrivenDistance && DrivenDistance.length > 0) {
      this.totalDrivenDistanceSummary = 0;
      DrivenDistance.forEach(distance => {
        this.totalDrivenDistanceSummary += +distance;
      });
      this.totalDrivenDistanceSummary = parseFloat(this.totalDrivenDistanceSummary.toFixed(2));
    }
    if (RoutesListControls && RoutesListControls.length > 0) {
      let isExceedLimit = false;
      RoutesListControls.forEach(route => {
        let averageSpeed = route.controls.AverageSpeed.value;
        if (parseInt(averageSpeed, 10) > 240) {
          isExceedLimit = true;
        }
      });
      if (isExceedLimit) {
          // console.log(averageSpeed);
          this.confirmAverageSpeedDialog(stepperElement);
      }
    }
    // console.log("****** separateTripFrom:", this.separateTripForm.value);
    let RoutesList = this.getFormRoutesList.controls;
    let UpdateRouteInputModel = this.separateTripForm.controls.UpdateRouteInputModel;
    console.log('UpdateRouteInputModel:', UpdateRouteInputModel);
    if (RoutesList && RoutesList.length > 0) {
      let previousTime;
      for (let i=0; i < RoutesList.length-1; i++) {
        let value = RoutesList[i].value.Time.length === 4 ? '0' + RoutesList[i].value.Time: RoutesList[i].value.Time;
        if (!previousTime) {
          previousTime = value;
        } else {
          // logic 1- check not less than selected trip start time and greater than selected trip end time
          // logic 2- check previous value is lessthan current value
          if (previousTime < value && value < UpdateRouteInputModel.value.EndTime && value > UpdateRouteInputModel.value.StartTime) {
            this.getFormRoutesList.controls[i].value.IsError = false;
          } else {
            this.getFormRoutesList.controls[i].value.IsError = true;
            this.formSubmitFieldInValid = true;
          }
          previousTime = value;
        }
      }
    }
  }

  confirmAverageSpeedDialog(stepper) {
    const title = this.translateService.instant('CONFIRMACTION');
    const message = this.translateService.instant('AVERAGESPEEDMESSAGE');
    const dialogData = {
      title: title,
      message: message
    };
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
     width: '70vmin',
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        // console.log(dialogResult);
      } else {
        // console.log('stay on same stepper');
        stepper.previous();
        // this.stepper.selectedIndex = 1;
      }
    });
  }

  thirdStepClick() {

  }

  onDismiss() {
    this.dialogRef.close();
  }

  close() {
    this.onDismiss();
  }

  onConfirm() {
    this.dialogRef.close(true);
  }

  timeChangedEvent(value, index: number, routes) {
    // console.log('******** timeChangedEvent', value, index);
    if (index === 0 || index === routes.length-1) {
      this.validateWithPreviousOrNextTripsTime(value, index, routes);
    }
    if (index%2 !== 0) {
      this.setFormArrayControlTimeValue(value, index);
    }
    this.getAverageSpeed(index, routes);
  }

  setFormArrayControlTimeValue(value, index) {
    let formArrayControl: any = this.getFormRoutesList.controls[index];
    let formArrayNextControl: any = this.getFormRoutesList.controls[index+1];
    let formArrayUpNextControl: any = this.getFormRoutesList.controls[index+2];
    if (formArrayControl) {
      formArrayControl.controls.Time.setValue(value);
    }
    if (formArrayNextControl && index+1 < this.getFormRoutesList.length-1) {
      let splitValue = value.split(':');
      const hr = splitValue[0];
      let minValue: any = (parseInt(splitValue[1], 10)+1);
      minValue = minValue.toString().length === 1 ? '0'+ minValue: minValue;
      formArrayNextControl.controls.Time.setValue( hr + ":" + minValue);
    }
    if (formArrayUpNextControl && index+2 < this.getFormRoutesList.length-1) {
      formArrayUpNextControl.controls.Time.setValue('');
    }
    this.getAverageSpeed(index, this.getFormRoutesList.controls);
  }

  validateWithPreviousOrNextTripsTime(value, index, routes) {
    let selectedVehiclePreviousRoute = this.storageService.get('selectedVehiclePreviousRoute');
    let selectedVehicleNextRoute = this.storageService.get('selectedVehicleNextRoute');
    if (selectedVehiclePreviousRoute || selectedVehicleNextRoute) {
      let currentDate = moment(routes[index].value.Date).format('DD.MM.YYYY');
      let previousDate = selectedVehiclePreviousRoute.EndDate;
      let nextDate = selectedVehicleNextRoute.EndDate;
      if (selectedVehiclePreviousRoute && currentDate === previousDate && value+':00' <= selectedVehiclePreviousRoute.EndTime) {
        this.formFieldValid = false;
        this.snackBar.open(this.translateService.instant('TIMECONFLICTPREVIOUSERRORMESSAGE'), this.translateService.instant('CANCEL'), AppConstants.SNACK_BAR_DELAY);
        return;
      }
      if (selectedVehicleNextRoute && currentDate === nextDate && value+':00' >= selectedVehicleNextRoute.StartTime) {
        this.formFieldValid = false;
        this.snackBar.open(this.translateService.instant('TIMECONFLICTNEXTERRORMESSAGE'), this.translateService.instant('CANCEL'), AppConstants.SNACK_BAR_DELAY);
        return;
      }
      this.formFieldValid = true;
    }
  }

  onAddressAutocompleteSelected(result: PlaceResult, index: number) {
    console.log('onAddressAutocompleteSelected: ', result, index);
  }

  onAddressLocationSelected(location: Location, index: number) {
    console.log('onAddressLocationSelected:', location, index);
  }

  onGermanAddressMapped($event: GermanAddress, index: number, routes) {
    console.log('onGermanAddressMapped', $event, index);
    let formArrayPreviousControl: any = this.getFormRoutesList.controls[index-1];
    let formArrayControl: any = this.getFormRoutesList.controls[index];
    let formArrayNextControl: any = this.getFormRoutesList.controls[index+1];
    let formArrayNextControl1: any = this.getFormRoutesList.controls[index+2];
    if (formArrayControl) {
      formArrayControl.controls.Address.setValue(this.commonService.formateToGermanAddress($event));
    }
    if (formArrayNextControl1) {
      formArrayNextControl.controls.Address.setValue(this.commonService.formateToGermanAddress($event));
    }
    // calculate the distance travelled on route of two points
    let source, destination;
    if (formArrayControl) {
      if (index%2 === 0) {
        source = $event.displayAddress;
        destination = formArrayControl.controls.Address.value;
        this.commonService.calculateDistance(source, destination, formArrayNextControl.controls.DrivenDistance, this.getFormRoutesList).then(() => {
          this.getAverageSpeed(index+1, routes);
        });
      } else {
        source = formArrayPreviousControl.controls.Address.value;
        destination = $event.displayAddress;
        this.commonService.calculateDistance(source, destination, formArrayControl.controls.DrivenDistance, this.getFormRoutesList).then(() => {
          this.getAverageSpeed(index, routes);
        });
      }
    }
    if (formArrayNextControl1) {
      source = $event.displayAddress;
      destination = formArrayNextControl1.controls.Address.value;
      this.commonService.calculateDistance(source, destination, formArrayNextControl1.controls.DrivenDistance, this.getFormRoutesList).then(() => {
        this.getAverageSpeed(index+1, routes);
        this.getAverageSpeed(index+2, routes);
      });
    }
  }

  resetStepper() {
    let stepper: any = this.stepperEle;
    if (stepper) {
      stepper.reset();
    }
  }

  submitSummaryDetails() {
    let options = {
      UpdateRouteInputModel: {},
      CreateRouteInputModels: []
    }
    let CreateRouteInputModels = this.processRouteInputModels();
    let UpdateRouteInputModel = this.separateTripForm.controls.UpdateRouteInputModel.value;
    const splitEndDate = this.separateTripForm.controls.UpdateRouteInputModel.value.EndDate.split('.');
    const splitStartDate = this.separateTripForm.controls.UpdateRouteInputModel.value.StartDate.split('.');
    UpdateRouteInputModel.EndDate = moment(new Date(splitEndDate[1] +'-'+ splitEndDate[0] +'-'+ splitEndDate[2])).format('YYYY-MM-DD');
    UpdateRouteInputModel.StartDate = moment(new Date(splitStartDate[1] +'-'+ splitStartDate[0] +'-'+ splitStartDate[2])).format('YYYY-MM-DD');
    UpdateRouteInputModel.StartAddress = CreateRouteInputModels[0].StartAddress;
    UpdateRouteInputModel.EndAddress = CreateRouteInputModels[0].EndAddress;
    UpdateRouteInputModel.StartMileage = CreateRouteInputModels[0].StartMileage;
    UpdateRouteInputModel.EndMileage = CreateRouteInputModels[0].EndMileage;
    UpdateRouteInputModel.StartTime = CreateRouteInputModels[0].StartTime;
    UpdateRouteInputModel.EndTime = CreateRouteInputModels[0].EndTime;
    options.UpdateRouteInputModel = UpdateRouteInputModel;
    if (CreateRouteInputModels && CreateRouteInputModels.length > 0) {
      for (let i=0; i < CreateRouteInputModels.length; i++) {
        if (i!==0) {
          options.CreateRouteInputModels.push(CreateRouteInputModels[i]);
        }
      }
    }
    this.driverService.splitVehicleRoute(options).subscribe((response) => {
      console.log(response);
      this.dialogRef.close(options);
    }, (error) => {
      console.log(error);
      this.dialogRef.close(false);
    });
  }

  processRouteInputModels() {
    let result = [];
    let data: any = this.data;
    let vehicle = this.storageService.get('selectedVehicle');
    let routesList = this.separateTripForm.controls.RoutesList.value;
    let StartMileage, EndMileage;
    if (routesList && routesList.length > 0) {
      const DrivenDistance = this.storageService.get('DrivenDistance');
      // console.log(DrivenDistance);
      for (let i=0, j=0; i <= routesList.length-2; i++, j++) {
        // console.log(routesList[i], routesList[i+1]);
        if (i===0) {
          StartMileage = this.separateTripForm.controls.UpdateRouteInputModel.value.StartMileage || 0;
        } else {
          StartMileage = EndMileage;
        }
        EndMileage = DrivenDistance[j] + StartMileage;
        result.push({
          VehicleId: vehicle.VehicleId,
          RouteTypeId: data.VehicleRouteTypeId,
          StartAddress: routesList[i].Address,
          EndAddress: routesList[i+1].Address,
          AdditionalInformation: data.AdditionalInformation,
          StartMileage: StartMileage.toFixed(2),
          EndMileage: EndMileage.toFixed(2),
          StartTime: routesList[i].Time+ ':00',
          EndTime: routesList[i+1].Time+ ':00',
          StartDate: moment(routesList[i].Date).format('YYYY-MM-DD'),
          EndDate: moment(routesList[i+1].Date).format('YYYY-MM-DD'),
          DriverName: routesList[i+1].Drvier,
          RouteIds: [data.RouteId],
          BusinessPartnerName: routesList[i+1].BusinessPartnerName
        });
        ++i;
      }
    }
    return result;
  }

  getTripType(value) {
    return this.commonService.getTripType(value);
  }

  getDriversList(value) {
    if (value === '') {
      return;
    }
    let result = [];
    if (this.driversList && this.driversList.length !== 0) {
      result = this.driversList.filter((driver) => {
        return (driver.ID === value);
      });
    }
    if (result && result.length > 0) {
      return result[0].Firstname + ' ' + result[0].Lastname;
    }
  }

  getTimeValue(i) {
    let result = "00:00";
    if (i > 0) {
      if (this.separateTripForm.value.RoutesList[i-1].Time) {
        result = this.separateTripForm.value.RoutesList[i-1].Time;
      }
    }
    return result;
  }

  selectChanged(event, route, index) {
    console.log('selected route changed to', event, route, index);
  }

  stepChanged(event, stepperElement) {
    if (event.previouslySelectedIndex > event.selectedIndex) {
      event.previouslySelectedStep.interacted = false;
    }
    // stepperElement.stepper.selected.interacted = false;
  }

  onChangeHour(event, i) {
    // console.log(event.target.value, i);
    this.setFormArrayControlHourTimeValue(event.target.value, i);
  }

  setFormArrayControlHourTimeValue(value, index) {
    value = value.toString().length === 1 ? '0'+ value: value;
    let formArrayControl: any = this.getFormRoutesList.controls[index];
    let formArrayNextControl: any = this.getFormRoutesList.controls[index+1];
    let formArrayUpNextControl: any = this.getFormRoutesList.controls[index+2];
    if (formArrayControl) {
      formArrayControl.controls.HourTime.setValue(value);
    }
    if (index+1 < this.getFormRoutesList.length-1 && formArrayNextControl) {
      let parseIntMinValue: any = parseInt(formArrayControl.controls.MinuteTime.value, 10);
      if (parseIntMinValue === 59) {
        value = parseInt(value, 10)+1;
        value = value.toString().length === 1 ? '0'+ value: value;
      }
      formArrayNextControl.controls.HourTime.setValue(value);
      if (index+1 >= this.getFormRoutesList.length-3) {
        this.getAverageSpeed(this.getFormRoutesList.length-1, this.getFormRoutesList.controls);
      }
    }
    if (index+2 < this.getFormRoutesList.length-1 && formArrayUpNextControl) {
      formArrayUpNextControl.controls.HourTime.setValue('');
      formArrayUpNextControl.controls.MinuteTime.setValue('');
    }
    if (formArrayControl.controls.HourTime.value !== '' && formArrayControl.controls.MinuteTime.value !== '') {
      const hr = formArrayControl.controls.HourTime.value.length === 1 ? '0' + formArrayControl.controls.HourTime.value:formArrayControl.controls.HourTime.value;
      const min = formArrayControl.controls.MinuteTime.value.length === 1? '0'+ formArrayControl.controls.MinuteTime.value: formArrayControl.controls.MinuteTime.value;
      let Time = hr + ':' + min;
      this.setFormArrayControlTimeValue(Time, index);
    }
  }

  onChangeMinute(event, i) {
    // console.log(event.target.value, i);
    this.setFormArrayControlMinuteTimeValue(event.target.value, i);
  }

  setFormArrayControlMinuteTimeValue(value, index) {
    value = value.toString().length === 1 ? '0'+ value: value;
    let formArrayControl: any = this.getFormRoutesList.controls[index];
    let formArrayNextControl: any = this.getFormRoutesList.controls[index+1];
    let formArrayUpNextControl: any = this.getFormRoutesList.controls[index+2];
    if (formArrayControl) {
      formArrayControl.controls.MinuteTime.setValue(value);
    }
    if (index+1 < this.getFormRoutesList.length-1 && formArrayNextControl) {
      if (parseInt(value, 10) >= 0 && parseInt(value, 10) < 59) {
        value = parseInt(value, 10)+1;
        if (value.toString().length === 1) {
          value = '0' + value;
        }
        let parseIntHrValue: any = parseInt(formArrayControl.controls.HourTime.value, 10);
        if (parseIntHrValue.toString().length === 1) {
          parseIntHrValue = '0' + parseIntHrValue;
        }
        formArrayNextControl.controls.HourTime.setValue(parseIntHrValue);
        formArrayNextControl.controls.MinuteTime.setValue(value);
      } else {
        let parseIntHrValue: any = parseInt(formArrayControl.controls.HourTime.value, 10)+1;
        if (parseIntHrValue.toString().length === 1) {
          parseIntHrValue = '0' + parseIntHrValue;
        }
        formArrayNextControl.controls.HourTime.setValue(parseIntHrValue);
        formArrayNextControl.controls.MinuteTime.setValue('00');
      }
      if (index+1 >= this.getFormRoutesList.length-3) {
        setTimeout(() => {
          this.getAverageSpeed(this.getFormRoutesList.length-1, this.getFormRoutesList.controls);
        }, 1000);
      }
    }
    if (index+2 < this.getFormRoutesList.length-1 && formArrayUpNextControl) {
      formArrayUpNextControl.controls.MinuteTime.setValue('');
      formArrayUpNextControl.controls.HourTime.setValue('');
    }
    if (formArrayControl.controls.HourTime.value !== '' && formArrayControl.controls.MinuteTime.value !== '') {
      const hr = formArrayControl.controls.HourTime.value.length === 1 ? '0' + formArrayControl.controls.HourTime.value:formArrayControl.controls.HourTime.value;
      const min = formArrayControl.controls.MinuteTime.value.length === 1? '0'+ formArrayControl.controls.MinuteTime.value: formArrayControl.controls.MinuteTime.value;
      let Time = hr + ':' + min;
      this.setFormArrayControlTimeValue(Time, index);
    }
  }

  getAverageSpeed(index, routes) {
    let result;
    if (routes) {
      let startTime, endTime, DrivenDistance, startDate, endDate;
      if (index%2 !== 0) {
        startTime = routes[index-1].get('Time').value;
        endTime = routes[index].get('Time').value;
        DrivenDistance = routes[index].get('DrivenDistance').value? this.commonService.getFloatWithDotDecimalValue(routes[index].get('DrivenDistance').value.toString()): undefined;
        startDate = routes[index-1].get('Date').value;
        endDate = routes[index].get('Date').value;

        if ((!startDate || !endDate) || ((startDate && startDate.toString() === '') || (endDate && endDate.toString() === ''))) {
          this.snackBar.open(this.translateService.instant('DATETIMEDISTANCEERRORMESSAGE'), this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
          return;
        } else {
          const tripInNumberOfDays = this.commonService.getDifferanceInDays(startDate, endDate);
          let startTimeSplit = startTime && startTime !== '00:00' ? startTime.split(':'): [0, 0];
          let endTimeSplit = endTime && endTime !== '00:00' ? endTime.split(':'): [0, 0];
          startTimeSplit = parseFloat(startTimeSplit[0] + '.' + startTimeSplit[1]);
          endTimeSplit = parseFloat(endTimeSplit[0] + '.' + endTimeSplit[1]);
          if (tripInNumberOfDays > 3) {
            routes[index].get('Date').setValue('');
            this.snackBar.open(this.translateService.instant('DATELIMITEXCEEDERRORMESSAGE'), this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
            return;
          } else if (tripInNumberOfDays === 1) {
            if (startTime && startTime !== '00:00' && endTime && endTime !== '00:00' && DrivenDistance) {
              if (endTimeSplit > startTimeSplit) {
                this.formFieldValid = true;
                let resultTime = endTimeSplit-startTimeSplit;
                result = this.commonService.getAverageSpeedByDistanceTime(DrivenDistance, resultTime);
                routes[index].get('AverageSpeed').setValue(result.toFixed(2));
                if (result > 240) {
                  routes[index].value.IsSubmitFieldError = true;
                  this.snackBar.open(this.translateService.instant('AVERAGESPEEDERRORMESSAGE'), this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
                } else {
                  routes[index].value.IsSubmitFieldError = false;
                }
              } else if (endTimeSplit <= startTimeSplit) {
                this.snackBar.open(this.translateService.instant('ENDTIMEERRORMESSAGE'), this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
                this.formFieldValid = false;
              }
            }
          } else if (tripInNumberOfDays === 2 || tripInNumberOfDays === 3) {
            let resultTime = this.commonService.getMinutesByDateTime(startTimeSplit, tripInNumberOfDays, endTimeSplit);
            result = this.commonService.getAverageSpeedByDistanceTime(DrivenDistance, resultTime);
            routes[index].get('AverageSpeed').setValue(result.toFixed(2));
            routes[index].value.IsError = true;
            if (result > 240) {
              routes[index].value.IsSubmitFieldError = true;
              this.snackBar.open(this.translateService.instant('AVERAGESPEEDERRORMESSAGE'), this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
            } else {
              routes[index].value.IsSubmitFieldError = false;
            }
          }
        }
      } else {
        startTime = routes[index].get('Time').value;
        endTime = routes[index+1].get('Time').value;
        DrivenDistance = routes[index+1].get('DrivenDistance').value? this.commonService.getFloatWithDotDecimalValue(routes[index+1].get('DrivenDistance').value.toString()): undefined;
        startDate = routes[index].get('Date').value;
        endDate = routes[index+1].get('Date').value;

        if ((!startDate || !endDate) || ((startDate && startDate.toString() === '') || (endDate && endDate.toString() === ''))) {
          this.snackBar.open(this.translateService.instant('DATETIMEDISTANCEERRORMESSAGE'), this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
          return;
        } else {
          const tripInNumberOfDays = this.commonService.getDifferanceInDays(startDate, endDate);
          let startTimeSplit = startTime && startTime !== '00:00' ? startTime.split(':'): [0, 0];
          let endTimeSplit = endTime && endTime !== '00:00' ? endTime.split(':'): [0, 0];
          startTimeSplit = parseFloat(startTimeSplit[0] + '.' + startTimeSplit[1]);
          endTimeSplit = parseFloat(endTimeSplit[0] + '.' + endTimeSplit[1]);
          if (tripInNumberOfDays > 3) {
            routes[index+1].get('Date').setValue('');
            this.snackBar.open(this.translateService.instant('DATELIMITEXCEEDERRORMESSAGE'), this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
            return;
          } else if (tripInNumberOfDays === 1) {
            if (startTime && startTime !== '00:00' && endTime && endTime !== '00:00' && DrivenDistance) {
              if (endTimeSplit > startTimeSplit) {
                this.formFieldValid = true;
                let resultTime = endTimeSplit-startTimeSplit;
                result = this.commonService.getAverageSpeedByDistanceTime(DrivenDistance, resultTime);
                routes[index+1].get('AverageSpeed').setValue(result.toFixed(2));
                if (result > 240) {
                  routes[index].value.IsSubmitFieldError = true;
                  this.snackBar.open(this.translateService.instant('AVERAGESPEEDERRORMESSAGE'), this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
                } else {
                  routes[index].value.IsSubmitFieldError = false;
                }
              } else if (endTimeSplit <= startTimeSplit) {
                this.snackBar.open(this.translateService.instant('ENDTIMEERRORMESSAGE'), this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
                this.formFieldValid = false;
              }
            }
          } else if (tripInNumberOfDays === 2 || tripInNumberOfDays === 3) {
            let resultTime = this.commonService.getMinutesByDateTime(startTimeSplit, tripInNumberOfDays, endTimeSplit);
            result = this.commonService.getAverageSpeedByDistanceTime(DrivenDistance, resultTime);
            routes[index+1].get('AverageSpeed').setValue(result.toFixed(2));
            if (result > 240) {
              routes[index].value.IsSubmitFieldError = true;
              this.snackBar.open(this.translateService.instant('AVERAGESPEEDERRORMESSAGE'), this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
            } else {
              routes[index].value.IsSubmitFieldError = false;
            }
          }
        }
      }
    }
  }
  isCheckExceededAverageSpeed(value) {
    return (value && value > 240) ? true: false;
  }
  get isFormSubmitFieldDiasable () {
    let isFormSubmitFieldDiasable = false;
    if (this.getFormRoutesList.controls && this.getFormRoutesList.controls.length > 0) {
      this.getFormRoutesList.controls.forEach((data) => {
        if (data.value && data.value.IsSubmitFieldError) {
          isFormSubmitFieldDiasable = true;
        }
      });
    }
    return isFormSubmitFieldDiasable;
  }
}
