<div *ngIf="InspectionDataSource">
  <table mat-table [dataSource]="InspectionDataSource" matSort class="mat-elevation-z8">
    <!-- MessageId Column -->
    <ng-container matColumnDef="MessageId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'NO.' | translate}} </th>
      <td mat-cell *matCellDef="let element"> {{element.MessageId}} </td>
    </ng-container>
  
    <!-- FromDate Column -->
    <ng-container matColumnDef="FromDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'FROMDATE' | translate}} </th>
      <td mat-cell *matCellDef="let element"> {{element.FromDate}} </td>
    </ng-container>
  
    <!-- RemainingKilometer Column -->
    <ng-container matColumnDef="RemainingKilometer">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'REMAININGKILOMETER' | translate}} </th>
      <td mat-cell *matCellDef="let element" class="remaining-kilometer"> {{element.RemainingKilometer}} </td>
    </ng-container>
    
    <!-- RemainingKM Column -->
    <ng-container matColumnDef="RemainingTime">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'REMAININGTIME' | translate}} </th>
      <td mat-cell *matCellDef="let element" class="remaining-time"> {{element.RemainingTime}} </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedInspectionColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedInspectionColumns;"></tr>
  </table>
</div>