<div *ngIf="selectedVehicleInfo" id="right-section-card" fxLayout="column" fxLayoutAlign="none" class="right-section-card" [hidden]="hiddenRightSectionCard">
    <mat-card class="example-card top-section" fxFlex="20" fxFlex.sm="20" fxFlex.xs="100">
        <mat-card-content>
            <div class="top-corner-button">
                <button class="right-section-button-icon" mat-icon-button>
                    <mat-icon>home</mat-icon>
                </button>
            </div>
            <div class="header-p">
                <p><b>{{selectedRightSectionOption}} {{ 'TRIP' | translate}} ( {{selectedVehicleInfo?.TotalDistance }} Km)</b></p>
            </div>
            <div *ngIf="selectedVehicleInfo.AdditionalInformation">
                <p><b>{{'REASONOFTHETRIP' | translate }}:</b> {{selectedVehicleInfo?.AdditionalInformation}}</p>
            </div>
            <div *ngIf="selectedVehicleInfo.AdditionalDriverName">
                <p><b>{{'DRIVERNAMEADDITIONALINFO' | translate }}:</b></p>
                {{selectedVehicleInfo?.AdditionalDriverName}}
            </div>
            <div class="example-button-row" dir="rtl" fxLayoutGap="10px" fxLayout="row" fxLayout.xs="column" fxLayout.sm="row" fxLayoutAlign="center center" fxLayoutAlign.sm="end end" fxLayoutAlign.lg="end end" *ngIf="selectedVehicleInfo">
                <button mat-stroked-button color="warn" (click)="removRoute(selectedVehicleInfo)" [disabled]="isDisableField">{{'REMOVETRIP' | translate }}</button>
                <button mat-stroked-button (click)="toEdit(selectedVehicleInfo)" [disabled]="isDisableField">{{'TOEDIT' | translate }}</button>
                <button mat-stroked-button (click)="separateTripBtnClick()" [disabled]="isDisableField">{{'SEPARATETRIP' | translate }}</button>
            </div>
        </mat-card-content>
    </mat-card>
    <mat-card class="example-card bottom-section" fxFlex="80" fxFlex.sm="80" fxFlex.xs="100">
        <mat-card-content>
            <p>{{ 'ROUTEMAP' | translate}}:</p>
            <div>                
                <agm-map class="map" [latitude]="mapDetail.lat" [longitude]="mapDetail.lng">
                    <agm-marker 
                        [latitude]="mapDetail.origin.lat"
                        [longitude]="mapDetail.origin.lng">
                        <agm-info-window>
                            <strong>{{mapDetail.startAddress}}</strong>
                        </agm-info-window>
                    </agm-marker>
                    <agm-marker 
                        [latitude]="mapDetail.destination.lat"
                        [longitude]="mapDetail.destination.lng">
                        <agm-info-window>
                            <strong>{{mapDetail.endAddress}}</strong>
                        </agm-info-window>
                    </agm-marker>
                    <agm-polyline [editable]="true" [strokeOpacity]="0.5" [strokeWeight]="8" [zIndex]="" [strokeColor]="'blue'">
                        <agm-polyline-point
                            [latitude]="mapDetail.origin.lat"
                            [longitude]="mapDetail.origin.lng">
                        </agm-polyline-point>
                        <agm-polyline-point
                            [latitude]="mapDetail.destination.lat"
                            [longitude]="mapDetail.destination.lng">
                        </agm-polyline-point>
                    </agm-polyline>
                </agm-map>
            </div>
        </mat-card-content>
    </mat-card>
</div>