import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MileageValidator } from './mileage.validator';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '../../services/storage/storage.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppConstants } from '../../../app.constants';
import { CommonService } from '../../services/common/common.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-mileage',
  templateUrl: './mileage.component.html',
  styleUrls: ['./mileage.component.scss']
})
export class MileageComponent implements OnInit {

  mileageForm: FormGroup;
  mileageData;

  constructor(private dialogRef: MatDialogRef<MileageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translateService: TranslateService,
    private storageService: StorageService,
    private snackBar: MatSnackBar,
    private commonService: CommonService,
    private matDialog: MatDialog,
    private fb: FormBuilder) {
    }

  ngOnInit() {
    this.mileageData = this.data;
    this.mileageForm = this.fb.group({
      mileage: ['', [Validators.required, MileageValidator.validate(this.translateService, this.storageService)]],
    });
  }

  get mileageform() {
    return this.mileageForm.controls;
  }

  mileageChange() {
    if (this.data.CurrentMileage !== this.mileageForm.controls['mileage'].value) {
      this.data.isCorrectCurrentMileage = false;
    }
  }

  close() {
    this.dialogRef.close(false);
  }

  save() {
    this.data.PreviousMileage = this.storageService.get('selectedVehicleMileageInfo');
    this.data.isCorrectCurrentMileage = false;
    this.data.CurrentMileage = parseFloat(this.commonService.getFloatWithDotDecimalValue(this.mileageForm.controls['mileage'].value.toString()));
    this.confirmCurrentMielageDialog();
  }

  remindMeClick() {
		let userInfo: any = this.storageService.get('userInfo');
    this.snackBar.open('Hey ' + userInfo.name + '! , ' + this.translateService.instant('REMINDMELATERMESSAGE'), this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
    this.dialogRef.close(false);
  }

  confirmCurrentMielageDialog(): void {
		window.scroll(0, 0);
		const message = this.translateService.instant('UPDATEMIELAGECONFIRMATIONMESSAGE');
		const dialogData = {
      title: this.translateService.instant('UPDATEMIELAGECONFIRMATION', { value: this.data.CurrentMileage }),
      message: message
    };
		const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
			maxWidth: '130vmin',
			data: dialogData
		});

		dialogRef.afterClosed().subscribe(dialogResult => {
      // console.log(dialogResult);
      if (dialogResult) {
        this.dialogRef.close(this.data);
      }
		});
	}

}
