<section class="vehicle-section" (scroll)="onWindowScroll($event);">
    <mat-card id="vehicle-main-card-section" class="vehicle-card vehicle-main-card-section">
        <mat-card-content style="padding: 10px">
            <router-outlet></router-outlet>
        </mat-card-content>
    </mat-card>
</section>
<div class="spinner-blocker" fxLayout="row" fxLayoutAlign="center center">
	<mat-spinner [style.display]="showSpinner ? 'block' : 'none'"></mat-spinner>
</div>
