import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { TranslateConfigService } from '../../../shared/services/translate/translate-config.service';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss']
})
export class TermsConditionsComponent implements OnInit {

  title: string;
  contents$: Observable<[]>;

  constructor(private router: Router,
    private translateService: TranslateService,
    private translateConfigService: TranslateConfigService) { }

  ngOnInit(): void {
    this.loadTermsAndConditions();
    this.translateConfigService.selectedLanguage$.subscribe(() => {
      this.loadTermsAndConditions();
    });
  }

  loadTermsAndConditions() {
    setTimeout(() => {
      this.title = this.translateService.instant('TermsAndConditions.Title');
      this.contents$ = of(this.translateService.instant('TermsAndConditions.Contents'));
    }, 100);
  }

  return() {
    this.router.navigate(['login']);
  }
}
