  <h1 mat-dialog-title>
    {{title}}
  </h1>
  
  <div mat-dialog-content>
    <form [formGroup]="additionalDriverForm" fxLayout="column" class="example-form">
      <mat-form-field class="example-full-width" appearance="outline" fxFlex.xs="100">
        <mat-label>{{ 'FIRSTNAME' | translate }}</mat-label>
        <mat-icon matPrefix *ngIf="isFirstnameFocused">person</mat-icon>
        <input (focus)="isFirstnameFocused=true" (blur)="isFirstnameFocused=false" class="input-tag" type="text" matInput placeholder="{{'FIRSTNAMEPLACEHOLDER' | translate}}" formControlName="Firstname">
          <mat-error *ngIf="additionalDriverForm.controls['Firstname'].invalid && additionalDriverForm.controls['Firstname'].touched">
            {{ getErrorFirstname() }}
        </mat-error>
        <mat-error *ngIf="additionalDriverForm.controls['Firstname'].errors?.invalidAlphabets && additionalDriverForm.controls['Firstname'].touched">
          {{ 'INVALIDALPHABET' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="example-full-width" appearance="outline" fxFlex.xs="100">
        <mat-label>{{ 'LASTNAME' | translate }}</mat-label>
        <mat-icon matPrefix *ngIf="isLastnameFocused">person</mat-icon>
        <input (focus)="isLastnameFocused=true" (blur)="isLastnameFocused=false" class="input-tag" type="text" matInput placeholder="{{'LASTNAMEPLACEHOLDER' | translate}}" formControlName="Lastname">
        <mat-error *ngIf="additionalDriverForm.controls['Lastname'].invalid && additionalDriverForm.controls['Lastname'].touched">
            {{ getErrorLastname() }}
        </mat-error>
        <mat-error *ngIf="additionalDriverForm.controls['Lastname'].errors?.invalidAlphabets && additionalDriverForm.controls['Lastname'].touched">
          {{ 'INVALIDALPHABET' | translate }}
        </mat-error>
      </mat-form-field>
    </form>
  </div>
  
  <div mat-dialog-actions align="end">
    <button mat-button (click)="onDismiss()">{{'CANCEL'| translate}}</button>
    <button mat-raised-button [disabled]="additionalDriverForm.invalid" color="primary" (click)="onConfirm()">{{'SUBMIT'| translate}}</button>
  </div>