<div class="vehicleInformation" *ngIf="vehicleInformation" style="margin: 10px 0px">
    <mat-card class="mat-card-section" style="margin: 15px 5px">
        <div class="vehicle-card">
            <mat-card-header class="vehicle-card-header">
                <mat-card-title fxLayout="row" fxLayoutAlign="space-between" *ngIf="userInfo && userInfo.UserRole === appConstants.userRole.driver">
                    <div fxFlex="80">
                        <span> {{'STANDARDDRIVER' | translate}} </span>
                    </div>
                    <div fxFlex="20" fxLayoutAlign="end end">
                        <button (click)="EditStandardDriver(vehicleInformation)" mat-icon-button color="primary" aria-label="Edit Driver">
                            <mat-icon color="primary">edit</mat-icon>
                        </button>
                    </div>
                </mat-card-title>
                <mat-card-title fxLayout="row" fxLayout.xs="column" *ngIf="userInfo && userInfo.UserRole === appConstants.userRole.admin">
                    <span>{{'STANDARDADMIN' | translate}}</span>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="none">
                    <div fxFlex="25" fxFlex.sm="50" fxFlex.xs="50">
                        <p class="p-title"><b>{{'FIRSTNAME' | translate}}:</b></p>
                        <p>{{vehicleInformation?.Firstname}}</p>
                    </div>
                    <div fxFlex="25" fxFlex.sm="50" fxFlex.xs="50">
                        <p><b>{{'SURNAMEORLASTNAME' | translate}}:</b></p>
                        <p>{{vehicleInformation?.Lastname}}</p>
                    </div>
                    <div fxFlex="25" fxFlex.sm="50" fxFlex.xs="50">
                        <p><b>{{'EMAIL' | translate}}:</b></p>
                        <p>{{vehicleInformation?.Email}}</p>
                    </div>
                    <div fxFlex="25" fxFlex.sm="50" fxFlex.xs="50">
                        <mat-checkbox class="example-margin"
                            [matTooltip]="'DAILYREPORTUNMANAGEDTRIPSTOOLTIPMESSSAGE'|translate"
                            [checked]="isSubscribed" (change)="changeUnmanagedTripSubscribe($event)">
                            {{'DAILYREPORTUNMANAGEDTRIPS'|translate}}
                        </mat-checkbox>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="none">
                    <div fxFlex="25" fxFlex.sm="50" fxFlex.xs="50">
                        <p><b>{{'PHONENUMBER' | translate}}:</b></p>
                        <p>{{vehicleInformation?.TelephoneNumber}}</p>
                    </div>
                    <div fxFlex="25" fxFlex.sm="50" fxFlex.xs="50">
                        <p><b>{{'MOBILENUMBER' | translate}}:</b></p>
                        <p>{{vehicleInformation?.MobileNumber}}</p>
                    </div>
                </div>

                <div fxLayout="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="none" *ngIf="userInfo && userInfo.UserRole === appConstants.userRole.driver">
                    <mat-card class="mat-card-section" fxLayout="column" fxLayoutAlign="none" fxFlex="50" fxFlex.sm="100" fxFlex.xs="100">
                        <div fxFlex="row" class="home-address-section">
                            <div fxFlex="80" fxFlex.sm="80" fxFlex.xs="100" fxLayoutAlign="start center">
                                <span><b>{{'HOMEADDRESS' | translate}}:</b></span>
                            </div>
                            <div fxFlex="20" fxFlex.sm="20" fxFlex.xs="100" fxLayoutAlign="end end">
                                <button (click)="addHomeAddress()"
                                    mat-icon-button color="primary" aria-label="Add home address">
                                    <mat-icon color="primary">add</mat-icon>
                                </button>
                            </div>
                        </div>
                        <div fxFlex="row">
                            <mat-selection-list #homeAddress>
                                <mat-list-option *ngFor="let homeAddress of HomeAddresses" [value]="homeAddress">
                                    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutAlign.xs="end end">
                                        <div fxFlex="70" class="address-text">
                                            {{homeAddress.Address}}
                                        </div>
                                        <div fxFlex="30" fxLayout="row" fxLayoutAlign="end end">
                                            <button mat-icon-button color="primary" (click)="homeAddressClick(homeAddress)"><mat-icon>edit</mat-icon></button>
                                            <button (click)="deleteHomeAddress(homeAddress)" mat-icon-button color="primary" aria-label="Delete home address">
                                                <mat-icon color="primary">delete</mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                </mat-list-option>
                            </mat-selection-list>
                        </div>
                    </mat-card>
                    <mat-card class="mat-card-section" fxLayout="column" fxLayoutAlign="none" fxFlex="50" fxFlex.sm="100" fxFlex.xs="100">
                        <div fxFlex="row" class="work-address-section">
                            <div fxFlex="80" fxLayoutAlign="start center">
                                <span><b>{{'WORKADDRESS' | translate}}:</b></span>
                            </div>
                            <div fxFlex="20" fxLayoutAlign="end end">
                                <button (click)="addWorkAddress()" mat-icon-button color="primary" aria-label="Add work address">
                                    <mat-icon color="primary">add</mat-icon>
                                </button>
                            </div>
                        </div>
                        <div fxFlex="row">
                            <mat-selection-list #officeAddress>
                                <mat-list-option *ngFor="let officeAddress of OfficeAddresses" [value]="officeAddress">
                                    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutAlign.xs="end end">
                                        <div fxFlex="70" class="address-text">
                                            {{officeAddress.Address}}
                                          </div>
                                        <div fxFlex="30" fxLayout="row" fxLayoutAlign="end end">
                                            <button mat-icon-button color="primary" (click)="officeAddressClick(officeAddress)"><mat-icon>edit</mat-icon></button>
                                            <button (click)="deleteWorkAddress(officeAddress)" mat-icon-button color="primary" aria-label="Delete home address">
                                                <mat-icon color="primary">delete</mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                </mat-list-option>
                            </mat-selection-list>
                        </div>
                    </mat-card>
                </div>
            </mat-card-content>
        </div>
    </mat-card>
    <mat-card class="mat-card-section" *ngIf="userInfo && userInfo.UserRole === appConstants.userRole.driver" style="margin: 15px 5px">
        <div class="vehicle-card">
            <mat-card-header>
                <mat-card-title>
                    <span>
                        {{'VEHICLEDETAILS' | translate}}
                    </span>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content *ngIf="vehicleInformation?.Vehicles && vehicleInformation?.Vehicles.length > 0">
                <div *ngFor="let vehicle of vehicleInformation.Vehicles;let i = index;">
                    <mat-card class="mat-card-section">
                        <mat-card-content>
                            <div fxLayout="row" fxLayout.xs="row" fxLayoutAlign="none">
                                <div fxFlex="33" fxFlex.sm="33" fxFlex.xs="50">
                                    <p><b>{{'BRAND' | translate}}:</b></p>
                                    <p>{{vehicle?.VehicleBrand}}</p>
                                </div>
                                <div fxFlex="33" fxFlex.sm="33" fxFlex.xs="50">
                                    <p><b>{{'MODEL' | translate}}:</b></p>
                                    <p>{{vehicle?.VehicleModel}}</p>
                                </div>
                                <div fxFlex="33" fxFlex.sm="33" fxFlex.xs="100">
                                    <p><b>{{'MARK' | translate}}:</b></p>
                                    <p>{{vehicle?.PlateNumber}}</p>
                                </div>
                            </div>
                            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="none">
                                <div fxFlex="33" fxFlex.sm="33" fxFlex.xs="50">
                                    <p><b>{{'MILEAGE' | translate}}:</b></p>
                                    <p>{{vehicle?.Mileage}}</p>
                                </div>
                                <div fxFlex="67" fxFlex.sm="67" fxFlex.xs="50">
                                    <p><b>{{'MILEAGELASTUPDATEDDATE' | translate}}:</b></p>
                                    <p>{{vehicle?.MileageLastUpdatedDate}}</p>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </mat-card-content>
        </div>
    </mat-card>
    <mat-card class="mat-card-section" *ngIf="vehicleInformation?.AdditionalDrivers && userInfo && userInfo.UserRole === appConstants.userRole.driver" style="margin: 15px 5px">
        <div class="vehicle-card">
            <mat-card-header class="vehicle-card-header">
                <mat-card-title fxLayout="row" fxLayoutAlign="space-between">
                    <span fxFlex="80">{{ 'ADDITIONALDRIVERS' | translate}}</span>
                    <div fxFlex="20" fxLayoutAlign="end start">
                        <button (click)="addAdditionalDriver()"
                            mat-icon-button color="primary" aria-label="Add Driver">
                            <mat-icon color="primary">add</mat-icon>
                        </button>
                    </div>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div *ngFor="let additionalDriver of vehicleInformation.AdditionalDrivers;let i = index;">
                    <mat-card class="mat-card-section" fxLayout="row" fxLayoutAlign="none">
                        <div fxFlex="80" fxLayout="row" fxLayoutAlign="start start">
                            <div fxFlex="40">
                                <p><b>{{'FIRSTNAME' | translate}}:</b></p>
                                <p>{{additionalDriver?.Firstname}}</p>
                            </div>
                            <div fxFlex="40">
                                <p><b>{{'SURNAMEORLASTNAME' | translate}}:</b></p>
                                <p>{{additionalDriver?.Lastname}}</p>
                            </div>
                        </div>
                        <div fxFlex="20" *ngIf="userInfo && userInfo.UserRole === appConstants.userRole.driver"
                            fxLayout="row" fxLayoutAlign="end end">
                            <button (click)="EditAdditionalDriver(additionalDriver)"
                                mat-icon-button color="primary" aria-label="Edit Driver">
                                <mat-icon color="primary">edit</mat-icon>
                            </button>
                            <button (click)="DeleteAdditionalDriver(additionalDriver)"
                                mat-icon-button color="primary" aria-label="Delete Driver">
                                <mat-icon color="primary">delete</mat-icon>
                            </button>
                        </div>
                    </mat-card>
                </div>
            </mat-card-content>
        </div>
    </mat-card>
    <mat-card class="mat-card-section" *ngIf="userInfo && userInfo.UserRole === appConstants.userRole.driver" style="margin: 15px 5px">
        <div class="vehicle-card">
            <mat-card-header class="vehicle-card-header">
                <mat-card-title>
                    <span>{{ 'UPLOADOUTLOOKCONTACTS' | translate}}</span>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div fxLayout="row">
                    <div class="bulk-upload-button" fxLayout="row">
                        <input #csvInput hidden="true" type="file" onclick="this.value=null" (change)="csvInputChange($event)" accept=".csv"/>
                        <button mat-flat-button color="primary" (click)="csvInput.click()">{{ 'UPLOADCSVFILE' | translate}}</button>
                    </div>
                    <div class="bulk-upload-button" *ngIf="ELEMENT_DATA && ELEMENT_DATA.length > 0">
                        <button mat-flat-button color="primary" (click)="submitBulkContacts()">{{ 'SUBMIT' | translate}}</button>
                    </div>
                    <div class="bulk-upload-button">
                        <button mat-flat-button color="primary" (click)="AddContacts()">{{ 'ADD' | translate}}</button>
                    </div>
                    <div class="bulk-upload-button" *ngIf="(ELEMENT_DATA && ELEMENT_DATA.length > 0) && (selection && selection.selected && selection.selected.length > 0)">
                        <button mat-flat-button color="primary" (click)="deleteBulkContacts()">{{ 'DELETE' | translate}}</button>
                    </div>
                </div>
                <div fxLayout="row" *ngIf="ELEMENT_DATA && ELEMENT_DATA.length === 0">
                    {{'NOUPLOADEDCONTACTS' | translate}}
                </div>
                <br/>
                <div fxLayout="row">
                    <div class="mat-elevation-z8 table-wrapper" *ngIf="ELEMENT_DATA && ELEMENT_DATA.length > 0">
                        <table mat-table [dataSource]="dataSource" #firstTableSort="matSort" matSort>
                            <!-- Checkbox Column -->
                            <ng-container matColumnDef="select">
                                <th mat-header-cell *matHeaderCellDef>
                                    <mat-checkbox (change)="$event ? masterToggle() : null"
                                        [checked]="selection.hasValue() && isAllSelected()"
                                        [indeterminate]="selection.hasValue() && !isAllSelected()">
                                    </mat-checkbox>
                                </th>
                                <td mat-cell *matCellDef="let row">
                                    <mat-checkbox (click)="$event.stopPropagation()"
                                        (change)="$event ? selection.toggle(row) : null"
                                        [checked]="selection.isSelected(row)">
                                    </mat-checkbox>
                                </td>
                            </ng-container>

                            <!-- Firstname Column -->
                            <ng-container matColumnDef="Firstname">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'FIRSTNAME' | translate}} </th>
                                <td mat-cell *matCellDef="let element" data-label="Firstname"> {{element.Firstname}} </td>
                            </ng-container>

                            <!-- Surname Column -->
                            <ng-container matColumnDef="Surname">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'SURNAMEORLASTNAME' | translate}} </th>
                                <td mat-cell *matCellDef="let element" data-label="Surname"> {{element.Surname}} </td>
                            </ng-container>

                            <!-- Company Column -->
                            <ng-container matColumnDef="Company">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'COMPANY' | translate}} </th>
                                <td mat-cell *matCellDef="let element" data-label="Company"> {{element.Company}} </td>
                            </ng-container>

                            <!-- House number Column -->
                            <ng-container matColumnDef="HouseNumber">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'HOUSENUMBER' | translate}} </th>
                                <td mat-cell *matCellDef="let element" data-label="Housenumber"> {{element.BusinessPartnerAddresses && element.BusinessPartnerAddresses.length > 0? element.BusinessPartnerAddresses[0].HouseNumber: ''}} </td>
                            </ng-container>

                            <!-- Street Column -->
                            <ng-container matColumnDef="Street">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'STREET' | translate}} </th>
                                <td mat-cell *matCellDef="let element" data-label="Street"> {{element.Street}} </td>
                            </ng-container>

                            <!-- City Column -->
                            <ng-container matColumnDef="City">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'CITY' | translate}} </th>
                                <td mat-cell *matCellDef="let element" data-label="City"> {{element.City}} </td>
                            </ng-container>

                            <!-- Zipcode Column -->
                            <ng-container matColumnDef="Zipcode">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'ZIPCODE' | translate}} </th>
                                <td mat-cell *matCellDef="let element" data-label="Zipcode"> {{element.Zipcode}} </td>
                            </ng-container>

                            <!-- Country Column -->
                            <ng-container matColumnDef="Country">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'COUNTRY' | translate}} </th>
                                <td mat-cell *matCellDef="let element" data-label="Country"> {{element.Country}} </td>
                            </ng-container>

                            <!-- Operations Column -->
                            <ng-container  matColumnDef="Operations">
                                <th mat-header-cell *matHeaderCellDef> {{'OPERATIONS' | translate}} </th>
                                <td mat-cell *matCellDef="let element" data-label="Operations">
                                    <div class="action-section" fxLayout="row" fxLayout.sm="row" fxLayout.xs="row">
                                        <button mat-icon-button color="primary" (click)="editContact(element)"><mat-icon>edit</mat-icon></button>
                                        <button mat-icon-button color="primary" (click)="deleteContact(element)"><mat-icon>delete</mat-icon></button>
                                    </div>
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                        <mat-paginator #pagination2 [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <p>{{'BUSINESSPARTNERCONTACTS' | translate}}</p>
                    <div class="bulk-upload-button" *ngIf="(BusinessPartnerData && BusinessPartnerData.length > 0) && (businessPartnerSelection && businessPartnerSelection.selected && businessPartnerSelection.selected.length > 0)">
                        <button mat-flat-button color="primary" (click)="deleteBulkBusinessPartners()">{{'DELETE' | translate}}</button>
                    </div>
                </div>
                <div fxlayout="row">
                    <div class="mat-elevation-z8 table-wrapper" *ngIf="BusinessPartnerData && BusinessPartnerData.length > 0">
                        <table mat-table multiTemplateDataRows [dataSource]="BusinessPartnerDataSource" matSort (matSortChange)="sortData($event)">

                            <!-- Checkbox Column -->
                            <ng-container matColumnDef="businessPartnerSelect">
                                <th mat-header-cell *matHeaderCellDef>
                                    <mat-checkbox (change)="$event ? businessPartnerMasterToggle() : null"
                                        [checked]="businessPartnerSelection.hasValue() && isAllBusinessPartnerSelected()"
                                        [indeterminate]="businessPartnerSelection.hasValue() && !isAllBusinessPartnerSelected()">
                                    </mat-checkbox>
                                </th>
                                <td mat-cell *matCellDef="let row">
                                    <mat-checkbox (click)="$event.stopPropagation()"
                                        (change)="$event ? businessPartnerSelection.toggle(row) : null"
                                        [checked]="businessPartnerSelection.isSelected(row)">
                                    </mat-checkbox>
                                </td>
                            </ng-container>

                            <!-- ID Column -->
                            <ng-container matColumnDef="ID">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'ID' | translate}} </th>
                                <td mat-cell *matCellDef="let element;let i = dataIndex;" [attr.data-label]="'ID' | translate"> {{(element.Duplicate) ? '': element.ID}} </td>
                            </ng-container>

                            <!-- Firstname Column -->
                            <ng-container matColumnDef="Firstname">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'FIRSTNAME' | translate}} </th>
                                <td mat-cell *matCellDef="let element;" [attr.data-label]="'FIRSTNAME' | translate">  {{(element.Duplicate) ? '': element.FirtName}} </td>
                            </ng-container>

                            <!-- Surname Column -->
                            <ng-container matColumnDef="Surname">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'SURNAMEORLASTNAME' | translate}} </th>
                                <td mat-cell *matCellDef="let element;let i = dataIndex;" [attr.data-label]="'SURNAMEORLASTNAME' | translate"> {{(element.Duplicate) ? '': element.LastName}} </td>
                            </ng-container>

                            <!-- Company Column -->
                            <ng-container matColumnDef="Company">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'COMPANY' | translate}} </th>
                                <td mat-cell *matCellDef="let element;let i = dataIndex;" [attr.data-label]="'COMPANY' | translate"> {{(element.Duplicate) ? '': element.CompanyName}} </td>
                            </ng-container>

                            <!-- Street Column -->
                            <ng-container matColumnDef="Street">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'STREET' | translate}} </th>
                                <td mat-cell *matCellDef="let element;" [attr.data-label]="'STREET' | translate"> {{element.Street}} </td>
                            </ng-container>

                            <!-- HouseNumber Column -->
                            <ng-container matColumnDef="HouseNumber">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'HOUSENUMBER' | translate}} </th>
                                <td mat-cell *matCellDef="let element" [attr.data-label]="'HOUSENUMBER' | translate"> {{element.HouseNumber}} </td>
                            </ng-container>

                            <!-- City Column -->
                            <ng-container matColumnDef="CityName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'CITY' | translate}} </th>
                                <td mat-cell *matCellDef="let element" [attr.data-label]="'CITY' | translate"> {{element.CityName}} </td>
                            </ng-container>

                            <!-- State Column -->
                            <ng-container matColumnDef="FederalStateName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'STATE' | translate}} </th>
                                <td mat-cell *matCellDef="let element" [attr.data-label]="'STATE' | translate"> {{element.FederalStateName}} </td>
                            </ng-container>

                            <!-- Country Column -->
                            <ng-container matColumnDef="CountryName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'COUNTRY' | translate}} </th>
                                <td mat-cell *matCellDef="let element" [attr.data-label]="'COUNTRY' | translate"> {{element.CountryName}} </td>
                            </ng-container>

                            <!-- PostalCode Column -->
                            <ng-container matColumnDef="PostalCode">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'POSTALCODE' | translate}} </th>
                                <td mat-cell *matCellDef="let element; let i = dataIndex;" [attr.data-label]="'POSTALCODE' | translate"> {{element.PostalCode}} </td>
                            </ng-container>

                            <!-- Operations Column -->
                            <ng-container matColumnDef="Operations">
                                <th mat-header-cell *matHeaderCellDef> {{'OPERATIONS' | translate}} </th>
                                <td mat-cell *matCellDef="let element;let i = dataIndex;" data-label="Operations">
                                    <div class="action-section" fxLayout="row" fxLayout.sm="row" fxLayout.xs="row">
                                        <button mat-icon-button color="primary" (click)="editBusinessPartner(element)"><mat-icon>edit</mat-icon></button>
                                        <button mat-icon-button color="primary" (click)="deleteBusinessPartnerAddress(element)" *ngIf="element.Duplicate"><mat-icon>delete</mat-icon></button>
                                        <button mat-icon-button color="primary" (click)="deleteBusinessPartner(element)" *ngIf="!element.Duplicate"><mat-icon>delete</mat-icon></button>
                                        <button mat-icon-button color="primary" (click)="toggleRow(element, i)" *ngIf="element.isMultipleAddress"><mat-icon>list</mat-icon></button>
                                    </div>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedBusinessPartnerDataColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedBusinessPartnerDataColumns;let i = dataIndex;"></tr>
                        </table>
                        <mat-paginator #paginator2 [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"></mat-paginator>
                    </div>
                </div>
                <div fxLayout="row" *ngIf="BusinessPartnerData && BusinessPartnerData.length === 0">
                    {{'NOBUSINESSPARTNERS' | translate}}
                </div>
            </mat-card-content>
            <mat-card-actions>
            </mat-card-actions>
        </div>
    </mat-card>
    <mat-card class="vehicle-card mat-card-section" *ngIf="userInfo && userInfo.UserRole === appConstants.userRole.driver" style="margin: 15px 5px">
        <div class="technical-car-section" fxLayout="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="none">
            <div fxFlex="50" fxFlex.sm="50" fxFlex.xs="100" fxLayoutAlign="start center">
                <p class="mat-card-title">{{'TECHNICALCARINFORMATION' | translate}}</p>
            </div>
            <div fxFlex="50" fxFlex.sm="50" fxFlex.xs="100" fxLayoutAlign="end center">
                <button mat-stroked-button color="primary" (click)="displayAllForwardedMessages()">
                    <mat-icon aria-hidden="false" aria-label="Create New Trip/Route" color="primary">message</mat-icon>
                    {{'ALLFORWARDEDMESSAGES' | translate}}
                </button>
            </div>
        </div>
        <div fxLayout="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="none">
            <mat-accordion class="example-headers-align" multi>
                <mat-expansion-panel [expanded]="true">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{'INSPECRTIONWARNING' | translate}}
                        </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <form class="technical-car-inspection-form" [formGroup]="technicalCarInspectionForm" fxLayout="column" fxLayoutAlign="none">
                        <div>
                            <mat-form-field appearance="fill" class="vehicle-select-list">
                                <mat-label>{{ 'VEHICLES' | translate}}</mat-label>
                                <mat-select formControlName="vehicle" (selectionChange)="vehicleChangeEvent($event, technicalCarInspectionForm)">
                                    <mat-option *ngFor="let vehicle of vehiclesSelectionList" [value]="vehicle.VehicleId">
                                        {{vehicle.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div>
                            <mat-form-field appearance="outline">
                                <mat-label>{{'REMAININGKM' | translate}}</mat-label>
                                <input matInput placeholder="{{'KILOMETERSPLACEHOLDER' | translate}}" formControlName="remainingKM">
                            </mat-form-field>
                        </div>
                        <div>
                            <mat-form-field appearance="outline">
                                <mat-label>{{'REMAININGTIME' | translate}}</mat-label>
                                <mat-select matInput formControlName="remainingTime">
                                    <mat-option *ngFor="let time of remainingTime;let i=index;" [value]="time">
                                        {{ time }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </form>
                    <div fxLayout="row" fxLayoutAlign="end">
                        <button mat-button (click)="inspectionInfoSubmit()"
                        [disabled]="!technicalCarInspectionForm.valid">{{'SUBMIT' | translate}}</button>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{'OTHERMESSAGE' | translate}}
                        </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <form [formGroup]="technicalCarOtherForm" fxLayout="column" fxLayoutAlign="none">
                        <div>
                            <mat-form-field appearance="fill" class="vehicle-select-list">
                                <mat-label>{{ 'VEHICLES' | translate}}</mat-label>
                                <mat-select formControlName="vehicle" (selectionChange)="vehicleChangeEvent($event, technicalCarOtherForm)">
                                    <mat-option *ngFor="let vehicle of vehiclesSelectionList" [value]="vehicle.VehicleId">
                                        {{vehicle.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div>
                            <mat-form-field appearance="outline">
                                <mat-label>{{'SUBJECT' | translate}}</mat-label>
                                <mat-select matInput formControlName="subject" required>
                                    <mat-option *ngFor="let subject of subjectsList;let i=index;" [value]="subject">
                                        {{ subject }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="!technicalCarOtherForm.get('subject').hasError('required')">
                                    {{validationMessages.subject.required}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div>
                            <mat-form-field appearance="outline">
                                <mat-label>{{'FREETEXT' | translate}}</mat-label>
                                <textarea matInput placeholder="{{'FREETEXTPLACEHOLDER' | translate}}" formControlName="freeText"></textarea>
                            </mat-form-field>
                        </div>
                    </form>
                    <div fxLayout="row" fxLayoutAlign="end">
                        <button mat-button (click)="otherInfoSubmit()" [disabled]="!technicalCarOtherForm.valid">{{'SUBMIT' | translate}}</button>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </mat-card>
    <mat-card *ngIf="userInfo && userInfo.UserRole === appConstants.userRole.admin" style="margin: 15px 5px">
        <app-buildings-list></app-buildings-list>
    </mat-card>
</div>

