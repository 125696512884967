import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/services/auth-guard/auth-guard.service';
import { ChangePasswordComponent } from './shared/components/change-password/change-password.component';
import { DocviewerComponent } from './shared/components/docviewer/docviewer.component';
import { PrefferedCountryComponent } from './shared/components/preffered-country/preffered-country.component';
import { TermsConditionsComponent } from './shared/components/terms-conditions/terms-conditions.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  }, {
    path: 'login',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  }, {
		path: 'dashboard',
		loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard]
	}, {
    path: 'termsConditions',
    component: TermsConditionsComponent
  }, {
    path: 'documentViewer',
    component: DocviewerComponent,
    canActivate: [AuthGuard]
  }, {
		path: 'change-password',
    component: ChangePasswordComponent,
		canActivate: [AuthGuard]
	}, {
    path: 'preffered-country',
    component: PrefferedCountryComponent,
    canActivate: [AuthGuard]
  }, {
    path: 'logout',
    redirectTo: 'login' 
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
