import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { AppConstants } from '../../../app.constants';
import { MasterDataService } from '../../services/master-data/master-data.service';
import { StorageService } from '../../../shared/services/storage/storage.service';

@Component({
  selector: 'app-preffered-country',
  templateUrl: './preffered-country.component.html',
  styleUrls: ['./preffered-country.component.scss']
})
export class PrefferedCountryComponent implements OnInit {

  prefferedCountryForm: FormGroup;
  countries;

  constructor(private fb: FormBuilder,
    private masterDataService: MasterDataService,
    private snackbar: MatSnackBar,
    private translateService: TranslateService,
    private storageService: StorageService) { }

  ngOnInit(): void {
    this.prefferedCountryForm = this.fb.group({
      'country': ['', Validators.required],
    });
    this.getCountriesAndStates();
  }

  getCountriesAndStates() {
    this.masterDataService.getCountriesandStates().subscribe((response) => {
      this.countries = response;
    }, (error) => {
      console.log(error);
    });
  }

  selectedCountry(value) {
    console.log(value);
  }

  submit() {
    // console.log(this.prefferedCountryForm.value);
    this.storageService.set('SelectedCountry', this.prefferedCountryForm.value);
    this.snackbar.open(this.translateService.instant('SELECTEDCOUNTRYSAVED'), this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
  }
}
