<section>
    <mat-card class="reset-password-section">
        <mat-card-title>{{'RESETPASSWORD' | translate}}</mat-card-title>
        <mat-card-content>
            <mat-label><p>{{'NAME' | translate}}: <b>{{userInfo.name}}</b></p></mat-label>
            <form [formGroup]="resetPasswordForm">
                <mat-form-field appearance="outline">
                    <mat-label>{{'OLDPASSWORD' | translate}}</mat-label>
                    <input matInput formControlName="oldPassword" placeholder="{{'OLDPASSWORDPLACEHOLDER'| translate}}">
                    <mat-error *ngIf="oldPassword.invalid">{{'OLDPASSWORDREQUIRED' | translate}}</mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{'NEWPASSWORD' | translate}}</mat-label>
                    <input matInput formControlName="newPassword" placeholder="{{'NEWPASSWORDPLACEHOLDER'| translate}}">
                    <mat-error *ngIf="newPassword.invalid">{{'NEWPASSWORDREQUIRED' | translate}}</mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{'CONFIRMEDPASSWORD' | translate}}</mat-label>
                    <input matInput formControlName="confirmPassword" placeholder="{{'CONFIRMPASSWORDPLACEHOLDER'| translate}}">         
                    <mat-error *ngIf="confirmPassword.invalid">{{'CONFIRMPASSWORDREQUIRED' | translate}}</mat-error>
                </mat-form-field>
                <mat-error *ngIf="confirmPassword.valid && (resetPasswordForm.errors && resetPasswordForm.errors.pwdsDontMatch)">{{'PASSWORDNOTMATCH' | translate}}</mat-error>
                <br/>
                <button mat-raised-button color="primary" [disabled]="resetPasswordForm.invalid" (click)="submit()">{{'SUBMIT' | translate}}</button>
            </form>
        </mat-card-content>
    </mat-card>
</section>
