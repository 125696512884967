import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import * as logo from './logo.js';
import * as moment from 'moment';
import { StorageService } from '../../../shared/services/storage/storage.service.js';
import { CommonService } from '../../../shared/services/common/common.service.js';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ExportExcelService {

  constructor(private storageService: StorageService,
    private commonService: CommonService,
    private translateService: TranslateService) { }

  exportExcel(excelData) {

    // Title, Header & Data
    const title = excelData.title;
    const header = excelData.headers;
    const data = excelData.data;

    // Create a workbook with a worksheet
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Log Data');

    // Add Hearder - Image
    const myLogoImage = workbook.addImage({
      base64: logo.logoBase64,
      extension: 'png',
    });
    worksheet.mergeCells('A1:A4');
    worksheet.addImage(myLogoImage, 'A1:A4');

    // Add Header - Title
    worksheet.mergeCells('B1', 'E4');
    const titleRow = worksheet.getCell('B1');
    titleRow.value = title;
    titleRow.font = {
      name: 'Calibri',
      size: 16,
      underline: 'single',
      bold: true,
      color: { argb: '0085A3' }
    };
    titleRow.alignment = { vertical: 'middle', horizontal: 'center' };

    // Add Header - Date
    worksheet.mergeCells('F1:F4');
    const d = new Date();
    const date = moment(d).format('DD.MM.YYYY HH:mm:ss');
    const dateCell = worksheet.getCell('F1');
    dateCell.value = date;
    dateCell.font = {
      name: 'Calibri',
      size: 12,
      bold: true
    };
    dateCell.alignment = { vertical: 'middle', horizontal: 'center' };

    // user and vehicle details Row
    const user_Vehicle = worksheet.addRow([
      'Vehicle Number:', excelData.option.vehicleNumber,
      'Driver Name:', excelData.option.driverName,
      'Start Trip Date:', excelData.option.initialTripDate
    ]);
    // user_Vehicle.eachCell((cell, number) => {
    //   cell.fill = {
    //     type: 'pattern',
    //     pattern: 'solid',
    //     fgColor: { argb: '87CEEB' },
    //     bgColor: { argb: '' }
    //   };
    //   cell.font = {
    //     bold: true,
    //     color: { argb: 'FFFFFF' },
    //     size: 12
    //   };
    // });

    // mileage details Row
    const mileage_details = worksheet.addRow([
      'Inital Mileage(KM):', excelData.option.initalMileage,
      'Final Mileage(KM):', excelData.option.finalMileage,
      'Final Trip Date:', excelData.option.finalTripDate
    ]);
    // mileage_details.eachCell((cell, number) => {
    //   cell.fill = {
    //     type: 'pattern',
    //     pattern: 'solid',
    //     fgColor: { argb: '87CEEB' },
    //     bgColor: { argb: '' }
    //   };
    //   cell.font = {
    //     bold: true,
    //     color: { argb: 'FFFFFF' },
    //     size: 12
    //   };
    // });

    // Adding Routes Table Header Row
    const headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4167B8' },
        bgColor: { argb: '' }
      };
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12
      };
    });

    // Add Routes Table Data Rows with Conditional Formatting
    data.forEach(d => {
      const row = worksheet.addRow(d);
    });

    worksheet.getColumn(1).width = 35;
    worksheet.getColumn(2).width = 35;
    worksheet.getColumn(3).width = 20;
    worksheet.getColumn(4).width = 20;
    worksheet.getColumn(5).width = 15;
    worksheet.getColumn(6).width = 22;
    worksheet.getColumn(7).width = 50;

    worksheet.addRow([]);

    if (excelData.option.VehicleReport) {

      // Yearly based Summary
      const selectedReportYear = this.storageService.get('selectedReportYear') || '';
      const annualOverview = worksheet.addRow([
        this.translateService.instant('ANNUALOVERVIEW') + ' ' + selectedReportYear
      ]);
      annualOverview.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '4167B8' },
          bgColor: { argb: '' }
        };
        cell.font = {
          bold: true,
          color: { argb: 'FFFFFF' },
          size: 12
        };
      });
      let YearInformation = excelData.option.VehicleReport.Year;
      let resultantYearSummaryData;
      // console.log('YearInformation', YearInformation);
      resultantYearSummaryData = this.commonService.processYearInformation(YearInformation);
      // console.log(this.translateService.instant('ANNUALOVERVIEW') + ' ' + selectedReportYear);
      console.log(resultantYearSummaryData);

      // Adding Year Summary Table Header Row
      const yearSummaryHeaderRow = worksheet.addRow(resultantYearSummaryData.headers);
      yearSummaryHeaderRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '4167B8' },
          bgColor: { argb: '' }
        };
        cell.font = {
          bold: true,
          color: { argb: 'FFFFFF' },
          size: 12
        };
      });

      // Add Year Summary Table Data Rows with Conditional Formatting
      resultantYearSummaryData.data.forEach(d => {
        const row = worksheet.addRow(d);
      });

      worksheet.getColumn(1).width = 30;
      worksheet.getColumn(2).width = 30;
      worksheet.getColumn(3).width = 20;
      worksheet.getColumn(4).width = 20;
      worksheet.getColumn(5).width = 15;
      worksheet.getColumn(6).width = 22;
      worksheet.getColumn(7).width = 50;

      worksheet.addRow([]);

      // Driver based Summary
      const driverOverview = worksheet.addRow([
        this.translateService.instant('DRIVEROVERVIEW')
      ]);

      driverOverview.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '4167B8' },
          bgColor: { argb: '' }
        };
        cell.font = {
          bold: true,
          color: { argb: 'FFFFFF' },
          size: 12
        };
      });
      let DriverInformation = excelData.option.VehicleReport.DriverInfo;
      let resultantDriverSummaryData;
      // console.log('DriverInformation' , DriverInformation);
      resultantDriverSummaryData = this.commonService.processDriversInformation(DriverInformation);
      // console.log(this.translateService.instant('DRIVEROVERVIEW'), resultantExcelData);
      console.log(resultantDriverSummaryData);

      // Adding Driver Summary Table Header Row
      const driverSummaryHeaderRow = worksheet.addRow(resultantDriverSummaryData.headers);
      driverSummaryHeaderRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '4167B8' },
          bgColor: { argb: '' }
        };
        cell.font = {
          bold: true,
          color: { argb: 'FFFFFF' },
          size: 12
        };
      });

      // Add Driver Summary Table Data Rows with Conditional Formatting
      resultantDriverSummaryData.data.forEach(d => {
        const row = worksheet.addRow(d);
      });

      worksheet.getColumn(1).width = 30;
      worksheet.getColumn(2).width = 30;
      worksheet.getColumn(3).width = 20;
      worksheet.getColumn(4).width = 20;
      worksheet.getColumn(5).width = 15;
      worksheet.getColumn(6).width = 22;
      worksheet.getColumn(7).width = 50;

      worksheet.addRow([]);

    }

    // Generate & Save Excel File
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, title + '.xlsx');
    });

  }
}
