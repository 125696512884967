import { EventListenerFocusTrapInertStrategy } from '@angular/cdk/a11y';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { StorageService } from '../../../../app/shared/services/storage/storage.service';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GeofenceService {
  
  CR_URL = environment.crapi.URL;
  ASSETS_URL = environment.assetsapi.URL;

	drawingPolygons = [];
  polygons: Subject<any> = new Subject<any>();
  polygons$ = this.polygons.asObservable();

  drawingCircles = [];
  circles: Subject<any> = new Subject<any>();
  circles$ = this.circles.asObservable();

  drawingGeofences = [];
  geofences: Subject<any> = new Subject<any>();
  geofences$ = this.geofences.asObservable();
  
  refreshCircles: Subject<any> = new Subject<any>();
  refreshCircles$ = this.refreshCircles.asObservable();

  refreshPolygons: Subject<any> = new Subject<any>();
  refreshPolygons$ = this.refreshPolygons.asObservable();

  refreshGeofences: Subject<any> = new Subject<any>();
  refreshGeofences$ = this.refreshGeofences.asObservable();

  constructor(private http: HttpClient,
    private storageService: StorageService) { }

  get getPolygons() {
    return this.polygons;
  }

  get getDrawingPolygons() {
    return this.drawingPolygons;
  }

  public addPolygon(value, event) {
    if (value) {
      if (event && event.type === 'polygon') {
        this.drawingPolygons.push(event);
      }
      this.polygons.next({
        polygon: {
          Corners: value
        },
        event: event,
        type: 'create'
      });
    }
  }

  public updatePolygon(polygon, event) {
    if (this.drawingPolygons && this.drawingPolygons.length > 0) {
      this.drawingPolygons.forEach((data) => {
        if (data.id === polygon.id) {
          data.overlay = polygon.overlay;
        }
      });
    }
    this.polygons.next({
      polygon: polygon,
      event: event,
      type: 'updated'
    });
  }

  deletePolygon(polygon) {
    let result = [];
    this.getDrawingPolygons.forEach((data) => {
      if (data.id !== polygon.value.id) {
        result.push(data);
      }
    });
    this.drawingCircles = result;
    this.polygons.next({
      polygon: polygon,
      type: 'deleted'
    });
  }

  clearAllPolygons() {
    this.polygons.next([]);
    this.drawingPolygons = [];
  }

  get getCircles() {
    return this.circles;
  }

  get getDrawingCircles() {
    return this.drawingCircles;
  }

  public addCircle(value, event) {
    if (value && value !== []) {
      if (event && event.type === 'circle') {
        this.drawingCircles.push(event);
      }
      this.circles.next({
        circle: value,
        event: event,
        type: 'create'
      });
    }
  }

  public updateCircle(circle, event) {
    console.log(circle, this.circles);
    this.drawingCircles.forEach((data) => {
      if (data.id === circle.id) {
        data.overlay = circle;
      }
    });
    this.circles.next({
      circle: circle,
      event: event,
      type: 'updated'
    });
  }

  deleteCircle(circle) {
    let result = [];
    this.getDrawingCircles.forEach((data, index) => {
      if (data.id !== circle.value.id) {
        result.push(data);
      }
    });
    this.drawingCircles = result;
    this.circles.next({
      circle: circle,
      type: 'deleted'
    });
  }

  get getGeofence() {
    return this.circles;
  }

  get getDrawingGeofence() {
    return this.drawingGeofences;
  }

  public addGeofence(value, event) {
    if (value && value !== []) {
      if (event && event.type === 'circle') {
        this.drawingGeofences.push(event);
      }
      this.geofences.next({
        geofence: value,
        event: event,
        type: 'create'
      });
    }
  }

  public updateGeofence(geofence, event) {
    // console.log(geofence);
    this.geofences.next({
      geofence: geofence,
      event: event,
      type: 'updated'
    });
  }

  deleteGeofenceData(geofence) {
    let result = [];
    this.getDrawingGeofence.forEach((data, index) => {
      if (data.id !== geofence.value.id) {
        result.push(data);
      }
    });
    this.drawingCircles = result;
    this.geofences.next({
      geofence: geofence,
      type: 'deleted'
    });
  }

  clearAllCircles() {
    this.circles.next([]);
    this.drawingCircles = [];
  }

  clearAllGeofence() {
    this.geofences.next([]);
    this.drawingGeofences = [];
  }

  getAllGeofences(options) {
    if (options.Search && options.Search !== '') {
      return this.http.get(this.ASSETS_URL + '/GeoFence?page='+ options.PageNumber + '&limit='+ options.PageSize + '&Search='+ options.Search, {
        headers: this.getAuthorizationHeaders()
      });
    } else {
      return this.http.get(this.ASSETS_URL + '/GeoFence?page='+ options.PageNumber + '&limit='+ options.PageSize, {
        headers: this.getAuthorizationHeaders()
      });
    }
  }

  getAllCircles(options) {
    if (options.Search && options.Search !== '') {
      return this.http.get(this.ASSETS_URL + '/GeoFence/GeoCircle?page='+ options.PageNumber + '&limit='+ options.PageSize + '&Search='+ options.Search, {
        headers: this.getAuthorizationHeaders()
      });
    } else {
      return this.http.get(this.ASSETS_URL + '/GeoFence/GeoCircle?page='+ options.PageNumber + '&limit='+ options.PageSize, {
        headers: this.getAuthorizationHeaders()
      });
    }
  }

  getAllPolygons(options) {
    if (options.Search && options.Search !== '') {
      return this.http.get(this.ASSETS_URL + '/GeoFence/GeoPolygon?page='+ options.PageNumber + '&limit='+ options.PageSize + '&Search='+ options.Search, {
        headers: this.getAuthorizationHeaders()
      });
    } else {
      return this.http.get(this.ASSETS_URL + '/GeoFence/GeoPolygon?page='+ options.PageNumber + '&limit='+ options.PageSize, {
        headers: this.getAuthorizationHeaders()
      });
    }
  }

  saveGeofence(options) {
    return this.http.post(this.ASSETS_URL + '/GeoFence', options, {
      headers: this.getAuthorizationHeaders()
    });
  }

  deleteGeofence(options) {
    return this.http.delete(this.ASSETS_URL + '/GeoFence/'+ options.id, {
      headers: this.getAuthorizationHeaders()
    });
  }

  saveGeoCircle(options) {
    return this.http.post(this.ASSETS_URL + '/GeoFence/GeoCircle', options, {
      headers: this.getAuthorizationHeaders()
    });
  }

  deleteGeoCircle(options) {
    return this.http.delete(this.ASSETS_URL + '/GeoFence/GeoCircle/'+ options.id, {
      headers: this.getAuthorizationHeaders()
    })
  }

  saveGeoPolygon(options) {
    return this.http.post(this.ASSETS_URL + '/GeoFence/GeoPolygon', options, {
      headers: this.getAuthorizationHeaders()
    });
  }

  deleteGeoPolygon(options) {
    return this.http.delete(this.ASSETS_URL + '/GeoFence/GeoPolygon/'+ options.id, {
      headers: this.getAuthorizationHeaders()
    })
  }

  getAuthorizationHeaders() {
    const headers = {
      Authorization: ''
    };
    const session = localStorage.getItem('AssetsApiSession');
    if (session && session !== '') {
      const storageSession = JSON.parse(session);
      headers.Authorization = storageSession.token_type + ' ' + storageSession.access_token;
    }
    return headers;
  }

  refreshDataOnSavedCircle(data) {
    this.refreshCircles.next(data);
  }

  refreshDataOnSavedPolygon(data) {
    this.refreshPolygons.next(data);
  }

  refreshDataOnSavedGeofence(data) {
    this.refreshGeofences.next(data);
  }

}
