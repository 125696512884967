import { Component, OnInit, OnDestroy, ViewEncapsulation, ViewChild, Output, EventEmitter, HostListener, Inject } from '@angular/core';
import { MatSelectionList } from '@angular/material/list';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { animate, query, stagger, state, style, transition, trigger } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { DriverService } from '../../../shared/services/driver/driver.service';
import { MileageComponent } from '../../../shared/components/mileage/mileage.component';
import { EditVehicleTripComponent } from '../edit-vehicle-trip/edit-vehicle-trip.component';
import { DriverBook } from '../../models/route/driver-book.model';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { VehicleInformation } from '../../../information/models/vehicle-information/vehicle-information.model';
import { MileageSnackbarComponent } from '../../../shared/components/mileage-snackbar/mileage-snackbar.component';
import { AppConstants } from '../../../app.constants';
import { CommonService } from '../../../shared/services/common/common.service';
import { StorageService } from '../../../shared/services/storage/storage.service';
import { SeparateVehicleRouteComponent } from '../../../shared/components/separate-vehicle-route/separate-vehicle-route.component';
import { PostMileageRequest } from '../../../shared/models/mileage/post-mileage-request.model';
import { BusinessPartnerDialogComponent } from '../../../information/components/business-partner-dialog/business-partner-dialog.component';
import { UserInformation } from '../../../auth/models/user-information-response.model';
import { ContactUsSendEmailDialogComponent } from '../contact-us-send-email-dialog/contact-us-send-email-dialog.component';
import { TranslateConfigService } from '../../../shared/services/translate/translate-config.service';
import { SharedDialogComponent } from '../../../shared/components/shared-dialog/shared-dialog.component';
import { BusinessSummaryComponent } from '../business-summary/business-summary.component';

const listAnimation = trigger('listAnimation', [
	transition('* <=> *', [
	  query(':enter',
		[style({ opacity: 0 }), stagger('60ms', animate('600ms ease-out', style({ opacity: 1 })))],
		{ optional: true }
	  ),
	  query(':leave',
		animate('200ms', style({ opacity: 0 })),
		{ optional: true}
	  )
	])
]);

const fadeAnimation = trigger('fadeAnimation', [
      state('void', style({ opacity : 0})),
      transition(':enter',[ animate(300)]),
      transition(':leave',[ animate(500)])
]);

@Component({
	selector: 'app-vehicles',
	templateUrl: './vehicles.component.html',
	styleUrls: ['./vehicles.component.scss'],
	encapsulation: ViewEncapsulation.None,
	animations: [listAnimation, fadeAnimation],
	providers: [
		{ provide: MAT_DATE_FORMATS, useValue: AppConstants.DE_DATE_FORMATS }
	]
})
export class VehiclesComponent implements OnInit, OnDestroy {

	@ViewChild(MatSelectionList) vehiclesInfo: MatSelectionList;
	showSpinner: boolean = false;
	hiddenRightSectionCard = false;
	selectedVehicleInfo;
	driversSelectionList = [];
	vehiclesSelectionList = [];
	selectedVehicle: any;
	selectedTripsToCombine = [];
	selectedVehicleRouteInfo: any;
	selectedVehicleMileageInfo;
	vehicles;
	routeTypesList;
	routeStatusList;
	selectedOption;
	selectedOptionIds;
	selectedRightSectionOption;
	vehicleStartTime;
	vehicleEndTime;
	isCombineTrips = false;
	totalBusinessTripsDrivenKM = 0;
	totalPrivateTripsDrivenKM = 0;
	totalHomeTripsDrivenKM = 0;
	totalWorkTripsDrivenKM = 0;
	currentMileage = 0;
	maxDate = new Date();
	mapDetail = {
		lat: null,
		lng: null,
		origin: null,
		destination: null,
		startAddress: null,
		endAddress: null
	};
	public lat;
	public lng;
	public origin: any;
	public destination: any;
	dateForm: FormGroup;
	vehicleForm: FormGroup;
	filterDriverVehicles: FormGroup;
	validationMessages = {
		startDate: {
		  required: this.translateService.instant('STARTDATEREQUIRED'),
		},
		endDate: {
		  required:  this.translateService.instant('ENDDATEREQUIRED'),
		},
	};
	userInfo: UserInformation;
	driversList = [];
	appConstants;

	@Output() rightSideNavEmitter = new EventEmitter<any>();
	combine = false;
	selectedTabIndex: any;
	selectedVehicleCurrentMileage;
	isMileageOpened = false;
	isRemindMeClicked = false;
	isVehcileChanged = false;
	expiryTripsInformation;
	expiryTripsInfo;
	threeDaysExpireDescription;
	twoDaysExpireDescription;
	oneDayExpireDescription;

	compareFunction = (o1: any, o2: any) => o1.RouteId === o2.RouteId;

	constructor(private driverService: DriverService,
		private snackBar: MatSnackBar,
		private matDialog: MatDialog,
		public  dialogRef: MatDialogRef<MileageComponent>,
		public  dialogEditRef: MatDialogRef<EditVehicleTripComponent>,
		private formBuilder: FormBuilder,
		private commonService: CommonService,
		private translateService: TranslateService,
		private router: Router,
		private route: ActivatedRoute,
		private dateAdapter: DateAdapter<Date>,
		private translateConfigService: TranslateConfigService,
		private storageService: StorageService) {
		this.appConstants = AppConstants;
	}

	ngOnInit() {
		this.vehicleForm = this.formBuilder.group({
			driver: [''],
			vehicle: [''],
		});
		this.dateForm = this.formBuilder.group({
			startDate: ['', [Validators.required]],
			endDate: ['', [Validators.required]],
		});
		this.filterDriverVehicles = this.formBuilder.group({
			active: [true],
			inActive: []
		});
		this.dateForm.get('startDate').valueChanges.subscribe(value => {
		});
		this.dateForm.get('endDate').valueChanges.subscribe(value => {
		});
		this.loadData();
		this.commonService.vehicleTripsRefreshed$.subscribe((value) => {
			if (value && this.userInfo.UserRole !== AppConstants.userRole.driver) {
				this.getDriversList();
			}
		});
		this.setCalendarLanguage();
		this.translateConfigService.selectedLanguage$.subscribe((value: string) => {
			setTimeout(() => {
				this.setCalendarLanguage();
				this.refreshExpiryTripsInfo(this.expiryTripsInfo);
			}, 250);
		});
	}

	setCalendarLanguage() {
		let language = this.storageService.get('language');
		this.dateAdapter.setLocale(language);
	}

	loadData() {
		let userInfo = this.storageService.get('userInfo');
		if (userInfo) {
			this.userInfo = userInfo;
		}
		this.showSpinner = true;
		if (this.userInfo.UserRole) {
			if (this.userInfo.UserRole !== AppConstants.userRole.driver) {
				this.getDriversList();
			} else if (this.userInfo.UserRole === AppConstants.userRole.driver) {
				this.getVehiclesInformation();
			}
			this.getVehicleRouteStatus();
			this.getVehicleRouteTypes();
		}
	}

	ngOnDestroy(): void {
		this.snackBar.dismiss();
	}

	getDriversList() {
		this.showSpinner = true;
		this.driverService.getDriversList().subscribe((response: any) => {
			this.showSpinner = false;
			if (response && response.status === 400) {
				this.snackBar.open(response.message, this.translateService.instant('CANCEL'), AppConstants.SNACK_BAR_DELAY);
				return;
			}
			this.driversSelectionList = this.processDriversListInformation(response);
			if (this.driversSelectionList && this.driversSelectionList.length > 0) {
				this.vehicleForm.controls.driver.setValue(this.driversSelectionList[0].Firstname + ' ' + this.driversSelectionList[0].Lastname + ' - ' + this.driversSelectionList[0].PlateNumber + ' ' + this.driversSelectionList[0].VehicleBrand);
				this.vehicles = this.driversSelectionList[0].Vehicles;
				this.getVehiclesList(this.driversSelectionList[0]);
				this.storageService.set('driversList', this.driversSelectionList);
				this.storageService.set('selectedDriver', this.driversSelectionList[0]);
			}
			this.getMileageCheck();
			this.hiddenRightSectionCard = false;
		});
	}

	processUnManagedTrips() {
		this.expiryTripsInfo = this.getExpiryTripsInformation();
		this.refreshExpiryTripsInfo(this.expiryTripsInfo);
	}

	openBussinessSummary() {
		const data = {
			title: this.translateService.instant('UNMANAGEDTRIPSSUMMARY'),
			userInfo: this.userInfo,
			expiryTripsInformation: this.expiryTripsInformation,
		};
		let dialogRef = this.matDialog.open(BusinessSummaryComponent, {
			data: data
		});
		dialogRef.afterClosed().subscribe(value => {
			if (value && value.isDialogClose) {
				console.log(value);
			}
		});
	}

	refreshExpiryTripsInfo(expiryTripsInformation) {
		this.expiryTripsInformation = this.translateService.instant('UNMANAGEDDESCRIPTION', expiryTripsInformation);
		this.threeDaysExpireDescription = this.translateService.instant('EXPIREIN3DAYS', this.expiryTripsInfo);
		this.twoDaysExpireDescription = this.translateService.instant('EXPIREIN2DAYS', this.expiryTripsInfo);
		this.oneDayExpireDescription = this.translateService.instant('EXPIREIN1DAY', this.expiryTripsInfo);
		if (this.storageService.get('isFirstTimeLoggedIn') && this.userInfo.UserRole === AppConstants.userRole.driver) {
			this.openBussinessSummary();
			this.storageService.set('isFirstTimeLoggedIn', false);
		}
	}

	getExpiryTripsInformation() {
		let expireTrip = {
			in3Days: {
				businessCount: 0,
				privateCount:0,
				totalCount: 0
			}, in2Days: {
				businessCount: 0,
				privateCount:0,
				totalCount: 0
			}, in1Day: {
				businessCount: 0,
				privateCount:0,
				totalCount: 0
			},
		}
		if (this.vehicles && this.vehicles.length > 0) {
			if (this.vehicles[0].Routes && this.vehicles[0].Routes.length > 0) {
				// based on past 7 days we need 3rd day to get list of routes and respective business trip count
				let RoutesGetBy3Days = this.getRoutesGetByDays(3, this.vehicles[0].Routes);
				console.log('RoutesGetBy3Days:', RoutesGetBy3Days);
				if (RoutesGetBy3Days && RoutesGetBy3Days.length > 0) {
					expireTrip['in3Days'].totalCount = RoutesGetBy3Days.length;
					RoutesGetBy3Days.forEach(route => {
						if (route.VehicleRouteTypeId === 2 && !route.IsDeleted) {
							expireTrip['in3Days'].businessCount += 1;
						}
					});
					expireTrip['in3Days'].privateCount = expireTrip['in3Days'].totalCount - expireTrip['in3Days'].businessCount;
				}

				// based on past 7 days we need 3rd day to get list of routes and respective business trip count
				let RouteGetBy2Days = this.getRoutesGetByDays(2, this.vehicles[0].Routes);
				console.log('RouteGetBy2Days:', RouteGetBy2Days);
				if (RouteGetBy2Days && RouteGetBy2Days.length > 0) {
					expireTrip['in2Days'].totalCount = RouteGetBy2Days.length;
					RouteGetBy2Days.forEach(route => {
						if (route.VehicleRouteTypeId === 2 && !route.IsDeleted) {
							expireTrip['in2Days'].businessCount += 1;
						}
					});
					expireTrip['in2Days'].privateCount = expireTrip['in2Days'].totalCount - expireTrip['in2Days'].businessCount;
				}

				// based on past 7 days we need 3rd day to get list of routes and respective business trip count
				let RouteGetBy1Day = this.getRoutesGetByDays(1, this.vehicles[0].Routes);
				console.log('RouteGetBy1Day:', RouteGetBy1Day);
				if (RouteGetBy1Day && RouteGetBy1Day.length > 0) {
					expireTrip['in1Day'].totalCount = RouteGetBy1Day.length;
					RouteGetBy1Day.forEach(route => {
						if (route.VehicleRouteTypeId === 2 && !route.IsDeleted) {
							expireTrip['in1Day'].businessCount += 1;
						}
					});
					expireTrip['in1Day'].privateCount = expireTrip['in1Day'].totalCount - expireTrip['in1Day'].businessCount;
				}
			}
		}
		return expireTrip;
	}

	getRoutesGetByDays(day, Routes) {
		let date = this.dateForm.get('endDate').value.split('-');
		let formatedDate = '';
		let result;
		if (day === 3) {
			formatedDate = moment(new Date(date[1]+ '/' + (parseInt(date[2],10)-5) + '/' + date[0])).format('DD.MM.YYYY');
		} else if (day === 2) {
			formatedDate = moment(new Date(date[1]+ '/' + (parseInt(date[2],10)-6) + '/' + date[0])).format('DD.MM.YYYY');
		} else if (day === 1) {
			formatedDate = moment(new Date(date[1]+ '/' + (parseInt(date[2],10)-7) + '/' + date[0])).format('DD.MM.YYYY');
		}
		if (Routes && Routes.length > 0) {
			result = Routes.filter(route => {
				return route.EndDate === formatedDate && !route.IsDeleted;
			});
		}
		return result;
	}

	scrollToContent(moveToContent) {
		var x = document.getElementById(moveToContent);
		if (x) {
			x.scrollIntoView();
		}
	}

	navigateTo3DaysSection() {
		let date = this.dateForm.get('endDate').value.split('-');
		let moveToContent = moment(new Date(date[1]+ '/' + (parseInt(date[2],10)-5) + '/' + date[0])).format('DD.MM.YYYY');
		this.scrollToContent(moveToContent);
	}

	navigateTo2DaysSection() {
		let date = this.dateForm.get('endDate').value.split('-');
		let moveToContent = moment(new Date(date[1]+ '/' + (parseInt(date[2],10)-6) + '/' + date[0])).format('DD.MM.YYYY');
		this.scrollToContent(moveToContent);
	}

	navigateTo1DaysSection() {
		let date = this.dateForm.get('endDate').value.split('-');
		let moveToContent = moment(new Date(date[1]+ '/' + (parseInt(date[2],10)-7) + '/' + date[0])).format('DD.MM.YYYY');
		this.scrollToContent(moveToContent);
	}

	processDriversListInformation(response) {
		let result = [];
		if (response && response.length > 0) {
			response.forEach(driver => {
				if (driver.Vehicles && driver.Vehicles.length > 0) {
					driver.Vehicles.forEach(vehicle => {
						let driverVehicle = {};
						driverVehicle['AdditionalDrivers'] = driver.AdditionalDrivers;
						driverVehicle['Addresses'] = driver.Addresses;
						driverVehicle['Firstname'] = driver.Firstname;
						driverVehicle['ID'] = driver.ID;
						driverVehicle['Lastname'] = driver.Lastname;
						driverVehicle['Mileage'] = vehicle.Mileage;
						driverVehicle['MileageLastUpdatedDate'] = vehicle.MileageLastUpdatedDate;
						driverVehicle['PlateNumber'] = vehicle.PlateNumber;
						driverVehicle['VehicleBrand'] = vehicle.VehicleBrand;
						driverVehicle['VehicleId'] = vehicle.VehicleId;
						driverVehicle['VehicleMessages'] = vehicle.VehicleBrand;
						driverVehicle['VehicleModel'] = vehicle.VehicleId;
						driverVehicle['IsActive'] = vehicle.IsActive;
						result.push(driverVehicle);
					});
				} else {
					let driverVehicle = {};
					driverVehicle['AdditionalDrivers'] = driver.AdditionalDrivers;
					driverVehicle['Addresses'] = driver.Addresses;
					driverVehicle['Firstname'] = driver.Firstname;
					driverVehicle['ID'] = driver.ID;
					driverVehicle['Lastname'] = driver.Lastname;
					result.push(driverVehicle);
				}
			});
		}
		return result;
	}

	driverChange(event) {
		let result;
		let driversSelectionList = this.storageService.get('driversList');
		if (event.target.value) {
			let filteredData;
			let eventTargetValue = event.target.value + event.key;
			if (driversSelectionList) {
				filteredData = driversSelectionList.filter((driver) => {
					let value;
					if ((driver.Firstname && driver.Lastname && (driver.Firstname + ' ' + driver.Lastname).toLowerCase().indexOf(eventTargetValue.toLowerCase()) > -1) || (driver.PlateNumber && driver.PlateNumber.toLowerCase().indexOf(eventTargetValue.toLowerCase()) > -1)) {
						if (!this.filterDriverVehicles.controls.active.value && !this.filterDriverVehicles.controls.inActive.value) {
							value = true;
						} else if (this.filterDriverVehicles.controls.active.value && this.filterDriverVehicles.controls.inActive.value) {
							value = true;
						} else {
							if ((this.filterDriverVehicles.controls.active.value && driver.IsActive) || (this.filterDriverVehicles.controls.inActive.value && !driver.IsActive)) {
								value = true;
							} else {
								value = false;
							}
						}
					} else {
						value = false;
					}
					return value;
				});
			}
			result = filteredData;
			if (result && result.length > 0) {
				this.driversSelectionList = result;
			} else {
				// this.snackBar.open('Not matched with any of the driver or licence plate number!', this.translateService.instant('CANCEL'), AppConstants.SNACK_BAR_DELAY);
				this.driversSelectionList = [];
			}
		} else if (event.value) {
			result = this.driversSelectionList.filter((driver) => {
				return (driver.ID === event.value);
			});
		} else if (event.target.value === '') {
			this.driversSelectionList = [];
		}
	}

	processDriverSelection(result) {
		if (result) {
			this.storageService.set('selectedDriver', result);
			this.getMileageCheck();
			this.getVehiclesList(result);
			this.vehicleChangeEvent(false);
			this.setSelectedVehicle(result);
		}
	}

	driverChangeKeyEnter(event) {
		event.preventDefault();
		event.stopPropagation();
	}

	selectedDriverOption(value) {
		// console.log('driver options selected', value);
		let concatValue;
		if (value.Firstname) {
			concatValue = value.Firstname + ' ' + value.Lastname;
		}
		if (value.PlateNumber) {
			concatValue += ' - ' + value.PlateNumber + ' ' + value.VehicleBrand
		}
		this.vehicleForm.controls.driver.setValue(concatValue);
		this.processDriverSelection(value);
	}

	setSelectedVehicle(data) {
		this.selectedVehicle = data;
		this.storageService.set('selectedVehicle', this.selectedVehicle);
	}

	getVehicleRouteStatus() {
		this.driverService.getVehicleRouteStatus().subscribe((statusData) => {
			// console.log('************** types data', typesData);
			// localStorage.setItem('routeStatusList', JSON.stringify(statusData));
			this.storageService.set('routeStatusList', statusData);
			this.routeStatusList = statusData;
		}, (error) => {
			// console.log('******error_getVehicleRouteTypes******', error);
			this.showSpinner = false;
			this.snackBar.open(this.translateService.instant('ERRORMESSAGE') + ' ' + error.mesage,
				this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
		});
	}

	getVehicleRouteTypes() {
		this.driverService.getVehicleRouteTypes().subscribe((typesData) => {
			// console.log('************** types data', typesData);
			// localStorage.setItem('routeTypesList', JSON.stringify(typesData));
			this.storageService.set('routeTypesList', typesData);
		}, (error) => {
			// console.log('******error_getVehicleRouteTypes******', error);
			this.showSpinner = false;
			this.snackBar.open(this.translateService.instant('ERRORMESSAGE') + ' ' + error.message,
				this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
		});
	}

	getMileageCheck() {
		this.showSpinner = true;
		this.driverService.getMileageCheck().subscribe((data: any) => {
			// console.log('****** mileage data: ', data);
			if (data && data.length > 0) {
				this.currentMileage = data[0].CurrentMileage;
				this.storageService.set('vehicleMileage', data);
				this.updateCurrentMileage();
				let isLoggedInNow = this.storageService.get('loggedIn');
				if (isLoggedInNow && data && data[0].CheckMileage && !this.isRemindMeClicked) {
					this.promptMileageReminder();
					this.storageService.set('loggedIn', false);
				}
			}
			this.showSpinner = false;
		}, (error) => {
			// console.log('****** error: ', error);
			this.showSpinner = false;
			this.snackBar.open(this.translateService.instant('ERRORMESSAGE') + ' ' + error.message,
				this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
		}, () => {
			this.getDriverBookTrips();
		});
	}

	get getSelectedVehicleCurrentMileage() {
		let selectedVehicle = this.storageService.get('selectedVehicle');
		let vehicleMileage = this.storageService.get('vehicleMileage');
		let mileageInfoMatched;
		let result;
		if (selectedVehicle && !selectedVehicle.VehicleId) {
			return '0';
		}
		if (vehicleMileage && vehicleMileage.length > 0) {
			vehicleMileage.forEach(mileage => {
				if (mileage.VehicleId === selectedVehicle.VehicleId) {
					mileageInfoMatched = mileage;
				}
			});
			this.storageService.set('selectedVehicleMileageInfo', mileageInfoMatched);
			this.selectedVehicleMileageInfo = mileageInfoMatched;
			result = mileageInfoMatched.CurrentMileage;
		}
		if (this.userInfo.UserRole === AppConstants.userRole.admin && selectedVehicle) {
			if (selectedVehicle.CurrentMileage || selectedVehicle.Mileage) {
				if (selectedVehicle.CurrentMileage) {
					result = selectedVehicle.CurrentMileage;
				}
				if (selectedVehicle.Mileage) {
					result = selectedVehicle.Mileage;
				}
			} else {
				result = undefined;
			}
		}
		return result;
	}

	promptMileageReminder() {
		if (this.userInfo) {
			// const message = 'Hey ' + this.userInfo.name + '! , ' + this.translateService.instant('CURRENTMILEAGEOFCAR', { value: this.currentMileage });
			// this.openSnackBar(message, {
			// 	remindMe: this.translateService.instant('REMINDMELATER'),
			// 	yes:  this.translateService.instant('YES'),
			// 	no:  this.translateService.instant('NO'),
			// 	mileageInfo: {
			// 		currentMileage: this.currentMileage
			// 	}
			// });
			if (!this.isMileageOpened) {
				this.updateCurrentMileage();
				this.updateCurrentMileageInfo();
				this.isMileageOpened = true;
			}
		}
	}

	getVehiclesInformation() {
		this.driverService.getVehiclesInformation().subscribe((data: VehicleInformation) => {
			// console.log('****Vehiles Information***', data);
			// localStorage.setItem('userInformation', JSON.stringify(data));
			this.storageService.set('userInformation', data);
			if (this.userInfo && this.userInfo.UserRole === AppConstants.userRole.driver) {
				this.getVehiclesList(data);
			}
		}, (error: any) => {
		  	this.snackBar.open('Error: Vehicle information: ' + error, this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
		}, () => {
			this.getMileageCheck();
		});
	}

	getVehiclesList(data) {
		this.vehiclesSelectionList = [];
		let dataValue;
		if (this.userInfo.UserRole === AppConstants.userRole.admin) {
			dataValue = data;
		} else {
			dataValue = data.Vehicles;
		}
		if (dataValue && dataValue.length > 0) {
			this.vehicles = dataValue;
			dataValue.map((vehicleData: any) => {
				this.vehiclesSelectionList.push({
					VehicleId: vehicleData.VehicleId,
					name: vehicleData.PlateNumber + ' ' + vehicleData.VehicleBrand
					// RentStartDate: vehicleData && vehicleData.RentStartDate ? this.driverService.getEuropianDateFormat(vehicleData.RentStartDate): '',// moment(vehicleData.RentStartDate).format('DD.MM.YYYY'),
					// RentEndDate: vehicleData && vehicleData.RentEndDate ? this.driverService.getEuropianDateFormat(vehicleData.RentEndDate): '' // moment(vehicleData.RentEndDate).format('DD.MM.YYYY'),
				});
			});
			// console.log('vehiclesSelectionList: ', this.vehiclesSelectionList);
			this.storageService.set('vehiclesSelectionList', this.vehiclesSelectionList);
			this.vehicleForm.controls['vehicle'].setValue(this.vehiclesSelectionList[0].VehicleId);
			this.storageService.set('defaultVehicle', this.vehiclesSelectionList[0]);
			// this.selectedVehicle = data.Vehicles[0];
			// this.storageService.set('selectedVehicle', this.selectedVehicle);
			this.setSelectedVehicle(dataValue[0]);
		}
	}

	getDriverBookInfo(data) {
		let dateFilter: any = {};
		if (data && data !== '') {
			dateFilter = {
				startDate: data.startDate,
				endDate: data.endDate,
			};
			if (this.selectedVehicle) {
				if (this.selectedVehicle.VehicleId) {
					dateFilter['vehicleId'] = this.selectedVehicle.VehicleId;
				} else if (this.selectedVehicle.value) {
					dateFilter['vehicleId'] = this.selectedVehicle.value;
				}
			}
			this.storageService.set('filteredDates', dateFilter);
		}
		this.showSpinner = true;
		if (this.vehicleForm.controls.vehicle.value && this.vehicleForm.controls.vehicle.value !== '') {
			dateFilter['vehicleId'] = this.vehicleForm.controls.vehicle.value;
		}
		if (this.userInfo.UserRole === AppConstants.userRole.driver) {
			this.driverService.getDriverBookInfo(dateFilter).subscribe((response: DriverBook) => {
				this.processRoutesLoading(response);
			}, (error) => {
				// console.log('******error_getDriverBookInfo******', error);
				this.showSpinner = false;
				this.snackBar.open(error.message, this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
			});
		} else if (this.userInfo.UserRole === AppConstants.userRole.admin) {
			let selectedDriver = this.storageService.get('selectedDriver');
			const driverId = selectedDriver.ID;
			this.driverService.getAdminDriverBookInfo(driverId, dateFilter).subscribe((response: DriverBook) => {
				this.processRoutesLoading(response);
			}, (error) => {
				// console.log('******error_getDriverBookInfo******', error);
				this.showSpinner = false;
				this.snackBar.open(error.message, this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
			});
		}
	}

	processRoutesLoading(response) {
		// console.log('****** data', data);
		this.showSpinner = false;
		if (this.dateForm.value.startDate === ''&& this.dateForm.value.endDate === '') {
			let currentDate: any = moment().subtract(7, 'd').format('yyyy-MM-DD');
			let sevenDaysAfterDate: any = moment().format('yyyy-MM-DD');
			this.dateForm.controls.startDate.setValue(currentDate);
			this.dateForm.controls.endDate.setValue(sevenDaysAfterDate);
			let dateFilter = {
				startDate: currentDate.replaceAll('-', '.'),
				endDate: sevenDaysAfterDate.replaceAll('-', '.'),
				vehicleId: this.selectedVehicle && this.selectedVehicle.value ? this.selectedVehicle.value: (this.selectedVehicle && this.selectedVehicle.VehicleId ? this.selectedVehicle.VehicleId: '')
			};
			this.storageService.set('filteredDates', dateFilter);
		}
		if (response) {
			this.updateCurrentMileage();
			if (response.status === 400 || response.status === 404) {
				this.snackBar.open(response.message, this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
				return;
			}
			this.vehicles = response;
			if (this.vehicles && this.vehicles.length > 0) {
				if (this.vehicles[0] === null) {
					this.snackBar.open(this.translateService.instant('NOTRIPDETAILS'),this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
					return;
				}
				// console.log('vehiclesSelectionList: ', this.vehiclesSelectionList);
				this.vehicleChange({ value: this.vehicleForm.controls['vehicle'].value});
				this.storageService.set('DriverBookInfo', response[0].Routes);
				this.updateCurrentMileage();
				// get latest vehicle trip mileage Information to compare with the current mileage
				let latestSuccessfulVehicleTrip = this.selectedVehicle.Routes[this.selectedVehicle.Routes.length-1];
				let isLatestVehicleTripFilled = false;
				for(let i = this.selectedVehicle.Routes.length-1;i >= 0; i--) {
					this.routeStatusList.map((routeStatus) => {
						// only which has routeEnded status trip from LasrInFirstTrip can be called as latestSuccessfulVehicleTrip
						if(routeStatus.Status === AppConstants.routeStatus.RouteEnded) {
							if (this.selectedVehicle.Routes[i].VehicleRouteStatusId === routeStatus.Id) {
								latestSuccessfulVehicleTrip = this.selectedVehicle.Routes[i];
								isLatestVehicleTripFilled = true;
								return;
							}
						}
					});
					if (isLatestVehicleTripFilled) {
						break;
					}
				}
				setTimeout(() => {
					if (this.vehiclesInfo) {
						this.vehiclesInfo.deselectAll();
					}
				}, 100);
			} else {
				let selectedVehicleMileageInfo = this.storageService.get('selectedVehicleMileageInfo');
				this.selectedVehicle = {
					Vehicle: selectedVehicleMileageInfo && selectedVehicleMileageInfo.PlateNumber? selectedVehicleMileageInfo.PlateNumber: '',
					Routes: response
				};
				let selectedDriver = this.storageService.get('selectedDriver');
				if (selectedDriver && this.userInfo.UserRole === AppConstants.userRole.admin) {
					this.selectedVehicle['VehicleId'] = selectedDriver.VehicleId;
				} else {
					this.selectedVehicle['VehicleId'] = this.vehicleForm.controls.vehicle.value;
				}
				this.vehicleStartTime = '';
				this.vehicleEndTime = '';
				if (this.userInfo.UserRole === AppConstants.userRole.admin) {
					let selectedDriver = this.storageService.get('selectedDriver');
					if (selectedDriver) {
						this.selectedVehicleCurrentMileage = selectedDriver.Mileage;
						this.selectedVehicle.CurrentMileage = this.selectedVehicleCurrentMileage;
					}
				}
				this.storageService.set('selectedVehicle', this.selectedVehicle);
				this.storageService.set('DriverBookInfo', response);
				this.snackBar.open(this.translateService.instant('NOTRIPDETAILS'), this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
			}
			let selectedVehicleMileageInfo = this.storageService.get('selectedVehicleMileageInfo');
			if (this.isVehcileChanged && selectedVehicleMileageInfo && selectedVehicleMileageInfo.CheckMileage) {
				this.promptMileageReminder();
				this.isVehcileChanged = false;
			}
			if (this.storageService.get('isFirstTimeLoggedIn') && this.userInfo.UserRole === AppConstants.userRole.driver) {
				this.processUnManagedTrips();
			}
		} else {
			this.snackBar.open(this.translateService.instant('NOTRIPDETAILS'), this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
			return;
		}
	}

	onChangedRouteType(RouteChanged) {
		// console.log('RouteChanged value:', RouteChanged);
	}

	onSelection($event) {
		this.hiddenRightSectionCard = true;
		if (!this.isCombineTrips && this.vehiclesInfo) {
			this.vehiclesInfo.deselectAll();
			$event.option.selected = true;
		}
		// console.log('$event and selectedOptionIds: ', $event, this.selectedOptionIds);
		if (this.selectedOptionIds && this.selectedVehicle && this.selectedVehicle.routes) {
			for (let i=0; i < this.selectedVehicle.routes.length; i++) {
				if (this.selectedOptionIds[0] === this.selectedVehicle.routes[i].RouteId) {
					this.selectedVehicle.routes[i].isSelectedTrip = true;
					this.storageService.set('selectedVehicleRoute', this.selectedVehicle.routes[i]);
					this.setPreviousRoute(i-1, this.selectedVehicle.routes);
					this.setNextRoute(i+1, this.selectedVehicle.routes);
				} else {
					this.selectedVehicle.routes[i].isSelectedTrip = false;
				}
			}
		}
	}

	setPreviousRoute(index, routes) {
		console.log("Previous route info:", routes[index]);
		let selectedPreviousRoute;
		if (routes[index]) {
			if (routes[index].IsDeleted) {
				if ((index-1) >= 0) {
					this.setPreviousRoute(index-1, routes);
					return;
				}
			} else {
				selectedPreviousRoute = routes[index];
			}
			if ((index) >= 0) {
				this.storageService.set('selectedVehiclePreviousRoute', selectedPreviousRoute);
			} else {
				this.storageService.remove('selectedVehiclePreviousRoute');
			}
		} else {
			this.storageService.remove('selectedVehiclePreviousRoute');
		}
	}

	setNextRoute(index, routes) {
		console.log("Next route info:", routes[index]);
		let selectedNextRoute;
		if (routes[index]) {
			if (routes[index].IsDeleted) {
				if ((index+1) >= 0) {
					this.setNextRoute(index+1, routes);
					return;
				}
			} else {
				selectedNextRoute = routes[index];
			}
			if (index <= routes.length-1) {
				this.storageService.set('selectedVehicleNextRoute', selectedNextRoute);
			} else {
				this.storageService.remove('selectedVehicleNextRoute');
			}
		}
		else {
			this.storageService.remove('selectedVehicleNextRoute');
		}
	}

	rightSideNavToggle() {
		setTimeout(() => {
			if (this.isNotMobileView && this.selectedOptionIds && this.hiddenRightSectionCard) {
				let data = {
					selectedVehicleInfo: this.selectedVehicleInfo,
					selectedRightSectionOption: this.selectedRightSectionOption,
					hiddenRightSectionCard: this.hiddenRightSectionCard,
					mapDetail: this.mapDetail
				}
				this.commonService.setRightSideNavToggle(data);
				document.getElementsByTagName('body')[0].style.overflow = 'hidden';
			}
		}, 100);
	}

	onSelectionOption(vehicleInformation) {
		// console.log('********vehicleInformation**********', vehicleInformation);
		// console.log('********selectedVehicles**********', this.selectedVehicle);.
		// console.log('********selectedOption**********', this.selectedOption);
		// console.log('********selectedOptionIds**********', this.selectedOptionIds);
		if (vehicleInformation && this.selectedVehicle && this.selectedVehicle.routes) {
			this.selectedVehicle.routes.forEach((route) => {
				if(vehicleInformation.RouteId === route.RouteId) {
					route.isSelectedTrip = true;
				} else {
					route.isSelectedTrip = false;
				}
			});
			this.selectedVehicleInfo = vehicleInformation;
			this.getDirection(vehicleInformation);
			this.getSelectedTripName(vehicleInformation);
		}
	}

	getSelectedTripName(vehiclesInformation) {
		// console.log('123456789', vehiclesInformation);
		if (vehiclesInformation) {
			if (vehiclesInformation.VehicleRouteTypeId === 1) {
				this.selectedRightSectionOption = this.translateService.instant(AppConstants.tripTypes.PRIVATE);
			} else if (vehiclesInformation.VehicleRouteTypeId === 2) {
				this.selectedRightSectionOption = this.translateService.instant(AppConstants.tripTypes.BUSINESS);
			} else if (vehiclesInformation.VehicleRouteTypeId === 3) {
				this.selectedRightSectionOption = this.translateService.instant(AppConstants.tripTypes.COMMUTEHOME);
			} else if (vehiclesInformation.VehicleRouteTypeId === 4) {
				this.selectedRightSectionOption = this.translateService.instant(AppConstants.tripTypes.COMMUTEWORK);
			}
		}
		// console.log('********* selectedRightSectionOption', this.selectedRightSectionOption);
	}

	getDirection(data: any) {
		this.lat = data.StartLatitude;
		this.lng = data.StartLongitude;
		this.origin = { lat: data.StartLatitude, lng: data.StartLongitude };
		this.destination = { lat: data.EndLatitude, lng: data.EndLongitude };
		this.mapDetail = {
			lat: this.lat,
			lng: this.lng,
			origin: this.origin,
			destination: this.destination,
			startAddress: data.StartAddress,
			endAddress: data.EndAddress
		};
	}

	selectedTripValueChanged(data) {
		// console.log('****** data', data);
		this.selectedRightSectionOption = data.value.Type;
		this.showSpinner = true;
		this.checkBuinessPartner(data);
	}

	checkBuinessPartner(data) {
		if (data.value.Type === this.translateService.instant('BUSINESS')) {
			this.openBusinessPartnerDialog(data);
		} else {
			this.updateRouteType(data);
		}
	}

	openBusinessPartnerDialog(data) {
		this.showSpinner = false;
		this.storageService.set('selectedVehicleRoute', data);
		const dialogConfig = new MatDialogConfig();
		data.title = this.translateService.instant('CHOOSEBUSINESSPARTNER');
		data.isAddNewBusinessPartner= false;
		dialogConfig.data = data || '';
		dialogConfig.disableClose = true;
		dialogConfig.width = '400px';
		window.scroll(0, 0);
		let dialogBusinessPartnerRef = this.matDialog.open(BusinessPartnerDialogComponent, dialogConfig);
		dialogBusinessPartnerRef.afterClosed().subscribe(value => {
			console.log(`Dialog closed and sent: ${value}`);
			if (value && value.isClosedDialog && value.RouteId) {
				let options = {
					RouteId: value.RouteId,
					BusinessPartnerId: value.selectedBusinessPartnerId,
					AdditionalInformation: value.AdditionalInformation,
					RouteTypeId: data.vehicleInfo.VehicleRouteTypeId
				}
				this.driverService.updateRouteType(options).subscribe((response: any) => {
					if (response && response.message) {
					  this.snackBar.open(response.message, this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
					}
					this.getTripSummaryTotal();
					this.getDriverBookTrips();
				});
			} else {
				this.getTripSummaryTotal();
				this.getDriverBookTrips();
			}
		});
	}

	updateRouteType(data) {
		const postData = {
			RouteId: data.vehicleInfo.RouteId,
			RouteTypeId: data.vehicleInfo.VehicleRouteTypeId
		};
		this.driverService.updateRouteType(postData).subscribe(res => {
			// console.log(res);
			this.getTripSummaryTotal();
			this.getDriverBookTrips();
			this.snackBar.open(res.message, this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
			this.showSpinner = false;
		}, (err: HttpErrorResponse) => {
			if (err.error instanceof Error) {
				// console.log('An error occurred:', err.error.message);
				this.snackBar.open(this.translateService.instant('ERRORMESSAGE') + ' ' + err.error.message,
				this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
			} else {
				//   console.log('Backend returned status code: ', err.status);
				//   console.log('Response body:', err.error);
				this.snackBar.open(this.translateService.instant('ERRORMESSAGE') + ' ' + err.error,
				this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
			}
			this.showSpinner = false;
		});
	}

	applyDateFilterOnDriverBookTrips() {
		// console.log(this.dateForm.value);
		if (this.dateForm.value.startDate === '' && this.dateForm.value.endDate !== '') {
			this.snackBar.open(this.translateService.instant('STARTDATEREQUIRED'), this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
			return;
		}
		if (this.dateForm.value.startDate !== '' && this.dateForm.value.endDate === '') {
			this.snackBar.open(this.translateService.instant('ENDDATEREQUIRED'), this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
			return;
		}
		if (this.dateForm.value.startDate === '' && this.dateForm.value.endDate === '') {
			this.snackBar.open(this.translateService.instant('STARTANDENDREQUIRED'), this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
			return;
		}
		this.getDriverBookTrips();
	}

	getDriverBookTrips() {
		let date;
		let filteredDates = this.storageService.get('filteredDates');
		if(this.dateForm.value.startDate !== '' && this.dateForm.value.endDate !== '') {
			date = {
				startDate: moment(this.dateForm.value.startDate).format('YYYY.MM.DD'),
				endDate: moment(this.dateForm.value.endDate).format('YYYY.MM.DD')
			}
		} else if (filteredDates && filteredDates.startDate !== '' && filteredDates.endDate !== '') {
			date = {
				startDate: filteredDates.startDate,
				endDate: filteredDates.endDate
			}
		}
		this.getDriverBookInfo(date);
	}

	edit(data) {
		this.openEditVehicleTripComponent(data);
	}

	getVehicleDate(date: string) {
		const dateString = date;
		const dateParts: any = dateString.split('.');
		return this.driverService.getEuropianDateFormat(new Date(+dateParts[2], (dateParts[1] - 1), +dateParts[0])); // moment(date).format('YYYY.MM.DD');
	}

	vehicleChangeEvent(calledFromEventChange) {
		let selectedVehicleMileageInfo = this.storageService.get('selectedVehicleMileageInfo');
		this.selectedVehicle = {
			Vehicle: selectedVehicleMileageInfo && selectedVehicleMileageInfo.PlateNumber? selectedVehicleMileageInfo.PlateNumber: '',
			Routes: []
		};
		let selectedDriver = this.storageService.get('selectedDriver');
		if (selectedDriver) {
			if (this.userInfo.UserRole === AppConstants.userRole.admin) {
				this.selectedVehicle['VehicleId'] = selectedDriver.VehicleId;
			} else {
				this.selectedVehicle['VehicleId'] = this.vehicleForm.controls.vehicle.value;
			}
		}
		if (this.userInfo.UserRole === AppConstants.userRole.admin) {
			let selectedDriver = this.storageService.get('selectedDriver');
			if (selectedDriver) {
				this.selectedVehicleCurrentMileage = selectedDriver.Mileage;
				this.selectedVehicle.CurrentMileage = this.selectedVehicleCurrentMileage;
			}
		}
		this.storageService.set('selectedVehicle', this.selectedVehicle);
		this.isVehcileChanged = true;
		if (calledFromEventChange) {
			this.getDriverBookTrips();
		}
	}

	updateCurrentMileage() {
		this.selectedVehicleCurrentMileage = this.getSelectedVehicleCurrentMileage;
	}

	selectedVehicleOption(value) {
		// console.log('value', value);
		this.vehicleForm.controls.vehcile.setValue(value.name);
	}

	vehicleChange(event) {
		// console.log(event);
		if (this.vehicles && this.vehicles.length > 0) {
			this.vehicles.forEach((data) => {
				if (data.Vehicle === event.value || data.PlateNumber === event.PlateNumber) {
					if (data.RentStartDate) {
						this.vehicleStartTime =  this.driverService.getEuropianDateFormat(data.RentStartDate) // moment(data.RentStartDate).format('DD.MM.YYYY');
					} else {
						this.vehicleStartTime = '';
					}
					if (data.RentEndDate) {
						this.vehicleEndTime = this.driverService.getEuropianDateFormat(data.RentEndDate) // moment(data.RentEndDate).format('DD.MM.YYYY');
					} else {
						this.vehicleEndTime = '';
					}
					this.selectedVehicle = data;
					this.storageService.set('selectedVehicle', this.selectedVehicle);
				}
			});
			if (this.selectedVehicle && this.selectedVehicle.Routes) {
				this.selectedVehicle = this.sortSelectedVehicleRoutes(this.selectedVehicle);
				if (this.selectedVehicle && this.selectedVehicle.Routes && this.selectedVehicle.Routes.length > 0) {
					if (this.vehiclesInfo) {
						this.vehiclesInfo.deselectAll();
					}
					let result = this.selectedVehicle.Routes[0];
					for (let i=0; i < this.selectedVehicle.Routes.length; i++) {
						if (!this.selectedVehicle.Routes[i].IsDeleted) {
							result = this.selectedVehicle.Routes[i];
							break;
						}
					};
					// select by default first selected vehicle route trip
					this.selectedOptionIds = [result.RouteId];
					this.selectedOption = [result];
					this.onSelectionOption(result);
					this.getSelectedTripName(result);
					this.hiddenRightSectionCard = false;
					this.getTripSummaryTotal();
					// localStorage.setItem('selectedVehicle', JSON.stringify(this.selectedVehicle));
					this.storageService.set('selectedVehicle', this.selectedVehicle);
					this.storageService.set('selectedVehicleRoute', result);
				} else {
					if (this.vehiclesInfo) {
						this.vehiclesInfo.deselectAll();
					}
					let result;
					this.vehicles.Routes.forEach(route => {
						if (!route.IsDeleted) {
							result = route;
							return;
						}
					});
					result = result || this.vehicles.Routes[0];
					// select by default first vehicle route trip
					this.selectedOptionIds = [result.RouteId];
					this.selectedOption = [result];
					this.onSelectionOption(result);
					this.getSelectedTripName(result);
					this.storageService.set('selectedVehicleRoute', result);
				}
			}
		} else {
			this.selectedVehicle = event;
			this.storageService.set('selectedVehicle', this.selectedVehicle);
		}
	}

	getTripSummaryTotal() {
		this.totalBusinessTripsDrivenKM = 0;
		this.totalPrivateTripsDrivenKM = 0;
		this.totalHomeTripsDrivenKM = 0;
		this.selectedVehicle.Routes.forEach(route => {
			if (route && !route.IsDeleted) {
				if (route.VehicleRouteTypeId === 1) {
					// calc total KM driven for private Trip
					this.totalPrivateTripsDrivenKM = parseFloat((this.totalPrivateTripsDrivenKM + route.TotalDistance).toFixed(2));
				} else if (route.VehicleRouteTypeId === 2) {
					// calc total KM driven for business Trip
					this.totalBusinessTripsDrivenKM = parseFloat((this.totalBusinessTripsDrivenKM + route.TotalDistance).toFixed(2));
				} else if(route.VehicleRouteTypeId === 3) {
					// calc total KM driven for Home Trip
					this.totalHomeTripsDrivenKM = parseFloat((this.totalHomeTripsDrivenKM + route.TotalDistance).toFixed(2));
				} else if(route.VehicleRouteTypeId === 4) {
					// calc total KM driven for Work Trip
					this.totalWorkTripsDrivenKM = parseFloat((this.totalWorkTripsDrivenKM + route.TotalDistance).toFixed(2));
				}
			}
		});
		// localStorage.setItem('tripsSummary', JSON.stringify({
		// 	businessTrip: this.totalBusinessTripsDrivenKM,
		// 	privateTrip: this.totalPrivateTripsDrivenKM,
		// 	commuteHome: this.totalHomeTripsDrivenKM,
		// 	commuteWork: this.totalWorkTripsDrivenKM
		// }));
		this.storageService.set('tripsSummary', {
			businessTrip: this.totalBusinessTripsDrivenKM,
			privateTrip: this.totalPrivateTripsDrivenKM,
			commuteHome: this.totalHomeTripsDrivenKM,
			commuteWork: this.totalWorkTripsDrivenKM
		});
	}

	sortSelectedVehicleRoutes(selectedVehicle) {
		let result;
		let routes;
		result = selectedVehicle;
		routes = selectedVehicle.Routes;
		const sortedArray: any  = routes.sort((a, b) => new Date(a.Date).valueOf() - new Date(b.Date).valueOf());
		result.routes = sortedArray;
		return result;
	}

	openEditVehicleTripComponent(data) {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.data = data ? data : '';
		dialogConfig.disableClose = true;
		dialogConfig.width = '400px';
		window.scrollTo(0, 0);
		this.dialogEditRef = this.matDialog.open(EditVehicleTripComponent, dialogConfig);
		this.dialogEditRef.afterClosed().subscribe(value => {
			// console.log(`Dialog sent: ${value}`);
			if (this.vehiclesInfo) {
				this.vehiclesInfo.deselectAll();
			}
			if (value && value.isClosedDialog) {
				this.getMileageCheck();
			}
		});
	}

	creatNewRoute() {
		this.openEditVehicleTripComponent(null);
	}

	confirmDeleteRouteDialog(): void {
		window.scroll(0, 0);
		const message = this.translateService.instant('DELETEROUTECONFIRMATIONMESSAGE');
		const dialogData = {
			title: this.translateService.instant('DELETEROUTECONFIRMATION'),
			message: message
		};
		const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
			maxWidth: '130vmin',
			data: dialogData
		});

		dialogRef.afterClosed().subscribe(dialogResult => {
		  	if (dialogResult) {
				// console.log(dialogResult, this.selectedVehicleRouteInfo);
				const options = {
					RouteId: this.selectedVehicleRouteInfo.RouteId,
					data: {
						routeTypeId: this.selectedVehicleRouteInfo.VehicleRouteTypeId || '',
						startAddress: this.selectedVehicleRouteInfo.StartAddress || '',
						endAddress: this.selectedVehicleRouteInfo.EndAddress || '',
						additionalInformation: this.selectedVehicleRouteInfo.AdditionalInformation || '',
						startMileage: this.selectedVehicleRouteInfo.StartMileage || '',
						endMileage: this.selectedVehicleRouteInfo.EndMileage || '',
						startTime: this.selectedVehicleRouteInfo.StartTime || '',
						endTime: this.selectedVehicleRouteInfo.EndTime || '',
						date: this.selectedVehicleRouteInfo.Date || '',
						vehicleId: this.selectedVehicleRouteInfo.VehicleId || '',
					}
				};
				// const selectedVehicle = localStorage.getItem('selectedVehicle');
				const selectedVehicle = this.storageService.get('selectedVehicle');
				if (selectedVehicle) {
					options.data.vehicleId = selectedVehicle.VehicleId;

				}
				this.driverService.deleteVehicleRoute(options).subscribe((response) => {
					// console.log(response);
					if (response.status === 200) {
						this.getMileageCheck();
					}
					this.snackBar.open(response.message, this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
				}, (error) => {
					this.snackBar.open(this.translateService.instant('ERRORMESSAGE') + ' ' + error.message,
					this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
				});
				this.storageService.remove('selectedVehiclePreviousRoute');
				this.storageService.remove('selectedVehicleNextRoute');
		  	}
		});
	}

	removeRoute(selectedVehicleRouteInfo) {
		this.selectedVehicleRouteInfo = selectedVehicleRouteInfo;
		this.confirmDeleteRouteDialog();
	}

	processTripsCombine() {
		let totalDistance = 0;
		let additionalInformation = '';
		let firstTrip;
		let lastTrip;
		//construct the combine trip object with the combine trips list
		if (this.selectedTripsToCombine && this.selectedTripsToCombine.length > 0) {
			// console.log('**********first trip to get the start Address for combine trip', this.selectedTripsToCombine[0]);
			// console.log('**********last trip to get the end Address for combine trip', this.selectedTripsToCombine[this.selectedTripsToCombine.length-1]);
			firstTrip = this.selectedTripsToCombine[0];
			lastTrip = this.selectedTripsToCombine[this.selectedTripsToCombine.length-1];
			this.selectedTripsToCombine.forEach((trip) => {
				totalDistance += trip.TotalDistance;
				additionalInformation += ' ' + trip.AdditionalInformation;
			});
		}
		const combinedTrip = {
			StartAddress: firstTrip.StartAddress ? firstTrip.StartAddress: undefined,
			StartMileage: firstTrip.StartMileage? firstTrip.StartMileage: undefined,
			StartTime: firstTrip.StartTime ? firstTrip.StartTime: undefined,
			StartDate: firstTrip.StartDate ? this.commonService.formatToYYYYMMDD(firstTrip.StartDate): undefined,

			EndAddress: lastTrip.EndAddress ? lastTrip.EndAddress: undefined,
			EndMileage: lastTrip.EndMileage ? lastTrip.EndMileage: undefined,
			EndTime: lastTrip.EndTime ? lastTrip.EndTime: undefined,
			EndDate: lastTrip.EndDate ? this.commonService.formatToYYYYMMDD(lastTrip.EndDate): undefined,

			UpdateExpired: lastTrip.UpdateExpired,
			RouteIds: this.selectedOptionIds ? this.selectedOptionIds: [],
			TotalDistance: totalDistance ? totalDistance: 0,
			AdditionalInformation: additionalInformation,

			VehicleRouteTypeId: lastTrip.VehicleRouteTypeId ? lastTrip.VehicleRouteTypeId: undefined,

			BusinessPartnerName: undefined,
			DriverName: undefined,
			VehicleId: 0,
			isCombinedTrip: true
		};
		this.openEditVehicleTripComponent(combinedTrip)
	}

	tripSeparateEmitter(data) {
		if (data) {
			if (this.selectedVehicleInfo) {
				// selected vehicle route list to separate the trip
				// console.log(this.selectedVehicle.Routes);
				this.openSeparateTrip(this.selectedVehicleInfo);
			}
		}
	}

	openSeparateTrip(data) {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.data = data;
		dialogConfig.disableClose = true;
		window.scrollTo(0, 0);
		let dialogRef = this.matDialog.open(SeparateVehicleRouteComponent, dialogConfig);
		dialogRef.afterClosed().subscribe(value => {
			if (value) {
				this.getMileageCheck();
			}
		});
	}

	openSnackBar(message: string, actions: any) {
		// this.snackBar.open(message, action, {});
		const data  = {
			actions: actions,
			message: message,
			preClose: () => {
				// console.log('pre close of snackbar');
				this.getMileageCheck();
			}
		}
		this.snackBar.openFromComponent(MileageSnackbarComponent, {
			data: data
		});
	}

	get isNotMobileView() {
		return window.innerWidth > 600;
	}

	editTrip(e, route) {
		e.preventDefault();
		e.stopPropagation();
		// console.log('****** route', route);
		this.openEditVehicleTripComponent(route);
	}

	openMileageDialog(data) {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.data = data;
		dialogConfig.disableClose = true;
		this.dialogRef = this.matDialog.open(MileageComponent, dialogConfig);
		this.dialogRef.afterClosed().subscribe(value => {
			if (value) {
				this.saveCurrentMileage(value);
			} else {
				this.isRemindMeClicked = true;
			}
			this.isMileageOpened = false;
		});
	}

	saveCurrentMileage(requestData) {
		let requestBody: PostMileageRequest = {
		  CurrentMileage: null,
		  VehicleId: null,
		  CurrentMileageIsCorrect: null,
		  PreviousMileage: null
		};
		if (requestData) {
		  requestBody.CurrentMileage = requestData.CurrentMileage;
		  requestBody.VehicleId = requestData.VehicleId;
		  requestBody.CurrentMileageIsCorrect = requestData.isCorrectCurrentMileage;
		  requestBody.PreviousMileage = requestData.PreviousMileage.CurrentMileage;
		}
		// console.log('mileage request body', requestBody);
		this.driverService.saveMileage(requestBody).subscribe((response: any) => {
		  // console.log('updated mileage response', response);
		  if(response && response.status === 200) {
			let vehicleMileage = localStorage.getItem('vehicleMileage');
			let parsedVehicleMileage = JSON.parse(vehicleMileage);
			parsedVehicleMileage.CurrentMileage = requestBody.CurrentMileage;
			localStorage.setItem('vehicleMileage', JSON.stringify(parsedVehicleMileage));
		  }
		  this.snackBar.open(response.message, this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
		}, (error: any) => {
		  // this.snackBar.open(error, this.translateService.instant('CANCEL'), AppConstants.SNACK_BAR_DELAY);
		  // this.mileageSnackBarRef.dismiss();
		  this.snackBar.open(this.translateService.instant('ERRORMESSAGE') + ' ' + error.message,
		  this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
		}, () => {
			this.getMileageCheck();
		});
	}

	updateCurrentMileageInfo() {
		let selectedVehicleMileageInfo = this.storageService.get('selectedVehicleMileageInfo');
		this.openMileageDialog(selectedVehicleMileageInfo);
	}

	contactus() {
		const data = {
			title: this.translateService.instant('CONTACTUS'),
			selectedVehicleInfo: this.storageService.get('selectedVehicle'),
			selectedRouteInfo: this.selectedVehicleInfo,
			width: '1000px'
		};
		// console.log(data);
		let dialogRef = this.matDialog.open(ContactUsSendEmailDialogComponent, {
			data: data
		});
		dialogRef.afterClosed().subscribe(value => {
			if (value && value.isDialogClose) {
				let body = '<h3><b>'+ this.translateService.instant('DRIVERINFORMATION') + '</b>:</h3>' + '\n' +
				'\n' +
				'<b>'+ this.translateService.instant('EMAIL') + '</b>: ' + '\n' + '<p>'+ value.data.Email + '</p>' +'\n' +
				'\n' +
				'<b>'+ this.translateService.instant('PHONENUMBER') + '</b>: ' + '\n' + '<p>'+ value.data.TelephoneNumber + '</p>' +'\n' +
				'\n' +
				'<b>'+ this.translateService.instant('MOBILENUMBER') + '</b>: ' + '\n' + '<p>'+ value.data.MobileNumber + '</p>' +'\n' +
				'\n' +
				'<h3><b>'+ this.translateService.instant('CARINFORMATION') + '</b>:</h3>' + '\n' +
				'\n' +
				'<b>'+ this.translateService.instant('VEHICLEID') + '</b>: ' + '\n' + '<p>'+ value.data.SelectedVehicleInformation.VehicleId + '</p>' +'\n' +
				'<b>'+ this.translateService.instant('VEHICLE') + '</b>: ' + '\n' + '<p>'+ value.data.SelectedVehicleInformation.Vehicle + '</p>' +'\n' +
				'\n' +
				'<h3><b>'+ this.translateService.instant('TRIPINFORMATION') + '</b>:</h3>' + '\n' +
				'\n' +
					'<b>'+ this.translateService.instant('ROUTEID') + '</b>: ' + '\n' + ' <p>'+ value.data.SelectedRouteInformation.RouteId + '</p>' +'\n' +
					'<b>'+ this.translateService.instant('STARTADDRESS') + '</b>: ' + '\n' + ' <p>'+ value.data.SelectedRouteInformation.StartAddress + '</p>' +'\n' +
					'<b>'+ this.translateService.instant('ENDADDRESS') + '</b>: ' + '\n' + ' <p>'+ value.data.SelectedRouteInformation.EndAddress + '</p>' +'\n' +
					'<b>'+ this.translateService.instant('STARTDATE') + '</b>: ' + '\n' + ' <p>'+ value.data.SelectedRouteInformation.StartDate + '</p>' +'\n' +
					'<b>'+ this.translateService.instant('ENDDATE') + '</b>: ' + '\n' + ' <p>'+ value.data.SelectedRouteInformation.EndDate + '</p>' +'\n' +
					'<b>'+ this.translateService.instant('STARTTIME') + '</b>: ' + '\n' + ' <p>'+ value.data.SelectedRouteInformation.StartTime + '</p>' +'\n' +
					'<b>'+ this.translateService.instant('ENDTIME') + '</b>: ' + '\n' + ' <p>'+ value.data.SelectedRouteInformation.EndTime + '</p>' +'\n' +
					'<b>'+ this.translateService.instant('STARTMILEAGE') + '</b>: ' + '\n' + ' <p>'+ value.data.SelectedRouteInformation.StartMileage + '</p>' +'\n' +
					'<b>'+ this.translateService.instant('ENDMILEAGE') + '</b>: ' + '\n' + ' <p>'+ value.data.SelectedRouteInformation.EndMileage + '</p>' +'\n' +
					'<b>'+ this.translateService.instant('DRIVENDISTANCE') + '</b>: ' + '\n' + ' <p>'+ value.data.SelectedRouteInformation.TotalDistance + ' km </p>' +'\n' +
					'<b>'+ this.translateService.instant('ADDITIONALINFORMATION') + '</b>: ' + '\n' + ' <p>'+ value.data.SelectedRouteInformation.AdditionalInformation + '</p>' +'\n' +
				'\n' +
				'<b>'+ this.translateService.instant('INFORMATION') + '</b>: ' + '\n' + ' <p>'+ value.data.Description + '</p>';
				// console.log(value);
				let options = {
					Email: value.data.Email,
					Subject: value.data.Subject,
					Body: body
				}
				this.commonService.sendEmail(options);
			}
		});
	}

	enableCombineBtnClick() {
		if (this.vehiclesInfo) {
			this.vehiclesInfo.deselectAll();
		}
		this.combine = !this.combine;
		this.isCombineTrips = this.combine;
	}

	tripsCombine(data) {
		this.combine = false;
		this.isCombineTrips = this.combine;
		if(data) {
			if (this.selectedVehicle.Routes && this.selectedVehicle.Routes.length > 0) {
				// in a selected vehicle routes list filtering the selected trips to combine
				this.selectedTripsToCombine = this.selectedVehicle.Routes.filter((route) => {
					return this.selectedOptionIds.indexOf(route.RouteId) > -1;
				});
				// console.log('***** last object from trip combine:', this.selectedTripsToCombine[this.selectedTripsToCombine.length-1]);
			}
		}
		this.processTripsCombine();
	}

	removRoute(selectedRouteInfo) {
		this.removeRoute(selectedRouteInfo);
	}

	separateTripBtnClick() {
		if (this.selectedVehicleInfo) {
			this.openSeparateTrip(this.selectedVehicleInfo);
		}
	}

	getToolTipInfo(value) {
		if (!this.isNotMobileView) {
			let dialogData = {
				title: this.translateService.instant('INFO'),
				description: this.translateService.instant(value)
			};
			this.matDialog.open(SharedDialogComponent, {
				maxWidth: '280px',
				data: dialogData
			});
			return;
		}
		return this.translateService.instant(value);
	}

	get isEmptyRoute() {
		return this.selectedVehicle && this.selectedVehicle.Routes && this.selectedVehicle.Routes.length === 0? true: false;
	}

	get isDisableButtonField() {
		let result = true;
		if (this.userInfo.UserRole && this.userInfo.UserRole === 'Admin') {
			result = false;
		} else if (!this.selectedVehicleInfo?.UpdateExpired) {
			result = false;
		}
		return result;
	}
}
