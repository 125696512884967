import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateLanguageModule } from './translate-language/translate-language.module';
import { MaterialModule } from '../material/material.module';
import { NgxMatDatetimePickerModule } from '@angular-material-components/datetime-picker';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClientModule } from '@angular/common/http';
import { SelectSearchModule } from './modules/selectsearch/select-search.module';
import { AgmCoreModule } from '@agm/core';
import { AgmDrawingModule } from '@agm/drawing';
import { AgmDirectionModule } from 'agm-direction';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MomentDateModule } from '@angular/material-moment-adapter';
import { AddBusinessPartnerComponent } from './components/add-business-partner/add-business-partner.component';
import { BusinessPartnerAddressesComponent } from './components/business-partner-addresses/business-partner-addresses.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { CommonBaseComponent } from './components/common-base/common-base.component';
import { DocviewerComponent } from './components/docviewer/docviewer.component';
import { MileageComponent } from './components/mileage/mileage.component';
import { MileageSnackbarComponent } from './components/mileage-snackbar/mileage-snackbar.component';
import { PrefferedCountryComponent } from './components/preffered-country/preffered-country.component';
import { ReportConfirmationComponent } from './components/report-confirmation/report-confirmation.component';
import { ResponsiveStepperComponent } from './components/responsive-stepper/responsive-stepper.component';
import { SeparateVehicleRouteComponent } from './components/separate-vehicle-route/separate-vehicle-route.component';
import { SharedDialogComponent } from './components/shared-dialog/shared-dialog.component';
import { TermsConditionsComponent } from './components/terms-conditions/terms-conditions.component';
import { TermsConditionsBottomSheetComponent } from './components/terms-conditions-bottom-sheet/terms-conditions-bottom-sheet.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { NgArrayPipesModule } from 'ngx-pipes';
import { VehicleRouteDetailComponent } from './components/vehicle-route-detail/vehicle-route-detail.component';
import { CommonService } from './services/common/common.service';
import { MatSelectInfiniteScrollModule } from 'ng-mat-select-infinite-scroll';

const declarations = [
  AddBusinessPartnerComponent,
  BusinessPartnerAddressesComponent,
  ChangePasswordComponent,
  CommonBaseComponent,
  ConfirmDialogComponent,
  DocviewerComponent,
  MileageComponent,
  MileageSnackbarComponent,
  PrefferedCountryComponent,
  ReportConfirmationComponent,
  ResponsiveStepperComponent,
  SeparateVehicleRouteComponent,
  SharedDialogComponent,
  TermsConditionsComponent,
  TermsConditionsBottomSheetComponent,
  VehicleRouteDetailComponent
];
const modules = [
  CommonModule,
  NgxMatDatetimePickerModule,
  NgxMaterialTimepickerModule,
  NgxMatMomentModule,
  FormsModule,
  ReactiveFormsModule,
  FlexLayoutModule,
  HttpClientModule,
  MaterialModule,
  SelectSearchModule,
  AgmCoreModule.forRoot({ // @agm/core
    // change this key with prod console.google account api key
    apiKey: 'AIzaSyBJ6ogO8d94dF7aM9gucuvbXEMtONWfdtA',
    // 'AIzaSyBfCpR-cIflU1rkWrzKXAMr-JeIIZwBcYQ',
    // apiKey: 'AIzaSyBqOaxIFLg4Lf0SJCLjOiaPCSWPSYkoN6s',
    // apiKey: environment.maps_api_key,
    libraries: ['places', 'drawing', 'geometry'],
    language: 'en'
  }),
  AgmDrawingModule,
  AgmDirectionModule,
  MatGoogleMapsAutocompleteModule,
  TranslateLanguageModule,
  TranslateModule,
  NgxDocViewerModule,

  MatDatepickerModule,
  MatNativeDateModule,
  MatFormFieldModule,
  MomentDateModule,
  SelectSearchModule,
  NgArrayPipesModule,
  MatSelectInfiniteScrollModule
];
@NgModule({
  declarations: [declarations],
  imports: [modules],
  exports: [modules, declarations],
  providers: [],
  bootstrap: []
})
export class SharedModule { }
