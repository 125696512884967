<div *ngIf="jsonData" class="document-viewer">
    <mat-card>
        <div class="row">    
            <h3>
                {{jsonData.title}}:
            </h3>   
        </div>
        <div class="row" *ngFor="let value of jsonData.values;let i = index">
            <h3>{{value.title}}:</h3>
            <mat-accordion>
                <mat-expansion-panel *ngFor="let questionableValue of value.values;let j = index;" (opened)="setStep(j)" hideToggle>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                           {{questionableValue.question}}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p>
                        {{questionableValue.answer}}
                    </p>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </mat-card>
</div>
<!-- <ngx-doc-viewer [url]="DemoDoc" [viewer]="viewer" style="width:100%;height:100vh;"></ngx-doc-viewer>   -->