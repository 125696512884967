import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppConstants } from 'src/app/app.constants';

@Component({
  selector: 'app-business-summary',
  templateUrl: './business-summary.component.html',
  styleUrls: ['./business-summary.component.scss']
})
export class BusinessSummaryComponent implements OnInit {
  title: string;
  expiryTripsInformation;
  userInfo;
  appConstants;
  constructor(public dialogRef: MatDialogRef<BusinessSummaryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,) {
      this.appConstants = AppConstants;
    }

  ngOnInit(): void {
    this.title = this.data.title;
    this.expiryTripsInformation =  this.data.expiryTripsInformation;
    this.userInfo = this.data.userInfo;
  }

  onDismiss() {
    this.dialogRef.close(false);
  }

}
