import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { TranslateConfigService } from '../../../shared/services/translate/translate-config.service';
import { CommonBaseComponent } from 'src/app/shared/components/common-base/common-base.component';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { StorageService } from 'src/app/shared/services/storage/storage.service';
import { Building } from '../../models/building.model';
import { BuildingsService } from '../../services/buildings.service';
import { CreateBuildingComponent } from '../create-building/create-building.component';
import { AppConstants } from 'src/app/app.constants';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-buildings-list',
  templateUrl: './buildings-list.component.html',
  styleUrls: ['./buildings-list.component.scss']
})
export class BuildingsListComponent extends CommonBaseComponent implements OnInit {
  selection = new SelectionModel<any>(true, []);
  buildingsDataSource;
  columnsToDisplay = ['ID', 'Address', 'NumberOfObjectUnits', 'BuiltOn', 'Operations'];
  expandedElement;

	buildingsList: any[] = [];
  pageSizeOptions: number[] = [5, 10, 25, 50, 100];
	totalRecords = 0;
	pageIndex = 0;
	pageSize = 10;
	pageEvent: PageEvent;
	pageOptions;
	filterValue;

  firstTableSort: MatSort;
  @ViewChild(MatSort) set matSort1(sort: MatSort) {
    this.firstTableSort = sort? sort : this.firstTableSort;
    this.loadTableData();
  }

  paginator1: MatPaginator;
  @ViewChild(MatPaginator) set matPaginator1(mp: MatPaginator) {
    this.paginator1 = mp? mp: this.paginator1;
    this.loadTableData();
  }
  constructor(private buildingsService: BuildingsService,
    private dialog: MatDialog,
    protected storageService: StorageService,
    protected translateService: TranslateService,
    protected translateConfigService: TranslateConfigService,
    private snackBar: MatSnackBar) {
      super(translateConfigService, translateService, storageService);
      super.ngOnInit();
    }

  ngOnInit(): void {
    this.getBuildings(this.pageEvent);
  }

  getBuildings(pageEvent) {
    let options = {
      PageNumber: pageEvent && pageEvent.pageIndex? pageEvent.pageIndex: this.pageIndex,
      PageSize: pageEvent && pageEvent.pageSize? pageEvent.pageSize: this.pageSize,
      SearchString: ''
    };
    this.pageOptions = options;
    if (this.filterValue && this.filterValue !== '') {
      options['SearchString'] = this.filterValue;
    }
    this.buildingsService.getBuildings(options).subscribe((response: Building[]) => {
      this.buildingsList = response;
      this.loadTableData();
    }, (error) => {
      console.log(error);
    });
  }

  loadTableData() {
    if (this.buildingsList && this.buildingsList.length > 0) {
      this.buildingsDataSource = new MatTableDataSource(this.buildingsList);
      this.buildingsDataSource.sort = this.firstTableSort;
      this.buildingsDataSource.paginator = this.paginator1;
    }
  }

  editBuildingClick(element) {
    const title = this.translateService.instant('CREATENEWBUILDING');
    const dialogData = {
      title: title,
      data: element
    };
    const dialogRef = this.dialog.open(CreateBuildingComponent, {
     width: '70vmin',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.getBuildings(this.pageEvent);
      }
    });
  }

  deleteBuildingClick(element) {
    this.confirmDialog(element);
  }

  sortData(event) {
  }

  addNewBuilding() {
    const title = this.translateService.instant('CREATENEWBUILDING');
    const dialogData = {
      title: title
    };
    const dialogRef = this.dialog.open(CreateBuildingComponent, {
     width: '70vmin',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.getBuildings(this.pageEvent);
      }
    });
  }

  confirmDialog(data): void {
    const title = this.translateService.instant('DELETEBUILDINGCONFIRMATION');
    const message = this.translateService.instant('DELETEBUILDINGMESSAGE', { value: data.Address});
    const dialogData = {
      title: title,
      message: message
    };
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
     width: '70vmin',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.deleteBuilding(data);
      }
    });
  }

  deleteBuilding(data) {
    if (data) {
      this.buildingsService.deleteBuilding({ BuildingId: data.BuildingId}).subscribe((data: any) => {
        if (data && data.message) {
          this.snackBar.open(data.message, this.translateService.instant('CANCEL'), AppConstants.DELAYTOOLTIP);
        }
        this.getBuildings(this.pageEvent);
      }, (error) => {
        console.log(error);
      });
    }
  }

  applyFilter(value) {
    this.paginator1.pageIndex=0;
    this.filterValue = value.trim().toLowerCase();
    this.getBuildings(this.pageEvent);
  }

}
