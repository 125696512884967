<mat-card class="example-card">
    <mat-card-title>
        {{ title }}
    </mat-card-title>
    <mat-card-content *ngIf="contents$">
        <div *ngFor="let content of contents$ | async ; index as i">
            <h3 *ngIf="content.h3">{{content.h3}}</h3>
            <p *ngIf="content.p">{{content.p}}</p>
        </div>
    </mat-card-content>
    <mat-card-actions align="end">
        <button mat-button (click)="return()">{{ 'BACK' | translate}}</button>
    </mat-card-actions>
</mat-card>