<section>
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <p>{{ 'ADDRESSES'| translate}}:</p>
        <button mat-icon-button (click)="addNewAddress()" *ngIf="AddAddress && AddAddress.length === 0">
            <mat-icon>add</mat-icon>
        </button>
    </div>
    <form [formGroup]="addressForm" *ngIf="((Addresses && Addresses.length > 0) || (AddAddress && AddAddress.length > 0))">
        <div *ngIf="isAddNewAddressClicked">
            <div formArrayName="AddBuinessPartnerAddress" *ngFor="let address of AddAddress; let i = index">
                <div class="address-form-section" [formGroupName]="i">
                    <div fxLayout="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="space-between center">
                        <mat-form-field class="example-full-width" appearance="outline" fxFlex="45" fxFlex.sm="45" fxFlex.xs="100">
                            <mat-label>{{'STREET' | translate}}</mat-label>
                            <input matInput placeholder="{{ 'STREETPLACEHOLDER' | translate }}" formControlName="Street" required>
                        </mat-form-field>
                        <mat-form-field class="example-full-width" appearance="outline" fxFlex="45" fxFlex.sm="45" fxFlex.xs="100">
                            <mat-label>{{'HOUSENUMBER' | translate}}</mat-label>
                            <input matInput placeholder="{{ 'HOUSENUMBERPLACEHOLDER' | translate }}" formControlName="HouseNumber" required>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayout.sm="row" fxLayout.xs="column">
                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
                            <mat-label>{{'CHOOSECOUNTRY' | translate}}</mat-label>
                            <mat-select (selectionChange)="selectedAddCountry($event.value, i, false)" formControlName="CountryId">
                                <mat-option *ngFor="let country of countries" [value]="country.ID">
                                    {{country.Name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayout.sm="row" fxLayout.xs="column">
                        <mat-form-field appearance="outline" *ngIf="states && states.length > 0" fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
                            <mat-label>{{'CHOOSESTATE' | translate}}</mat-label>
                            <mat-select (selectionChange)="selectedAddState($event.value, i, false)" formControlName="FederalStateId">
                                <mat-option *ngFor="let state of states" [value]="state.ID">
                                    {{state.Name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayout.sm="row" fxLayout.xs="column">
                        <mat-form-field appearance="outline" *ngIf="cities && cities.length > 0" fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
                            <mat-label>{{'CHOOSECITY' | translate}}</mat-label>
                            <mat-select (selectionChange)="selectedAddCity($event.value, i)" formControlName="CityId">
                                <mat-option *ngFor="let city of cities" [value]="city.ID">
                                    {{city.Name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row" fxLayout.xs="column">
                    <div fxFlex="100" fxFlex.xs="100" fxLayout="row" fxLayoutAlign="space-between center">
                        <button mat-raised-button (click)="onDeleteAddress(i)">{{'DELETE' | translate}}</button>
                        <button mat-raised-button color="primary" (click)="onSaveAddress(i)">{{'SAVE' | translate}}</button>
                    </div>
                </div>
            </div>
        </div>
        <br/>
        <mat-accordion class="example-headers-align" multi>
            <div formArrayName="BuinessPartnerAddress" *ngFor="let address of Addresses;let i = index;">
                <div [formGroupName]="i">
                    <mat-expansion-panel (opened)="openExpansionPanelGroup(address, i)">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <div fxLayout="row" fxLayoutAlign="space-between center">
                                    <p>{{ 'ADDRESS' | translate}} {{i+1}}</p>
                                </div>
                            </mat-panel-title>
                            <mat-panel-description>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <!-- <div fxLayout="column" fxLayoutAlign="start start">
                            <mat-form-field appearance="outline">
                                <mat-label>{{'STREET' | translate}}</mat-label>
                                <input matInput (change)="onStreetChange($event.value, i)" formControlName="Street" placeholder="{{ 'STREETPLACEHOLDER' | translate }}" value=""/>
                            </mat-form-field>

                            <mat-form-field appearance="outline">
                                <mat-label>{{'HOUSENUMBER' | translate}}</mat-label>
                                <input matInput (change)="onHouseNumberChange($event.value, i)" formControlName="HouseNumber" placeholder="{{ 'HOUSENUMBERPLACEHOLDER' | translate }}" value=""/>
                            </mat-form-field>

                            <mat-form-field appearance="outline">
                                <mat-label>{{'CHOOSECOUNTRY' | translate}}</mat-label>
                                <mat-select (change)="selectedCountry($event.value, i, false)" (selectionChange)="selectedCountry($event.value, i, false)" 
                                    formControlName="CountryId">
                                    <mat-option *ngFor="let country of countries" [value]="country.ID">{{country.Name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="outline" *ngIf="states && states.length > 0">
                                <mat-label>{{'CHOOSESTATE' | translate}}</mat-label>
                                <mat-select (selectionChange)="selectedState($event.value, i, false)" formControlName="FederalStateId" placeholder="Search City">
                                    <mat-option *ngFor="let state of states" [value]="state.ID">
                                        {{state.Name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="outline" *ngIf="cities && cities.length > 0">
                                <mat-label>{{'CHOOSECITY' | translate}}</mat-label>
                                <mat-select (selectionChange)="selectedCity($event.value, i)" formControlName="CityId" placeholder="Search City">
                                    <mat-option *ngFor="let city of cities" [value]="city.ID">
                                        {{city.Name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div> -->
                        <div fxlayout="column" fxLayoutAlign="start start">
                            <mat-form-field class="example-full-width full-width" appearance="outline">
                                <input matInput required
                                    matGoogleMapsAutocomplete
                                    [type]="'address'"
                                    [country]="language"
                                    (onAutocompleteSelected)="onBusinessPartnerAddressAutocompleteSelected($event, i)"
                                    (onLocationSelected)="onBusinessPartnerAddressLocationSelected($event, i)"
                                    (onGermanAddressMapped)="onGermanBusinessPartnerAddressMapped($event, i)"
                                    formControlName="BusinessPartnerAddress">
                            </mat-form-field>
                        </div>
                        <div fxLayout="row">
                            <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100" fxLayoutAlign="space-between center">
                                <button (click)="updateBusinessPartnerAddress(address, i)"
                                    mat-raised-button color="primary" aria-label="Update Address">
                                    <mat-icon>edit</mat-icon>
                                    {{'UPDATE' | translate}}
                                </button>
                                <button (click)="deleteBusinessPartnerAddress(address, i)"
                                    mat-raised-button color="primary" aria-label="Delete Address">
                                    <mat-icon>delete</mat-icon>
                                    {{'DELETE' | translate}}
                                </button>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </div>
            </div>
        </mat-accordion>
    </form>
    <div  *ngIf="Addresses && Addresses.length === 0">
        {{'NOADDRESSAVAIALABLE' | translate}}
    </div>
</section>
