<mat-sidenav-container id="all-wrap" class="all-wrap" [class.is-mobile]="mobileQuery.matches" fullscreen (backdropClick)="drawerBackdropClicked()">
  <mat-sidenav #sidenav mode="push" class="left-side-panel" (close)="onClose()" (closedStart)="closedStart()">
    <mat-nav-list>
      <a *ngFor="let route of routes" mat-list-item (click)="navigateToggle(route)">
        {{route?.label | translate}}
      </a>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav #rightSideNav mode="over" class="rigth-side-panel" position="end" (close)="onClose()" (closedStart)="closedStart()">
    <button mat-icon-button class="side-nav-menu-icon" aria-label="Menu" (click)="rightSideNavCloseClicked();" *ngIf="!rightSideNaveMenu && isCrApiTokenAvailable">
      <mat-icon>close</mat-icon>
    </button>
    <div *ngIf="vehicleData">
      <app-vehicle-route-detail
        [selectedVehicleInfo]="vehicleData.selectedVehicleInfo"
        [selectedRightSectionOption]="vehicleData.selectedRightSectionOption"
        [hiddenRightSectionCard]="vehicleData.hiddenRightSectionCard"
        (rightSideNavCloseClickedEmitter)="rightSideNavCloseClicked()"
        [mapDetail]="vehicleData.mapDetail">
      </app-vehicle-route-detail>
    </div>
  </mat-sidenav>

  <mat-sidenav-content>
    <header class="header">
      <mat-toolbar color="primary">
        <div class="header-flex-container">
          <div>
            <button *ngIf="userInfo" class="side-nav-menu-open-button" mat-icon-button aria-label="Menu" (click)="menuClicked();">
              <mat-icon>menu</mat-icon>
            </button>

            <span>
              Driver Log Book
            </span>
          </div>
          <div>
            <ul *ngIf="userInfo" class="header-main-menu">
              <li *ngFor="let route of routes">
                <a mat-button (click)="navigate(route)">
                  {{route?.label | translate}}
                </a>
              </li>
            </ul>
          </div>
          <div>
            <ul>
              <li *ngFor="let language of languages">
                <button [class.active]="selectedLanguage === language" mat-icon-button color="primary" (click)="languageChange(language)">
                  <img src="./assets/{{language}}.png"/>
                </button>
              </li>
            </ul>

            <div *ngIf="userInfo">
              <button mat-icon-button [matMenuTriggerFor]="toolbarUserMenu">
                <mat-icon>more_vert</mat-icon>
              </button>

              <mat-menu #toolbarUserMenu="matMenu">
                <button mat-menu-item (click)="goInformationPage()">
                  <mat-icon>account_circle</mat-icon>
                  <span>{{userInfo?.name}}</span>
                </button>

                <button *ngFor="let route of userMenuItems" mat-menu-item (click)="navigate(route)">
                  <mat-icon>{{ route?.icon }}</mat-icon>
                  <span>{{route?.label | translate}}</span>
                </button>
              </mat-menu>
            </div>
          </div>
        </div>
      </mat-toolbar>
    </header>
    <main class="content">
      <!-- <div (click)="drawerBackdropClicked()" class="mat-drawer-backdrop drawer-backdrop" [ngClass]="{'enabled': drawerBackdropEnable}"></div> -->
      <router-outlet></router-outlet>
    </main>
    <footer *ngIf="!(isLoggedIn | async)">
      <mat-toolbar color="primary">
        <span class="fill-remaining-space">{{'FOOTERTEXT' | translate}}
        </span>
      </mat-toolbar>
    </footer>
  </mat-sidenav-content>
</mat-sidenav-container>
