import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonBaseComponent } from '../../../shared/components/common-base/common-base.component';
import { TranslateConfigService } from '../../../shared/services/translate/translate-config.service';
import { AppConstants } from '../../../app.constants';
import { StorageService } from '../../../shared/services/storage/storage.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent extends CommonBaseComponent implements OnInit {
  showSpinner: boolean = false;
  appConstants;

  constructor(protected storageService: StorageService,
    private router: Router,
		private route: ActivatedRoute,
    protected translateService: TranslateService,
    protected translateConfigService: TranslateConfigService) {
      super(translateConfigService, translateService, storageService);
      super.ngOnInit();
      this.appConstants = AppConstants;
  }

	@HostListener('window:scroll', ['$event'])
	onWindowScroll(e) {
		let element = document.getElementById('left-box-bottom-section');
		if (element) {
			if (this.isNotMobileView) {
				if (window.pageYOffset > 450) {
					element.classList.add('sticky');
				} else {
					element.classList.remove('sticky');
				}
			} else {
				element.classList.remove('sticky');
			}
		}
	}

  get isNotMobileView() {
		return window.innerWidth > 600;
	}
}
