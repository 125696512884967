<mat-dialog-content>
    <h2 mat-dialog-title>{{addressText}} {{resultTitle}}</h2>
    <form class="address-form-section" [formGroup]="addressForm">
        <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>{{'STREET' | translate}}</mat-label>
            <input matInput placeholder="{{ 'STREETPLACEHOLDER' | translate}}" formControlName="street" required>
            <mat-error *ngIf="addressForm.get('street').hasError('required')">
                {{'FIELDREQUIRED' | translate}}
            </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>{{'HOUSENUMBER' | translate}}</mat-label>
            <input matInput placeholder="{{ 'HOUSENUMBERPLACEHOLDER' | translate}}" formControlName="houseNo" required>
            <mat-error *ngIf="addressForm.get('houseNo').hasError('required')">
                {{'FIELDREQUIRED' | translate}}
            </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>{{'FEDERALSTATE' | translate}}</mat-label>
          <input matInput placeholder="{{ 'FEDERALSTATEPLACEHOLDER' | translate}}" formControlName="federalState">
        </mat-form-field>

        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>{{'CITY' | translate}}</mat-label>
          <input matInput placeholder="{{ 'CITY' | translate}}" formControlName="city" required>
          <mat-error *ngIf="addressForm.get('city').hasError('required')">
              {{'FIELDREQUIRED' | translate}}
          </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>{{'COUNTRY' | translate}}</mat-label>
          <input matInput placeholder="{{ 'COUNTRY' | translate}}" formControlName="country" required>
          <mat-error *ngIf="addressForm.get('country').hasError('required')">
              {{'FIELDREQUIRED' | translate}}
          </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>{{'POSTALCODE' | translate}}</mat-label>
          <input matInput placeholder="{{ 'POSTALCODE' | translate}}" formControlName="postalCode" required>
          <mat-error *ngIf="addressForm.get('postalCode').hasError('required')">
              {{'FIELDREQUIRED' | translate}}
          </mat-error>
        </mat-form-field>

        <div fxLayout="row" fxLayout.sm="row" fxLayout.xs="column" *ngIf="addressTypes && addressTypes.length > 0">
            <mat-label class="address-type">{{'ADDRESSTYPE' | translate}}:</mat-label>
            {{getAddressType()}}
        </div>
    </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-raised-button class="close" (click)="close()">{{'CLOSE' | translate}}</button>
    <button mat-raised-button color="primary" [disabled]="addressForm.invalid" (click)="save()">{{'SAVE' | translate}}</button>
</mat-dialog-actions>
