import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ForwardMessage } from '../../models/forward-message/forward-message.model';

@Component({
  selector: 'app-forward-messages',
  templateUrl: './forward-messages.component.html',
  styleUrls: ['./forward-messages.component.scss']
})
export class ForwardMessagesComponent implements OnInit {

  forwardMessages: ForwardMessage;
  ELEMENT_DATA: any[];
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(private dialogRef: MatDialogRef<ForwardMessagesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    this.ELEMENT_DATA = this.data;
  }

  close() {
    this.dialogRef.close('closed');
  }

  getMessagesData (messages, type) {
    let result: any = [];
    if (type === 'inspectionWarning') {
      result = new MatTableDataSource(messages.filter((message) => message.Inspection));
    } else if (type === 'otherMessages') {
      result = new MatTableDataSource(messages.filter((message) => !message.Inspection));
    }
    return result;
  }

}
