import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { DriverService } from '../../../shared/services/driver/driver.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogConfig, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ForwardMessagesComponent } from '../forward-messages/forward-messages.component';
import { ForwardMessage } from '../../models/forward-message/forward-message.model';
import { VehicleInformation } from '../../models/vehicle-information/vehicle-information.model';
import { AddAddressComponent } from '../add-address/add-address.component';
import { MileageComponent } from '../../../shared/components/mileage/mileage.component';
import { AddAdditionalDriverComponent } from '../add-additional-driver/add-additional-driver.component';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { EditContactBusinessPartnerDialogComponent } from '../../components/edit-contact-business-partner-dialog/edit-contact-business-partner-dialog.component';
import { EditBusinessPartnerDialogComponent } from '../../components/edit-business-partner-dialog/edit-business-partner-dialog.component';
import { UpsertBusinessPartnerRequest } from '../../models/vehicle-information/upsert-business-partner-request.model';
import { SelectionModel } from '@angular/cdk/collections';
import { AppConstants } from '../../../app.constants';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '../../../shared/services/storage/storage.service';
import { QueryList } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { UpdateStandardDriverModalComponent } from '../update-standard-driver-modal/update-standard-driver-modal.component';
import { StandardDriverRequest } from '../../models/vehicle-information/standard-driver.model';
import { BusinessPartnerDialogComponent } from '../business-partner-dialog/business-partner-dialog.component';
import { TranslateConfigService } from '../../../shared/services/translate/translate-config.service';
import { PostMileageRequest } from '../../../shared/models/mileage/post-mileage-request.model';
import { CommonService } from '../../../shared/services/common/common.service';
import { CommonBaseComponent } from '../../../shared/components/common-base/common-base.component';
import { BusinessPartner } from 'src/app/dashboard/models/route/route.model';

@Component({
  selector: 'app-vehicle-information',
  templateUrl: './vehicle-information.component.html',
  styleUrls: ['./vehicle-information.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class VehicleInformationComponent extends CommonBaseComponent implements OnInit, AfterViewInit {

  Addresses;
  HomeAddresses;
  OfficeAddresses;
  vehicleInformation;
  vehicleCallCounter = 0;
  subjectsList = [];
  remainingTime = [];
  technicalCarInspectionForm: FormGroup;
  technicalCarOtherForm: FormGroup;
  businessPartnerAddressList = [];
  isSubscribed: boolean = false;
  toggleDuplicateData= false;
  displayedBusinessPartnerDataColumns: string[] = ['businessPartnerSelect', 'ID', 'Firstname', 'Surname', 'Company', 'CityName', 'CountryName', 'FederalStateName', 
  'Street', 'PostalCode', 'HouseNumber', 'Operations'];
  // innerDisplayedBusinessPartnerAddressColumns = ['Street', 'HouseNumber', 'CityName', 'FederalStateName', 'PostalCode', 'CountryName'];
  @ViewChildren('innerSort') innerSort: QueryList<MatSort>;
  @ViewChildren('innerTables') innerTables: QueryList<MatTable<any>>;
  expandedElement: null;

  BusinessPartnerData: any[];
  BusinessPartnerDataSource = new MatTableDataSource<any>();
  pageSizeOptions = AppConstants.pageSizeOptions;
  pageSize = 10;

  displayedColumns: string[] = ['select', 'Firstname', 'Surname', 'Company', 'Street', 'HouseNumber', 'City', 'Zipcode', 'Country', 'Operations'];
  ELEMENT_DATA: any[];
  dataSource = new MatTableDataSource<any>();
  userInfo;
  appConstants;
  vehiclesSelectionList = [];

  paginator1: MatPaginator;
  @ViewChild(MatPaginator) set matPaginator1(mp: MatPaginator) {
    this.paginator1 = mp? mp: this.paginator1;
    this.loadTableData();
  }

  paginator2: MatPaginator;
  @ViewChild(MatPaginator) set matPaginator2(mp: MatPaginator) {
    this.paginator2 = mp? mp: this.paginator2;
    if (mp) {
      this.pageSize = mp.pageSize;
    }
    this.loadBusinessPartnerTableData();
  }

  firstTableSort: MatSort;
  @ViewChild(MatSort) set matSort1(sort: MatSort) {
    this.firstTableSort = sort? sort : this.firstTableSort;
    this.loadTableData();
  }

  secondTableSort: MatSort;
  @ViewChild(MatSort) set matSort2(sort: MatSort) {
    this.secondTableSort = sort? sort : this.secondTableSort;
    this.loadBusinessPartnerTableData();
  }

  selection = new SelectionModel<any>(true, []);
  businessPartnerSelection = new SelectionModel<any>(true, []); 

  validationMessages = {
    fromDate: {
      required: this.translateService.instant('FROMDATEREQUIRED')
    },
    freeText: {
      required: this.translateService.instant('FREETEXTREQUIRED')
    },
    subject: {
      required: this.translateService.instant('SUBJECTREQUIRED')
    },
    remainingKM: {
      required: this.translateService.instant('RAMAININGKMREQUIRED')
    },
    remainingTime: {
      required: this.translateService.instant('REMAININGTIMEREQUIRED')
    }
  };

  constructor(
    private driverService: DriverService,
    private fb: FormBuilder,
    private matDialog: MatDialog,
    private dialogRef: MatDialogRef<ForwardMessagesComponent>,
    private mileageDialogRef: MatDialogRef<MileageComponent>,
    protected translateService: TranslateService,
    protected translateConfigService: TranslateConfigService,
    protected storageService: StorageService,
    private changeDetectionRef: ChangeDetectorRef,
    private commonService: CommonService,
    private snackBar: MatSnackBar) {
      super(translateConfigService, translateService, storageService);
      super.ngOnInit();
      this.dataSource = new MatTableDataSource([]);
      this.BusinessPartnerDataSource = new MatTableDataSource([]);
      this.appConstants = AppConstants;
  }

  ngOnInit() {
    this.technicalCarInspectionForm = this.fb.group({
      remainingKM: [''],
      remainingTime: [''],
      vehicle: ['']
    });

    this.technicalCarOtherForm = this.fb.group({
      subject: ['', Validators.required],
      freeText: [''],
      vehicle: ['']
    });

    this.getVehiclesInformation();
    this.translateConfigService.selectedLanguage$.subscribe((data) => {
      this.translationValues();
    });
    this.translationValues();
  }

  translationValues() {
    setTimeout(() => {
      this.subjectsList = this.translateService.instant('SUBJECTSLIST');
      this.remainingTime = this.translateService.instant('REMAININGTIMEDATA');
      this.userInfo = this.storageService.get('userInfo');
      this.vehiclesSelectionList = this.storageService.get('vehiclesSelectionList');
    }, 10);
  }
  
  ngAfterViewInit() {    
  }

  loadTableData() {
    if (this.ELEMENT_DATA && this.ELEMENT_DATA.length > 0) {
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      this.dataSource.sort = this.firstTableSort;
    }
  }

  loadBusinessPartnerTableData() {
    if (this.BusinessPartnerData && this.BusinessPartnerData.length > 0) {
      this.BusinessPartnerDataSource = new MatTableDataSource(this.BusinessPartnerData);
      this.BusinessPartnerDataSource.sort = this.secondTableSort;
      this.BusinessPartnerDataSource.paginator = this.paginator2;
    }
  }

  getVehiclesInformation() {
    this.driverService.getVehiclesInformation().subscribe((data: VehicleInformation) => {
      this.vehicleInformation = data;
      this.Addresses = data.Addresses;
      this.HomeAddresses = this.getFilterAddress(this.Addresses, AppConstants.DriverAddressType.HOME);
      this.OfficeAddresses = this.getFilterAddress(this.Addresses, AppConstants.DriverAddressType.WORK);
      this.isSubscribed = data.Subscribed;
    }, (error: any) => {
      this.snackBar.open('Error: failed to get the Vehicle information', this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
    }, () => {
      this.getBusinessPartners();
    });
  }

  getFilterAddress(addresses, AddressType) {
    let result = [];
    if (AddressType === AppConstants.DriverAddressType.HOME) {
      result = addresses.filter((address) => {
        return address.AddressTypeId === 6;
      });
    } else if (AddressType === AppConstants.DriverAddressType.WORK) {
      result = addresses.filter((address) => {
        return address.AddressTypeId === 7;
      });
    }
    return result;
  }

  displayAllForwardedMessages() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = this.vehicleInformation ? this.vehicleInformation.Vehicles: [];
    dialogConfig.maxWidth = '600px';
    this.dialogRef = this.matDialog.open(ForwardMessagesComponent, dialogConfig);
    this.dialogRef.afterClosed().subscribe(value => {
      // console.log(`Dialog sent: ${value}`);
    });
  }

  inspectionInfoSubmit() {
    const requestObject = new ForwardMessage();
    if (this.technicalCarInspectionForm.controls.remainingKM.value === ''
    && this.technicalCarInspectionForm.controls.remainingTime.value === '') {
      const message = this.translateService.instant('INSPECTIONERRORMESSAGE');
      this.snackBar.open(message, this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
      return;
    }
    requestObject.VehicleId = this.technicalCarInspectionForm.controls.vehicle && this.technicalCarInspectionForm.controls.vehicle.value !== 0 ? this.technicalCarInspectionForm.controls.vehicle.value : '';
    requestObject.Inspection = true;
    if (this.technicalCarInspectionForm.controls.remainingKM.value
      && this.technicalCarInspectionForm.controls.remainingKM.value !== '') {
        requestObject.RemainingKm = this.technicalCarInspectionForm.controls.remainingKM.value;
    }

    if (this.technicalCarInspectionForm.controls.remainingTime.value
      && this.technicalCarInspectionForm.controls.remainingTime.value !== '') {
        requestObject.RemainingTime = this.technicalCarInspectionForm.controls.remainingTime.value;
    }
    this.driverService.saveVehicleTechnicalCarMessage(requestObject).subscribe((response) => {
      this.getVehiclesInformation();
      this.technicalCarInspectionForm.reset();
      this.snackBar.open(this.translateService.instant('MESSAGESENT'), this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
    });
  }

  otherInfoSubmit() {
    const requestObject = new ForwardMessage();
    if (this.technicalCarOtherForm.controls.subject.value === '') {
      const message = this.translateService.instant('OTHERFORMERRORMESSAGE');
      this.snackBar.open(message, this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
      return;
    }
    requestObject.VehicleId = this.technicalCarOtherForm.controls.vehicle && this.technicalCarOtherForm.controls.vehicle.value !== 0 ? this.technicalCarOtherForm.controls.vehicle.value: '';
    requestObject.Inspection = false;
    if (this.technicalCarOtherForm.controls.subject.value
      && this.technicalCarOtherForm.controls.subject.value !== '') {
        requestObject.Subject = this.technicalCarOtherForm.controls.subject.value;
    }

    if (this.technicalCarOtherForm.controls.freeText.value
      && this.technicalCarOtherForm.controls.freeText.value !== '') {
        requestObject.Text = this.technicalCarOtherForm.controls.freeText.value;
    }

    this.driverService.saveVehicleTechnicalCarMessage(requestObject).subscribe((response) => {
      this.getVehiclesInformation();
      this.technicalCarOtherForm.reset();
      this.snackBar.open(this.translateService.instant('MESSAGESENT'), this.translateService.instant('CLOSE'), {
        duration: 2000
      });
    });
  }

  homeAddressClick(address) {
    // console.log(address);
    this.openEditAddressDialog(address, AppConstants.DriverAddressType.HOME);
  }

  officeAddressClick(address) {
    // console.log(address);
    this.openEditAddressDialog(address, AppConstants.DriverAddressType.WORK);
  }

  openEditAddressDialog(address, type) {
    const dialogRef = this.matDialog.open(AddAddressComponent, {
      width: '550px',
      data: {
        title: type,
        address: address
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {
        this.getVehiclesInformation();
      }
    });
  }

  addAdditionalDriver() {
    this.openAdditionalDriverDialog(null);
  }

  EditAdditionalDriver(data) {
    this.openAdditionalDriverDialog(data);
  }

  DeleteAdditionalDriver(data) {
    // console.log(data.ID);
    const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
      width: '550px',
      data: {
        title: this.translateService.instant('DELETEADDITIONALDRIVER'),
        message: this.translateService.instant('DELETEDRIVERMESSAGE', { value: data })
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed', result);
      if (result) {
        this.driverService.deleteAdditionalDriver(data.ID).subscribe((response: any) => {
          // console.log(response);
          this.getVehiclesInformation();
        }, (error) => {
          console.log(error);
        });
      }
    });

  }

  openAdditionalDriverDialog(data) {
    const dialogRef = this.matDialog.open(AddAdditionalDriverComponent, {
      width: '550px',
      data: {
        title: data ? this.translateService.instant('EDITADDITIONALDRIVER'): this.translateService.instant('ADDADDITIONALDRIVER'),
        data: (data? data: null)
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {
        this.getVehiclesInformation();
      }
    });
  }

  addHomeAddress() {
    this.openAddressDialog(AppConstants.DriverAddressType.HOME);
  }

  deleteHomeAddress(address) {
    this.openDeleteHomeAddressDialog(address);
  }

  openDeleteHomeAddressDialog(address) {
    const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
      width: '550px',
      data: {
        title: this.translateService.instant('DELETEHOMEADDRESS'),
        message: this.translateService.instant('DELETEADDRESSMESSAGE', { value: address.Street + ' ' + address.HouseNumber + ' ' + address.PostalCode + ' ' + address.CountryName})
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed', result);
      if (result) {
        // console.log(address);
        this.driverService.deleteDriverAddress(address.ID).subscribe((response: any) => {
          // console.log('updated mileage response', response);
          this.snackBar.open(
            this.translateService.instant('DELETEDHOMEADDRESS'),
            this.translateService.instant('CLOSE'),
            AppConstants.SNACK_BAR_DELAY);
          this.getVehiclesInformation();
        }, (error: any) => {
          this.snackBar.open(error, this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
        });
      }
    });
  }

  addWorkAddress() {
    this.openAddressDialog(AppConstants.DriverAddressType.WORK);
  }

  deleteWorkAddress(address) {
    this.openDeleteWorkAddressDialog(address);
  }

  openDeleteWorkAddressDialog(address) {
    const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
      width: '550px',
      data: {
        title: this.translateService.instant('DELETEWORKADDRESS'),
        message: this.translateService.instant('DELETEADDRESSMESSAGE', { value: address.Street + ' ' + address.HouseNumber + ' ' + address.PostalCode + ' ' + address.CountryName })
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed', result);
      if (result) {
        // console.log(address);
        this.driverService.deleteDriverAddress(address.ID).subscribe((response: any) => {
          // console.log('updated mileage response', response);
          this.snackBar.open(
            this.translateService.instant('DELETEDWORKADDRESS'),
            this.translateService.instant('CLOSE'),
            AppConstants.SNACK_BAR_DELAY
          );
          this.getVehiclesInformation();
        }, (error: any) => {
          this.snackBar.open(error, this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
        });
      }
    });
  }

  openAddressDialog(title) {
    const dialogRef = this.matDialog.open(AddAddressComponent, {
      width: '550px',
      data: {
        title: title
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {
        this.getVehiclesInformation();
      }
    });
  }

  save(requestData: any) {
    let requestBody: PostMileageRequest = {
      VehicleId: null,
      CurrentMileage: 0,
      CurrentMileageIsCorrect: false,
      PreviousMileage: null
    }
    if (requestData) {
      requestBody.CurrentMileage = requestData.CurrentMileage;
      requestBody.VehicleId = requestData.VehicleId;
      requestBody.CurrentMileageIsCorrect = requestData.isCorrectCurrentMileage;
		  requestBody.PreviousMileage = requestData.PreviousMileage.CurrentMileage;
    }
    // console.log('mileage request body', requestBody);
    this.driverService.saveMileage(requestBody).subscribe((response: any) => {
      // console.log('updated mileage response', response);
      if(response && response.status === 200) {
        this.snackBar.open(this.translateService.instant('UPDATEDMILEAGEMESSAGE'), this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
      } else {
        this.snackBar.open(response.message, this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
      }
      this.dialogRef.close();
    }, (error: any) => {
      this.snackBar.open(error, this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
      this.dialogRef.close();
    });
  }

  csvInputChange(fileInputEvent: any) {
    let files = fileInputEvent.target.files;
    if (this.commonService.isValidCSVFile(files[0])) {
      let reader = new FileReader();
      reader.readAsText(files[0], "ISO-8859-1");
      reader.onload = () => {
        let csvData: any = reader.result;
        let csvRecordsArray = (csvData).split(/\r\n|\n/);
        // let headersRow = this.getHeaderArray(csvRecordsArray);
        console.log(this.ELEMENT_DATA);
        this.ELEMENT_DATA = this.getDataRecordsArrayFromCSVFile(csvRecordsArray);
        this.loadTableData();
      };
      reader.onerror = function () {
        console.log('error is occured while reading file!');
      };
    } else {
      this.snackBar.open(this.translateService.instant('CSVUPLOADERRORMESSAGE'), this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
      // this.fileReset();
    }
  }

  getDataRecordsArrayFromCSVFile(csvRecordsArray: any) {
    let csvArr = [];
    // console.log(csvRecordsArray);
    for (let i = 1; i < csvRecordsArray.length; i++) {
      if (csvRecordsArray[i]) {
        csvRecordsArray[i] = csvRecordsArray[i].replaceAll('\""', '');
        let curruntRecord = (csvRecordsArray[i]).split(',');
        let csvRecord = {
          Id: 0,
          Firstname: '',
          Surname: '',
          Company: '',
          Street: '',
          City: '',
          Zipcode: '',
          Country: '',
        };
        csvRecord.Id = i;
        csvRecord.Firstname = curruntRecord[1] ? curruntRecord[1].trim(): '';
        // csvRecord.Middlename = curruntRecord[2] ? curruntRecord[2].trim(): '';
        csvRecord.Surname = curruntRecord[3] ? curruntRecord[3].trim(): '';
        csvRecord.Company = curruntRecord[5] ? curruntRecord[5].trim(): '';
        csvRecord.Street = curruntRecord[8] || curruntRecord[9] || curruntRecord[10] ? curruntRecord[8] + ' ' + curruntRecord[9] + ' ' + curruntRecord[10]: '';
        csvRecord.City = curruntRecord[11] ? curruntRecord[11].trim(): '';
        csvRecord.Zipcode = curruntRecord[13] ? curruntRecord[13].trim(): '';
        csvRecord.Country = curruntRecord[14] ? curruntRecord[14].trim(): '';
        if (curruntRecord[8] && curruntRecord[8] !== '') {
          csvArr.push(csvRecord);
        }
      }
    }
    return csvArr;
  }

  getHeaderArray(csvRecordsArr: any) {
    csvRecordsArr[0] = (csvRecordsArr[0]).replaceAll("\"", '');
    let headers = (csvRecordsArr[0]).split(',');
    let headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    return headerArray;
  }

  editContact(contact) {
    console.log(contact);
    this.openEditContact(contact);
  }

  openEditContact(contact) {
    let title = this.translateService.instant('EDITCONTACT');
    const dialogRef = this.matDialog.open(EditContactBusinessPartnerDialogComponent, {
      width: '550px',
      data: {
        title: title,
        contact: contact
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed', result);
      if (result.isDialogClose) {
        // this.getVehiclesInformation();
        console.log(result.data);
        for (let i=0;i < this.ELEMENT_DATA.length; i++) {
          if (result.data.Id === this.ELEMENT_DATA[i].Id) {
            this.ELEMENT_DATA[i] = {
              Company: result.data.Company,
              Country: result.data.Country,
              Firstname: result.data.Firstname,
              Id: result.data.Id,
              Street:  result.data.Street,
              City: result.data.City,
              Surname: result.data.Surname,
              Zipcode: result.data.Zipcode,
            };
          }
        }
        // console.log(this.ELEMENT_DATA);
        this.loadTableData();
      }
    });
  }

  deleteContact(contact) {
    let title = this.translateService.instant('DELETECONTACT');
    const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
      width: '550px',
      data: {
        title: title,
        message: this.translateService.instant('DELETECONTACTMESSAGE', { value: contact})
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed', result);
      if (result) {
        this.ELEMENT_DATA = this.ELEMENT_DATA.filter((data) => {
          return contact.Id !== data.Id;  
        });
        // console.log(this.ELEMENT_DATA);
        this.loadTableData();
      }
    });
  }

  submitBulkContacts() {
    // console.log(this.ELEMENT_DATA);
    let options = {
      BusinessPartnerFlatObjects: this.ELEMENT_DATA
    }
    this.driverService.bulkUploadBusinessPartners(options).subscribe((response: any) => {
      // console.log(response);
      if (response && response.message) {
        this.snackBar.open(response.message, this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
      }
      if (response && response.status === 400) {
        return;
      }
      this.getBusinessPartners();
      this.ELEMENT_DATA = [];
      this.loadTableData();
    }, (error) => {
      this.snackBar.open(error.message, this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY)
    });
  }

  getBusinessPartners() {
    this.BusinessPartnerData = [];
    this.driverService.getBusinessPartners().subscribe((response: any) => {
      this.BusinessPartnerData = response;
      this.storageService.set('BusinessPartners', response);
      this.processDuplicateHideFlag();
      this.loadBusinessPartnerTableData();
    });
  }

  processDuplicateHideFlag() {
    if (this.BusinessPartnerData && this.BusinessPartnerData.length > 0) {
      let previousElement;
      this.BusinessPartnerData.map((data, index) => {
        if (previousElement && previousElement.ID === data.ID) {
          data.Duplicate = true;
          // first similar object data.ID should have isMultipleAddress falg true
          this.insertFirstIndexWithIsMultipleAddress(data);
        } else if (!previousElement) {
          previousElement = data;
          data.Duplicate = false;
        } else {
          data.Duplicate = false;
          previousElement = data;
        }
      });
      this.storageService.set('BusinessPartners', this.BusinessPartnerData);
      this.BusinessPartnerData = this.BusinessPartnerData.filter((data: any)=>!data.Duplicate);
      this.storageService.set('filteredBusinessPartners', this.BusinessPartnerData);
      // console.log(this.BusinessPartnerData);
    }
  }

  insertFirstIndexWithIsMultipleAddress(data) {
    for (let i=0; i<this.BusinessPartnerData.length; i++) {
      if (this.BusinessPartnerData[i].ID === data.ID) {
        this.BusinessPartnerData[i].isMultipleAddress = true;
        this.BusinessPartnerData[i].isOpen = false;
        return;
      }
    }
  }

  editBusinessPartner(data) {
    this.openEditBusinessPartner(data);
  }

  openEditBusinessPartner(contact) {
    let title = this.translateService.instant('EDITBUSINESSPARTNER');
    window.scroll(0, 0);
    const dialogRef = this.matDialog.open(EditBusinessPartnerDialogComponent, {
      width: '550px',
      data: {
        title: title,
        contact: contact
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.isDialogClose) {
        console.log(result.data);
        this.updateBusinessPartner(result.data);
      }
    });
  }

  updateBusinessPartner(updatedBusinessPartnerData) {
    let options: UpsertBusinessPartnerRequest = {
      ID: updatedBusinessPartnerData.Id,
      CompanyName: updatedBusinessPartnerData.Company,
      FirstName: updatedBusinessPartnerData.Firstname,
      LastName: updatedBusinessPartnerData.Surname,
    };
    this.driverService.upsertBusinessPartner(options).subscribe((response: any) => {
      if (response.status === 400) {
        this.snackBar.open(response.message, this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
        return;
      }
      if (response.status === 200) {
        this.snackBar.open(this.translateService.instant('UPDATEDBUSINESSPARTNER'), this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
      }
      this.getBusinessPartners();
    }, (error) => {
      this.snackBar.open(error.message, this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
    });
  }

  deleteBusinessPartner(businessPartner) {
    let title = this.translateService.instant('DELETEBUSINESSPARTNER');
    const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
      width: '550px',
      data: {
        title: title,
        message: this.translateService.instant('DELETEBUSINESSPARTNERMESSAGE', {value: businessPartner})
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed', result);
      if (result) {    
        this.driverService.deleteBusinessPartner(businessPartner.ID).subscribe((response: any) => {
          this.snackBar.open(response.message, this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
          this.getBusinessPartners();
        }, (error) => {
          this.snackBar.open(error.message, this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
        });
      }
    });
  }

  deleteBusinessPartnerAddress(data) {
    let title = this.translateService.instant('DELETEBUSINESSPARTNERADDRESS');
    let value = data.Street + ' ' + data.HouseNumber + ', ' + data.PostalCode + ' ' + data.CityName + ', ' + data.FederalStateName + ' ' + data.CountryName;
    const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
      width: '550px',
      data: {
        title: title,
        message: this.translateService.instant('DELETEBUSINESSPARTNERADDRESSMESSAGE', {value: value})
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed', result);
      if (result) {
        this.driverService.deleteBusinessPartnerAddress(data.AddressID).subscribe((response: any) => {
          this.snackBar.open(response.message, this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
          this.getBusinessPartners();
        }, (error) => {
          this.snackBar.open(error.message, this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
        });
      }
    });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  deleteBulkContacts() {
    const numSelected = this.selection.selected;
    if (numSelected.length > 0) {  
      // console.log("Are you sure to delete items ");
      let title = this.translateService.instant('DELETECONTACTS');
      const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
        width: '550px',
        data: {
          title: title,
          message: this.translateService.instant('DELETECONTACTSMESSAGE')
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        // console.log('The dialog was closed', result);
        if (result) {
          for (let i=0; i <= numSelected.length; i++) {
            this.ELEMENT_DATA = this.ELEMENT_DATA.filter((data) => {
              return numSelected[i].Id !== data.Id;  
            });
            // console.log(this.ELEMENT_DATA);
            this.loadTableData();
          }
        }
      });
    } else {  
      // alert("Select at least one row");
      this.snackBar.open(this.translateService.instant('SELECTONEROWERRORMESSAGE'), this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
    }
  }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllBusinessPartnerSelected() {
    const numSelected = this.businessPartnerSelection.selected.length;
    const numRows = this.BusinessPartnerDataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  businessPartnerMasterToggle() {
    this.isAllBusinessPartnerSelected() ?
      this.businessPartnerSelection.clear() :
      this.BusinessPartnerDataSource.data.forEach(row => this.businessPartnerSelection.select(row));
  }

  deleteBulkBusinessPartners() {
    const numSelected = this.businessPartnerSelection.selected;
    // console.log(numSelected);
    if (numSelected.length > 0) {  
      // console.log("Are you sure to delete items ");
      let title = this.translateService.instant('DELETEBUSINESSPARTNERS');
      const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
        width: '550px',
        data: {
          title: title,
          message: this.translateService.instant('DELETEBUSINESSPARTNERSCONFIRMMESSAGE')
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        // console.log('The dialog was closed', result);
        if (result) {
          for (let i=0; i <= numSelected.length; i++) {
            this.driverService.deleteBusinessPartner(numSelected[i].ID).subscribe((response) => {
              // console.log(response);
              if (i === numSelected.length-1) {
                this.getBusinessPartners();
                this.snackBar.open(this.translateService.instant('DELETEBUSINESSPARTNERSUCESSMESSAGE'), this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
              }
            }, (error)=> {
              console.log(error);
            });
            // console.log(this.ELEMENT_DATA);
            this.loadTableData();
          }
        }
      });
    } else {  
      // alert("Select at least one row");
      this.snackBar.open(this.translateService.instant('SELECTONEROWERRORMESSAGE'), this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
    }
  }

  sortData(sort: Sort) {
    let SortedData;
    const data = this.BusinessPartnerData.slice();
    if (!sort.active || sort.direction === '') {
      SortedData = data;
      return;
    }

    SortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'Firstname': return this.compare(a.FirtName, b.FirtName, isAsc);
        case 'Surname': return this.compare(a.LastName, b.LastName, isAsc);
        case 'Company': return this.compare(a.CompanyName, b.CompanyName, isAsc);
        case 'Street':
          let aStreetValue = a.BusinessPartnerAddresses && a.BusinessPartnerAddresses.length > 0 ?
            a.BusinessPartnerAddresses[0].Street: '';
          let bStreetValue = b.BusinessPartnerAddresses && b.BusinessPartnerAddresses.length > 0 ? 
            b.BusinessPartnerAddresses[0].Street: '';
          return this.compare(aStreetValue, bStreetValue, isAsc);
        case 'City':
        let aCityValue = a.BusinessPartnerAddresses && a.BusinessPartnerAddresses.length > 0 ?
          a.BusinessPartnerAddresses[0].CityName: '';
        let bCityValue = b.BusinessPartnerAddresses && b.BusinessPartnerAddresses.length > 0 ? 
          b.BusinessPartnerAddresses[0].CityName: '';
          return this.compare(aCityValue,bCityValue, isAsc);
        case 'Zipcode':
        let aPostalCodeValue = a.BusinessPartnerAddresses && a.BusinessPartnerAddresses.length > 0 ?
          a.BusinessPartnerAddresses[0].PostalCode: '';
        let bPostalCodeValue = b.BusinessPartnerAddresses && b.BusinessPartnerAddresses.length > 0 ? 
          b.BusinessPartnerAddresses[0].PostalCode: '';
          return this.compare(aPostalCodeValue, bPostalCodeValue, isAsc);
        case 'Country':
          let aCountryValue = a.BusinessPartnerAddresses && a.BusinessPartnerAddresses.length > 0 ?
            a.BusinessPartnerAddresses[0].CountryName: '';
          let bCountryValue = b.BusinessPartnerAddresses && b.BusinessPartnerAddresses.length > 0 ? 
            b.BusinessPartnerAddresses[0].CountryName: '';
          return this.compare(aCountryValue, bCountryValue, isAsc);
        default: return 0;
      }
    });
    // console.log(SortedData);
    this.BusinessPartnerData = SortedData;
    this.loadBusinessPartnerTableData();
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  toggleRow(element: any, i) {
    console.log('Insert duplicate respective to ID', i);
    let businessPartnerSession = this.storageService.get('BusinessPartners');
    const filteredBusinessPartners = this.storageService.get('filteredBusinessPartners');
    this.resetBusinessPartnerData(filteredBusinessPartners);
    if (!element.isOpen) {
      // add duplicate data into table w-r-to ID
      filteredBusinessPartners.forEach((businessPartner, index) => {
        if (businessPartner.ID === element.ID) {
          let fitleredBusinessPartnerSession = businessPartnerSession.filter((data)=> data.ID === element.ID);
          fitleredBusinessPartnerSession.shift();
          if (fitleredBusinessPartnerSession && fitleredBusinessPartnerSession.length > 0) {
            this.BusinessPartnerData.map((value) => {
              if (value.ID === fitleredBusinessPartnerSession[0].ID) {
                value.isOpen = true;
                if ((i+1) === this.pageSize) this.isLastIndexRowClick();
                this.loadBusinessPartnerTableData();
                this.changeDetectionRef.detectChanges();
              } else {
                value.isOpen = false;
              }
              return value;
            });
            fitleredBusinessPartnerSession.forEach((data) => {
              this.BusinessPartnerData.splice(index+1, 0, data);
            });
          }
        }
      });
    } else {}
    this.loadBusinessPartnerTableData();
    this.changeDetectionRef.detectChanges();
  }

  isLastIndexRowClick() {
    let matchedPageSizeIndex = 0;
    this.pageSizeOptions.forEach((value, index) => {
      if(this.pageSize === value) {
        matchedPageSizeIndex = index;
        return;
      }
    });
    if (this.pageSize) {
      this.pageSize = this.pageSizeOptions[matchedPageSizeIndex+1];
    }
  }

  resetBusinessPartnerData(filteredBusinessPartners) {
    const copyFilteredBusinessPartners = [ ...filteredBusinessPartners ];
    this.BusinessPartnerData = copyFilteredBusinessPartners;
    this.loadBusinessPartnerTableData();
    this.changeDetectionRef.detectChanges();
  }

  EditStandardDriver(vehicleInformation) {
    // console.log(vehicleInformation);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = vehicleInformation;
    dialogConfig.data.title = this.translateService.instant('INFORMRATIONDRIVER');
    dialogConfig.maxWidth = '600px';
    let dialogRef = this.matDialog.open(UpdateStandardDriverModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(value => {
      // console.log(`Dialog sent: ${value}`);
      let driverId = undefined;
      if (value && value.isDialogClose && value.data) {
        let options: StandardDriverRequest = {
          Email: value.data.Email,
          MobileNumber: value.data.MobileNumber,
          TelephoneNumber: value.data.TelephoneNumber
        }
        this.updateStandardDriverInformation(options, driverId);
      }
    });
  }
  
  updateStandardDriverInformation(options, driverId) {
    if (options) {
      this.driverService.updateStandardDriver(options, driverId).subscribe((response: any) => {
        this.getVehiclesInformation();
        if (response.status === 200) {
          this.snackBar.open(this.translateService.instant('UPDATESENT'), this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
        }
      }, (error: Error) => {
        this.snackBar.open(error.message, this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
      });
    }
  }

  vehicleChangeEvent(event, form) {
    console.log(event, form);
  }

  AddContacts() {
    // console.log('ADD CONTACTS clicked');
    this.openBusinessPartnerDialog();
  }

  checkBuinessPartner(data) {
		if (data.value.Type === this.translateService.instant('BUSINESS')) {
			this.openBusinessPartnerDialog();
		}
	}

	openBusinessPartnerDialog() {
		const dialogConfig = new MatDialogConfig();
    let data = {
      title: this.translateService.instant('CHOOSEBUSINESSPARTNER'),
      isAddNewBusinessPartner: true
    };
		dialogConfig.data = data || '';
		dialogConfig.disableClose = true;
		dialogConfig.width = '400px';
		window.scroll(0, 0);
		let dialogBusinessPartnerRef = this.matDialog.open(BusinessPartnerDialogComponent, dialogConfig);
		dialogBusinessPartnerRef.afterClosed().subscribe(value => {
			// console.log(`Dialog closed and sent: ${value}`);
			if (value && value.isClosedDialog) {
        this.getBusinessPartners();
			}
		});
	}

  changeUnmanagedTripSubscribe(event) {
    console.log(event);
    if (event) {
      let options = {
        Subscribed: event.checked
      };
      this.updateStandardDriverInformation(options, null);
    }
  }

}

