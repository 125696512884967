<form [formGroup]="addBusinessPartnerForm" class="example-form">
    <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>{{ 'FIRSTNAME' | translate }}</mat-label>
        <input class="input-tag" type="text" matInput placeholder="{{ 'FIRSTNAMEPLACEHOLDER' | translate }}" formControlName="firstname" (change)="onChangeFirstname()" required>
        <mat-error *ngIf="addBusinessPartnerForm.controls['firstname'].invalid && addBusinessPartnerForm.controls['firstname'].touched">
            {{ getErrorFirstname() }}
        </mat-error>
        <mat-error *ngIf="addBusinessPartnerForm.controls['firstname'].errors?.invalidAlphabets && addBusinessPartnerForm.controls['firstname'].touched">
            {{ 'INVALIDALPHABET' | translate }}
        </mat-error>
   </mat-form-field>
   <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>{{ 'LASTNAME' | translate }}</mat-label>
        <input class="input-tag" type="text" matInput placeholder="{{ 'LASTNAMEPLACEHOLDER' | translate }}" formControlName="lastname" (change)="onChangeLastname()" required>
        <mat-error *ngIf="addBusinessPartnerForm.controls['lastname'].invalid && addBusinessPartnerForm.controls['lastname'].touched">
            {{ getErrorLastname() }}
        </mat-error>
        <mat-error *ngIf="addBusinessPartnerForm.controls['lastname'].errors?.invalidAlphabets && addBusinessPartnerForm.controls['lastname'].touched">
            {{ 'INVALIDALPHABET' | translate }}
        </mat-error>
   </mat-form-field>

   <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>{{ 'COMPANY' | translate }}</mat-label>
        <input class="input-tag" type="text" matInput placeholder="{{ 'COMPANYPLACEHOLDER' | translate }}" formControlName="companyname" (change)="onChangeCompanyName()">
    </mat-form-field>

    <button *ngIf="!enableNewBusinessPartnerAddress" mat-raised-button color="primary" (click)="onSaveBusinessPartner()">{{'NEXT'| translate}}</button>

    <div *ngIf="enableNewBusinessPartnerAddress">
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <p>{{ 'ADDADRESSBUSINESSPARTNER' | translate}}:</p>
            <button *ngIf="Addresses && Addresses.length === 0" (click)="addAddress()" mat-icon-button color="primary" aria-label="Update Address">
                <mat-icon>add</mat-icon>
            </button>
        </div>
        <div formArrayName="BuinessPartnerAddress" *ngFor="let address of Addresses; let i = index">
            <div class="address-form-section" [formGroupName]="i">
                <h3>{{'ADDRESS' | translate}}:</h3>
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>{{'ADDRESS' | translate}}</mat-label>
                    <!-- <input matInput placeholder="" formControlName="EndAddress" required> -->
                    <input matInput required
                        matGoogleMapsAutocomplete
                        [type]="'address'"
                        [country]="language"
                        (onAutocompleteSelected)="onBusinessPartnerAddressAutocompleteSelected($event, i)"
                        (onLocationSelected)="onBusinessPartnerAddressLocationSelected($event, i)"
                        (onGermanAddressMapped)="onGermanBusinessPartnerAddressMapped($event, i)"
                        formControlName="BusinessPartnerAddress">
                </mat-form-field>
                <!--<mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>{{'STREET' | translate}}</mat-label>
                    <input matInput placeholder="{{ 'STREETPLACEHOLDER' | translate }}" formControlName="street" required>
                    <mat-error *ngIf="address.controls['street'].invalid && address.controls['street'].touched">
                        {{'FIELDREQUIRED' | translate}}
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>{{'HOUSENUMBER' | translate}}</mat-label>
                    <input matInput placeholder="{{ 'HOUSENUMBERPLACEHOLDER' | translate }}" formControlName="houseNo" required>
                    <mat-error *ngIf="address.controls['houseNo'].invalid && address.controls['houseNo'].touched">
                        {{'FIELDREQUIRED' | translate}}
                    </mat-error>
                </mat-form-field>
                <div fxLayout="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="space-between center">
                    <mat-form-field appearance="outline" fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
                        <mat-label>{{'CHOOSECOUNTRY' | translate}}</mat-label>
                        <mat-select (selectionChange)="selectedCountry($event.value, i, false)" formControlName="countryId">
                            <mat-option *ngFor="let country of countries" [value]="country.ID">
                                {{country.Name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="space-between center">
                    <mat-form-field appearance="outline" *ngIf="states && states.length > 0"
                        fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
                        <mat-label>{{'CHOOSESTATE' | translate}}</mat-label>
                        <mat-select (selectionChange)="selectedState($event.value, i, false)" formControlName="federalStateId">
                            <mat-option *ngFor="let state of states" [value]="state.ID">
                            {{state.Name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="space-between center">
                    <mat-form-field appearance="outline" *ngIf="cities && cities.length > 0" fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
                        <mat-label>{{'CHOOSECITY' | translate}}</mat-label>
                        <mat-select (selectionChange)="selectedCity($event.value, i)" formControlName="cityId">
                            <mat-option *ngFor="let city of cities" [value]="city.ID">
                                {{city.Name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayout.sm="row" fxLayout.xs="column">
                    <mat-label class="address-type">{{'ADDRESSTYPE' | translate}}:</mat-label>
                    {{getAddressType()}}
                </div>
                <br/>-->
            </div>
            <div fxLayout="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="space-between center">
                <button mat-raised-button (click)="onDeleteAddress(i)">{{'DELETE' | translate}}</button>
                <button mat-raised-button color="primary" (click)="onSaveAddress(i)">{{'SAVE' | translate}}</button>
            </div>
        </div>
    </div>
    <br/>
</form>