import { Component, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { AppConstants } from '../../../app.constants';
import { UpsertBusinessPartnerAdressRequest } from '../../../shared/models/upsert-business-partner-address-request.model';
import { DriverService } from '../../../shared/services/driver/driver.service';
import { CommonService } from '../../../../app/shared/services/common/common.service';

@Component({
  selector: 'app-edit-business-partner-dialog',
  templateUrl: './edit-business-partner-dialog.component.html',
  styleUrls: ['./edit-business-partner-dialog.component.scss']
})
export class EditBusinessPartnerDialogComponent implements OnInit {

  title: string;
  EditBusinessPartnerForm: FormGroup;

  constructor(public dialogRef: MatDialogRef<EditBusinessPartnerDialogComponent>,
    private formBuilder: FormBuilder,
    private driverService: DriverService,
    private snackbar: MatSnackBar,
    private commonService: CommonService,
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.title = this.data.title;
  }

  ngOnInit(): void {
    // console.log('Edit business partner: ', this.data);
    if (this.data) {
      this.EditBusinessPartnerForm = this.formBuilder.group({
        Id: [this.data.contact? this.data.contact.ID: ''],
        Firstname: [this.data.contact? this.data.contact.FirtName: '', [Validators.required, this.commonService.validateAlphabetString()]],
        Surname: [this.data.contact? this.data.contact.LastName: '', [Validators.required, this.commonService.validateAlphabetString()]],
        Company: [this.data.contact? this.data.contact.CompanyName:'', Validators.required]
      });
    }
  }

  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close({
      isDialogClose: true,
      data: this.EditBusinessPartnerForm.value
    });
  }

  businessPartnerUpdateAddressEmit(Address: any) {
    let options: UpsertBusinessPartnerAdressRequest = {
      BusinessPartnerId: this.EditBusinessPartnerForm.value.Id,
      CityId: Address.value.CityId,
      CountryId: Address.value.CountryId,
      FederalStateId: Address.value.FederalStateId,
      Street: Address.value.Street,
      HouseNumber: Address.value.HouseNumber
    };
    if (Address.value.AddressID && Address.value.AddressID !== "") {
      options['ID'] = Address.value.AddressID;
    }
    this.updateBusinessPartnerAddress(options);
  }

  businessPartnerDeleteAddressEmit(Address: any) {
    this.deleteBusinessPartnerAddress(Address.value.Id);
  }

  businessPartnerAddAddressEmit(value) {
    if (value) {
      this.onConfirm();
    }
  }

  updateBusinessPartnerAddress(options: UpsertBusinessPartnerAdressRequest) {
    this.driverService.upsertBusinessPartnerAddress(options).subscribe((response: any) => {
      if (response.message) {
        this.snackbar.open(response.message, this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
        this.onConfirm();
      }
    }, (error) => {
      this.snackbar.open(error.message, this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
    });
  }

  deleteBusinessPartnerAddress(businessPartnerAddressId) {
    this.driverService.deleteBusinessPartnerAddress(businessPartnerAddressId).subscribe((response: any) => {
      this.snackbar.open(response.message, this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
      this.onConfirm();
    }, (error) => {
      this.snackbar.open(error.message, this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
    });
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }

}
