import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../services/auth/auth.service';
import { StorageService } from '../../../shared/services/storage/storage.service';
import { UserInformation } from '../../models/user-information-response.model';
import { AppConstants } from '../../../app.constants';
import { CommonService } from '../../../shared/services/common/common.service';
import { CommonBaseComponent } from '../../../shared/components/common-base/common-base.component';
import { TranslateConfigService } from '../../../shared/services/translate/translate-config.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent extends CommonBaseComponent implements OnInit  {
	showSpinner: boolean = false;
	hide = true;
	isUsernameFocused = false;
	isPasswordFocused = false;
	loginForm: FormGroup;

	constructor(private router: Router,
  		private formBuilder: FormBuilder,
		private snackBar: MatSnackBar,
		private dialogRef: MatDialog,
		private authService: AuthService,
		protected translateService: TranslateService,
		protected translateConfigService: TranslateConfigService,
		private commonService: CommonService,
		protected storageService: StorageService) {
			super(translateConfigService, translateService, storageService);
			super.ngOnInit();
	}

	ngOnInit() {
		this.dialogRef.closeAll();
		this.loginForm = this.formBuilder.group({
			username: ['', Validators.required],
			password: ['', Validators.required],
		});
		this.commonService.removeLocalStorageValues();
		let data = this.storageService.get('UserData');
		if (data) {
			this.loginForm.controls.username.setValue(data.username);
			this.loginForm.controls.password.setValue(data.password);
		}
	}
	
	getErrorUsername() {
    	return this.loginForm.get('username').hasError('required') ? this.translateService.instant('FIELDREQUIRED'):
      this.loginForm.get('username').hasError('alreadyInUse') ? this.translateService.instant('USERNAMEUSEDMESSAGE'): '';
  	}

  	getErrorPassword() {
    	return this.loginForm.get('password').hasError('required') ? this.translateService.instant('PASSWORDREQUIRED') :
      		this.loginForm.get('password').hasError('requirements') ? this.translateService.instant('PASSWORDVALIDATIONMESSAGE'): '';
  	}

	clearAllStorageData() {
		this.storageService.clearAll();
	}

    login(): void {
		this.showSpinner = true;
		if(this.loginForm.controls['username'].value !== '' && this.loginForm.controls['password'].value !== '') {
			const options_CR = {
				username: this.loginForm.controls['username'].value,
				password: this.loginForm.controls['password'].value
			}
			this.authService.loginCrApi(options_CR).subscribe((response) => {
				this.showSpinner = true;
				const options_Token = {
					username: this.loginForm.controls['username'].value,
					password: this.loginForm.controls['password'].value,
					grant_type: 'password'
				}
				if (this.commonService.isMobileOrTabletView()) {
					this.storageService.set('UserData', options_Token);
				}
				this.authService.loginCrApiToken(options_Token).subscribe((tokenResponse) => {
					this.storageService.set('CrApiSession', tokenResponse);
					this.storageService.set('loggedIn', true);
					this.storageService.set('isFirstTimeLoggedIn', true);
					this.getUserInformation();
				}, (error) => {
					console.log('******error_loginCrApiToken******', error);
					this.showSpinner = false;
					this.snackBar.open(this.translateService.instant('INVALIDCREDENTIALS'), this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
				});
			}, (error) => {
				console.log('******error_loginCrApi******', error);
				this.showSpinner = false;
				this.snackBar.open(this.translateService.instant('INVALIDCREDENTIALS'), this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
			});
		} else {
			this.authService.setAuthenticated(false);
			this.snackBar.open(this.translateService.instant('USERNAMEPASSWORDINVALIDMESSAGE'), this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
			this.showSpinner = false;
		}
	}

	getUserInformation() {
		this.authService.getUserInformation().subscribe((response: UserInformation) => {
			const userInfo = {
				name: response.Firstname + ' ' + response.Lastname,
				EmailAddress: response.EmailAddress,
				Firstname: response.Firstname,
				Lastname: response.Lastname,
				UserId: response.UserId,
				UserRole: response.UserRole,
				Username: response.Username
			};
			this.storageService.set('userInfo', userInfo);
			if (userInfo && userInfo.UserRole === 'Admin') {
				this.getLoginAssetsToken();
				return;
			}
			this.navigateToDashboard();
		}, (error) => {
			console.log(error);
			this.snackBar.open(error.message, this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
		});
	}

	getLoginAssetsToken() {
		const options_Token = {
			username: this.loginForm.controls['username'].value,
			password: this.loginForm.controls['password'].value,
			grant_type: 'password'
		}
		this.authService.loginAssetsApi(options_Token).subscribe((tokenResponse) => {
			this.storageService.set('AssetsApiSession', tokenResponse);
			this.storageService.set('loggedIn', true);
			this.storageService.set('isFirstTimeLoggedIn', true);
			this.navigateToDashboard();
		}, (error) => {
			console.log('******error_loginAssetsApiToken******', error);
			this.showSpinner = false;
			this.snackBar.open(this.translateService.instant('INVALIDCREDENTIALS'), this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
		});
	}
	
	navigateToDashboard() {
		this.authService.setAuthenticated(true);
		const userInfo = localStorage.getItem('userInformation');
		let parsedUserInfo;
		if (userInfo && userInfo !== '') {
		  	parsedUserInfo = JSON.parse(userInfo);
			if (parsedUserInfo) {
				this.snackBar.open(this.translateService.instant('SUCCESSFULLOGINWELCOMEMESSAGE', { parsedUserInfoValue: parsedUserInfo.Firstname + ' ' + parsedUserInfo.Lastname + '!'}) , this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
			} else {
				this.snackBar.open(this.translateService.instant('SUCCESSFULLOGINMESSAGE'), this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
			}
		}
		this.showSpinner = false; 
		this.router.navigate(['dashboard']);
	}

	keyEnterpressed() {
		this.login();
	}

}
