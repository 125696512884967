// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  maps_api_key: 'AIzaSyBJ6ogO8d94dF7aM9gucuvbXEMtONWfdtA', // 'AIzaSyBfCpR-cIflU1rkWrzKXAMr-JeIIZwBcYQ', 
  crapi: {
    URL: 'https://crapistag.smartvernetzt.com', // 'http://localhost:54847/'
  },
  assetsapi: {
  	URL: 'https://assetsapistag.smartvernetzt.com', // 'http://localhost:54259/'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
