<div class="mileage-snackbar-section">
    <div fxLayout="column">
        <div fxLayout="row">
            <div>{{data.message}}</div>
        </div>
        <div *ngIf="data.actions" fxLayout="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="space-between center">
            <div fxLayout="row" fxFlex="50" fxFlex.sm="50" fxFlex.xs="100">
                <button class="remind-me-button" (click)="remindMeClick()" mat-raised-button *ngIf="data.actions.remindMe">
                    {{data.actions.remindMe}}
                </button>
            </div>
            <div fxLayout="row" fxLayoutAlign="end end" fxFlex="50" fxFlex.sm="50" fxFlex.xs="100">
                <button class="yes-button" (click)="yesBtnClick()" color="primary" mat-raised-button *ngIf="data.actions.yes">
                    {{data.actions.yes}}
                </button>
                <button class="no-button" (click)="noBtnClick()" color="warn" mat-raised-button *ngIf="data.actions.no">
                    {{data.actions.no}}
                </button>
            </div>
        </div>
    </div>
  </div>