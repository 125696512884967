import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';

@Component({
  selector: 'app-report-confirmation',
  templateUrl: './report-confirmation.component.html',
  styleUrls: ['./report-confirmation.component.scss']
})
export class ReportConfirmationComponent implements OnInit {
  title: string;
  message: string;
  years;
  currentYear;
  SelectedYear;
  YearForm: FormGroup;

  constructor(public dialogRef: MatDialogRef<ReportConfirmationComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.title = this.data.title;
  }

  ngOnInit() {
    this.YearForm = this.formBuilder.group({
			SelectedYear: ['', [Validators.required]]
		});
    this.currentYear = moment().year();
    // console.log(this.data, this.currentYear);
    this.years = this.getYears(this.currentYear);
    this.YearForm.controls.SelectedYear.setValue(this.currentYear);
  }

  getYears(currentYear) {
    currentYear = (typeof(currentYear) == 'undefined') ? 2021 : currentYear;
    let years = [];
    for (let i=currentYear; i>=(currentYear-5); i--) {
      years.push(i);
    } 
    return years;
  }

  onConfirm(): void {
    // Close the dialog, return true
    // console.log(this.YearForm.controls.SelectedYear.value);
    this.dialogRef.close(this.YearForm.controls.SelectedYear.value);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }

}
