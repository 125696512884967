import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';

@Injectable({
  providedIn: 'root'
})
export class AppDateAdapterService extends NativeDateAdapter {
	format(date: Date, displayFormat: Object): string {
		if (displayFormat === 'input') {
		  let day: string = date.getDate().toString();
		  day = +day < 10 ? '0' + day : day;
		  let month: string = (date.getMonth() + 1).toString();
		  month = +month < 10 ? '0' + month : month;
		  let year = date.getFullYear();
		  return `${day}.${month}.${year}`;
		}
		return date.toDateString();
	}
}