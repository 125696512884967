import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '../../../shared/services/common/common.service';
import { DriverService } from '../../../shared/services/driver/driver.service';

@Component({
  selector: 'app-add-additional-driver',
  templateUrl: './add-additional-driver.component.html',
  styleUrls: ['./add-additional-driver.component.scss']
})
export class AddAdditionalDriverComponent implements OnInit {
  title: string;
  message: string;
  additionalDriverForm: FormGroup;
	isFirstnameFocused = false;
  isLastnameFocused = false;

  constructor(
    public additionalDriverRef: MatDialogRef<AddAdditionalDriverComponent>,
    @Inject(MAT_DIALOG_DATA) public response,
    public  dialogRef: MatDialogRef<AddAdditionalDriverComponent>,
    private driverService: DriverService,
    private translateService: TranslateService,
    private commonService: CommonService,
    private formBuilder: FormBuilder) { 
      this.title = this.response.title;
  }

  ngOnInit(): void {
    // console.log(this.response.data);
		this.additionalDriverForm = this.formBuilder.group({
			Firstname: [this.response && this.response.data ? this.response.data.Firstname: '', [Validators.required, this.commonService.validateAlphabetString()]],
			Lastname: [this.response && this.response.data? this.response.data.Lastname: '', [Validators.required, this.commonService.validateAlphabetString()]],
		});
  }

  getErrorFirstname() {
    return this.additionalDriverForm.get('Firstname').hasError('required') ? this.translateService.instant('FIRSTNAMEREQUIRED'): '';
  }

  getErrorLastname() {
    return this.additionalDriverForm.get('Lastname').hasError('required') ? this.translateService.instant('LASTNAMEREQUIRED'): '';
  }

  onConfirm(): void {
    if (this.additionalDriverForm.valid) {
      let options = this.additionalDriverForm.value;
      if (this.response && this.response.data) {
        options.ID = this.response.data.ID;
      }
      // console.log(options);
      this.driverService.saveAdditionalDriver(options).subscribe((response: any) => {
        // console.log(response);
        // Close the dialog, return true
        this.dialogRef.close(true);
      }, (error) => {
        console.log(error);
      });
    } else {
      console.log('Invalid, form reuired to be filled');
    }
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }

}
