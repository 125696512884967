  <!-- <h1 mat-dialog-title>
    {{title}}
  </h1> -->

  <div mat-dialog-content>
    <div class="close-button" fxLayout="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="end end">
      <!-- <button mat-raised-button> -->
        <mat-icon (click)="close()">clear</mat-icon>
      <!-- </button> -->
    </div>
    <responsive-stepper [orientation]="(mobile$ | async) ? 'vertical' : 'horizontal'" [linear]="true" #stepper (selectionChange)="stepChanged($event, stepper);">
      <mat-step>
        <form class="separate-trip-form" [formGroup]="separateTripForm">
          <ng-template matStepLabel>{{'FILLOUTNUMBERSEPARATEDTRIPS' | translate}}</ng-template>
          <mat-label>
            <h3>{{'HOWMANYTRIPSSEPARATE' | translate}}:</h3>
            <p class="note">{{'NOTETRIPSIZE' | translate}}</p>
          </mat-label>
          <div class="divided-trip-size-section">
            <mat-form-field>
              <mat-select name="Divided Trips Size" formControlName="dividedTripSize">
                <mat-option *ngFor="let i of maximumDividedTripsSize" [value]="i">
                  {{i+1}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </form>
        <div fxLayout="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="end end">
          <button mat-button matStepperNext (click)="firstStepClick()">{{'NEXT' | translate}}</button>
        </div>
      </mat-step>
      <mat-step label="Fill out your address" [stepControl]="separateTripForm" >
        <div class="address-section">
          <ng-template matStepLabel>{{'FILLOUTROUTEDETAILS' | translate}}</ng-template>
          <div>
            <form [formGroup]="separateTripForm">
              
              <!--{{separateTripForm.value | json}}-->
              <!-- Start form RoutesList array with first row must and dynamically add more -->
              <div class="mat-card-section card-section" formArrayName="RoutesList">
                <!-- loop throught routes -->
                <div [formGroupName]="i" *ngFor="let route of getFormRoutesList.controls;let i = index; first as isFirst"> 
                  
                  <!-- row divider show for every row to exclude if first row -->
                  <mat-divider *ngIf="i%2===0 && getFormRoutesList.controls.length > 1 && i > 1" ></mat-divider>

                  <!-- group name in this case row index -->
                  <div fxLayout="column" fxLayoutGap="0.5%" fxLayoutAlign="start start">
                    <!-- Route Address input field -->
                    <div class="full-width" fxLayout="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutGap="0.5%" fxLayoutAlign="start start" fxLayoutAlign.xs="start center">
                      <div class="form-field-section" fxFlex="40%" fxFlex.sm="40%" fxFlex.xs="100%">
                        <mat-form-field appearance="outline">
                          <mat-label *ngIf="i%2!==0">{{'ENDADDRESS' | translate }}</mat-label>
                          <mat-label *ngIf="i%2===0">{{'STARTADDRESS' | translate }}</mat-label>
                          <input matInput
                            formControlName="Address"
                            matGoogleMapsAutocomplete
                            [country]="language"
                            (onAutocompleteSelected)="onAddressAutocompleteSelected($event, i)"
                            (onLocationSelected)="onAddressLocationSelected($event, i)"
                            (onGermanAddressMapped)="onGermanAddressMapped($event,  i, getFormRoutesList.controls)" 
                            [readonly]="i===0 || i===getFormRoutesList.controls.length-1">
                            <mat-error *ngIf="route.controls.Address.errors?.required">
                              {{validationMessages.required}}
                            </mat-error>
                        </mat-form-field>
                      </div>
                      <div class="form-field-section" fxFlex="30%" fxFlex.sm="30%" fxFlex.xs="100%">
                        <!-- Route Date input field -->
                        <mat-form-field appearance="outline">
                          <mat-label>{{'DATE' | translate }}</mat-label>
                          <input matInput [disabled]="i===0 || i=== getFormRoutesList.controls.length-1" [matDatepicker]="Date" formControlName="Date" [min]="minDate" [max]="maxDate">
                          <mat-datepicker-toggle matSuffix [for]="Date"></mat-datepicker-toggle>
                          <mat-datepicker #Date color="primary"></mat-datepicker>
                          <mat-error *ngIf="route.controls.Date.errors?.required">
                            {{validationMessages.required}}
                          </mat-error>
                        </mat-form-field>
                      </div>
                      <div class="form-field-section ngx-timepicker" fxFlex="30%" fxFlex.sm="30%" fxFlex.xs="100%">
                        <!-- Route Time input field -->
                        <div class="time-field" appearance="outline">
                          <div fxLayout="row" fxLayout.sm="row" fxLayout.xs="row" fxLayoutAlign="start">
                            <mat-form-field class="min-time-example" appearance="outline" fxFlex="30%">
                              <mat-label>{{'HOUR' | translate }}</mat-label>
                              <input matInput formControlName="HourTime" [readonly]="i===0 || i===getFormRoutesList.controls.length-1" (change)="onChangeHour($event, i)">
                              <mat-error *ngIf="route.controls.HourTime.errors?.required">
                                {{validationMessages.required}}
                              </mat-error>
                              <mat-error *ngIf="route.controls.HourTime.errors?.invalid">
                                {{validationMessages.invalid}}
                              </mat-error>
                              <mat-error *ngIf="route.controls.HourTime.errors?.invalidHour">
                                {{translateService.instant("INVALIDHOUR")}}
                              </mat-error>
                            </mat-form-field>
                            <span class="splitter-coloun">:</span>
                            <mat-form-field class="min-time-example" appearance="outline" fxFlex="30%">
                              <mat-label>{{'MINUTE' | translate }}</mat-label>
                              <input matInput formControlName="MinuteTime" [readonly]="i===0 || i===getFormRoutesList.controls.length-1" (change)="onChangeMinute($event, i)">
                              <mat-error *ngIf="route.controls.MinuteTime.errors?.required">
                                {{validationMessages.required}}
                              </mat-error>
                              <mat-error *ngIf="route.controls.MinuteTime.errors?.invalid">
                                {{validationMessages.invalid}}
                              </mat-error>
                              <mat-error *ngIf="route.controls.MinuteTime.errors?.invalidMinute">
                                {{translateService.instant("INVALIDMINUTE")}}
                              </mat-error>
                            </mat-form-field>
                            <!--<ngx-timepicker-field
                              formControlName="Time"
                              [defaultTime]="defaultTime"
                              [format]="24"
                              (timeChanged)="timeChangedEvent($event, i, getFormRoutesList.controls)"></ngx-timepicker-field>-->
                            <!--<ng-template #icon>
                              <mat-icon>schedule</mat-icon>
                            </ng-template>-->
                          </div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="i%2!==0" class="full-width bottom-section" fxLayout="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutGap="0.5%" fxLayoutAlign="start start" fxLayoutAlign.xs="start center">
                      <div class="form-field-section" fxFlex="30%" fxFlex.sm="30%" fxFlex.xs="100%">
                        <!-- Route Driver input field, calculated and editable -->
                        <mat-form-field appearance="outline">
                          <mat-label>{{'DRIVER' | translate }}</mat-label>
                          <mat-select formControlName="Driver">
                            <mat-option *ngFor="let driver of driversList" [value]="driver.ID"> {{driver.Firstname}} {{driver.Lastname}}</mat-option>
                          </mat-select>
                          <!-- <mat-select [(value)]="selectedDriver" placeholder="Mode">
                            <mat-option *ngFor="let driver of driversList" [value]="driver.ID">Domain</mat-option>
                          </mat-select> -->
                        </mat-form-field>
                      </div>
                      <div class="form-field-section" fxFlex="30%" fxFlex.sm="30%" fxFlex.xs="100%">
                        <mat-form-field appearance="outline">
                          <mat-label>{{'TRIPTYPE' | translate }}</mat-label>
                          <mat-select class="routeTypeItem" matInput (selectionChange)="selectChanged($event, route, i)" formControlName="RouteTypeId">
                            <mat-option *ngFor="let routeTypeItem of routeTypesList" [value]="routeTypeItem.Id">
                              {{ routeTypeItem.Type }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="form-field-section" fxFlex="20%" fxFlex.sm="20%" fxFlex.xs="100%">
                        <mat-form-field appearance="outline">
                          <mat-label>{{'DRIVEDISTANCE' | translate }}</mat-label>
                          <input matInput placeholder="{{'DRIVENPLACEHOLDER' | translate}}" formControlName="DrivenDistance">
                        </mat-form-field>
                      </div>
                      <div class="form-field-section" fxFlex="20%" fxFlex.sm="20%" fxFlex.xs="100%">
                        <!-- Route Driven distance input field, calculated and editable -->
                        <mat-form-field appearance="outline">
                          <mat-label>{{'AVERAGESPEED' | translate }}</mat-label>
                          <input matInput placeholder="{{'AVERAGESPEEDPLACEHOLDER' | translate}}" formControlName="AverageSpeed">
                        </mat-form-field>
                      </div>
                    </div>
                    <div *ngIf="route.controls.RouteTypeId.value === 2" class="full-width bottom-section" fxLayout="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutGap="0.5%" fxLayoutAlign="start start" fxLayoutAlign.xs="start center">
                      <div class="form-field-section" fxFlex="40%" fxFlex.sm="40%" fxFlex.xs="100%">
                        <!-- Route: Business partner input field, calculated and editable -->
                        <mat-form-field appearance="outline">
                          <mat-label>{{'BUSINESSPARTNER' | translate }}</mat-label>
                          <input matInput placeholder="{{'BUSINESSPARTNERPLACEHOLDER' | translate}}" formControlName="BusinessPartnerName">
                        </mat-form-field>
                      </div>
                      <div class="form-field-section" fxFlex="30%" fxFlex.sm="30%" fxFlex.xs="100%">
                        <!-- Route: Reason of trip input field, calculated and editable -->
                        <mat-form-field appearance="outline">
                          <mat-label>{{'REASONOFTRIP' | translate }}</mat-label>
                          <input matInput placeholder="{{'REASONOFTRIPPLACEHOLDER' | translate}}" formControlName="ReasonOfTrip">
                        </mat-form-field>
                      </div>
                      <div class="form-field-section" fxFlex="30%" fxFlex.sm="30%" fxFlex.xs="100%"></div>
                    </div>
                  </div>
                </div>
              </div> <!-- End form RoutesList array --> 
            </form> <!-- Page form end -->
          </div>
          <br/>
          <div fxLayout="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="end end">
            <button mat-button matStepperPrevious>{{'BACK' | translate}}</button>
            <button mat-button [disabled]="!(separateTripForm.valid && formFieldValid)" matStepperNext (click)="secoundStepClick(getFormRoutesList.controls, stepper)">{{'NEXT' | translate}}</button>
          </div>
        </div>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>{{'SUMMARY' | translate}}</ng-template>
        <p>{{'REVIEWCONFIGUREDSEPARATEDTRIPS' | translate}}</p>
        <div [formGroup]="separateTripForm" class="address-section">
          <div [ngClass]="{'i%2===0': 'box-shadow'}" class="mat-card-section" formArrayName="RoutesList" *ngFor="let route of getFormRoutesList.controls;let i = index; first as isFirst">
            <div [formGroupName]="i">
              <!-- row divider show for every row to exclude if first row -->
              <mat-divider *ngIf="i%2===0 && getFormRoutesList.controls.length > 1 && i > 0" ></mat-divider><br>
              <!-- group name in this case row index -->
              <div class="main-section" fxLayout="column">
                <!-- Route Address input field -->
                <div fxLayout="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutGap="0.5%" fxLayoutAlign="start center">
                  <div class="form-field-section" fxFlex="40%" fxFlex.sm="100%" fxFlex.xs="100%">
                    <mat-label *ngIf="i%2!==0">{{'ENDADDRESS' | translate }}</mat-label>
                    <mat-label *ngIf="i%2==0">{{'STARTADDRESS' | translate }}</mat-label>
                    <p>{{route.controls.Address.value}}</p>
                  </div>
                  <div class="form-field-section" fxFlex="20%" fxFlex.sm="100%" fxFlex.xs="100%">
                    <mat-label>{{'DATE' | translate }}:</mat-label>
                    <p>{{moment(route.controls.Date.value).format('DD.MM.YYYY')}}</p>
                  </div>
                  <div class="form-field-section" fxFlex="15%" fxFlex.sm="100%" fxFlex.xs="100%">
                    <mat-label>{{'TIME' | translate }}:</mat-label>
                    <p [ngClass]="{'error-red': route.value.IsError}">{{route.controls.Time.value}}</p>
                  </div>
                  <div *ngIf="i%2!==0" class="form-field-section" fxFlex="20%" fxFlex.sm="100%" fxFlex.xs="100%">
                    <mat-label>{{'DRIVER' | translate }}:</mat-label>
                    <p>{{getDriversList(route.controls.Driver.value)}}</p>
                  </div>
                </div>
                <br/>
                <div fxLayout="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutGap="0.5%" fxLayoutAlign="start center">
                  <div *ngIf="i%2!==0" class="form-field-section" fxFlex="20%" fxFlex.sm="20%" fxFlex.xs="100%">
                    <mat-label>{{'TRIPTYPE' | translate }}:</mat-label>
                    <p>{{getTripType(route.controls.RouteTypeId.value)}}</p>
                  </div>
                  <div *ngIf="i%2!==0" class="form-field-section" fxFlex="20%" fxFlex.sm="20%" fxFlex.xs="100%">
                    <mat-label>{{'DRIVEDISTANCE' | translate }}:</mat-label>
                    <p>{{route.controls.DrivenDistance.value}}</p>
                  </div>
                  <div *ngIf="i%2!==0" class="form-field-section" fxFlex="20%" fxFlex.sm="20%" fxFlex.xs="100%">
                    <mat-label>{{'AVERAGESPEED' | translate }}</mat-label>
                    <p [ngClass]="{'error-red': isCheckExceededAverageSpeed(route.controls.AverageSpeed.value)}">{{route.controls.AverageSpeed.value}}</p>
                  </div>
                  <div *ngIf="route.controls.RouteTypeId.value === 2" class="form-field-section" fxFlex="20%" fxFlex.sm="20%" fxFlex.xs="100%">
                    <mat-label>{{'BUSINESSPARTNER' | translate }}:</mat-label>
                    <p>{{route.controls.BusinessPartnerName.value}}</p>
                  </div>
                  <div *ngIf="route.controls.RouteTypeId.value === 2" class="form-field-section" fxFlex="20%" fxFlex.sm="20%" fxFlex.xs="100%">
                    <mat-label>{{'REASONOFTRIP' | translate }}:</mat-label>
                    <p>{{route.controls.ReasonOfTrip.value}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br>
          <mat-card>
            {{'TOTALDRIVENDISTANCE' | translate}} {{totalDrivenDistanceSummary}} km
          </mat-card>
        </div>
        <div fxLayout="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="end end">
          <button mat-button matStepperPrevious>{{'BACK' | translate}}</button>
          <!-- <button mat-button (click)="resetStepper()">{{'RESET' | translate}}</button> -->
          <button mat-button [disabled]="isFormSubmitFieldDiasable" (click)="submitSummaryDetails()">{{'SUBMIT' | translate}}</button>
        </div>
      </mat-step>
    </responsive-stepper>
  </div>
  
  <!-- <div mat-dialog-actions align="end">
    <button mat-button (click)="onDismiss()">No</button>
    <button mat-raised-button color="primary" (click)="onConfirm()">Yes</button>
  </div> -->