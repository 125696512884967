import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddAdditionalDriverComponent } from './components/add-additional-driver/add-additional-driver.component';
import { AddAddressComponent } from './components/add-address/add-address.component';
import { BusinessPartnerDialogComponent } from './components/business-partner-dialog/business-partner-dialog.component';
import { EditBusinessPartnerDialogComponent } from './components/edit-business-partner-dialog/edit-business-partner-dialog.component';
import { EditContactBusinessPartnerDialogComponent } from './components/edit-contact-business-partner-dialog/edit-contact-business-partner-dialog.component';
import { ForwardMessagesComponent } from './components/forward-messages/forward-messages.component';
import { InspectionWarningComponent } from './components/inspection-warning/inspection-warning.component';
import { OtherMessagesComponent } from './components/other-messages/other-messages.component';
import { UpdateStandardDriverModalComponent } from './components/update-standard-driver-modal/update-standard-driver-modal.component';
import { SharedModule } from '../shared/shared.module';
import { VehicleInformationComponent } from './components/vehicle-information/vehicle-information.component';
import { InformationRoutingModule } from './information.routing';
import { BuildingsListComponent } from '../building-management/components/buildings-list/buildings-list.component';
import { CreateBuildingComponent } from '../building-management/components/create-building/create-building.component';
@NgModule({
  declarations: [
    AddAdditionalDriverComponent,
    AddAddressComponent,
    BusinessPartnerDialogComponent,
    EditBusinessPartnerDialogComponent,
    EditContactBusinessPartnerDialogComponent,
    ForwardMessagesComponent,
    InspectionWarningComponent,
    OtherMessagesComponent,
    UpdateStandardDriverModalComponent,
    VehicleInformationComponent,
    BuildingsListComponent,
    CreateBuildingComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    InformationRoutingModule
  ]
})
export class InformationModule { }
