import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { AbstractControl, FormArray, FormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { AppConstants } from '../../../app.constants';
import { RouteType } from '../../../dashboard/models/route/route-type.model';
import { StorageService } from '../storage/storage.service';
import { DriverService } from '../driver/driver.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  types: RouteType[];
  rightSideNavSubject: Subject<any> = new Subject<any>();
  rightSideNavSubject$ = this.rightSideNavSubject.asObservable();

  vehicleTripsRefreshed: Subject<any> = new Subject<any>();
  vehicleTripsRefreshed$ = this.vehicleTripsRefreshed.asObservable();

  vehicleInformationRefreshed: Subject<any> = new Subject<any>();
  vehicleInformationRefreshed$ = this.vehicleInformationRefreshed.asObservable();

  constructor(private storageService: StorageService,
    private translateService: TranslateService,
    private snackbar: MatSnackBar,
    private driverService: DriverService,
    private formBuilder: FormBuilder) { }

  setRightSideNavToggle(value: any) {
    if (value) {
      this.rightSideNavSubject.next(value);
    }
  }

  setVehicleTripsRefreshed(value: any) {
    if (value) {
      this.vehicleTripsRefreshed.next(value);
    }
  }

  setVehicleInformationRefreshed(value: any) {
    if (value) {
      this.vehicleInformationRefreshed.next(value);
    }
  }

  isBusinessTripType(value: string) {
    return (value === AppConstants.tripTypes.BUSINESS) ? true: false;
  }

  getTripType(value) {
    if (value === 1) {
      return AppConstants.tripTypes.PRIVATE;
    } else if (value === 2) {
      return AppConstants.tripTypes.BUSINESS;
    } else if (value === 3) {
      return AppConstants.tripTypes.COMMUTEHOME;
    } else if (value === 2) {
      return AppConstants.tripTypes.COMMUTEWORK;
    }
  }

  getRouteNameOrId(RouteType, option) {
    let result;
    this.types = this.storageService.get('routeTypesList');
    if (this.types && this.types.length > 0) {
      this.types.map((obj: RouteType) => {
        if (option === 'getName' && obj.Id.toString() === RouteType.toString()) {
          result = obj.Type; // obj.Types.replace(/\s/g, '');
        } else if (option === 'getId' && obj.Type === RouteType) {
          result = obj.Id;
        }
      });
    }
    return result;
  }

  findNearstCloseByPreviousRouteBasedOnDateTime(DateTimeField, routes) {
    let targetedStartDateTimeFromField = +DateTimeField;
    let nearestEndDateTime, nearestDiff = Infinity;
    let nearestRoute;
    if(routes && routes.length > 0) {
      for( var i = 0, n = routes.length;  i < n;  ++i ) {
        var diff = +(routes[i].EndDateTime) - targetedStartDateTimeFromField;
        if( diff > 0  &&  diff < nearestDiff ) {
          nearestDiff = diff;
          if ((i-1) >= 0 && routes[i-1]) {
            nearestEndDateTime =  routes[i-1] ? routes[i-1].EndDateTime: undefined;
            nearestRoute = routes[i-1];
          } else if (i === 0 && routes[i]) {
            nearestEndDateTime = routes[i] ? routes[i].EndDateTime: undefined;
            nearestRoute = routes[i];
          } else {
            nearestEndDateTime = undefined;
            nearestRoute = undefined;
          }
        }
      }
    }
    return nearestRoute;
  }

  convertStringToFloatNumber(value: string) {
    return parseFloat(value);
  }

  processFloatValuesToFixed(value) {
    let result;
    if(!value) {
      return undefined;
    }
    if (typeof value === 'string') {
      result = this.convertStringToFloatNumber(value);
    } else if (typeof value === 'number') {
      result = value;
    }
    return parseFloat(result.toFixed(2));
  }

  formatToYYYYMMDD(date: string) {
		return date.split('.')[2] + '-' + date.split('.')[1] + '-' + date.split('.')[0]
  }

  calculateDistance(origin, destination, formField, formArrayControl): Promise<any> {
    return new Promise((resolve) => {
      new google.maps.DistanceMatrixService().getDistanceMatrix({
        origins: [origin],
        destinations: [destination],
        travelMode: google.maps.TravelMode.DRIVING,
        unitSystem: google.maps.UnitSystem.IMPERIAL,
        avoidHighways: false,
        avoidTolls: false
      }, (response, status) => {
        resolve(this.processDistanceCallbackFunc(response, status, formField, formArrayControl));
      });
    });
  }

  processDistanceCallbackFunc(response, status, formField, formArrayControl) {
    if (status != google.maps.DistanceMatrixStatus.OK) {
      console.log(response);
    } else {
      var origin = response.originAddresses[0];
      var destination = response.destinationAddresses[0];
      if (response.rows[0].elements[0].status === "ZERO_RESULTS") {
        console.log("Better get on a plane. There are no roads between " + origin + " and " + destination);
      } else {
        const distance = response.rows[0].elements[0].distance;
        if (distance) {
          const distance_value = distance.value;
          const distance_text = distance.text;
          let miles = distance_text.substring(0, distance_text.length - 3);
          miles = parseInt(miles.replaceAll(/[a-zA-Z]+/g,'').trim(), 10);
          let distanceInKm = miles * 1.609;
          distanceInKm = parseFloat(distanceInKm.toFixed(2));
          // console.log("It is " + distanceInKm + " Km from " + origin + " to " + destination);
          formField.setValue(distanceInKm);
          if (!formArrayControl) {
            let drivenDistance = this.storageService.get('DrivenDistance') || [];
            drivenDistance.push(distanceInKm);
            this.storageService.set('DrivenDistance', drivenDistance);
          } else {
            this.processDrivenDistance(formArrayControl);
          }
        }
      }
    }
  }

  processDrivenDistance(getFormRoutesList) {
    let DrivenDistance = [];
    let RoutesListControlsLength = getFormRoutesList.controls.length;
    if (RoutesListControlsLength && RoutesListControlsLength !== 0) {
      for (let i=0; i < RoutesListControlsLength; i++) {
        if (i%2 !== 0) {
          let formControl: any = getFormRoutesList.controls[i];
          let DrivenDistanceValue = formControl.controls.DrivenDistance.value;
          DrivenDistance.push(DrivenDistanceValue);
        }
      }
      this.storageService.set('DrivenDistance', DrivenDistance);
    }
  }

  sendEmail(options) {
    let data = {
			Subject: options.Subject,
			Body: options.Body
		};
    this.driverService.sendEmail(data).subscribe((response: any) => {
      // console.log('data:', response);
      if (response.status === 200) {
        this.snackbar.open(this.translateService.instant('MESSAGESENTSUCCESFULLY'), this.translateService.instant('CLOSE'), AppConstants.SNACK_BAR_DELAY);
      }
    });
  }

  processYearInformation(YearInformation) {
    // console.log(YearInformation);
    let YearTableData = [];
    let headers = this.translateService.instant('YEARSUMMARYHEADER');
    let rowValues = [];
    if (YearInformation && YearInformation.MonthlyTripDetails) {
      YearInformation.MonthlyTripDetails.forEach(row => {
        // data.push(trip)
        rowValues = [];
        rowValues[0] = row.MonthName ? row.MonthName : '--';
        rowValues[1] = row.Business ? this.addKm(row.Business) : '--';
        rowValues[2] = row.CommuteHome ? this.addKm(row.CommuteHome) : '--';
        rowValues[3] = row.CommuteWork ? this.addKm(row.CommuteWork) : '--';
        rowValues[4] = row.Private ? this.addKm(row.Private) : '--';
        rowValues[5] = row.TotalDistance ? this.addKm(row.TotalDistance) : '--';
        YearTableData.push(rowValues);
      });
    }
    if (YearInformation && YearInformation.Summary) {
      let Summary = YearInformation.Summary;
      YearTableData.push([
        this.translateService.instant('SUMMARY'), // 'Summary',
        this.addKm(Summary.TotalBusiness),
        this.addKm(Summary.TotalCommuteHome),
        this.addKm(Summary.TotalCommuteWork),
        this.addKm(Summary.TotalPrivate),
        this.addKm(Summary.TotalDistance),
      ]);
      YearTableData.push([
        '',
        this.addPercentage(Summary.TotalBusinessPerPercentage),
        this.addPercentage(Summary.TotalCommuteHomePercentage),
        this.addPercentage(Summary.TotalCommuteWorkPercentage),
        this.addPercentage(Summary.TotalPrivatePercentage),
        this.addPercentage(Summary.TotalDistancePercentage),
      ]);
    }
    let result = {
      headers: headers,
      data: YearTableData
    }
    return result;
  }

  processDriversInformation(DriversInformation) {
    // console.log(DriversInformation);
    let DriversTableData = [];
    let headers = this.translateService.instant('DRIVERSUMMARYHEADER');
    let rowValues = [];
    if (DriversInformation && DriversInformation.TripDetails) {
      DriversInformation.TripDetails.forEach(row => {
        // data.push(trip)
        rowValues = [];
        rowValues[0] = row.DriverName ? row.DriverName : '--';
        rowValues[1] = row.Business ? this.addKm(row.Business) : '--';
        rowValues[2] = row.CommuteHome ? this.addKm(row.CommuteHome) : '--';
        rowValues[3] = row.CommuteWork ? this.addKm(row.CommuteWork) : '--';
        rowValues[4] = row.Private ? this.addKm(row.Private) : '--';
        rowValues[5] = row.TotalDistance ? this.addKm(row.TotalDistance) : '--';
        DriversTableData.push(rowValues);
      });
    }
    if (DriversInformation && DriversInformation.Summary) {
      let Summary = DriversInformation.Summary;
      DriversTableData.push([
        this.translateService.instant('SUMMARY'), // 'Summary',
        this.addKm(Summary.TotalBusiness),
        this.addKm(Summary.TotalCommuteHome),
        this.addKm(Summary.TotalCommuteWork),
        this.addKm(Summary.TotalPrivate),
        this.addKm(Summary.TotalDistance),
      ]);
      // DriversTableData.push([
      //   '',
      //   Summary.TotalBusinessPerPercentage,
      //   Summary.TotalCommuteHomePercentage,
      //   Summary.TotalCommuteWorkPercentage,
      //   Summary.TotalPrivatePercentage,
      //   Summary.TotalDistancePercentage,
      // ]);
    }
    let result = {
      headers: headers,
      data: DriversTableData
    }
    return result;
  }

  addKm(data) {
    return data + ' km';
  }

  addPercentage(data) {
    return data + ' %'
  }

  getDifferanceInDays(startDate, endDate) {
    const date1: any = new Date(startDate.toString());
    const date2: any = new Date(endDate.toString());
    const diffTime = Math.abs(date2 - date1) + 1;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    // console.log('DiffDays:', diffDays);
    return diffDays;
  }

  validateStartDate(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      let valid = true;
      let startDateValue;
      let currentDate;
      if (!control.value) {
        return null;
      }
      // console.log('startDateValue: ', control.value, typeof control.value);
      let userInfo = this.storageService.get('userInfo');
      if (typeof control.value === 'object' && (userInfo && userInfo.UserRole !== 'Admin')) {
        startDateValue =  control.value.toDate();
        currentDate = new Date();
        currentDate.setDate(currentDate.getDate() - 7);
        // console.log(startDateValue, currentDate);
        valid = currentDate.getTime() >= startDateValue.getTime() ? false: true;
      }
      return valid? null : { invalidDate: true };
    };  
  }

  validateNumberDecimal(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      let valid = true;
      if (!control.value) {
        return null;
      }
      // console.log('Number value: ', control.value, typeof control.value);
      if (control.value) {
        // number only and 2 decimal accepted
        valid = /^[0-9]+([\.,][0-9]{1,2})?$/.test(control.value)? true: false;
      }
      return valid? null : { invalidNumber: true };
    };
  }

  validateEmail(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      let valid = true;
      if (!control.value) {
        return null;
      }
      // console.log('Email value: ', control.value, typeof control.value);
      if (control.value) {
        // email accepted
        valid = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(control.value)? true: false;
      }
      return valid ? null: { invalidEmail: true };
    };
  }

  validateNumber(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      let valid = true;
      if (!control.value) {
        return null;
      }
      if (control.value) {
        // number only and 2 decimal accepted
        valid = /^[0-9]+?$/.test(control.value)? true: false;
      }
      return valid? null : { invalidNumber: true };
    }
  }

  validateThreeDigitsNumber(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      let valid = true;
      if (!control.value) {
        return null;
      }
      if (control.value) {
        // 3 digit number onlyaccepted
        valid = /^\d{3}$/.test(control.value)? true: false;
      }
      return valid? null : { invalidNumber: true };
    }
  }

  validateAlphabetNumericString(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      let valid = true;
      if (!control.value) {
        return null;
      }
      if (control.value) {
        // alphanumberic with space or _,- are accepted
        valid = /^[a-zA-Z0-9äüößáéíóúÄÜÖÁÉÍÓÚ´]+([-_\s]{1}[a-zA-Z0-9äüößáéíóúÄÜÖÁÉÍÓÚ´]+)*$/.test(control.value)? true: false;
      }
      return valid? null : { invalid: true };
    }
  }

  validateAlphabetString(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      let valid = true;
      if (!control.value) {
        return null;
      }
      if (control.value) {
        // number only and 2 decimal accepted
        valid = /^[a-zA-ZäüößáéíóúÄÜÖÁÉÍÓÚ´]*$/.test(control.value)? true: false;
      }
      return valid? null : { invalidAlphabets: true };
    }
  }

  validateTwoDigitIntegerString() {
    return (control: AbstractControl): { [key: string]: any } => {
      let valid = true;
      if (!control.value) {
        return null;
      }
      if (control.value) {
        // number only and 2 decimal accepted
        valid = /^\d{1,2}(\.\d{1,2})?$/.test(control.value)? true: false;
      }
      return valid? null : { invalid: true };
    }
  }

  validateTwoDigitIntegerHourString() {
    return (control: AbstractControl): { [key: string]: any } => {
      let valid = true;
      if (!control.value) {
        return null;
      }
      if (control.value) {
        // number only and 2 decimal accepted
        valid = control.value < 24 && control.value >= 0? true: false;
      }
      return valid? null : { invalidHour: true };
    }
  }

  validateTwoDigitIntegerMinuteString() {
    return (control: AbstractControl): { [key: string]: any } => {
      let valid = true;
      if (!control.value) {
        return null;
      }
      if (control.value) {
        // number only and 2 decimal accepted
        valid = control.value < 60 && control.value >= 0? true: false;
      }
      return valid? null : { invalidMinute: true };
    }
  }

  formateToGermanAddress(data) {
    let result;
    if (data) {
      if (data.name) {
        result = data.name;
      } 
      if (data.postalCode) {
        result = result + ',' + data.postalCode;
      } 
      if (data.locality && data.locality.long) {
        result = result + ',' + data.locality.long;
      }
      if (data.country && data.country.long) {
        result = result + ',' + data.country.long;
      }
    }
    return result;
  }

  getAverageSpeedByDistanceTime(DrivenDistance, resultTime) {
    return ((DrivenDistance * 60)/resultTime)/100;
  }

  getMinutesByDateTime(startTimeSplit, tripInNumberOfDays, endTimeSplit) {
    return (24 - startTimeSplit) + ((tripInNumberOfDays-2 <= 0 ? 0 : tripInNumberOfDays-2) * 24) + (endTimeSplit);
  }

  getFloatWithDotDecimalValue(value) {
    return value.indexOf(',') > -1 ? value.replaceAll(',', '.'): value;
  }

  removeLocalStorageValues() {
    let list = AppConstants.StorageValues;
    for (var i=0; i < list.length; i++) {
      localStorage.removeItem(list[i]);
    }
  }

  //check etension is CSV formate or not
  isValidCSVFile(file: any) {
    return file.name.endsWith(".csv") || file.name.endsWith(".CSV");
  }

  getAddressByLatLng(eventLatLng) {
    const getAddressByLatLngPromise = new Promise((resolve, reject) => {
      let geocoder = new google.maps.Geocoder();
      let latlng;
      if (eventLatLng.lat && eventLatLng.lng) {
        latlng = new google.maps.LatLng(eventLatLng.lat, eventLatLng.lng);
      } else if (eventLatLng.Latitude && eventLatLng.Longitude) {
        latlng = new google.maps.LatLng(eventLatLng.Latitude, eventLatLng.Longitude);
      }
      let request = {
        location: latlng
      };
      geocoder.geocode(request, (response, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          resolve({response, status});
        } else {
          reject({response, status});
        }
      });
    });
    return getAddressByLatLngPromise;
  }

  getLatLngByAddress(eventAddress) {
    const getLatLngByAddressPromise = new Promise((resolve, reject) => {
      let geocoder = new google.maps.Geocoder();
      let request = {
        'address' : eventAddress
      };
      setTimeout(() => {  
        geocoder.geocode(request, (response, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            // console.log(response[0].geometry.location);
            resolve({response, status});
          } else {
            reject({response, status});
          }
        });
      }, 1000);
    });
    return getLatLngByAddressPromise;
  }

  getCenterFormat(value) {
    return value.toJSON();
  }

  getBoundsFormat(value) {
    return value.toJSON();
  }

  isCreateAreaTabActive() {
		return this.storageService.get('geofenceCreateActiveTab') === 0;
	}

  getCircles(form): FormArray {
		return (<any>form.get('circles')) as FormArray;
	}

	getPolygons(form): FormArray {
		return (<any>form.get('polygons')) as FormArray;
	}

  addPolygon(data) {
    // console.log(data);
	  return this.createPolygonFormGroup({
			Name: (data.event && data.event.data && data.event.data.Name)? data.event.data.Name: (data.Name)? data.Name:'',
			Corners: (data.polygon && data.polygon.Corners && data.polygon.Corners.length > 0)? data.polygon.Corners: data.Corners? data.Corners: data.event.data.Corners,
      Id: data.event && data.event.data? data.event.data.ID: (data.ID)? data.ID: (data.event? data.event.id: '')
		});
  }

  addCircle(data) {
    if (data) {
      let center = data.circle? this.getCenterFormat(data.circle.getCenter()): data.Center;
      // console.log('center', center);
      return this.getAddressByLatLng(center).then((value: any) => {
        return this.createCircleFormGroup({
          Name: (data.event && data.event.data && data.event.data.Name)? data.event.data.Name: data.Name? data.Name: '',
          Center: center,
          Bounds: data.circle? this.getBoundsFormat(data.circle.getBounds()): {},
          Address: (data.event && data.event.data && data.event.data.Center)? data.event.data.Center.Address: data.Address? data.Address: value.response[0].formatted_address,
          Radius: data.circle? data.circle.getRadius(): data.Radius,
          Id: data.event && data.event.data? data.event.data.ID: (data.event? data.event.id: data.ID? data.ID: '')
        });
      });
    }
  }

  createCircleFormGroup(circle) {
		return this.formBuilder.group({
			name: [circle && circle.Name ? circle.Name: '', [Validators.required, this.validateAlphabetNumericString()]],
			center: [circle && circle.Center ? circle.Center: '', []],
			bounds: [circle && circle.Bounds ? circle.Bounds: '', []],
			radius: [circle && circle.Radius ? circle.Radius: '', [Validators.required]],
      address: [circle && circle.Address ? circle.Address: '', []],
      id: [circle && circle.Id ? circle.Id: '', []]
		});
	}

	createPolygonFormGroup(polygon) {
		return this.formBuilder.group({
			name: [polygon && polygon.Name? polygon.Name: '', [Validators.required, this.validateAlphabetNumericString()]],
      type: [polygon && polygon.Type? polygon.Type: null],
			corners: [polygon && polygon.Corners? polygon.Corners: '', []],
			cornerIds: [polygon && polygon.CornerIds? polygon.CornerIds: '', []],
			locationGroup: [polygon && polygon.LocationGroup? polygon.LocationGroup: '', []],
      id: [polygon && polygon.Id ? polygon.Id: '', []]
		});
	}

  deleteAllShapes(form) {
		// console.log(this.geoFenceFormGroup.get('circles').value);
		// console.log(this.geoFenceFormGroup.get('polygons').value);
		(<any>form.controls['circles']).clear();
		(<any>form.controls['polygons']).clear();
    	// this.deleteAllShapesEmit.emit(true);
	}

  getUpdatedPointList(path) {
		let pointList = [];
    if (path) {
      const len = path.getLength();
      for (let i = 0; i < len; i++) {
        pointList.push(path.getAt(i).toJSON());
      }
    }
    pointList.map((points) => {
      points.Latitude = points.lat;
      points.Longitude = points.lng;
      return points;
    })
		return pointList;
 	}

  isAdminUser(userInfo) {
    return userInfo && userInfo.UserRole === 'Admin';
  }

  isMobileOrTabletView() {
		return window.innerWidth <= 1024;
	}

  filterDataByName(list, value) {
    return list.filter((item) => {
      return item.Name.indexOf(value) > -1;
    });
  }

  filterDataByNameOrId(list, value) {
    return list.filter((item) => {
      return item.Id.toString().indexOf(value) > -1 || item.Name.indexOf(value) > -1;
    });
  }

  // write the csv file with Array data, columns and filename
  downloadFile(data, columns, filename = 'download') {
		let csvData = this.ConvertToCSV(data, columns);
		console.log(csvData);
		let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
		let downloadLink = document.createElement("a");
		let url = URL.createObjectURL(blob);
		let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
		if (isSafariBrowser) {
		  downloadLink.setAttribute("target", "_blank");
		}
		downloadLink.setAttribute("href", url);
		downloadLink.setAttribute("download", filename + ".csv");
		downloadLink.style.visibility = "hidden";
		document.body.appendChild(downloadLink);
		downloadLink.click();
		document.body.removeChild(downloadLink);
	}

	ConvertToCSV(objArray, headerList) {
		let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
		let str = '';
		let row = '';
	
		for (let index in headerList) {
		  row += headerList[index] + ';';
		}
		row = row.slice(0, -1);
		str += row + '\r\n';
		for (let i = 0; i < array.length; i++) {
		  let line = (i + 1) + '';
		  for (let index in headerList) {
        let head = headerList[index];
        if (array[i][head]) {
          line += ';' + array[i][head];
        }
		  }
		  str += line + '\r\n';
		}
		return str;
	}

  csvInputChange(event) {
    console.log(event);
    let files: FileList = event.target.files;
    if(files && files.length > 0) {
      let file: File = files.item(0); 
      console.log('file:', file);
      let reader: FileReader = new FileReader();
      reader.readAsText(file);
      reader.onload = (e) => {
        let csv: string = reader.result as string;
        console.log('csv:', csv);
      }
    }
  }

  async getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any, CSVColumn, separator) {
		let csvArr = [];
		for (let i = 1; i < csvRecordsArray.length; i++) {
		  let curruntRecord = separator ? (csvRecordsArray[i]).split(separator): (csvRecordsArray[i]).split(';');
		  if (curruntRecord.length === headerLength) {
			  let csvRecord = {};
        for (let i=0;i<CSVColumn.length; i++) {
          csvRecord[CSVColumn[i]] = curruntRecord[i]? curruntRecord[i].trim(): '';
        }
			  csvArr.push(csvRecord);
		  }
		}
		return csvArr;
	}

  convertCsvToJson(headers, columnsArrayData) {
    console.log(headers, columnsArrayData);
    let result = [];
    let obj = {};
    if (columnsArrayData && columnsArrayData.length > 0) {
      for (let j=0;j<columnsArrayData.length;j++) {
        if (headers && headers.length > 0) {
          for (let i=0;i<headers.length;i++) {
            obj[headers[i]] = columnsArrayData[j][headers[i]];
          }
        }
        result.push(obj);
      }
    }
    // console.log(result);
    return result;
  }

  downloadTemplate(filename = 'assets/LocationsTemplate') {
    let link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.href = filename + '.csv';
    link.download = 'Template';
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

	getHeaderArray(csvRecordsArr: any, separator) {
		let headers = (csvRecordsArr[0]).split(separator);
		let headerArray = [];
		for (let j = 0; j < headers.length; j++) {
		  headerArray.push(headers[j]);
		}
		return headerArray;
	}

  getLanguages() {
    let language = [];
    let EuropeCountries = AppConstants.Europe_Countries;
    if (EuropeCountries && EuropeCountries.length > 0) {
      EuropeCountries.forEach((country) => {
        language.push(country.code.toLowerCase());
      });
    }
    return language;
  }

  getCountryORStateORCityDetails(value: string, list) {
    return list.filter((country)=>country.Name === value);
  }

  getRadiusInKM (value) {
    return value/1000;
  }
}
