<button mat-icon-button class="close-button" [mat-dialog-close]="true">
    <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>
<h1 mat-dialog-title>
    <div fxLayout="row">
        <div fxFlex="80" fxFlex.sm="80" fxFlex.xs="100">{{title}}</div>
        <div fxFlex="20" fxFlex.sm="20" fxFlex.xs="100">
            <button class="add-button-icon" mat-icon-button color="primary" aria-label="Add Business partner">
                <mat-icon (click)="addNewBusinessPartner()">person_add_alt</mat-icon>
            </button>
        </div>
    </div>
</h1>
<div mat-dialog-content>
    <form [formGroup]="updateBusinessPartnerForm" class="example-form">
        <mat-accordion>
            <mat-expansion-panel *ngIf="!panelOpenState1" [expanded]="!panelOpenState1">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{ 'ADDNEWBUSINESSPARTNER' | translate}}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    <app-add-business-partner (addBusinessPartnerFormEventEmit)="addBusinessPartnerFormEventEmit($event)">
                    </app-add-business-partner>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel *ngIf="nearestBusinessPartners && nearestBusinessPartners.length > 0" [expanded]="panelOpenState1" (opened)="panelOpenState1 = true" (closed)="panelOpenState1 = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <p>{{ 'NEARESTBUSINESSPARTNERS' | translate}}:</p>
                    </mat-panel-title>
                    <mat-panel-description></mat-panel-description>
                </mat-expansion-panel-header>
                <div *ngIf="nearestBusinessPartners && nearestBusinessPartners.length > 0">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>{{'BUSINESSPARTNERNAME' | translate}}</mat-label>
                        <mat-select (selectionChange)="onSelection($event)" formControlName="SelectedBusinessPartnerId">
                            <mat-option *ngFor="let nearestBusinessPartner of nearestBusinessPartners" [value]="nearestBusinessPartner.ID">
                                {{nearestBusinessPartner.FirtName}} {{nearestBusinessPartner.LastName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div *ngIf="nearestBusinessPartners && nearestBusinessPartners.length === 0">
                    {{ 'NONEARESTBUSINESSPARTNERS' | translate}}
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <br/>
        <mat-form-field *ngIf="!data.isAddNewBusinessPartner" class="example-full-width" appearance="outline">
            <mat-label>{{ 'REASONOFTRIP' | translate}}</mat-label>
            <textarea matInput placeholder="{{'TRIPDETAILPLACEHOLDER' | translate}}" formControlName="AdditionalInformation" required></textarea>
        </mat-form-field>
    </form>
</div>

<div mat-dialog-actions align="end" *ngIf="!data.isAddNewBusinessPartner">
    <button mat-raised-button (click)="onDismiss()">{{ 'CANCEL'| translate}}</button>
    <button mat-raised-button color="primary" [disabled]="updateBusinessPartnerForm.invalid" (click)="onConfirm()">{{ 'CONFIRM'| translate}}</button>
</div>