<div class="mat-card-section">
    <h2>{{'BUILDINGLIST'| translate}}:</h2>
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <div>
            <button (click)="addNewBuilding()" mat-raised-button color="primary" aria-label="create new building">
                <mat-icon>add</mat-icon> {{'CREATENEWBUILDING'| translate}}
            </button>
        </div>
        <div>
            <mat-form-field fxLayout="row" fxLayoutAlign="end center">
                <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
            </mat-form-field>
        </div>
    </div>

    <div fxLayout="column">
        <table mat-table [dataSource]="buildingsDataSource" multiTemplateDataRows class="buildings-table mat-elevation-z8" matSort (matSortChange)="sortData($event)">
            <!-- BuildingID Column -->
            <ng-container matColumnDef="ID">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'ID' | translate}} </th>
                <td mat-cell *matCellDef="let element" data-label="ID"> {{element.BuildingId}} </td>
            </ng-container>

            <!-- Address Column -->
            <ng-container matColumnDef="Address">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'ADDRESS' | translate}} </th>
                <td mat-cell *matCellDef="let element" data-label="ADDRESS"> {{element.Address}} </td>
            </ng-container>

            <!-- NumberOfObjectUnits Column -->
            <ng-container matColumnDef="NumberOfObjectUnits">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'NumberOfObjectUnits' | translate}} </th>
                <td mat-cell *matCellDef="let element" data-label="NumberOfObjectUnits"> {{element.NumberOfApartments}} </td>
            </ng-container>

            <!-- BuiltOn Column -->
            <ng-container matColumnDef="BuiltOn">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'BuiltOn' | translate}} </th>
                <td mat-cell *matCellDef="let element" data-label="BuiltOn"> {{element.BuiltOn}} </td>
            </ng-container>

            <!-- Operations Column -->
            <ng-container matColumnDef="Operations">
                <th mat-header-cell *matHeaderCellDef> {{'OPERATIONS' | translate}} </th>
                <td mat-cell *matCellDef="let element" data-label="OPERATIONS">
                    <div class="action-section" fxLayout="row" fxLayout.sm="row" fxLayout.xs="row">
                        <button mat-icon-button color="primary" (click)="editBuildingClick(element)"><mat-icon>edit</mat-icon></button>
                        <button mat-icon-button color="primary" (click)="deleteBuildingClick(element)"><mat-icon>delete</mat-icon></button>
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
            <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
        </table>
        <mat-paginator #pagination1 [length]="totalRecords" [pageSize]="pageSize" [pageIndex]="pageIndex"></mat-paginator>
    </div>
</div>
