<mat-dialog-content>
    <h2 mat-dialog-title *ngIf="data.title">{{data.title}}</h2>
    <div fxLayout="column" [formGroup]="buildingForm">
        <mat-form-field appearance="outline" fxFlex="80">
            <mat-label>{{'STREET' | translate}}</mat-label>
            <input matInput formControlName="street">
            <mat-error *ngIf="buildingForm.controls['street'].hasError('required')">
                {{'FIELDREQUIRED' | translate}}
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="80">
            <mat-label>{{'NUMBER' | translate}}</mat-label>
            <input matInput formControlName="number">
            <mat-error *ngIf="buildingForm.controls['number'].hasError('required')">
                {{'FIELDREQUIRED' | translate}}
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="80">
            <mat-label>{{'POSTALCODE' | translate}}</mat-label>
            <input matInput formControlName="postalCode">
            <mat-error *ngIf="buildingForm.controls['postalCode'].hasError('required')">
                {{'FIELDREQUIRED' | translate}}
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="80">
            <mat-label>{{'CITY' | translate}}</mat-label>
            <input matInput formControlName="city">
            <mat-error *ngIf="buildingForm.controls['city'].hasError('required')">
                {{'FIELDREQUIRED' | translate}}
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="80">
            <mat-label>{{'FEDERALSTATE' | translate}}</mat-label>
            <input matInput formControlName="federalState">
            <mat-error *ngIf="buildingForm.controls['federalState'].hasError('required')">
                {{'FIELDREQUIRED' | translate}}
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="80">
            <mat-label>{{'NUMBEROFOBJECTUNITS' | translate}}</mat-label>
            <input matInput type="number" formControlName="numberOfObjectUnits">
            <mat-error *ngIf="buildingForm.controls['numberOfObjectUnits'].hasError('required')">
                {{'FIELDREQUIRED' | translate}}
            </mat-error>
        </mat-form-field>
        <mat-form-field class="date-form-field" appearance="outline" fxLayout="column" fxLayoutAlign="none">
            <mat-label>{{'BUILTON' | translate}}</mat-label>
            <input matInput [matDatepicker]="builtOn" formControlName="builtOn">
            <mat-datepicker-toggle matSuffix [for]="builtOn"></mat-datepicker-toggle>
            <mat-datepicker #builtOn color="primary"></mat-datepicker>
            <mat-error *ngIf="buildingForm.controls.builtOn.errors?.required">
                {{'FIELDREQUIRED' | translate}}
            </mat-error>
        </mat-form-field>
    </div>
</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end">
    <button mat-raised-button class="cancel" (click)="close(false)">{{'CANCEL' | translate}}</button>
    <button mat-raised-button [disabled]="buildingForm.invalid" class="save" color="primary" (click)="submit()">{{'SAVE' | translate}}</button>
</mat-dialog-actions>

