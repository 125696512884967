import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-contact-business-partner-dialog',
  templateUrl: './edit-contact-business-partner-dialog.component.html',
  styleUrls: ['./edit-contact-business-partner-dialog.component.scss']
})
export class EditContactBusinessPartnerDialogComponent implements OnInit {
  title: string;
  EditContactForm: FormGroup;
  constructor(public dialogRef: MatDialogRef<EditContactBusinessPartnerDialogComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.title = this.data.title;
  }

  ngOnInit(): void {
    if (this.data) {
      this.EditContactForm = this.formBuilder.group({
        Id: [this.data.contact? this.data.contact.Id: '', Validators.required],
        Firstname: [this.data.contact? this.data.contact.Firstname: '', Validators.required],
        Surname: [this.data.contact? this.data.contact.Surname: '', Validators.required],
        Company: [this.data.contact? this.data.contact.Company:'', Validators.required],
        Street:  [this.data.contact? this.data.contact.Street:'', Validators.required],
        City:  [this.data.contact? this.data.contact.City:'', Validators.required],
        Zipcode: [this.data.contact? this.data.contact.Zipcode: '', Validators.required],
        Country: [this.data.contact? this.data.contact.Country:'', Validators.required],
      });
    }
  }

  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close({
      isDialogClose: true,
      data: this.EditContactForm.value
    });
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }

}
